<section class="subpage-content">
    <header class="app-page-header">
        <h2><fa-icon [icon]="faSettingsIcon"></fa-icon>User Profile</h2>
    </header>

    <article>
        <app-tabs-list>

            <app-tabs-shared></app-tabs-shared>

            <app-tab-item [label]="'Contact'">
                <app-tab-label>Contact</app-tab-label>
                <app-tab-body>

                    <ul class="inline-edit-fields-list" *ngIf="!!user">
                        <li>
                            <label for="">Username</label>
                            <span class="value">{{user?.username}}</span>
                        </li>
                        <li>
                            <label for="">Email</label>
                            <span class="value">{{user?.email}}</span>
                        </li>
                        <li>
                            <label [for]="fieldSettings.firstName[0].id">First Name</label>
                            <lib-inline-edit
                                [settings]="fieldSettings.firstName"
                                [data]="user?.firstName"
                                [fieldName]="'fullName'"
                                (focusOut)="saveValue($event, 'firstName')"
                                (changeData)="saveValue($event, 'firstName')"
                                [showEditable]="userManager.userInfoEditablePreview">
                            </lib-inline-edit>
                        </li>
                        <li>
                            <label [for]="fieldSettings.lastName[0].id">Last Name</label>
                            <lib-inline-edit
                                [settings]="fieldSettings.lastName"
                                [data]="user?.lastName"
                                [fieldName]="'lastName'"
                                (focusOut)="saveValue($event, 'lastName')"
                                (changeData)="saveValue($event, 'lastName')"
                                [showEditable]="userManager.userInfoEditablePreview">
                            </lib-inline-edit>
                        </li>
                        <li>
                            <label [for]="fieldSettings.phone[0].id">Phone</label>
                            <lib-inline-edit
                                [settings]="fieldSettings.phone"
                                [data]='user?.jsonPhone'
                                [fieldName]="'phone'"
                                (focusOut)="saveValue($event, 'phone')"
                                (changeData)="saveValue($event, 'phone')"
                                [showEditable]="userManager.userInfoEditablePreview">
                            </lib-inline-edit>
                        </li>

                        <li>
                          <label [for]="fieldSettings.jobTitle[0].id">Job Title</label>
                          <lib-inline-edit
                              [settings]="fieldSettings.jobTitle"
                              [data]='user?.jobTitle'
                              [fieldName]="'jobTitle'"
                              (focusOut)="saveValue($event, 'jobTitle')"
                              (changeData)="saveValue($event, 'jobTitle')"
                              [showEditable]="userManager.userInfoEditablePreview">
                          </lib-inline-edit>
                        </li>
                    </ul>

                </app-tab-body>
            </app-tab-item>

            <app-tab-item [label]="'Company'">
                <app-tab-label>Company</app-tab-label>
                <app-tab-body>

                    <ul class="inline-edit-fields-list" *ngIf="user">
                        <li *ngIf="userService.userPermissions['canChangeCompanyName']">
                            <label [for]="fieldSettings.company[0].id">Company</label>
                            <lib-inline-edit
                                [settings]="fieldSettings.company"
                                [data]="user?.company.name"
                                [fieldName]="'company'"
                                (focusOut)="saveCompanyValue($event, 'name')"
                                (changeData)="saveCompanyValue($event, 'name')"
                                [showEditable]="userManager.userInfoEditablePreview">
                            </lib-inline-edit>
                        </li>
                        <li *ngIf="!userService.userPermissions['canChangeCompanyName']">
                          <label for="">Company</label>
                          <span class="value">{{user?.company.name}}</span>
                        </li>

                        <li *ngIf="userService.userPermissions['canChangeCompanyName']">
                          <!-- <lib-select-form-field
                            [addValueUseCustomFunction]="true"
                            (dropdownChanged)="usesUpdated($event.value)"
                            (valueAdded)="addCustomUsesNumber($event)"
                            [noBottomMargin]="true"
                            [formGroupParameter]="quoteForm"
                            [fieldSettings]="fieldSettings.industry">
                        </lib-select-form-field> -->


                          <!-- <label [for]="fieldSettings.jobTitle[0].id">Job Title</label>
                          <lib-inline-edit
                              [settings]="fieldSettings.industry"
                              [fieldName]="'industry'"
                              (focusOut)="saveValue($event, 'industry')"
                              (changeData)="saveValue($event, 'industry')"
                              [showEditable]="userManager.userInfoEditablePreview">
                          </lib-inline-edit> -->

                          <label>Industry</label>
                          <form  id="industryForm" [bottomOffset]="0" novalidate  [formGroup]="industryForm">



                            <lib-form-field [noLabel]="true" [value]="industryForm.value['industry']"   (dropdownChanged)="saveCompanyValue(industryForm.value['industry'],'industry')" [inlineLayout]="false" [noBottomMargin]="true" [formGroupParameter]="industryForm" [fieldSettings]="industryFormSettings['industry']"></lib-form-field>

                            <!-- <lib-select-form-field [inlineLayout]="true" (dropdownChanged)="setLicenseDates()" [noBottomMargin]="true" [formGroupParameter]="industryForm" [fieldSettings]="industryFormSettings['industry']"></lib-select-form-field> -->

                          </form>
                        </li>
                        <li *ngIf="!userService.userPermissions['canChangeCompanyName']">
                          <label>Industry</label>
                          <span class="value" [innerHTML]="user?.industry"></span>
                        </li>

                        <li *ngIf="companyOwner" class="baseline">
                          <label>Company Owner</label>
                          <span class="value">{{companyOwner.fullName}}
                            <span class="email">
                              <a class="mailto-icon" href="mailto:{{companyOwner.email}}" title="Send email">{{companyOwner.email}}</a>
                              <lib-clipboard-copy [text]="''" [value]="companyOwner.email" [message]="'The EMAIL ADDRESS was copied to clipboard.'"></lib-clipboard-copy>
                            </span>
                          </span>
                        </li>

                        <li *ngIf="companyAdministrators && companyAdministrators.length > 0" class="baseline">
                          <label *ngIf="companyAdministrators.length === 1">Company Administrator</label>
                          <label *ngIf="companyAdministrators.length > 1">Company Administrators</label>
                          <span class="value">
                            <ul>
                              <li *ngFor="let a of companyAdministrators">
                                {{a.fullName}}
                                <span class="email">
                                  <a class="mailto-icon" href="mailto:{{a.email}}" title="Send email">{{a.email}}</a>
                                  <lib-clipboard-copy [text]="''" [value]="a.email" [message]="'The EMAIL ADDRESS was copied to clipboard.'"></lib-clipboard-copy>
                                </span>

                              </li>
                            </ul>
                          </span>
                        </li>

                    </ul>

                </app-tab-body>
            </app-tab-item>

        </app-tabs-list>
    </article>

</section>
