<div class="left-sidebar-container" [class.narrow-side-menu]="!uiService.sideMenuWide">
    <div class="collapse-button" #sidebarToggleButton (click)="uiService.toggleSideMenu()">
        <a>&#xab;</a>
    </div>
    <div class="left-sidebar" #leftSidebar>

        <div class="sidebar-logo">
            <a [routerLink]="'/'" class="logo tm-logo-long">
                <!-- <img class="long" src="assets/images/NineGoldLogo.svg" alt="logo"> -->
                <img class="long" src="/assets/images/smartcode-logo-white-no-circle.svg" alt="logo">
                <!-- <img class="long" src="/assets/images/NineGoldLogo_noCircle.svg" alt="logo"> -->
                <img src="/assets/images/circleA.svg" class="spinning" alt="logo">

                <!-- <img class="short" src="/assets/images/circleA.svg" alt="logo"> -->
            </a>
        </div>

        <div class="left-sidebar-content">

            <div *ngIf="userInfo" class="sidebar-section user-info">
                <p class="short">
                    {{userInfo.initials}}
                </p>
                <p class="long">
                    {{userInfo.fullName}}
                </p>
                <user-role-icon *ngIf="userInfo" class="short" [isGlobalAdmin]="(userInfo.roles.includes('administrator') ? true : false)" [userRole]="userRoleIcon"></user-role-icon>
            </div>

            <!-- <div class="sidebar-section">


                <div *ngIf="userInfo" class="user-role" [title]="userService.getHighestRole().text" [ngClass]="userService.getHighestRole().class">
                    <fa-icon *ngIf="!userService.getHighestRole().smallIcon" [icon]="userService.getHighestRole().icon"></fa-icon>
                    <span class="icon-set" *ngIf="userService.getHighestRole().smallIcon" [ngClass]="userService.getHighestRole().class">
                        <fa-icon class="main-icon" [icon]="userService.getHighestRole().icon"></fa-icon>
                        <fa-icon class="small-icon" [icon]="userService.getHighestRole().smallIcon"></fa-icon>
                    </span>
                </div>

                <ul class="user-roles">
                    <li *ngFor="let role of userService.activeUserRoles" [title]="role.text" [ngClass]="role.class">
                        <fa-icon *ngIf="!role.smallIcon" [icon]="role.icon"></fa-icon>
                        <span class="icon-set" *ngIf="role.smallIcon" [ngClass]="role.class">
                            <fa-icon class="main-icon" [icon]="role.icon"></fa-icon>
                            <fa-icon class="small-icon" [icon]="role.smallIcon"></fa-icon>
                        </span>
                    </li>
                </ul>
            </div> -->


            <div class="sidebar-section sidebar-nav-section">
                <nav role="navigation" class="app-sidebar-navigatipon">
                    <ul *ngIf="userService.userNav">
                        <li *ngFor="let navItem of userService.userNav">
                            <a [ngClass]="navItem.customClass" [routerLink]="navItem.link" routerLinkActive="active">
                                <fa-icon [icon]="navIcons[navItem.name]?.icon"></fa-icon>
                                <span class="text">{{navItem.text}}</span>
                            </a>
                        </li>
                        <li>
                          <a class="support-link" [routerLink]="['',{outlets: {sidePopup: 'supportrequest'}}]">
                            <fa-icon [icon]="supportIcon"></fa-icon>
                            <span class="text">Support Request</span>
                          </a>
                        </li>

                    </ul>
                    <!-- <ul>
                        <li>Dashboard</li>
                        <li>Companies</li>
                        <li>Company A</li>
                    </ul> -->
                </nav>
            </div>

            <div *ngIf="router.url === '/user/info'" [@enterAnimation]>
                <app-user-edit-sidebar></app-user-edit-sidebar>
            </div>
            <div *ngIf="router.url === '/user/subusers'" [@enterAnimation]>
                <app-subusers-sidebar></app-subusers-sidebar>
            </div>
            <div *ngIf="router.url === '/user/super-admin'" [@enterAnimation]>
                <app-super-admin-sidebar></app-super-admin-sidebar>
            </div>

        </div>

        <div class="space-filler"></div>

        <div class="sidebar-section bottom-icons">
            <!-- ToDo: move bottom icons section to component, clean up, add links and reuse in user and app layout  -->
            <ul>
                <li>
                    <div dropdown  [autoClose]="true" [dropup]="true" placement="top" triggers="hover">
                        <a
                            type="button"
                            dropdownToggle
                            class="info-links-button"
                            title="Get more info">
                            <img width="32" src="/assets/images/infoIcon.png" alt="Info">
                        </a>

                        <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu"
                        role="menu" aria-labelledby="button-animated">
                            <li role="menuitem" *ngFor="let link of infoNavLinks">
                                <a *ngIf="link.outerLink"
                                    [title]="link.altText"
                                    [href]="link.link"
                                    target="_blank"
                                    class="dropdown-item outer-link">
                                    {{link.text}}
                                </a>
                                <a *ngIf="!link.outerLink"
                                    [title]="link.altText"
                                    [routerLink]="[link.link]" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                    class="dropdown-item">
                                    {{link.text}}
                                </a>
                            </li>

                        </ul>
                </div>
                </li>

                <li>
                    <a (click)="Logout()" title="Logout" class="text-button logout-button"
                        tooltip="Click to Logout" animation-duration="500" tooltip-class="input-tooltip" hide-delay="500" autoPlacement="false" hideDelayAfterClick="1000" trigger="hover" placement="top" max-width="300">
                        <img src="/assets/images/powerOff2.png" alt="Logout">
                    </a>
                </li>
            </ul>

        </div>

    </div>
    <div class="subpage" [@fadeInAnimation]="appOutlet.isActivated ? appOutlet.activatedRoute : ''">
        <router-outlet #appOutlet="outlet"></router-outlet>
    </div>
</div>
