import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService, ModalDirective } from "ngx-bootstrap/modal";
import { AuthService } from "../../services/auth.service";
import { FormResultMessageFormat } from "../../shared/notification/notification";
import { NotificationService } from "../../shared/notification/notification.service";

@Component({
    selector: 'app-password-change-callback',
//     template: `<div class="spinner-container black">
//     <div class="loading-spinner"></div>
//   </div>`,
  templateUrl: './password-change-callback.component.html'
})

export class PasswordChangeCallbackComponent implements AfterViewInit {
    @ViewChild('modal', { static: false }) autoShownModal: TemplateRef<any>;
    modalRef: BsModalRef;

    constructor(
        private modalService: BsModalService,
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private notif: NotificationService
    ) {
    }

    ngAfterViewInit():void {
        function parseParms(str) {
            var pieces = str.split("&"), data = {}, i, parts;
            for (i = 0; i < pieces.length; i++) {
                parts = pieces[i].split("=");
                if (parts.length < 2) {
                    parts.push("");
                }
                data[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
            }
            return data;
        }

        this.activatedRoute.fragment.subscribe((fragment: string) => {
            const response = parseParms(fragment);
            if(response['kc_action_status'] === 'success') {
                this.modalRef = this.modalService.show(this.autoShownModal, {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true });
            } else {
                this.notif.warning("Action Cancelled", "Action of changing password was cancelled by the user.", FormResultMessageFormat.popup, 3000, null);
                this.router.navigate(['/user/security'], { replaceUrl: true });
            }
        })
    }

    Login() {
        this.authService.login('/user/security');
    }
}