<div class="content-wrapper">
  <article class="page-content">
    <header class="our-strategy-banner" role="banner">
      <div class="banner-inner">
        <h1 wmAnimate="landing" speed="normal" delay="500ms">Migration Risk<br>Assessment</h1>
      </div>
    </header>
    <main>
      <section class="blue-bg-summary">
        <div class="section-inner">
          <p>Don’t let replacement costs break your budget.  Smart Code can perform a risk assessment of your current state architecture and provide a strategy to reduce your bottom line without operational disruption.</p>
          <ul>
            <li>Evaluate existing iPaaS environment</li>
            <li>Risk Identification</li>
            <li>Risk Mitigation Strategy</li>
            <li>Migration Plan</li>
            <li>Risk Reviews</li>
            <li>Post Migration Support</li>
          </ul>
        </div>

      </section>


      <section class="more-bottom-margin">
        <div class="section-inner">
          <div class="transparetn-block-columns">
            <div class="transparent-block flipped-bg  style-2-left" once="true" speed="fast" wmAnimate="fadeInUp" delay="250ms" aos="0.25">
              <div class="transparent-block-text single-block">
                <img src="/assets/images/blue-arrow-right.png" class="arrow-image" alt="iPaaS to Client Systems diagram">
                <h2>Get Our Architectural Review</h2>
                <p>We help you determine the what services can be moved off the iPaaS and onto an open-source alternative.</p>
                <a role="button" href="https://calendly.com/diamondedgeit/30min" target="_blank" class="text-button dark-bg bigger external-link" rel="noopener noreferrer">Talk to us today</a>
              </div>
            </div>
            <div class="transparent-block   style-1-right" once="true" speed="fast" wmAnimate="fadeInUp" delay="250ms" aos="0.25">
              <div class="transparent-block-text space-evenly">
                <h3><strong>Smart Code</strong> works with You to do a Risk Assessment to Help You Migrate off the iPaaS.</h3>
                <div style="display: flex; justify-content: center; gap: 20px;">
                  <a role="button" href="https://calendly.com/diamondedgeit/30min" target="_blank" class="secondary no-bg" rel="noopener noreferrer">Book a Meeting</a>
                </div>
              </div>
            </div>
          </div>
        </div>


      </section>

    </main>
  </article>
</div>
