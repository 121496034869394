import { Inject, Injectable } from '@angular/core';
import { intervalToDuration } from 'date-fns';
import { formatDuration } from 'date-fns/fp';
import { ApiDeploymentType, ApiLicenseExtended, ApiLicenseStatus, ApiLicenseType, LicensePeriod } from 'projects/nine-gold-lib/src/lib/models/product';
import { ListsService } from 'projects/nine-gold-lib/src/lib/services/lists.service';
import { ButtonTypes, columnFilterTypes, ColumnTypes, NgListDataRow, sortingOrder } from 'projects/nine-gold-lib/src/lib/shared/listComponents/ng-list/ng-list-data';

type listApiDataType = ApiLicenseExtended;

@Injectable({
  providedIn: 'root'
})
export class NewLicenseListService extends ListsService {
  public inputStatus: ApiLicenseStatus[];
  @Inject('status') status: string;

// constructor() { }

// =============================================================================
// for all: id,productName,productId (hidden),period (string), notes (to show from actions), license Type (trial, project, enterprise, free for testing), total uses, uses left, depllyment type, isTrial, PO#, invoice#
// for active only: startDate,endDate (if ends soon add warning sign and popup), licenseUrl, licenseFilename
// for expired only: endDate (when it ended - error icon click show how long time ago it ended), startDate - hidden, license Type, licenseUrl & licenseFilename (only in details popup)
// =============================================================================

  setInitialColumns(): void {
    this.columns = [
      {
        columnName: 'id',
        columnText: '',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,
        columnOrder: null,
        rowIdentifier: true,
        notVisible: true
      },
      {
        columnName: 'productId',
        columnText: '',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,
        columnOrder: null,
        notVisible: true,
      },
      {
        columnName: 'productName',
        columnText: 'Product Name',
        columnType: ColumnTypes.text,
        sortable: true,
        filters: null,
        columnOrder: 0,
        canShowHide: false,
        sortColumnName: 'productName',
        customMapping: ['productCategory','name'],
        clickForInfo: false
      },
      {
        columnName: 'licenseType',
        columnText: 'Type',
        customMapping: ['licenseParams','type'],
        columnType: ColumnTypes.text,
        sortable: true,
        sortColumnName: 'licenseType',
        // filters: null, //TODO: add filters for all types: FREE, TRIAL,PROJECT,ENTERPRISE
        columnOrder: 1,
        canShowHide: true,
        columnClasses: 'type-container',
        displayFunction: 'setLicenseTypeText',
        filters: {
          optionSelected: null,
          filterName: 'type',
          filterPlaceholder: 'Choose type',
          filterType: columnFilterTypes.options,
          filterId: 'type',
          filterOptions: [
            {value: 'FreeLicense', text: 'Free'},
            {value: 'ProjectLicense', text: 'Project'},
            {value: 'EnterpriseLicense', text: 'Enterprise'},
            {value: 'UsageLicense', text: 'Usage'},
            {value: 'BetaTestingLicense', text: 'Beta Testing'},
            {value: 'TrialLicense', text: 'Trial'},
          ]
        }
      },
      {
        columnName: 'licensePeriod',
        columnText: 'Length',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,
        columnOrder: 2,
        canShowHide: true,
        tdHtml: true,
        displayFunction: 'setLicenseLengthText'
      },
      {
        columnName: 'remainingTime',
        columnText: 'Remaining',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,
        columnOrder: 3,
        tdHtml: true,
        canShowHide: true,
        // displayFunction: 'setLicenseRemainingText'
      },
      {
        columnName: 'uses',
        columnText: 'Used Uses',
        columnType: ColumnTypes.text,
        columnOrder: null,
        columnClasses: '',
        sortable: true,
        filters: null,
        canShowHide: false,
        notVisible: true,
        // displayNotAppplicable: 'null / null'
      },
      {
        columnName: 'totalUses',
        columnText: 'Total Uses',
        customMapping: ['licenseParams','maxUses'],
        columnType: ColumnTypes.text,
        columnOrder: null,
        sortable: false,
        filters: null,
        canShowHide: false,
        notVisible: true,
        displayFunction: 'setTotalUses'
        // displayNotAppplicable: 'N/A'
      },
      {
        columnName: 'usesOfTotal',
        // columnText: 'Uses<div class="flex-column-horizontal">Used<span class="th-divider"></span>Total</div>',
        columnText: 'Uses',
        sortColumnText: 'Uses',
        columnType: ColumnTypes.text,
        columnOrder: 4,
        sortable: false,
        // columnClasses: 'two-row-heading',
        filters: null,
        canShowHide: true,
        notVisible: true,
        displayFunction: 'setUsesOfTotal',
        thHtml: true,
        tdHtml: true
      },
      {
        columnName: 'onPremExecutionId',
        columnText: '',
        columnType: ColumnTypes.text,
        customMapping: ['licenseParams','onPremExecutionId'],
        sortable: false,
        filters: null,
        notVisible: true
      },
      {
        columnName: 'deploymentType',
        columnText: 'Deployment',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,  //TODO add filtering with OnPrem or Cloud
        columnOrder: 5,
        canShowHide: true,
        notVisible: true,
        columnClasses: 'type-container'
      },
      {
        columnName: 'start',
        columnText: 'Start Date',
        columnType: ColumnTypes.date,
        sortable: true,
        filters: null,
        columnOrder: 6,
        sortColumnName: 'start',
        notVisible: true,
        canShowHide: true
      },
      {
        columnName: 'end',
        columnText: 'End Date',
        columnType: ColumnTypes.date,
        sortable: true,
        filters: null,
        columnOrder: 7,
        sortColumnName: 'end',
        tdHtml: true,
        canShowHide: true,
        displayFunction: 'freeNoEndDate'
      },
      {
        columnName: 'purchaseOrder',
        columnText: 'PO number',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,
        columnOrder: 8,
        canShowHide: true,
        notVisible: true,
      },
      {
        columnName: 'invoiceNumber',
        columnText: 'Invoice number',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,
        columnOrder: 9,
        canShowHide: true,
        notVisible: true,
      },
      {
        columnName: 'status',
        columnText: 'Status',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,
        columnOrder: 10,
        canShowHide: true,
        notVisible: true,
        // displayFunction: 'setStatusText'
      },
      {
        columnName: 'actions',
        columnText: '',
        columnType: ColumnTypes.info,
        sortable: false,
        filters: null
      },
      {
        columnName: 'actions',
        columnText: 'Actions',
        columnType: ColumnTypes.buttons,
        sortable: false,
        filters: null,
        buttons: [
          // {
          //   buttonText: 'Info',
          //   type: ButtonTypes.info,
          //   value: ''
          // },
          {
            buttonText: 'Get Quote',
            type: ButtonTypes.quote,
            value: '',
            showButtonFunction: 'canQuote'
          },
          {
            buttonText: 'Renew License',
            type: ButtonTypes.renew,
            value: '',
            showButtonFunction: 'canRenew'
          }
        ]
      }
    ];


    // if(this.inputStatus.includes(ApiLicenseStatus.expired)) {
    //   this.columns = this.columns.filter(col => col.columnName !== 'remaining');
    // }


  }

  canQuote(row: listApiDataType): boolean {
    return !!row.canQuote;
  }

  canRenew(row: listApiDataType): boolean {
    return row.status === "expired" && [ApiLicenseType.ProjectLicense,ApiLicenseType.EnterpriseLicense].includes(row.licenseParams.type);
  }


  setUsesOfTotal(value: any): string {
    // return `<div class="uses-cell">${value.used} <span class="td-divider"></span> ${(value.total > -1 ? value.total : '<span title="Not Applicable" class="not-applicable">N/A</span>')}</div>`;

    if(value?.total > -1) {
      return `<div class="uses-cell" title="Used: ${value.used}; Available: ${value.total - value.used}; Total: ${value.total}">${value.used} / ${value.total}</div>`;
    } else {
      return `<div class="uses-cell"><span title="Not Applicable" class="not-applicable">N/A</span></div>`;
    }
    // return `<div class="uses-cell">${value.used} / ${(value.total > -1 ? value.total : '<span title="Not Applicable" class="not-applicable">N/A</span>')}</div>`;
  }

  setStatusText(value: boolean): string {
    return (value ? 'Valid' : 'Expired');
    // TODO: Add check for date about to expire
    // TODO: Add value based on uses (used or 10% remaining)
    // TODO: Check for license Type (if free, 'free valid'; if trial, 'valid trial')
  }

  setTotalUses(value: number): string | number {
    if(value === -1) {
      return '<span title="Not Applicable" class="not-applicable">N/A</span>';
    } else {
      return value;
    }
  }

  updateListParams(): void {
    this.listParameters = {
      numberOfPages: 1,
      currentPage: 1,
      itemsPerPage: 10,
      filters: [],
      sorting: {
        field: "productName",
        order: sortingOrder.asc
      }
    }
  }

  setApiName(): void {
    this.listApiName = 'user';
  }

  setSortSettings(): void {
    this.uiSortFilterPage = true;
  }

  private setLicenseLengthText(period: Duration): string {
    // return 'TBD';
    if(period !== null && period !== undefined) return formatDuration(period);
    else return '<span class="not-applicable" title="Not Applicable">N/A</span>';
    // return `${licenseLengthJson.value} ${licenseLengthJson.type}${(licenseLengthJson.value>1?'s':'')}`;
  }

  // private setLicenseRemainingText(period: Duration): string {
  //   if (period) return formatDuration(period);
  //     else return 'N/A';
  // }

  private setLicenseTypeText(value: string): string {
    return this.productService.getTypeItemNew(value)?.text || 'NA';
  }

  freeNoEndDate(value: string): string {
    return (value === 'N/A' ? '<span class="not-applicable" title="Not Applicable">N/A</span>' : value);
  }

  private setColumnVisibility(colName: string, notVisible: boolean, canShowHide: boolean): void {
    let col = this.columns.find(c => c.columnName === colName);
      if(col) {
        col.notVisible = notVisible;
        col.canShowHide = canShowHide;
      }
  }

  private updateLicenseTypeRelatedColumns(data: listApiDataType[]): void {
    let outputData: listApiDataType[] = [];
    if(data.find(l => [ApiLicenseType.UsageLicense, ApiLicenseType.TrialLicense].includes(l.licenseParams.type) )) {
      this.setColumnVisibility('usesOfTotal', false, true);
    } else {
      this.setColumnVisibility('usesOfTotal', true, false);
    }
    if(data.find(l => [ApiLicenseType.ProjectLicense, ApiLicenseType.TrialLicense, ApiLicenseType.EnterpriseLicense].includes(l.licenseParams.type) )) {
      this.setColumnVisibility('remainingTime', false, true);
    } else {
      this.setColumnVisibility('remainingTime', true, false);
      this.setColumnVisibility('licensePeriod', true, false);
      this.setColumnVisibility('end', true, false);
    }
    if(!data.find(l => [ApiLicenseType.ProjectLicense, ApiLicenseType.UsageLicense, ApiLicenseType.EnterpriseLicense].includes(l.licenseParams.type))) {
      this.setColumnVisibility('purchaseOrder', true, false);
      this.setColumnVisibility('invoiceNumber', true, false);
      this.setColumnVisibility('deploymentType', true, false);
    }
  }

  private setLicensesFromData(data: listApiDataType[]): void {
    this.updateLicenseTypeRelatedColumns(data);
    data.forEach(el => {
      // el.usesOfTotal = this.productService.setGenerationsText(el);
      el.deploymentType = (el.licenseParams.onPremExecutionId ? ApiDeploymentType.onPrem : ApiDeploymentType.cloud);
    });
    this.rawListData = this.MapDataNew(data,this.columns);
    let unorderedList = this.inlineFilter(this.rawListData, this.listParameters.filters);
    this.listData = this.inlineSort(unorderedList, this.listParameters.sorting.field, this.listParameters.sorting.order);

    setTimeout(() => {
      this.loadResultsEnded.emit();
      this.parametersUpdated.emit(this.listParameters);
    }, 200);
  }


  async LoadTableData(fromApi?: boolean): Promise<void> {
    // if(!fromApi) {
      if(this.inputStatus.includes(ApiLicenseStatus.expired)) {
        this.columns = this.columns.filter(c => c.columnName !== 'remainingTime')
      }
      let data: listApiDataType[] = [];
      if(this.productService.licensesLoaded) {
        data = this.productService.getLicenseList(this.inputStatus);
        this.setLicensesFromData(data);
      } else {
        this.productService.licensesUpdated.subscribe(x => {
          data = this.productService.getLicenseList(this.inputStatus);
          this.productService.licensesUpdated.unsubscribe;
          this.setLicensesFromData(data);
        });
      }
    // } else {
    //   let data: listApiDataType[] = [];
    //   this.apiService.getLicenses().subscribe(licenses=>{
    //     this.setLicensesFromData(licenses);
    //   })
    // }

  }
}
