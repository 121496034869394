

<h1>Invitation Details</h1>

<section>
  <h2>Hello <span class="blue-highlight">{{inviteDetails?.invite.email}}</span></h2>
  <p>You've been invited by <span class="blue-highlight">{{inviteDetails?.invite.from.fullName}}</span>
    to join <span class="blue-highlight">{{inviteDetails?.company.name}}</span> on <span class="wrap-if-needed bold">Smart Code</span>.</p>

  <div class="border">
    <h3>Invite info</h3>
    <p>
      <span class="label">Invitee email</span>
      <span class="value">{{inviteDetails?.invite.email}}</span>
    </p>
    <p>
      <span class="label">Created</span>
      <span class="value">{{inviteDetails?.invite.created | date : 'longDate'}}</span>
    </p>
    <p>
      <span class="label">Sent on</span>
      <span class="value">{{inviteDetails?.invite.sent | date : 'longDate'}}</span>
    </p>
  </div>

  <div class="border">
    <h3>Sender information</h3>
    <p>
      <span class="label">Name</span>
      <span class="value">{{inviteDetails?.invite.from.fullName}}</span>
    </p>
    <p>
      <span class="label">Email</span>
      <span class="value">{{inviteDetails?.invite.from.email}}</span>
    </p>
    <!-- <p>
      <span class="label">Phone</span>
      <span class="value">{{inviteDetails?.invite.from.phone}}</span>
    </p>
    <p>
      <span class="label">Job title</span>
      <span class="value">{{inviteDetails?.invite.from.jobTitle}}</span>
    </p> -->

  </div>

</section>

<div class="button-container flex-columns two-columns-with-button">
  <button (click)="declineInvite()" class="secondary">Decline Invite</button>
  <button (click)="acceptInvite()" class="primary">Accept Invite</button>
</div>



