import { Component, OnInit } from '@angular/core';
import { UiSettingsService } from 'projects/nine-gold-lib/src/lib/services/ui-settings.service';
import { CompaniesListNewService } from '../../services/companies-list-new.service';

@Component({
  selector: 'app-super-admin-sidebar',
  templateUrl: './super-admin-sidebar.component.html',
  styleUrls: ['./super-admin-sidebar.component.scss']
})
export class SuperAdminSidebarComponent implements OnInit {

  constructor(
    public companiesListService: CompaniesListNewService,
    private uiSettings: UiSettingsService
  ) { }

  ngOnInit(): void {
    this.companiesListService.hideFreeLicenses = this.uiSettings.globalAdmin?.hideFreeLicenses || false;
  }

  toggleFreeLicenses():void {
    this.companiesListService.hideFreeLicenses = !this.companiesListService.hideFreeLicenses;
    this.uiSettings.updateGA('hideFreeLicenses',this.companiesListService.hideFreeLicenses);
  }

}
