import { Component, Input, OnInit } from '@angular/core';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FormResultMessageFormat } from '../../shared/notification/notification';
import { NotificationService } from '../../shared/notification/notification.service';

@Component({
  selector: 'lib-clipboard-copy',
  templateUrl: './clipboard-copy.component.html',
  styleUrls: ['./clipboard-copy.component.scss']
})
export class ClipboardCopyComponent implements OnInit {
  @Input() text: string;
  @Input() value: string;
  @Input() message: string = "The text was copied to clipboard";

  copyIcon = faCopy;

  constructor(
    private notif: NotificationService
  ) { }

  ngOnInit() {
  }

  copyToClipboard(): void {
    this.textToClipboard(this.text);
    this.notif.info("Copied to clipboard", this.message, FormResultMessageFormat.popup, 3000);
  }

  private textToClipboard (text:string) {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    // TODO: find altertative to execCommand above
    document.body.removeChild(dummy);
  }
}
