import { Component, Input, OnInit } from '@angular/core';
import { ListsService } from '../../../services/lists.service';
import { ColumnTypes, NgListColumns } from '../ng-list/ng-list-data';

@Component({
  selector: 'lib-sort-select',
  templateUrl: './sort-select.component.html',
  styleUrls: ['./sort-select.component.scss']
})
export class SortSelectComponent implements OnInit {
  @Input() listsService: ListsService;
  @Input() id: string;
  sortOptions: any[];
  selectedSort: string;

  constructor() { }

  ngOnInit(): void {
    this.sortOptions = this.createSortingDropdown(this.listsService.GetTableColumns());
    this.SetSelectedSort();
    this.listsService.parametersUpdated.subscribe(
      (params) => {
        this.SetSelectedSort();
      }
    )
  }

  createSortingDropdown(columns: NgListColumns[]): any[] {
    let options = [];
    columns.filter(col => col.sortable).forEach(col => {
      let opt = {
        text: col.columnText + ": " + (col.columnType === ColumnTypes.date ? "Oldest to Newest" : "A to Z"),
        value: col.sortColumnName + " a",
        columnName: col.columnName,
        direction: "a"
      };
      options.push(opt);
      opt = {
        text: col.columnText + ": " + (col.columnType === ColumnTypes.date ? "Newest to Oldest" : "Z to A"),
        value: col.sortColumnName + " d",
        columnName: col.columnName,
        direction: "d"
      };
      options.push(opt);
    });
    return options;
  }

  SetSelectedSort(): void {
    let sortKey = this.listsService.GetSorting().field;
    let sortOrder = this.listsService.GetSorting().order;
    this.selectedSort = sortKey + " " + sortOrder;
  }

  sortSelected(selectedSort: string):void {
    let sortColumn = selectedSort.split(" ")[0];
    let sortDirection = selectedSort.split(" ")[1];
    this.listsService.UpdateSortAndOrder(sortColumn, sortDirection);
  }

}
