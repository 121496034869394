<!-- <section class="space-filler blue-gradient"></section> -->


<h1 >Invitation for Smart Code</h1>

<article *ngIf="!userLoggedIn">
  <section>
    <h2>Hello <span class="blue-highlight">{{invitee}}</span></h2>
    <p><span class="blue-highlight">{{inviter}}</span> has invited you to join their <span class="blue-highlight">{{companyName}}</span> account on <span class="bold wrap-if-needed">Smart Code</span>.</p>
    <p>Please select to <a role="button" class="text-button">register</a> your account or link your existing account by <a role="button" class="text-button">logging in</a>.</p>
  </section>

  <div class="flex-columns two-columns-with-button">
    <div class="button-container">
      <button (click)="DeclineInvite()" class="text-button">Decline Invite</button>
    </div>


    <div class="button-container flex-columns">
      <button (click)="Login()" class="primary">Login</button>
      <button (click)="Register()" class="primary">Register</button>
    </div>
  </div>
</article>

<article *ngIf="userLoggedIn && inviteeLoggedIn">
  <section>
    <h2>Hello <span class="blue-highlight">{{invitee}}</span></h2>
    <p><span class="blue-highlight">{{inviter}}</span> has invited you to join their <span class="blue-highlight">{{companyName}}</span> account on <span class="bold wrap-if-needed">Smart Code</span>.</p>
    <p>You are already logged in with the same email address, so click continue to accept or decline this invitation.</p>
  </section>

  <div class="button-container flex-columns">
    <button (click)="GoAcceptDeclime()" class="primary">Continue</button>
  </div>
</article>

<article *ngIf="userLoggedIn && !inviteeLoggedIn">
  <section>
    <h2>Hello <span class="blue-highlight">{{invitee}}</span></h2>
    <p><span class="blue-highlight">{{inviter}}</span> has invited you to join their <span class="blue-highlight">{{companyName}}</span> account on <span class="bold wrap-if-needed">Smart Code</span>.</p>
    <p>You are logged in with different email address. Please log out and click on email link again.</p>
  </section>
  <div class="button-container flex-columns">
    <button (click)="LogOut()" class="primary">Logout</button>
  </div>
</article>

