<section class="subpage-content" >
    <header class="app-page-header">
        <h2><fa-icon [icon]="faSecurityIcon"></fa-icon>Security Management</h2>
    </header>

    <article>
        <lib-box-container
            id="security"
            [firstOnTheTop]="true"
            [lessPadding]="true">
            <section class="section-with-button">
                <h3>Password</h3>
                <div>
                    <p>In order to change you will be redirected to login screen. Click a button to <strong>Change Password</strong> to login to the IDE account management console, where you will be able to change your password.</p>
                    <button (click)="ChangePassword()" class="secondary">Change Password</button>
                </div>
            </section>
            <!-- TODO: 2 factor validation needs some research of keycloak, to be done later as an engancement -->
            <!-- <section>
                <p>You can setup 2 factor validation with your Google Authenticator or any FreeOTP application.</p>
                <p>
                    <button (click)="SetUpTwoFactor()" class="primary">2-factor validation</button>
                </p>
            </section> -->
            <section class="section-with-button">
                <h3>User Data Transcript</h3>
                <div>
                    <p>You can request full transcript of your data from our database. Click the <strong>Request Data Transcript</strong> button and your data transcript will be downloaded in JSON format.</p>
                    <button (click)="RequestData()" class="secondary">Request data transcipt</button>
                </div>
            </section>
            <section class="section-with-button danger">
                <h3>Delete Account</h3>
                <div>
                    <p>To remove your account click the <strong>Delete My Account</strong> button. Users can also choose to have all of their information deleted from our servers under Consulting Privacy Notice for California Residents, even if they are not resident of California by selecting the CCPA checkbox on the deletion form.</p>
                    <!-- <p>You can remove your account. To do it, click <strong>Delete my account</strong> button and deletion confirmation form will open. Your account will be set non-active. All your action history will be lost. You can also choose to have all of your information deleted from our servers under Consulting Privacy Notice for California Residents, even if you are not resident of California. To do it, check <strong>I want delete all of my data under CCPA</strong> checkbox on the deletion form. </p> -->
                    <button class="primary danger" (click)="RequestDeletion()">Delete my account</button>
                </div>
            </section>
                    
                    
        </lib-box-container>
        
    </article>
</section>

<ng-template #userDataDownload>
    <div *ngIf="downloadJsonHref" class="">
  
      <header class="modal-header">
          <h2 class="modal-title">User Data Transcript</h2>
      </header>

      <div class="modal-body">
          <p>User data is generated for <span class="highlighted">{{currentUser.fullName}}</span> and ready to download.</p>
          <a role="button" class="primary" [href]="downloadJsonHref" (click)="modalRef.hide()" download="download.json">Download User Data</a>
      </div>
      
    </div>
  </ng-template>

  <ng-template #userDeleteConfirm>
    <div *ngIf="userToDelete" [libLoading]="userDataLoading"  [fullScreen]="false">
            <p class="text-with-toggle">
                <span>Remove user <span class="blue-highlight">{{userToDelete.username}}</span>.</span>
                <lib-toggle-switch  [checked]="allowDeleteUser" (change)="toggleAllowDeleteUser()" [labelOn]="'YES'" [labelOff]="'NO'"></lib-toggle-switch>
            </p>
            
            <p class="text-with-toggle">
                <span>
                    Delete my data under <a href="/california-consumer-privacy-statement" target="_blank" class="new-tab-link">CCPA</a>.
                    <span class="input-tooltip-icon" trigger="hover" tooltip="{{ccpaInfo}}"><fa-icon [icon]="infoIcon"></fa-icon></span>
                    
                </span>
                <lib-toggle-switch  [checked]="optOut" (change)="toggleOptOut()" [labelOn]="'YES'" [labelOff]="'NO'"></lib-toggle-switch>
            </p>
            <div class="border-with-title no-bottom-margin">
                <h4>User Data Transcript</h4>
                <p>Download data before proceeding.</p>
                <p><button [class.completed]="userDataLoaded" class="secondary request-and-download" [disabled]="userDataLoaded" (click)="RequestData(true)">request & download user transcript</button></p>
                <!-- <lib-accordion [insideGroup]="true" #donwloadInline [accordionBody]="donwloadInlineBody" [hideHeader]="true"></lib-accordion> -->
            </div>    
        <!-- </div> -->
        
        <!-- <div class="modal-footer">
            <div class="bottom-buttons">
                <button class="secondary">Cancel</button>
                <button class="primary danger" (click)="DeleteUser()">Delete</button>
            </div>
        </div> -->
    </div>
    

  </ng-template>

  <ng-template #donwloadInlineBody>
    <div class="centered-modal-table centered-value full-width">
        <p>User Data was generated.</p>
        <table>
            <tr>
                <td class="label">User File</td>
                <td class="value">
                    <lib-file-download fileName="user_transcript_{{userToDelete.username}}.json" [fileUrl]="downloadJsonHref"></lib-file-download>
                </td>
            </tr>
        </table>
    </div>
  </ng-template>

  <ng-template #userDeleteFooter>
    <div class="bottom-buttons">
        <button class="secondary" (click)="notif.hide()">Cancel</button>
        <button class="primary danger" [disabled]="!allowDeleteUser" (click)="DeleteUser()">Delete</button>
    </div>
  </ng-template>


  <ng-template #userDeleteConfirmation>
    <div>
        <p class="centered">{{userDeleteConfirmMessage}}</p>
        <h5>{{counter}}</h5>
    </div>
  </ng-template>