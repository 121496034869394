import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InviteDetails } from 'projects/nine-gold-lib/src/lib/models/invites';
import { FilterPipe } from 'projects/nine-gold-lib/src/lib/pipes/filter.pipe';
import { OrderByPipe } from 'projects/nine-gold-lib/src/lib/pipes/orderBy.pipe';
import { AppSettingsService } from 'projects/nine-gold-lib/src/lib/services/app-settings.service';
import { ListsService } from 'projects/nine-gold-lib/src/lib/services/lists.service';
import { UserService } from 'projects/nine-gold-lib/src/lib/services/user.service';
import { ConfirmDialog } from 'projects/nine-gold-lib/src/lib/shared/confirm-dialog/confirm-dialog';
import { ButtonTypes, ColumnTypes, ListUiParams, NgListButton, NgListColumns, NgListDataCell, NgListDataRow, sortingOrder } from 'projects/nine-gold-lib/src/lib/shared/listComponents/ng-list/ng-list-data';
// import { ButtonTypes, ColumnTypes, ListUiParams, NgListButton, NgListColumns, NgListDataCell, NgListDataRow, sortingOrder } from 'projects/nine-gold-lib/src/lib/shared/ng-list/ng-list-data';
import { FormResultMessageFormat } from 'projects/nine-gold-lib/src/lib/shared/notification/notification';
import { ConfirmDialogService, NotificationService } from 'projects/nine-gold-lib/src/public-api';
import { timeout } from 'rxjs/operators';
import { UserManagementService } from '../user-management.service';

type listApiDataType = InviteDetails;

@Injectable({
  providedIn: 'root'
})
export class InvitesListService extends ListsService {

  listData: NgListDataRow[] = [];
  rawListData: NgListDataRow[] = [];

  fakeData: listApiDataType[] = [];
  columns: NgListColumns[];

  uiSortFilterPage = true;

  listApiName = 'usersList';
  ApiUrl: string;

  listParameters:ListUiParams = {
    numberOfPages: 1,
    currentPage: 1,
    itemsPerPage: 10,
    filters: [],
    sorting: {
      field: "created",
      order: sortingOrder.desc
    }
  }



  invitesCompleteList: InviteDetails[];



  removeConfirm: ConfirmDialog = {
    title: "Delete Invite?",
    message: "Are you sure you want to remove invitation?",
    confirmBtn: "Yes",
    declineBtn: "No"
  }



setApiName(): void {
  this.listApiName = 'currentCompanyInvitesList';
}

setSortSettings(): void {
  this.uiSortFilterPage = true;
}

setInitialColumns(): void {
  this.columns = [
    {
      columnName: 'email',
      columnText: 'Sent To',
      columnType: ColumnTypes.text,
      sortable: true,
      sortColumnName: 'email',
      filters: null,
      columnOrder: 0
    },
    {
      columnName: 'created',
      columnText: 'Created',
      columnType: ColumnTypes.date,
      sortable: true,
      sortColumnName: 'created',
      filters: null,
      columnOrder: 1
    },
    {
      columnName: 'sent',
      columnText: 'Last Sent',
      columnType: ColumnTypes.date,
      sortable: true,
      sortColumnName: 'sent',
      filters: null,
      columnOrder: 2
    },
    {
      columnName: 'from',
      columnText: 'From',
      customMapping: ['from','fullName'],
      columnType: ColumnTypes.text,
      sortable: true,
      sortColumnName: 'sent',
      filters: null,
      columnOrder: 3
    },
    {
      columnName: 'code',
      columnText: 'Code',
      columnType: ColumnTypes.text,
      sortable: false,
      notVisible: true,
      rowIdentifier: true,
      filters: null
    },
    {
      columnName: 'info',
      columnText: '',
      columnType: ColumnTypes.info,
      sortable: false,
      filters: null
    },
    {
      columnName: 'actions',
      columnText: 'Actions',
      columnType: ColumnTypes.buttons,
      sortable: false,
      filters: null,
      columnOrder: 4,
      buttons: [
        {
          type: ButtonTypes.delete,
          buttonText: 'Remove invite',
          value: null,
          buttonCustomClass: 'trash-can'
        },
        {
          type: ButtonTypes.resend_invite,
          buttonText: 'Resend invite',
          value: null,
        }
      ]
    }
  ];
}


private prepareDetails(result: any): string {
  return `<p><span class='label'>Created:</span> ${result.created}</p>
  <p><span class='label'>Sent:</span> ${result.sent}</p>
  <p><span class='label'>Invitee email:</span> ${result.email}</p>
  <h3>Sender information</h3>
  <p><span class='label'>Sender name:</span> ${result.from.fullName}</p>
  <p><span class='label'>Sender email:</span> ${result.from.email}</p>
  <p><span class='label'>Sender phone:</span> ${result.from.phone}</p>
  <p><span class='label'>Sender job title:</span> ${result.from.jobTitle}</p>
  <p><span class='label'>Code:</span> ${result.code}</p>`
}




updateListParams(): void {
  this.listParameters = {
    numberOfPages: 1,
    currentPage: 1,
    itemsPerPage: 10,
    filters: [],
    sorting: {
      field: "created",
      order: sortingOrder.desc
    }
  }
}

public getInviteDetails(code: string): InviteDetails {
  let invite: InviteDetails = this.apiResutls.find(x => x.code === code);
  let p = JSON.parse(invite.from.phone);
  if(p) {
    invite.from.displayPhone = `(${p.countryCode}) ${p.dialCode} ${p.nationalNumber}`;
  }

  return this.apiResutls.find(x => x.code === code);
}



MapData(resultsData: InviteDetails[], columns: NgListColumns[]): NgListDataRow[] {
  let rows: NgListDataRow[] = [];
  resultsData.forEach(resultRow => {
    let row = <NgListDataRow>{};
    row['listRow'] = [];
    Object.keys(resultRow).forEach(key => {
      let element = <NgListDataCell>{};
      let column = columns.find(x => x.columnName === key);

      if(column) {
        element.columnName = column.columnName;
        element.columnOrder = column.columnOrder;
        element.type = column.columnType;

        if(column.columnType === ColumnTypes.buttons) {
          element.buttons = [];
          column.buttons.forEach(btn => {
            let cellBtn = <NgListButton>{};
            cellBtn.buttonText = btn.buttonText;
            cellBtn.type = btn.type;
            cellBtn.value = resultRow.code;
            element.buttons.push(cellBtn);
          });
        } else {
          let tempValue = resultRow;
          if(column.customMapping) {
            column.customMapping.forEach(x => {
              tempValue = tempValue[x];
            });
            element.value = tempValue;
            element.display = tempValue.toString();
          } else {
            element.value = resultRow[key];
            element.display = resultRow[key];
          }

        }
        row.listRow.push(element);
      }
    });
    row.listRow.sort((a, b) => a.columnOrder - b.columnOrder );
    row.rowIdentifier = resultRow.code;
    rows.push(row);
  });
  return rows;
}

}
