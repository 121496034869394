import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsListComponent } from './tabs-list/tabs-list.component';
import { TabItemComponent } from './tab-item/tab-item.component';
import { TabBodyComponent } from './tab-body/tab-body.component';
import { TabLabelComponent } from './tab-label/tab-label.component';
import { TabsSharedComponent } from './tabs-shared/tabs-shared.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    TabsListComponent,
    TabItemComponent,
    TabBodyComponent,
    TabLabelComponent,
    TabsSharedComponent
  ],
  exports: [
    TabsListComponent,
    TabItemComponent,
    TabBodyComponent,
    TabLabelComponent,
    TabsSharedComponent
  ]
})
export class Tabs1Module { }
