import { Injectable } from '@angular/core';
import { ApiUser } from 'projects/nine-gold-lib/src/lib/models/user';
import { Uses } from 'projects/nine-gold-lib/src/lib/models/uses';
import { ListsService } from 'projects/nine-gold-lib/src/lib/services/lists.service';
import { ButtonTypes, columnFilterTypes, ColumnTypes, ListUiParams, sortingOrder } from 'projects/nine-gold-lib/src/lib/shared/listComponents/ng-list/ng-list-data';

type listApiDataType = Uses;

@Injectable({
  providedIn: 'root'
})
export class UsesListService extends ListsService {

  listParameters:ListUiParams = {
    numberOfPages: 1,
    currentPage: 1,
    itemsPerPage: 10,
    filters: [],
    sorting: {
      field: "started",
      order: sortingOrder.desc
    }
  }

  usersInList: ApiUser[] = [];
  // TODO: on load of results set this list with all unique users
  // TODO: use this to filter by user
  // TODO: also use this to set user column text based on user Id

// constructor() { }

  setInitialColumns(): void {
    this.columns = [
      {
        columnName: 'id',
        columnText: '',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,
        columnOrder: null,
        rowIdentifier: true,
        notVisible: true
      },
      {
        columnName: 'projectName',
        columnText: 'Project Name',
        columnType: ColumnTypes.text,
        sortable: true,
        sortColumnName: 'projectName',
        filters: null,
        columnOrder: 0,
        notVisible: false,
        clickForInfo: false
      },
      {
        columnName: 'productId',
        columnText: 'Product',
        columnType: ColumnTypes.text,
        sortable: false,
        columnOrder: 1,
        notVisible: false,
        displayFunction: 'setProductName',
        tdHtml: true,
        // filters: null
        filters: {
          filterId: 'productId',
          filterName: 'Product',
          filterType: columnFilterTypes.options,
          filterPlaceholder: 'Select product',
          optionSelected: null,
          filterOptions:[
            {text: 'REST Generator', value: 'restgen'},
            {value: 'oastoramlconverter', text: 'OAS &rArr; RAML'},
            {value: 'ramltooasconverter', text: 'RAML &rArr; OAS'},
            {value: 'jsontoramlconverter', text: 'JSON SCHEMA &rArr; RAML'},
            {value: 'ramltojsonconverter', text: 'RAML Example &rArr; JSON'},
            {value: 'jsontoramlconverterex', text: 'JSON &rArr; RAML Example'},
            {value: 'postmancollection', text: 'Postman Collection'}
          ]
        }
      },
      {
        columnName: 'status',
        columnText: 'Status',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: {
          filterId: 'status',
          filterName: 'Status',
          filterType: columnFilterTypes.options,
          filterPlaceholder: 'Choose status',
          optionSelected: null,
          filterOptions: [
            { value: 'completed', text: 'Completed'},
            { value: 'processing', text: 'Processing'},
            { value: 'error', text: 'Error'},
          ]
        },  // TODO: add filters (get statuses from Dave)
        columnOrder: 2,
        notVisible: false,
        canShowHide: true,
        displayFunction: 'setDownloadStatusText',
        tdHtml: true
      },
      {
        columnName: 'user',
        columnText: 'User',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,  // TODO: add auto filter based on all users from list
        columnOrder: 3,
        customMapping: ['user','userName'],
        notVisible: false,
        canShowHide: true,
        // displayFunction: 'setUser'
        // TODO: user value is userId from custom mapping
        // TODO: display is username
        // TODO: add ColumnTypes.user, where it is clickable - get user popup, on hover there is email button, phone, info and filterBy (added by adding actions - standard actions in lists.service)
      },
      {
        columnName: 'resultsMessage',
        columnText: 'Results',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,
        columnOrder: 4,
        notVisible: true,
        canShowHide: true,
        customMapping: ['results','message']
      },
      {
        columnName: 'started',
        columnText: 'Date',
        columnType: ColumnTypes.date,
        sortable: true,
        sortColumnName: 'started',
        filters: null,
        columnOrder: 5,
        notVisible: false,
        canShowHide: true
      },
      // {
      //   columnName: 'completed',
      //   columnText: 'Completed',
      //   columnType: ColumnTypes.date,
      //   sortable: true,
      //   sortColumnName: 'completed',
      //   columnOrder: null,
      //   filters: null,
      //   notVisible: true,
      //   canShowHide: false
      // },
      {
        columnName: 'fileName',
        columnText: 'File',
        columnType: ColumnTypes.file_download,
        sortable: false,
        columnOrder: 6,
        filters: null,
        notVisible: false,
        canShowHide: true,
        customMapping: ['results','completedFile']
        // TODO: add file download type and make it work
      },
      {
        columnName: 'info',
        columnText: '',
        columnType: ColumnTypes.info,
        sortable: false,
        filters: null,
        noHeader: true
      },
      // {
      //   columnName: 'actions',
      //   columnText: 'Actions',
      //   columnType: ColumnTypes.buttons,
      //   columnClasses: 'fit-content',
      //   sortable: false,
      //   columnOrder: 10,
      //   filters: null,
      //   buttons: [
      //     {
      //       buttonText: 'Download result',
      //       value: null,
      //       type: ButtonTypes.download,
      //       showButtonFunction: 'canDownloadResultFile'
      //     }
      //   ]
      // }
    ]
  }

  canDownloadResultFile(row: listApiDataType) {
    return !!row.results?.completedFileDownload;
  }

  setProductName(val: string): string {
    return this.productService.getShortProductName(val);
  }

  updateListParams(): void {
    this.listParameters = {
      numberOfPages: 1,
      currentPage: 1,
      itemsPerPage: 4,
      filters: [],
      sorting: {
        field: "started",
        order: sortingOrder.desc
      }
    }
  }

  setSortSettings(): void {
    this.uiSortFilterPage = true;
  }

  setApiName(): void {
    // this.listApiName = 'currentCompanyUsesList';
    this.listApiName = 'usesFromAll';
    this.ApiFunctionName = 'getAllUses';
    // this.ApiParameter = ['tools_converters','tools_generators','rest_generator']
    // TODO: get from product service
  }

  async LoadTableData(fromApi = false):Promise<void> {
    this.loadResultsStarted.emit();
    let timeNow = new Date();
    let timeOut: boolean = !this.hardLoadTimestamp || (timeNow.getTime() - this.hardLoadTimestamp.getTime() >= this.apiHardLoadTimeout);
    if(fromApi || timeOut) {
      await this.apiService[this.ApiFunctionName]().toPromise().then(
        (results:listApiDataType[]) => {
          this.apiResutls = results.filter(row=>!['restgen-endpoints','restgen-specifications','restgen-parse'].includes(row.productId));
          this.hardLoadTimestamp = new Date();
          this.rawListData = this.MapDataNew<listApiDataType>(this.apiResutls,this.columns);
        }
      );
    }

    let unorderedList = this.inlineFilter(this.rawListData, this.listParameters.filters);
    this.listParameters.numberOfPages = Math.ceil(unorderedList.length / this.listParameters.itemsPerPage);
    this.listData = this.inlineSort(unorderedList, this.listParameters.sorting.field, this.listParameters.sorting.order);


    setTimeout(() => {

      this.loadResultsEnded.emit();
      this.parametersUpdated.emit(this.listParameters);
    }, 200);

  }

  setDownloadStatusText(status: string): string {
    return `<span class="download-status download-status-${status}">${status}<span>`
  }

}
