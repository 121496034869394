<article class="legal-content">
    <div class="page-inner">
        <header>
            <h1>Terms Of Service</h1>
            <h3 class="updated">Last updated on January 24<sup>th</sup>, 2024</h3>
        </header>
        <aside>
            <section class="intro">
                <p>Please check back regularly for updates. The following Terms of Service, together with any documents they expressly incorporate by reference (collectively, the "<strong>Terms of Service</strong>"), are entered into by and between you and Diamond Edge IT, Inc. ("<strong>Diamond Edge IT</strong>", "<strong>Company</strong>", "<strong>us</strong>", "<strong>our</strong>" or "<strong>we</strong>"). These Terms of Service govern your access and use of smartcodeplatform.com, restgenerator.com, utilgenerator.com, diamondedge-it.com and related sub-domains including any content, functionality and services offered on or through our websites, including but not limited to, the Smart Code Platform and Products (together, the "Services"). </p>
            </section>
            <section class="toc">
                <p>These Terms of Service are broken down into the following Sections for your convenience:</p>
                <nav class="scroll-navigation">
                    <ol>
                        <li><a (click)="onClick('CreationOfUserAccount')">Creation of User Account</a></li>
                        <li><a (click)="onClick('Subscriptions')">Grant of License(s)</a></li>
                        <li><a (click)="onClick('Dollars')">US Dollars and Foreign Currency Conversion</a></li>
                        <li><a (click)="onClick('Modifications')">Modifications to Service</a></li>
                        <li><a (click)="onClick('Reliance')">Reliance on Information Posted</a></li>
                        <li><a (click)="onClick('Consent')">Consent to Use of Electronic Communications</a></li>
                        <li><a (click)="onClick('Intellectual_Property')">Intellectual Property Rights</a></li>
                        <li><a (click)="onClick('Trademarks')">Diamond Edge IT Trademarks</a></li>
                        <li><a (click)="onClick('Restrictions')">Restrictions</a></li>
                        <li><a (click)="onClick('User_Contributions')">User Contributions</a></li>
                        <li><a (click)="onClick('Content_Standards')">Content Standards</a></li>
                        <li><a (click)="onClick('Monitoring')">Monitoring and Enforcement</a></li>
                        <li><a (click)="onClick('Termination')">Termination and Suspension of Account and/or User Access</a></li>
                        <li><a (click)="onClick('Third_Party')">Third Party Links</a></li>
                        <li><a (click)="onClick('Changes')">Changes to our Services</a></li>
                        <li><a (click)="onClick('Disclaimer')">Disclaimer of Warranties</a></li>
                        <li><a (click)="onClick('Liability')">Limitation on Liability</a></li>
                        <li><a (click)="onClick('Indemnification')">Indemnification</a></li>
                        <li><a (click)="onClick('Governing')">Governing Law and Jurisdiction</a></li>
                        <li><a (click)="onClick('Arbitration')">Arbitration</a></li>
                        <li><a (click)="onClick('General_Provisions')">General Provisions</a></li>
                        <li><a (click)="onClick('Contact_Us')">How to Contact Us</a></li>
                    </ol>
                </nav>
            </section>
            <section>
                <p class="legal-note">These terms of service contain provisions that govern how claims that you and Diamond Edge IT have against each other are resolved (see <a>section 15 - arbitration</a>). These terms of service also contain provisions requiring you to resolve certain disputes or claims relating to your use of the services by binding arbitration, rather than in court. If you do not consent to such terms, you are not permitted to use the services.</p>
                <p>Please review these Terms of Service carefully to understand all of the requirements and restrictions to utilizing our Services. If you do not agree with our terms, you cannot use our Services. By accessing or using our Services, you agree to these Terms of Service. These Terms of Service may change from time to time (see Changes to these Terms of Service). Your continued use of our Services after we make changes is deemed to be acceptance of those changes, so please check back periodically for updates. If you have any questions, comments or concerns about any aspect of these Terms of Service, please do not hesitate to contact us by using the details set forth below in the &quot;<a (click)="onClick('Contact_Us')">How To Contact Us</a>&quot; Section.</p>
            </section>
        </aside>
        <main>
            <ol class="main-content">
                <li class="top-level" >
                    <h2 id="CreationOfUserAccount">Creation of User Account</h2>
                    <p>To access the Services or some or all of the resources it offers, you may be asked to provide certain registration details or other information. In order to use Services, including the Smart Code Platform you must register to create an account. It is a condition of your use of the Services that all the information you provide in or through the Services, including when signing up for a user account through our websites, are accurate, current and complete. This means that you cannot setup an account using a name or contact information that does not apply to you, and you must provide accurate and current information on all registration forms that are part of the Services.</p>
                    <p>You agree that all data you provide to register with the Services or otherwise, including but not limited to through the use of any interactive features provided on or through the Services, are governed by our Privacy Statement found <a [routerLink]="['/privacy']">here</a>, and you consent to all actions we take with respect to your information consistent with our Privacy Statement.</p>
                    <p>If you choose a user name, password or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to the Services or portions of it using your user name, password or other security information. You agree to notify us immediately when you become aware of any unauthorized access to or use of your user name or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.</p>
                    <p>We have the right to disable any user name, password or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Service or our Privacy Statement.</p>
                    <p>You are responsible for:</p>
                    <ul>
                        <li>Making all arrangements necessary for you to have access to and utilize the Services; and</li>
                        <li>Ensuring that all persons who access the Services through your Internet connection are aware of these Terms of Service and comply with them.</li>
                    </ul>
                </li>

                <li class="top-level">
                    <h2 id="Subscriptions">Grant of License(s)</h2>
                    <ol>
                        <li>If the Software is a fourteen-day (14) day Trial Version, this Section 2.1 shall apply. Subject to the provisions of the present Agreement, Diamond Edge IT hereby grants to the Customer a limited, revocable, non-perpetual, non-exclusive, non-transferable product key license to review, test, and evaluate the Software for its Internal Use and Analytical Use (and not for commercial purposes) for the duration specified on Smart Code Platform Product website when the Software is installed (the "Trial License").</li>
                        <li>If the Software is not a Trial Version, this Section 2.2, and not Section 2.1 shall apply. Subject to the provisions of the present Agreement as well as the payment of all applicable fees for the term of such License, Diamond Edge IT hereby grants to the Customer a revocable, non-perpetual, non-exclusive, non-transferable product key license to use the Smart Code Platform Product Software for its Internal Use for the period purchased by the Customer (the "License"), the license including all applicable functionality.</li>
                        <li>All rights not specifically granted to the Customer by the present Agreement are reserved to Diamond Edge IT.</li>
                        <li>The License is a per-Activation license. The Customer may only activate the number of Activations it has purchased and is responsible for coordinating the activation and deactivation of the license key to permit Users to use the Software in accordance therewith. For the avoidance of doubt, (a) the Customer may transfer a license key from one User to another at no additional charge so long as the Customer does not exceed the total number of Activations, and (b) mere installation of the Software does not count toward the number of Activations.</li>
                        <li>If you wish to sign up for a trial or purchase an product key, you may be asked to supply certain information relevant to your purchase, including, and without limitation, your first name, last name, email address, phone number, and company name. By trialing or purchasing a key for the Services offered by Diamond Edge IT, you consent to the information collection, disclosure and use practices described in our Privacy Statement.</li>
                    </ol>
                </li>

                <li class="top-level">
                    <h2 id="Dollars">US Dollars and Foreign Currency Conversion</h2>
                    <p>The Services operate in U.S. Dollars. If your payment method is denominated in a currency other than U.S. Dollars and requires currency conversion to make payments in U.S. Dollars, foreign currency conversion rates may occur.</p>
                </li>

                <li class="top-level">
                    <h2 id="Modifications">Modifications and Maintenance to Service</h2>
                    <p>We reserve the right to withdraw or amend the Services, including any service or material we provide in or through our Services, in our sole and absolute discretion without notice. We will not be liable if for any reason all or any part of the Services are unavailable at any time or for any period. From time to time, we may restrict access to some or all parts of the Services to users, including registered users.</p>
    				<p>During the term of the License, Diamond Edge IT agrees to provide to the Customer software maintenance and support services, namely (i) making available to the Customer patches, fixes, updates and/or enhancements generally made available to Diamond Edge IT's customers from time to time, if any, and (ii) technical support, on an as-needed basis for the sole purpose of responding within a reasonable period of time and attempting to address, during normal business hours (technical issues relating to the use of the Software, which may include online technical support) Without limiting the generality of the foregoing, any requests by the Customer for additional features or functionality that fall outside of Diamond Edge IT's ongoing updates and/or enhancements of the Software are excluded from Maintenance and Support Services.</p>
                </li>

                <li class="top-level">
                    <h2 id="Reliance">Reliance of Information Posted</h2>
                    <p>The information presented on or through our Services are made available solely for general information purposes. We do not warrant the accuracy, completeness or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other user of our Services, or by anyone who may be informed of any of its contents.</p>
				    <p>The Services may include content provided by third parties, including materials provided by other users, bloggers and third-party licensors, syndicators, aggregators and/or reporting services, such as open source software. All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by the Diamond Edge IT, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of the Diamond Edge IT. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.</p>
                </li>

                <li class="top-level">
                    <h2 id="Consent">Consent to use of Electronic Communications</h2>
                    <p>In connection with these Terms of Service, you may be entitled to receive certain communications from us such as notices, product updates and changes to the Terms of Service, in writing.  To facilitate your use of our Services, you give us permission to provide these communications to you electronically instead of in paper form.</p>
				    <p>You can withdraw your consent to receive further communications electronically at any time by selecting to remove yourself as a registered user. Any withdrawal of your consent to receive communications will revoke your access to our Services. Any withdrawal of your consent to receive communications will be effective only after a reasonable period of time to process your request to revoke your access as a registered user. For more information on your rights relating to receiving communications, please see our Privacy Statement.</p>
                </li>

                <li class="top-level">
                    <h2 id="Intellectual_Property">Intellectual Property Rights</h2>
                    <p>Our Services and their entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof, such as the Smart Code Platform), are owned by Diamond Edge IT, its licensors or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.</p>
				    <p>These Terms of Service permit you to use the Services for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our Services, except as follows:</p>
                    <ul>
                        <li>Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials;</li>
                        <li>You may store files that are automatically cached by your web browser for display enhancement purposes;</li>
                        <li>You may print one copy of a reasonable number of pages from our website for your own personal, non-commercial use and not for further reproduction, publication or distribution; and</li>
                        <li>If we provide desktop, mobile or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.</li>
                    </ul>
                    <p>You must not:</p>
                    <ul>
                        <li>Modify copies of any materials from our website; and</li>
    					<li>Delete or alter any copyright, trademark or other proprietary rights notices from copies of materials from this site.</li>
                    </ul>
                    <p>If you print, copy, modify, download or otherwise use or provide any other person with access to any part of the Services in breach of the Terms of Service, your right to use our Services, including any of its contents, features and functionality, will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title or interest in or to our Services, or any content contained within our Services, are transferred to you, and all rights not expressly granted are reserved by Diamond Edge IT. Any use of the Services not expressly permitted by these Terms of Service is a breach of these Terms of Service and may violate copyright, trademark and other laws.</p>
                </li>

                <li class="top-level">
                    <h2 id="Trademarks">Diamond Edge IT Trademarks</h2>
                    <p>The Diamond Edge IT name, the terms "<strong>Smart Code</strong>", "<strong>Smart Code Platform</strong>", "<strong>Smart Projects</strong>", "<strong>Smart Utilities</strong>", "<strong>REST Generator</strong>" and "<strong>UTIL Generator</strong>", the Smart Code Platform logo and all related names, logos, product and service names, designs and slogans are trademarks of Diamond Edge IT or its affiliates or licensors. You must not use such marks without the prior written permission of Diamond Edge IT. All other names, logos, product and service names, designs and slogans on this Website are the trademarks of their respective owners.</p>
                </li>

                <li class="top-level">
                    <h2 id="Restrictions">Restrictions</h2>
                    <p>You may use the Services only for lawful purposes and in accordance with these Terms of Service and the Privacy Statement. You may not:</p>
                    <ul>
                        <li>Use our Services, and its entire contents, features and functionality (including but not limited to the Smart Code Platform) in any way that violates any applicable federal, state, local or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries) or otherwise in violation of these Terms of Service, our Privacy Statement or any other applicable policies or documentation;</li>
                        <li>Provide the Software serial codes, password or other product key information to any third party;</li>
                        <li>Share non-public features or content of the Software with any third party;</li>
                        <li>Access or use the Software in order to build a competitive product or service; to build a product using similar ideas, features, functions or graphics of the Software; or to copy any ideas, features, functions or graphics of the Software;</li>
                        <li>Submit content through our Services that do not belong to you or the company you are employed by or otherwise work for;</li>
                        <li>Use our Services to create content that is illegal, obscene, defamatory, libelous, threatening, pornographic, harassing, hateful, racially or ethnically offensive, or encourages conduct that would be considered a criminal offense, give rise to civil liability, violate any law, or is other inappropriate;</li>
                        <li>Impersonate or attempt to impersonate Diamond Edge IT, a Diamond Edge IT employee, another user or any other person or entity (including, without limitation, by using e-mail addresses associated with any of the foregoing);</li>
                        <li>Upload, launch, post, or transmit any material (including any bot, worm, scripting exploit or computer virus) that is likely to harm or corrupt our Services and/or our reputation, or harm or corrupt our or anyone else's computer systems, or data;</li>
                        <li>Use our Services to harm minors in any way, including anything that violates child pornography laws, child sexual exploitation laws, or any other laws protecting children;</li>
                        <li>Use our Services to disparage us or any partners, vendors or affiliates Diamond Edge IT has</li>
                        <li>Modify the Services, any part of the Services or any of its content; and</li>
                        <li>Engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Services, or which, as determined by us, may harm Diamond Edge IT or its users or otherwise expose them to liability.</li>
                    </ul>
                    <p>Additionally, you agree not to:</p>
                    <ul>
                        <li>Use the Services in any manner that could disable, overburden, damage, or impair the Services or interfere with any other party's use of the Services, including their ability to engage in real time activities through the Services;</li>
                        <li>Use any robot, spider or other automatic device, process or means to access the Services for any purpose, including monitoring or copying any of the material on the Services;</li>
                        <li>Use any manual process to monitor or copy any of the material on the Services or for any other unauthorized purpose without our prior written consent;</li>
                        <li>Use any device, software or routine that interferes with the proper working of our Services;</li>
                        <li>Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Services, the server on which the Services are stored, or any server, computer or database connected to the Services; and</li>
                        <li>Attack the Services via a denial-of-service attack or a distributed denial-of-service attack.</li>
                    </ul>
                </li>

                <li class="top-level">
                    <h2 id="User_Contributions">User Contributions</h2>
                    <p>The Services may contain message boards, chat rooms, personal web pages or profiles, forums, bulletin boards, comment sections and other interactive features (collectively, "<strong>Interactive Services</strong>") that allow users to post, submit, publish, display or transmit to other users or other persons (hereinafter, "<strong>Post</strong>") content or materials (collectively, "<strong>User Contributions</strong>") on or through the Services. All User Contributions must comply with the <a (click)="onClick('Content_Standards')">Content Standards</a> set out in these Terms of Service.</p>
                    <p>Any User Contribution you Post to the Services will be considered non-confidential and non-proprietary. By providing any User Contribution on or through our Services, you grant us and our affiliates and service providers, and each of their and our respective licensees, successors and assigns, the right to use, reproduce, modify, perform, display, distribute and otherwise disclose to third parties any such material for any purpose.</p>
                    <p>You represent and warrant that:</p>
                    <ul>
                        <li>You own or control all rights in and to the User Contributions and have the right to grant the license granted above to Diamond Edge IT and our affiliates and service providers, and each of their and our respective licensees, successors and assigns.</li>
                        <li>All of your User Contributions do and will comply with these Terms of Service.</li>
                    </ul>
                    <p>You understand and acknowledge that you are responsible for any User Contributions you submit or contribute, and you, not Diamond Edge IT, have fully responsibility for such content, including its legality, reliability, accuracy and appropriateness. Diamond Edge IT is not responsible, or liable to any third party, for the content or accuracy of any User Contributions posted by you or any other user of our Services.</p>
                </li>

                <li class="top-level">
                    <h2 id="Content_Standards">Content Standards</h2>
                    <p>These content standards apply to any and all User Contributions and use of any Interactive Services. User Contributions must in their entirety comply with all applicable federal, state, local and international laws and regulations. Without limiting the foregoing, User Contributions must not:</p>
                    <ul>
                        <li>Contain any material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory or otherwise objectionable;</li>
                        <li>Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation or age;</li>
                        <li>Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Service and our Privacy Statement located <a [routerLink]="['/privacy']">here</a>;</li>
                        <li>Be likely to deceive any person;</li>
                        <li>Promote any illegal activity, or advocate, promote or assist any unlawful act;</li>
                        <li>Cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm or annoy any other person;</li>
                        <li>Impersonate any person, or misrepresent your identity or affiliation with any person or organization;</li>
                        <li>Involve commercial activities or sales, such as contests, sweepstakes and other sales promotions, barter or advertising; or</li>
                        <li>Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.</li>
                    </ul>
                    <p>If you believe that any User Contributions violate your copyright, please send us a notice of copyright infringement to <a href="mailto:support@diamondege-it.com">support&#64;diamondege-it.com</a>. It is the policy of Diamond Edge IT to terminate the user accounts of repeat infringers.</p>
                </li>

                <li class="top-level">
                    <h2 id="Monitoring">Monitoring and Enforcement</h2>
                    <p>Diamond Edge IT has the right to:</p>
                    <ul>
                        <li>Remove or refuse to post any User Contributions for any or no reason in our sole discretion;</li>
                        <li>Take any action with respect to any User Contribution that we deem necessary or appropriate in our sole discretion, including if we believe that such User Contribution violates these Terms of Service, including the <a (click)="onClick('Content_Standards')">Content Standards</a>, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of any other users of the Services or the public, or which could create liability for Diamond Edge IT;</li>
                        <li>Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy;</li>
                        <li>Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Services; </li>
                        <li>Terminate or suspend your access to all or part of our Services for any or no reason, including without limitation, any violation of these Terms of Service or our <a [routerLink]="['/privacy']">Privacy Statement.</a></li>
                    </ul>
                    <p>Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone using our Services. YOU WAIVE AND HOLD HARMLESS DIAMOND EDGE IT AND ITS AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.</p>
                </li>

                <li class="top-level">
                    <h2 id="Termination">Termination and Suspension of Account and/or User Access</h2>
                    <p>We may suspend or terminate your account or cease providing you with all or part of the Services at any time and for any or no reason, including, but not limited to, if we reasonably believe: (i) you have violated these Terms of Service or our <a [routerLink]="['/privacy']">Privacy Statement.</a>; (ii) you create risk or possible legal exposure for Diamond Edge IT; (iii) prolonged periods of inactivity under your account; or (iv) our provision of the Services to you is no longer commercially viable. We will make reasonable efforts to notify you by the email address associated with your account or the next time you attempt to access your account, depending on the circumstances. In all such cases, the rights granted to you shall terminate, including, without limitation, your license to use the Services.</p>
                </li>

                <li class="top-level">
                    <h2 id="Third_Party">Third Party Links</h2>
                    <p>Our Services and any underlying websites or functionality, including but not limited to, the Smart Code Platform, may contain links to third-party websites or services that are not owned or controlled by Diamond Edge IT. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services.  You further acknowledge and agree that Diamond Edge IT shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with your use of or reliance on any such content, goods or services available on or through any such websites or services.</p>
                </li>

                <li class="top-level">
                    <h2 id="Changes">Changes to our Services</h2>
                    <p>We may update our Services, including but not limited to, any content located on our website, from time to time. All changes are effective immediately when we post them, and apply to all access to and use of the Services thereafter. Your continued use of the Services following the posting of revised Terms of Service means that you accept and agree to the changes.  Any of the material providing in or through our Services may be out of date at any given time, and we are under no obligation to update such material.</p>
                </li>

                <li class="top-level">
                    <h2 id="Disclaimer">Disclaimer of Warranties</h2>
                    <p class="legal-note">To the maximum extent allowed by applicable law and without affecting Your Rights as a consumer, you acknowledge and agree that the services are provided by Diamond Edge IT as-is and you assume all risks and liabilities arising from or relating to your use of and reliance upon the services, and that Diamond Edge IT makes no representation or warranty with respect thereto.</p>
				    <p class="legal-note">Diamond Edge IT hereby expressly disclaims all representations, warranties and conditions regarding the services, whether express or implied, including any representation or warranty in regard to quality, performance, non-infringement, commercial utility, merchantability or fitness of the services for a particular purpose. In addition, Diamond Edge IT expressly disclaims any express or implied obligation or warranty of the services, that could be construed to require Diamond Edge IT to provide services in such a manner to allow you to comply with any law, regulation, rule or court order applicable to the actions or functions of you. Without limiting the generality of the foregoing, we do not warrant that the services will meet any or all of your needs; will operate in all of the combinations which may be selected for use by you; or that the operation of the services will be uninterrupted, error-free or completely secure. No Diamond Edge IT employee, supplier or subcontractor is authorized to make any warranty on our behalf and if they make such warranties siteground shall not be bound by them.</p>
                </li>

                <li class="top-level">
                    <h2 id="Liability">Limitation on Liability</h2>
                    <p class="legal-note">In no event will Diamond Edge IT, its affiliates or their licensors, service providers, employees, agents, officers or directors be liable for damages of any kind, under any legal theory, arising out of or in connection with your use, or inability to use, the services, any websites linked to it, any content on the services or such other websites or any services or items obtained through the services, including any direct, indirect, special, incidental, consequential or punitive damages, including but not limited to, personal injury, pain and suffering, emotional distress, loss of revenue, loss of profits, loss of business or anticipated savings, loss of use, loss of goodwill, loss of data, and whether caused by tort (including negligence), breach of contract or otherwise, even if foreseeable. The foregoing does not affect any liability which cannot be excluded or limited under applicable law.</p>
                </li>

                <li class="top-level">
                    <h2 id="Indemnification">Indemnification</h2>
                    <p>You acknowledge and agree to indemnify, defend and hold harmless Diamond Edge IT, and to defend, fully compensate us, our affiliates, subsidiaries, parent and related companies, licensors and any third-party service providers and each of their respective officers, directors, employees, shareholders and agents (each an "indemnified party" and, collectively, "indemnified parties") from and against any and all claims, damages, losses, liabilities, suits, actions, demands, proceedings (whether legal or administrative), and expenses (including, but not limited to, reasonable attorneys' fees) threatened, asserted, or filed by a third party against any of the indemnified parties arising out of or relating to: (i) your use of the Service, including the Smart Code Platform, and any information obtained therein; (ii) any violation by you of these Terms of Service, our policies or documents which are incorporated herein, or any law, including but not limited to, our <a [routerLink]="['/privacy']">Privacy Statement.</a>; (iii) any breach of any of your representations, warranties or covenants contained in these Terms of Service; and/or (iv) any acts or omissions by you. The terms of this section shall survive any termination of these Terms of Service.</p>
                </li>

                <li class="top-level">
                    <h2 id="Governing">Governing Law and Jurisdiction</h2>
                    <p>All matters relating to the Websites and these Terms of Service and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of Florida without giving effect to any choice or conflict of law provision or rule (whether of the State of Florida or any other jurisdiction).</p>
				    <p>Any legal suit, action or proceeding arising out of, or related to, these Terms of Service or the Services, including any services or content obtained through the use of the Services, including but not limited to, your use of the Smart Code Platform, shall be instituted exclusively in the federal courts of the United States or the courts of the State of Florida and County of Miami-Dade, provided, however, we retain the right to bring any suit, action or proceeding against you for breach of these Terms of Service in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</p>
                </li>

                <li class="top-level">
                    <h2 id="Arbitration">Arbitration</h2>
                    <p>Please read this section carefully as it contains procedures for mandatory binding arbitration and a class action waiver. By agreeing to the agreement, you agree that you are required to resolve any claim that you may have against Diamond Edge IT on an individual basis in arbitration, as set forth in this section, and you are herebty precluded from bringing any class, collective, or representative action against Diamond Edge IT, and you are also hereby precluded from participating in or recovering relief under any current or future class, collective, consolidated, or representative action brought against Diamond Edge IT by someone else. By entering into this agreement and utilizing the services, you expressly acknowledge that you have read and understand all of the terms of this <a (click)="onClick('Arbitration')">Section 20</a> and have taken the time to consider the consequences of this important decision.</p>
                    <ol>
                        <li><strong>Agreement to Binding Arbitration between You and Diamond Edge IT.</strong> You acknowledge and agree that any dispute, claim or controversy arising out of or relating to: (a) this Agreement or the existence, breach, termination, enforcement, interpretation or validity thereof; and/or (b) your access to or use of the Services at any time, whether before or after the date you agreed to the Agreement, will be settled by binding arbitration, and not in a court of law, unless otherwise agreed-to by Diamond Edge IT. You acknowledge and agree that you are waiving the right to a trial by jury or to participate as a plaintiff or class member in any purported class action or representative proceeding. Unless Diamond Edge IT agrees otherwise in writing, any arbitration will be conducted only on an individual basis and not in a class, collective, consolidated, or representative proceeding. However, you and Diamond Edge IT each retain the right to bring an individual action in small claims court and the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation or violation of a party's copyrights, trademarks, trade secrets, patents or other intellectual property rights.</li>
                        <li><strong>Rules and Governing Law.</strong> The arbitration will be administered by the American Arbitration Association ("AAA") in accordance with the AAA's Consumer Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes (the "AAA Rules") then in effect, except as modified by this Arbitration Agreement. The AAA Rules are available at <a href="https://www.adr.org/arb_med" target="_blank">www.adr.org/arb_med</a> or by calling the AAA at 1-800-778-7879. The parties agree that the arbitrator ("Arbitrator"), and not any federal, state, or local court or agency, shall have exclusive authority to resolve any disputes relating to the interpretation, applicability, enforceability or formation of this Arbitration Agreement, including any claim that all or any part of this Arbitration Agreement is void or voidable. The Arbitrator shall also be responsible for determining all threshold arbitrability issues, including issues relating to whether the Terms are unconscionable or illusory and any defense to arbitration, including waiver, delay, laches, or estoppel. Notwithstanding any choice of law or other provision in the Terms, the parties agree and acknowledge that this Arbitration Agreement evidences a transaction involving interstate commerce and that the Federal Arbitration Act, 9 U.S.C. &sect; 1 et seq. ("FAA"), will govern its interpretation and enforcement and proceedings pursuant thereto. It is the intent of the parties that the FAA and AAA Rules shall preempt all state laws to the fullest extent permitted by law. If the FAA and AAA Rules are found to not apply to any issue that arises under this Arbitration Agreement or the enforcement thereof, then that issue shall be resolved under the laws of the state of Florida.</li>
                        <li><strong>Notice Requirement; Informal Dispute Resolution.</strong>  A party who desires to initiate arbitration must provide the other party with a written Demand for Arbitration as specified in the AAA Rules. The written demand must state the nature and basis of the claim or dispute and the requested relief. A written demand to Diamond Edge IT shall be sent to the following address: Diamond Edge IT, Inc. 1065 SW 8th St #251, Miami, FL 33130. After the notice is received, the parties may attempt to resolve the claim or dispute informally. The amount of any settlement offer made by any party may not be disclosed to the arbitrator until after the arbitrator has determined the amount of the award, if any, to which either party is entitled. If the claim or dispute is not resolved within thirty (30) days after the notice is received, either party may begin an arbitration proceeding. The AAA provides a form Demand for Arbitration - Consumer Arbitration Rules at <a href="https://www.adr.org/" target="_blank">www.adr.org</a> or by calling the AAA at 1-800-778-7879. The Arbitrator will be either (1) a retired judge or (2) an attorney specifically licensed to practice law in the state of Florida and will be selected by the parties from the AAA's roster of consumer dispute arbitrators. If the parties are unable to agree upon an Arbitrator within seven (7) days of delivery of the Demand for Arbitration, then the AAA will appoint the Arbitrator in accordance with the AAA Rules.</li>
                        <li><strong>Location and Procedure.</strong> Unless you and Diamond Edge IT otherwise agree, the arbitration will be conducted in Florida. If your claim does not exceed $10,000, then the arbitration will be a binding non-appearance-based arbitration conducted solely on the basis of documents you and Diamond Edge IT submit to the Arbitrator, unless you request a hearing or the Arbitrator determines that a hearing is necessary. If your claim exceeds $10,000, your right to a hearing will be determined by the AAA Rules. Subject to the AAA Rules, the Arbitrator will have the discretion to direct a reasonable exchange of information by the parties, consistent with the expedited nature of the arbitration. The arbitration for non-appearance based arbitrations shall be conducted by telephone, online and/or based solely on written submissions, and the specific manner shall be chosen by the party initiating the arbitration. The arbitration shall not involve any personal appearance by the parties or witnesses unless otherwise agreed by the parties.</li>
                        <li><strong>Arbitrator&lsquo;s Decision.</strong> The Arbitrator will render an award within the time frame specified in the AAA Rules. Judgment on the arbitration award may be entered in any court having competent jurisdiction to do so. The Arbitrator may award declaratory or injunctive relief only in favor of the claimant and only to the extent necessary to provide relief warranted by the claimant's individual claim. An Arbitrator's decision shall be final and binding on all parties. An Arbitrator's decision and judgment thereon shall have no precedential or collateral estoppel effect. If you prevail in arbitration you will be entitled to an award of attorneys' fees and expenses, to the extent provided under applicable law. Diamond Edge IT will not seek, and hereby waives all rights Diamond Edge IT may have under applicable law to recover, attorneys' fees and expenses if Diamond Edge IT prevails in arbitration.</li>
				        <li><strong>Fees.</strong> Your responsibility to pay any AAA filing, administrative and arbitrator fees will be solely as set forth in the AAA Rules. Each party shall bear its own cost (including attorney's fees) and disbursements arising out of the arbitration and shall pay an equal share of the fees and costs of the Arbitrator.</li>
				        <li><strong>Severability and Survival.</strong> If any portion of this Dispute Resolution section is found to be unenforceable or unlawful for any reason, (1) the unenforceable or unlawful provision shall be severed from this Agreement; (2) severance of the unenforceable or unlawful provision shall have no impact whatsoever on the remainder of the Dispute Resolution requirements or the parties' ability to compel arbitration of any remaining claims on an individual basis pursuant to the Dispute Resolution requirements; and (3) to the extent that any claims must therefore proceed on a class, collective, consolidated, or representative basis, such claims must be litigated in a civil court of competent jurisdiction and not in arbitration, and the parties agree that litigation of those claims shall be stayed pending the outcome of any individual claims in arbitration.</li>
				        <li><strong>Waiver of Class or Consolidated Actions.</strong> ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS <a (click)="onClick('Arbitration')">SECTION 20</a> MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS, AND CLAIMS OF MORE THAN ONE USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER USER UNLESS SPECIFICALLY AGREED TO IN WRITING BY DIAMOND EDGE IT.</li>
                        <li><strong>Confidentiality.</strong> All aspects of the arbitration proceeding, including but not limited to, the award of the Arbitrator and compliance therewith, shall be strictly confidential. You agree to maintain confidentiality unless otherwise required by law. This subsection 9.9 shall not prevent a party from submitting to a court of law any information necessary to enforce this section 9, to enforce an arbitration award, or to seek injunctive or equitable relief.</li>
                        <li><strong>Small Claims Court.</strong> Notwithstanding the foregoing, either you or Diamond Edge IT may bring an individual action in small claims court.</li>
                        <li><strong>Emergency Equitable Relief.</strong> Notwithstanding the foregoing, either party may seek emergency equitable relief before a court having jurisdiction in order to maintain the status quo pending arbitration. A request for interim measures shall not be deemed a waiver of any other rights or obligations under this <a (click)="onClick('Arbitration')">Section 20</a>.</li>
                        <li><strong>Claims Not Subject to Arbitration.</strong> Notwithstanding the foregoing, claims of infringement or misappropriation of Diamond Edge IT and/or Third Party Providers' patent, copyright, trademark or trade secrets rights shall not be subject to this <a (click)="onClick('Arbitration')">Section 20</a>.</li>
                        <li><strong>Survival of Agreement.</strong> This <a (click)="onClick('Arbitration')">Section 20</a> shall survive the termination of your relationship with Diamond Edge IT.</li>
                    </ol>
                </li>

                <li class="top-level">
                    <h2 id="General_Provisions">General Provisions</h2>
                    <ol>
                        <li><strong>Governing Law.</strong> These Terms of Service are governed by and construed in accordance with the laws of the state of Florida without giving effect to any conflict of law principles. You agree that, to the extent applicable and expressly subject to the dispute resolution procedures set out in <a (click)="onClick('Arbitration')">Section 20</a> above, to submit to the exclusive jurisdiction of the applicable courts located in Florida in circumstances where the terms of this Agreement permit litigation in court.</li>
                        <li><strong>Waiver. </strong>No waiver of by Diamond Edge IT of any term or condition set forth in these Terms of Service shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Diamond Edge IT to assert a right or provision under these Terms of Service shall not constitute a waiver of such right or provision. If any provision of these Terms of Service is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Service will continue in full force and effect.</li>
                        <li><strong>Entire Agreement.</strong> These Terms of Service, our Privacy Policy and any other documents incorporated herein by reference constitute the sole and entire agreement between you and Diamond Edge IT with respect to the Services and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Services.</li>
                        <li id="Changes_Terms"><strong>Changes To These Terms Of Service.</strong> We may revise and update these Terms of Service from time to time in our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of the Services thereafter. Your continued use of the Services following the posting of revised Terms of Service means that you accept and agree to the changes. While we will endeavor to provide notice of any updates to these Terms of Service, you are expected to check this page from time to time so you are aware of any changes, as they are binding on you.</li>
                        <li><strong>Assignment; Successors.</strong> You may not assign or transfer this Agreement or any of its rights or obligations hereunder, without our prior explicit written consent. Any assignments in violation of the foregoing shall be null and void and of no force or effect. You acknowledge and agree that Diamond Edge IT may assign its rights and obligations under this Agreement, and may engage subcontractors in performing its duties and exercising its rights hereunder, without your further explicit consent. This Agreement shall be binding upon and shall inure to the benefit of the parties hereto and their respective successors and permitted assignees.</li>
                        <li><strong>Severability.</strong> If any one or more of the provisions contained herein or of the applicable policies of Diamond Edge IT shall, for any reason, be held invalid, illegal or unenforceable in any respect by a court of competent jurisdiction, such provision(s) will be changed and interpreted to accomplish the objectives of the provision to the greatest extent possible under any applicable law. You further agree and understand that the validity of or enforceability of any other provision (or of such provision, to the extent its application is not invalid or unenforceable) of these Terms of Service and the policies announced on our site shall not be affected.</li>
                        <li><strong>Force Majeure.</strong> With the exception of your payment obligations, neither you nor Diamond Edge IT will be responsible for any interruption, delay or other failure to fulfill any obligation under these Terms of Service resulting from acts of God, storms, flood, riots, fire, acts of civil or military authority, war, terrorism, epidemics, pandemics, shortage of power, telecommunications or internet service interruptions or other acts or causes reasonably beyond the control of the delayed party.</li>
                        <li><strong>Notices.</strong> We will send notices to you using the contact information in your account. We may send you notices by email or a notice posted to your account. We have no responsibility for notices not delivered due to outdated or inaccurate contact information.</li>
                    </ol>
                </li>

                <li class="top-level">
                    <h2 id="Contact_Us">How to Contact Us</h2>
                    <p *ngIf="userLoggedIn">All other feedback, comments, and other communications relating to the Services should be directed to <a href="mailto:support@smartcodeplatform.com">support&#64;smartcodeplatform.com</a> or call us on (855) 376-1150. For technical support visit our <a [routerLink]="['',{outlets: {sidePopup: 'supportrequest'}}]">Support Request</a>.</p>
                    <p *ngIf="!userLoggedIn">All other feedback, comments, and other communications relating to the Services should be directed to <a href="mailto:support@smartcodeplatform.com">support&#64;smartcodeplatform.com</a> or call us on (855) 376-1150. For technical support visit our <a (click)="suportRequest()">Support Request</a>.</p>
                </li>
            </ol>
        </main>

    </div>

</article>
<lib-scroll-to-top></lib-scroll-to-top>
