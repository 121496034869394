import { animate, style, transition, trigger } from '@angular/animations';
import { AfterContentInit, AfterViewInit, ElementRef, EventEmitter, HostListener, Output, Renderer2, ViewEncapsulation } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faCheck, faPen, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { InputTypes } from '../../models/formField';
import { FormsService } from '../../services/forms.service';

@Component({
  selector: 'lib-inline-edit',
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({opacity: 0}),
          animate('300ms', style({opacity: 1}))
        ]),
        transition(':leave', [
          style({opacity: 1}),
          animate('300ms', style({opacity: 0}))
        ])
      ]
    )
  ],
  templateUrl: './inline-edit.component.html',
  styleUrls: ['./inline-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InlineEditComponent implements OnInit {
  @Input() data: any;
  @Input() fieldName: string;
  @Input() settings: any;
  @Input() showEditable = false;
  @Output() focusOut: EventEmitter<number> = new EventEmitter<number>();
  @Output() changeData: EventEmitter<any> = new EventEmitter<any>();
  currency = '$';
  editMode = false;
  penIcon = faPen;
  saveIcon = faCheck;
  cancelIcon = faTimes;
  addIcon = faPlus;

  displayPhone: string;

  inlineForm:FormGroup;

  checkClickBound = this.checkClick.bind(this)

  constructor(
    private fb:FormBuilder,
    private rd: Renderer2,
    private eRef: ElementRef,
    private formsService: FormsService) {}

  ngOnInit(): void {
    if(this.settings) {
      this.inlineForm = this.fb.group(this.formsService.convertToReactiveForm(this.settings));
      if(this.settings[0].inputType === 'tel' && this.data) {
        this.displayPhone = `${this.data.countryCode || ""} ${this.data.dialCode} ${!!this.data.nationalNumber ? this.data.nationalNumber : this.data.number}`;
      }
    } else {
      this.inlineForm = this.fb.group({
        inlineInput1: [this.data]
      });
    }
  }

  getField() {
    return this.inlineForm.get(this.settings[0].inputName);
  }

  requiredInvalid(): boolean {
    // return (this.formGroupParameter.get(this.fieldSettings.inputName).touched || this.formGroupParameter.get(this.fieldSettings.inputName).dirty) && this.formGroupParameter.get(this.fieldSettings.inputName).errors?.required;
    return (this.getField().touched || this.getField().dirty) && this.getField().errors?.required;
  }

  updateValue() {
    if(this.settings) {
      this.data = this.inlineForm.value[this.settings[0].inputName];
    } else {
      this.data = this.inlineForm.value.inlineInput1;
    }
    if (this.settings[0].inputType.toString() === "tel") {
      if(this.data) {
        this.displayPhone = `(${this.data.countryCode}) ${this.data.dialCode} ${this.data.nationalNumber}`;
        this.changeData.emit(JSON.stringify(this.data) );
      } else {
        this.changeData.emit("");
      }

    } else {
      this.changeData.emit(this.data);
    }


    document.removeEventListener('click',this.checkClickBound, false);
    this.editMode = false;
    // document.removeEventListener('click', this.checkClick.bind(this));
  }

  setToEdit() {

    if(this.settings[0].inputType.toString() === "tel") {
      // this.settings[0].value = {
      //   number: "718-916-8252",
      //   internationalNumber: "+1 718-916-8252",
      //   nationalNumber: "(718) 916-8252",
      //   e164Number: "+17189168252",
      //   countryCode: "US",
      //   dialCode: "+1"
      // }
      // this.settings[0].value = JSON.parse(this.data);
      this.settings[0].value = this.data;
      this.inlineForm.get(this.settings[0].inputName).patchValue(this.settings[0].value);
    } else {
      this.settings[0].value = this.data;
      this.inlineForm.get(this.settings[0].inputName).patchValue(this.data);
    }


    this.inlineForm.get(this.settings[0].inputName).markAsUntouched();
    this.editMode = true;


    setTimeout(() => {
      if(this.settings) {
        if(this.settings[0].inputType === InputTypes.tel) {
          const element = this.rd.selectRootElement('#' + this.settings[0].inputName);
          element.focus();
        } else {
          const element = this.rd.selectRootElement('#' + this.settings[0].id);
          element.focus();
        }
      } else {
        const element = this.rd.selectRootElement('#' + this.fieldName + 'Id');
        element.focus();
      }
      document.addEventListener('click', this.checkClickBound, false);
    }, 500);
  }

  checkClick(event) {
    // console.log(event);
    if(this.eRef.nativeElement.contains(event.target)) {
          // console.log('click inside target');
        } else {
          // console.log('click outside target');
          this.cancel();
          // if(this.editMode) this.cancel();
        }
  }

  cancel() {
    document.removeEventListener('click',this.checkClickBound, false);
    this.editMode=false;

  }

  onFocusOut() {
    this.focusOut.emit(this.data);
  }

}
