<!-- <button class="primary" (click)="toggleEditable()">Show Editable</button> -->
<div class="sidebar-section">
  <!-- <div class="form-group"> -->
  
    <div class="input-container toggle-container" [class.min]="">
      <input
        type="checkbox"
        class="toggle"
        id="toggleEditable"
        (click)="toggleEditable()"
        [checked]="userManager.userInfoEditablePreview" />
      <label for="toggleEditable" class="toggle-label right-sided">
        <fa-icon [icon]="penIcon"></fa-icon>
        <span class="text">Edit</span>
      </label>
    </div>
  
  <!-- </div> -->

</div>