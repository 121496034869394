import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { faChevronLeft, faDownload, faInfoCircle, faMask, faMaskFace, faMasksTheater, faPhoneSquare, faPlus, faPlusCircle, faRedoAlt, faSleigh, faStickyNote, faTimesCircle, faTrash, faUser, faUserCheck, faUserLock, faUserSecret, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { AppSettingsService } from 'projects/nine-gold-lib/src/lib/services/app-settings.service';
import { ConfirmDialog } from 'projects/nine-gold-lib/src/lib/shared/confirm-dialog/confirm-dialog';
import { ConfirmDialogService } from 'projects/nine-gold-lib/src/lib/shared/confirm-dialog/confirm-dialog.service';
// import { ButtonTypes, NgListDataRow } from 'projects/nine-gold-lib/src/lib/shared/ng-list/ng-list-data';
import { fadeInAnimation } from 'projects/nine-gold-lib/src/lib/animations/fade-in.animation';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NotificationService } from 'projects/nine-gold-lib/src/public-api';
import { FormResultMessageFormat } from 'projects/nine-gold-lib/src/lib/shared/notification/notification';
import { ApiUser, UserTypesData } from 'projects/nine-gold-lib/src/lib/models/user';
import { faCopy, faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { UserService } from 'projects/nine-gold-lib/src/lib/services/user.service';
import { ApiLicenseAdmin, ApiLicenseStatus, ApiLicenseType, ApiProductStatus,  } from 'projects/nine-gold-lib/src/lib/models/product';
import { ApiCompanyExtended, ICompanyFinal } from 'projects/nine-gold-lib/src/lib/models/company';
import { Router } from '@angular/router';
import { ButtonTypes, NgListDataRow } from 'projects/nine-gold-lib/src/lib/shared/listComponents/ng-list/ng-list-data';
import { CompaniesListNewService } from '../services/companies-list-new.service';
import { ApiService } from 'projects/nine-gold-lib/src/lib/services/api.service';
import { UserInterfaceService } from 'projects/nine-gold-lib/src/lib/services/user-interface.service';
import { ProductService } from 'projects/nine-gold-lib/src/lib/services/product.service';
import { formatDistanceToNow, formatDuration, intervalToDuration } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgModel } from '@angular/forms';




const slideInOut  = trigger(
  'slideInOut', [
    state('open',style({opacity: 1})),
    transition('* => open', [
      style({opacity: 0}),
      animate('500ms 300ms ease-in', style({opacity: 1}))
    ]),
    transition('* => closed', [
      animate('300ms ease-in', style({opacity: 0}))
    ])
  //   state('open', style({
  //     height: '200px',
  //     opacity: 1,
  //     backgroundColor: 'yellow'
  //  })),
  //  state('closed', style({
  //     height: '100px',
  //     opacity: 0.8,
  //     backgroundColor: '#c6ecff'
  //  })),
  //  transition('* => closed', [
  //     animate('1s')
  //  ]),
  //  transition('* => open', [
  //     animate('0.5s')
  //  ]),
    // transition('opem', [
    //   style({opacity: 0, height: 0, overflow: 'hidden'}),
    //   group([
    //     animate('.01s 0.5s', style({height: '*', overflow: 'visible'})),
    //     animate('.5s .51s', style({opacity: 1}))
    //   ]),

    // ]),
    // transition('closed', [
    //   style({opacity: 1}),
    //   animate('.5s', style({opacity: 0}))
    // ])
  ]
);

interface ITab {
  title: string;
  content: string;
  removable: boolean;
  disabled: boolean;
  active?: boolean;
  customClass?: string;
}

interface licenseRowData {
  companyId: string, companyName: string, productName: string, licenseId: string
}

interface companyRowData {
  companyId: string, companyName: string;
}

@Component({
  selector: 'app-super-admin',
  templateUrl: './super-admin.component.html',
  styleUrls: ['./super-admin.component.scss'],
  animations: [fadeInAnimation,slideInOut]
})
export class SuperAdminComponent implements OnInit {

  confirmProductName: string;
  confirmProductPattern: string;

  confirmCompanyName: string;
  confirmCompanyRemovalPattern: string;

  licenseNotes: ApiLicenseAdmin;

  modalRef: BsModalRef;

  loading = false;

  selectedRow: ICompanyFinal = null;

  removeLicenseConfirm: licenseRowData;
  removeCompanyDate: companyRowData;

  superAdminIcon = faUserShield;
  infoIcon = faInfoCircle;

  addLicenseButton = faPlusCircle;
  openButtons = faChevronLeft;
  noteIcon = faStickyNote;
  downloadIcon = faDownload;
  deleteIcon = faTrash;
  renewIcon = faRedoAlt;
  statusExpiredIcon = faTimesCircle;
  copyIcon = faCopy;



  openLicenseMenu: boolean[] = [];

  loadingUsers = false;

  companiesListData: NgListDataRow[] = [];

  // userDetailsData: UserApiDataFull = null;
  userDetailsData: ApiUser = null;
  userDetailsRoleIcon: UserTypesData;

  @ViewChild('userDetails')
    private userDetails: TemplateRef<any>

  @ViewChild('companyDetailsTemplate')
    private companyDetailsTemplate: TemplateRef<any>

  @ViewChild('deleteLicenseConfirm') deleteLicenseConfirm: TemplateRef<any>;

  @ViewChild('deleteCompanyConfirm') deleteCompanyConfirm: TemplateRef<any>;


  @ViewChild('licensNotesTemplate') licensNotesTemplate: TemplateRef<any>;

  protected companyDetails: ApiCompanyExtended;


  states: string[] = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Dakota',
    'North Carolina',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming'
  ];

  userIcon = faUser;
  phoneIcon = faPhoneSquare;
  emailIcon = faPaperPlane;
  impersonateIcon = faMasksTheater;
  addIcon = faPlus;
  regUsersIcon = faUserCheck;
  maxUsersIcon = faUserLock;

  tabs: ITab[] = [
    {
      title: 'Companies',
      content: '',
      disabled: false,
      removable: false,
      active: true
    }
  ];

  companiesDetails: {title: string}[] = [];

  selectedCompanyName: string = null;

  constructor(
    private http: HttpClient,
    private settings: AppSettingsService,
    private confirmService: ConfirmDialogService,
    private notif: NotificationService,
    private userService: UserService,
    private router: Router,
    public companiesListNewService: CompaniesListNewService,  //looks like this one is used
    public apiService: ApiService,
    private uiService: UserInterfaceService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private productService: ProductService
  ) { }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
    this.companiesListNewService.LoadPage(1, true);
    setTimeout(() => {
      this.companiesListData = this.companiesListNewService.GetTableRows();
    }, 1000);

  }

  addNewTab(name: string): void {
    const newTabIndex = this.tabs.length + 1;
    this.tabs.push({
      title: `${name}`,
      content: `Dynamic content ${newTabIndex}`,
      disabled: false,
      removable: true
    });
    this.companiesDetails.push({title: name});
  }

  removeTabHandler(tab: ITab): void {
    this.tabs.splice(this.tabs.indexOf(tab), 1);
  }

  impersonate(companyId: string): void {
    this.apiService.impersonateCompany(companyId).subscribe({
      next: result => {
        setTimeout(() => {
          this.apiService.sessionCompany().subscribe({
            next: result => {},
            error: err => console.log(err)
          });
        }, 1000);

      },
      error: err => console.log(err)
    })
  }

  newLicenseForm(companyId: string, productId?: string, action?: string, licenseId?: string): void {
    let routeArray = ['license',companyId];
    if(productId) routeArray.push(productId);
    if(action) routeArray.push(action);
    if(licenseId) routeArray.push(licenseId);
    this.productService.licenseAdded.subscribe(compId=>{
      setTimeout(() => {
        let companyData = this.companiesListNewService.listData.find(r=>r.rowIdentifier === compId);
        if(companyData) this.setCompanyDetails(companyData);
        this.apiService.getCompany(compId).subscribe(res=>{
          this.companiesListNewService.specialHandling(companyData.listRow.find(c=>c.columnName === 'licenseType'),res);
        });
      }, 100);
    })
    this.router.navigate([{ outlets: { sidePopup: routeArray }}]);
    // TODO: reload licenses for this (already opened) product.
  }

  private textToClipboard (text) {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    // TODO: find altertative to execCommand above
    document.body.removeChild(dummy);
  }

  protected copyToClipboard(code: string, name: string): void {
    this.textToClipboard(code);

    this.toastr.info( "Copied to clipboard", `${name} was copied to clipboard`, {timeOut: 6000, enableHtml: false, positionClass: 'toast-bottom-right' });
  }

  impersonateCompany(companyId: string, companyName: string): void {
    this.confirmService.confirm(this.uiService.getConfirmMessage('impersonateCompany',[companyName])).subscribe(answer => {
      if(answer) {
        this.settings.impersonateCompany({id: companyId, name: companyName});
        this.productService.setLicensesFromApi();
      }
    });
  }

  buttonClick(event: any):void {
    if(event.button.type === ButtonTypes.info) {
      if(this.tabs.length < 4) {
        this.addNewTab(event.row.listRow[1].value);
        this.tabs[this.tabs.length - 1].active = true;
      } else {
        this.notif.warning('Too many tabs','You can have only 3 tabs open at a time. Close some to open new ones.', FormResultMessageFormat.popup, 3000, null);
      }

    }
    if(event.button.type === ButtonTypes.impersonate) {
      this.impersonateCompany(event.row.listRow[0].value, event.row.listRow[1].value);
    }

    if(event.button.type === ButtonTypes.delete) {
      this.deleteCompanyConfirmation(event.row.listRow[0].value, event.row.listRow[1].value);
    } else if(event.button.type === ButtonTypes.cell_info) {
      if(event.cell.columnName === 'companyOwnerUsername') {
        this.userDetailsData = this.companiesListNewService.apiResutls.find(el => el.id === event.row.rowIdentifier).owner;
        this.userDetailsData.initials = this.userService.setUserInitials(this.userDetailsData.fullName);
        // TODO: fix user phone
        this.userDetailsRoleIcon = this.userService.getHighestRoleFromArray(this.userDetailsData.roles);
        if (this.userDetailsData.phone && this.userDetailsData.phone !== "** removed **") {
          let p = JSON.parse(this.userDetailsData.phone);
          this.userDetailsData.rawPhone = p.e164Number;
          this.userDetailsData.displayPhone = `(${p.countryCode}) ${p.dialCode} ${p.nationalNumber}`;
        }

        this.notif.info('', "", FormResultMessageFormat.modalHtml, 0, this.userDetails);
      }
    } else if(event.button.type === ButtonTypes.license_add) {
      let reloadEvemt = this.uiService.customUIevent.subscribe((reload: Boolean) => {
        if(reload) this.companiesListNewService.LoadTableData(true);
        // TODO: open company details
        reloadEvemt.unsubscribe();
      })
      this.router.navigate([{ outlets: { sidePopup: ['license',event.row.rowIdentifier] }}]);
    }
  }

  private deleteCompanyConfirmation(companyId: string, companyName: string):void {
    this.removeCompanyDate = {companyId: companyId, companyName: companyName};

    this.confirmCompanyRemovalPattern = `^${companyName}$`;
    this.confirmCompanyName = '';
    this.modalRef = this.modalService.show(this.deleteCompanyConfirm);
    this.modalRef.onHidden.subscribe(r=>{
      this.removeCompanyDate = null;
      this.confirmCompanyRemovalPattern = null;
      this.modalRef.onHidden.unsubscribe();
      this.confirmCompanyName = '';
    });
  }

  protected deleteCompany(companyId:string, companyName: string): void {
    this.modalRef.hide();
    this.loading = true;
    setTimeout(()=>{
      this.apiService.deleteCompany(companyId).subscribe({
        next: res => {
          this.loading = false;
          this.notif.success("Company was removed.", `The Company: <strong>${companyName}</strong> was removed.`,FormResultMessageFormat.modal,6000,null,true);
          this.companiesListNewService.LoadTableData(true);
        },
        error: err => {
          this.loading = false;
          console.log(err);
          if(err.status >= 500) {
            this.notif.error(`Error Status ${err.status}`,err.error.friendlyMessage,FormResultMessageFormat.popup,6000,null);
          }
        }
      });
    },500);
  }

  protected downloadLicense(url: string, filename: string): void {
    this.loading = true;
    this.http.get(this.settings.baseSetting.apiUrl + url,{withCredentials: true, responseType: 'arraybuffer'}).subscribe((response)=>{
      let blob = new Blob([response],{type: 'application/octet-stream'});
          let newUrl = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = newUrl;
          a.download = filename;
          a.click();
          this.loading = false;
    },
    (error:any)=>{
      this.loading = false;
      this.notif.error('Error', 'There was an Error loading the file.',FormResultMessageFormat.popup,5000,null);
    });
  }



  openCloseMenu(index:number):void {
    this.openLicenseMenu[index] = !this.openLicenseMenu[index];
  }

  deleteLicense(remLicenseData: licenseRowData): void {
    this.modalRef.hide();
    this.loading = true;

    setTimeout(() => {
      this.apiService.deleteLicense(remLicenseData.companyId, remLicenseData.licenseId).subscribe({
        next: res=> {
          this.loading = false;
          this.notif.success("The License was removed", `The License for <strong>${remLicenseData.productName}</strong> of company: <strong>${remLicenseData.companyName}</strong> was removed.`,FormResultMessageFormat.modal,6000,null,true);
          let companyData = this.companiesListNewService.listData.find(r=>r.rowIdentifier === remLicenseData.companyId);
          if(companyData) this.setCompanyDetails(companyData);
          this.apiService.getCompany(remLicenseData.companyId).subscribe(res=>{
            this.companiesListNewService.specialHandling(companyData.listRow.find(c=>c.columnName === 'licenseType'),res);
          });
        },
        error: err=> {
          this.loading = false;
          console.log(err);
          if(err.status >= 500) {
            this.notif.error(`Error Status ${err.status}`,err.error.friendlyMessage,FormResultMessageFormat.popup,6000,null);
          }
        }
      });
    }, 500);

  }
  noSubmit():void {
    this.notif.warning("Click Confirm Button","",FormResultMessageFormat.popup,3000,null);
  }

  deleteLicenseConfirmation(remLicenseData: licenseRowData): void {


    this.removeLicenseConfirm = remLicenseData;
    this.confirmProductName = '';
    this.confirmProductPattern = `^${remLicenseData.productName}$`;

    this.modalRef = this.modalService.show(this.deleteLicenseConfirm);
    this.modalRef.onHidden.subscribe(r=>{
      this.removeLicenseConfirm = null;
      this.confirmProductName = '';
      this.modalRef.onHidden.unsubscribe();
    });

  }


  protected updateNotes(licens: ApiLicenseAdmin): void {
    // TODO: addd PUT license API once it's supported
  }

  protected editLicenseNotes(license: ApiLicenseAdmin): void {
    this.licenseNotes = license;
    this.modalRef = this.modalService.show(this.licensNotesTemplate);
  }

  setCompanyDetails(companyData: NgListDataRow): void {
    this.companyDetails = this.companiesListNewService.apiResutls.find(c => c.id === companyData.rowIdentifier);
    let prCat = this.productService.getProductCategoriesList();
    // let prCat2 = prCat.find(pr=>pr.id === l.productCategoryId)
    this.companyDetails.freeLicenses = [];
    this.companyDetails.currentLicenses.forEach(l=>{
      let prC = prCat.find(pr=>pr.id === l.productCategoryId);
    });
    this.companyDetails.freeLicenses = this.companyDetails.currentLicenses.filter(l => prCat.find(pr=>pr.id === l.productCategoryId)?.status === ApiProductStatus.free);
    // this.companyDetails.freeLicenses = this.companyDetails.currentLicenses.filter(l => this.productService.getProductCategoriesList().find(pr=>pr.id === l.productCategoryId).status === ApiProductStatus.free);
    this.companyDetails.premiumLicenses = this.companyDetails.currentLicenses.filter(l => l.licenseParams.type !== 'FreeLicense' || this.productService.getProductCategoriesList().find(pr=>pr.id === l.productCategoryId)?.status !== ApiProductStatus.free);

    this.companyDetails.freeLicenses.forEach(l => {
      l.productName = this.productService.getShortProductName(l.productId);
    });

    this.openLicenseMenu = [];

    this.companyDetails.maxUsers = 1;

    // TODO: this needs to be finished once the either license params have maxUsers property
    // TODO: or there is maxUsers property on company level
    // this.companyDetails.premiumLicenses.forEach(l => {
    //   if(l.licenseParams.maxUsers && l.licenseParams.maxUsers > this.companyDetails.maxUsers) this.companyDetails.maxUsers = l.licenseParams.maxUses;
    // });
    // TODO: end of todo above

    this.apiService.getLicenses(companyData.rowIdentifier).subscribe({
      next: result => {
        this.productService.getProductCategoriesList().forEach(pr => {
          // TODO: if premium license is usage, get status from API results
          if(pr.status !== ApiProductStatus.free && !this.companyDetails.premiumLicenses.find(l => l.productCategoryId === pr.id)) {
            let resultsLicense = result.find(l=>l.productCategoryId === pr.id);
            if(resultsLicense) {
              if(resultsLicense.status === ApiLicenseStatus.expired && new Date(resultsLicense.start) > new Date()) {
                resultsLicense.status = ApiLicenseStatus.scheduled;
              }
              this.companyDetails.premiumLicenses.push(resultsLicense);
            } else {
              this.companyDetails.premiumLicenses.push({
                productId: pr.id,
                id: '',start:'',end:'',
                productName: pr.name,
                status: ApiLicenseStatus.no_license,
                purchaseOrder:'',invoiceNumber:'',uses:null,licenseFilename:null,licenseUrl:null,licenseParams:{type:null},notes:'',
                productCategory: null,
                productCategoryId: '',
                productCategoryName:''
              });
            }
          }
          // !Temporaty fix for missing license URL
          this.companyDetails.premiumLicenses.forEach(l=>{
            let res = result.find(l2=>l2.id === l.id);
            if (res) {
              l.licenseUrl = res.licenseUrl;
            }
          })
        });

        this.companyDetails.premiumLicenses.forEach(l=>{
          this.openLicenseMenu.push(false);
          // TODO: Update buttons choice (new, renew, update)
          l.addNew = l.status === ApiLicenseStatus.no_license || [ApiLicenseType.FreeLicense,ApiLicenseType.TrialLicense].includes(l.licenseParams.type);
          l.renew = [ApiLicenseType.EnterpriseLicense,ApiLicenseType.ProjectLicense,ApiLicenseType.UsageLicense].includes(l.licenseParams.type) && l.status !== 'scheduled';

          // * setting license period
          if(l.licenseParams.type && l.licenseParams.type !== ApiLicenseType.UsageLicense) {
            l.licensePeriod = intervalToDuration({start: new Date(l.start),end: new Date(l.end)});
            l.licensePeriodString = formatDuration(l.licensePeriod, {format: ['years', 'months', 'weeks', 'days']});
          } else {
            l.licensePeriodString = 'N/A';
          }

          // *  setting license remaining

          // let durationString = formatDuration(timeDuration, {format: ['years', 'months', 'weeks', 'days']});
          if(l.licenseParams.type && l.licenseParams.type !== ApiLicenseType.UsageLicense) {
            let timeDuration = intervalToDuration({start: new Date(), end: new Date(l.end)});
            let durationString = formatDuration(timeDuration, {format: ['years', 'months', 'weeks', 'days']});
            // let durationString = formatDistanceToNow(new Date(l.end), {addSuffix: false});
            if(l.status === ApiLicenseStatus.valid) {
              l.licenseTiming = `Remaining ${durationString}`;
            } else if(l.status === ApiLicenseStatus.expired) {
              // durationString = formatDistanceToNow(new Date(l.end), {addSuffix: true});
              durationString = formatDuration(timeDuration, {format: ['years', 'months', 'weeks', 'days']});
              // l.licenseTiming = `<span class='timing-value'>${durationString}</span><span class="pronoun">ago</span>`;
              l.licenseTiming = `${durationString} ago`;
            } else if(l.status === ApiLicenseStatus.scheduled) {
              durationString = formatDistanceToNow(new Date(l.start), {addSuffix: true});
              l.licenseTiming = `Starting ${durationString}`;
            }
          }

          // *  setting license type display value
          l.licenseTypeText = this.productService.typeListNew.find(pr=>pr.name === l.licenseParams.type)?.text || 'Unknown';

          // TODO: renew - if expired, expiring or exhausted
          // l.upgrade = l.status
          // TODO: update status
          // TODO: update length
          // TODO: update project Type

          if(l.licenseParams.maxUsers && l.licenseParams.maxUsers > this.companyDetails.maxUsers) this.companyDetails.maxUsers = l.licenseParams.maxUsers;

        });

          this.loadingUsers = true;
          setTimeout(() => {
            this.apiService.getUsersList(companyData.rowIdentifier).subscribe({
              next: result => {
                this.companyDetails.registeredUsers = result['membersList']?.length;
                this.loadingUsers = false;
              },
              error: err => {
                console.log(err);
                this.loadingUsers = false;
              }
            });
          }, 200);

      },
      error: err => console.log(err)
    });

    this.companyDetails.deployment = this.companyDetails.premiumLicenses.some(l => l.licenseParams.onPremExecutionId);

    this.companyDetails.canAddLicense = true;
    // CHECKING IF CAN ADD LICENSE
    this.companyDetails.premiumLicenses.forEach(l=>{
      if(['ProjectLicense','UsageLicense','EnterpriseLicense'].includes(l.licenseParams.type) && l.status === 'valid') {
        this.companyDetails.canAddLicense = false;
      }
    });

    // get numeber of registered users
  }




}
