o<div class="content-wrapper">
  <svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" width="34.66486mm" height="37.799946mm" viewBox="0 0 34.66486 37.799946" version="1.1" id="svg202" inkscape:version="1.0.1 (3bc2e813f5, 2020-09-07)" sodipodi:docname="drawing.svg">
    <defs id="defs196" />
    <metadata id="metadata199"></metadata>
    <g inkscape:label="Layer 1" inkscape:groupmode="layer" id="layer1" transform="translate(-72.625903,-76.350022)">
        <g id="symbol" transform="matrix(0.26458333,0,0,0.26458333,-90.440296,65.473001)" style="fill:#77a7ff;fill-opacity:1">
            <path class="cls-2" d="M 912.25,389.89 941.76,379 a 63.83,63.83 0 0 0 -3.54,-8 l -27.91,14.46 a 32.71,32.71 0 0 1 1.94,4.43 z" transform="translate(-198.23,-282.89)" id="path159" style="fill:#77a7ff;fill-opacity:1" />
            <path class="cls-2" d="m 908.9,383 26.53,-16.86 a 62.35,62.35 0 0 0 -3.59,-5.1 l -24.79,19.33 c 0.66,0.85 1.27,1.73 1.85,2.63 z" transform="translate(-198.23,-282.89)" id="path161" style="fill:#77a7ff;fill-opacity:1" />
            <path class="cls-2" d="m 913,410.32 30.34,8.23 a 65.09,65.09 0 0 0 2.2,-13.41 l -31.39,-1.84 a 33.75,33.75 0 0 1 -1.15,7.02 z" transform="translate(-198.23,-282.89)" id="path163" style="fill:#77a7ff;fill-opacity:1" />
            <path class="cls-2" d="m 914.16,399.45 31.4,-1.58 A 65.44,65.44 0 0 0 943.62,385 l -30.47,7.81 a 33.48,33.48 0 0 1 1.01,6.64 z" transform="translate(-198.23,-282.89)" id="path165" style="fill:#77a7ff;fill-opacity:1" />
            <path class="cls-2" d="m 908.26,420.53 25.94,17.77 a 65.84,65.84 0 0 0 7.09,-13.51 L 912,413.43 a 33.75,33.75 0 0 1 -3.74,7.1 z" transform="translate(-198.23,-282.89)" id="path167" style="fill:#77a7ff;fill-opacity:1" />
            <path class="cls-2" d="m 899.13,429.6 17.34,26.23 A 65.92,65.92 0 0 0 929.8,444 L 906,423.48 a 34.18,34.18 0 0 1 -6.87,6.12 z" transform="translate(-198.23,-282.89)" id="path169" style="fill:#77a7ff;fill-opacity:1" />
            <path class="cls-2" d="M 880.07,435.41 A 34.13,34.13 0 0 1 878,367.22 V 380 l 14.69,-10.44 23.73,-16.86 -20,-14.94 L 878,324 v 11.76 a 65.57,65.57 0 1 0 31,124.4 L 895.16,431.9 a 34.07,34.07 0 0 1 -15.09,3.51 z" transform="translate(-198.23,-282.89)" id="path171" style="fill:#77a7ff;fill-opacity:1" />
        </g>
    </g>
  </svg>

  <article class="page-content" >
    <header class="homepage-banner platform-banner" role="banner" style="transition: background-image 1s ease-in-out;" [defaultImage]="'/assets/images//output-onlinejpgtools-1.jpg'" [lazyLoad]="'/assets/images/banner-xlg.jpg'">

      <div class="banner-inner">

        <div class="creative">
          <p wmAnimate="landing" speed="normal" delay="500ms" class="creative-api">minimize development costs</p>
          <p wmAnimate="landing" speed="normal" delay="800ms" class="creative-gen">automated</p>
          <p wmAnimate="landing" speed="normal" delay="1100ms" class="creative-tools">integration</p>
          <p wmAnimate="landing" speed="normal" delay="1400ms" class="creative-conv">faster gtm</p>
          <p wmAnimate="landing" speed="normal" delay="1700ms" class="creative-rapid">code quality</p>
          <p wmAnimate="landing" speed="normal" delay="2000ms" class="creative-one-stop">breaks technology silos</p>
        </div>


        <h1 wmAnimate="landing" speed="normal" delay="1000ms">smart code<span>developer tools for<strong> system integration</strong> projects</span></h1>
      </div>
    </header>

    <main role="main">

      <section class="headquote-section platform-home-headquote-section">
        <div class="section-inner">
          <h2 once="true" speed="fast" wmAnimate="fadeInUp" aos="0.25">Developer tools aimed at reducing the total cost of ownership for organizations wanting to migrate their integrations to a new architecture.</h2>
          <ul>
            <li>Mitigate risk with our phased approach</li>
            <li>Give you freedom of choice with our decentralized strategy</li>
            <li>Standardize code for operational efficiency</li>
            <li>Reduce replacement costs with our patented code generators</li>
          </ul>
        </div>
      </section>

      <section class="strategy more-bottom-margin">
        <div class="section-inner">
          <h2>Bringing Our Strategy to You</h2>
          <div class="three-boxes-with-icons four-boxes">

            <section class="support-box box" wmAnimate="landing" speed="normal" once="true" aos="0.5" delay="300ms">
              <div class="box-background"></div>
              <div class="box-text">
                <p>We'll never lock you into anything you can't control yourself.</p>
              </div>
            </section>

            <section class="support-box box" wmAnimate="landing" speed="normal" once="true" aos="0.5" delay="800ms">
              <div class="box-background"></div>
              <div class="box-text">
                <p>Our goal is always to accelerate choice within your environment.</p>
              </div>
            </section>

            <section class="support-box box" wmAnimate="landing" speed="normal" once="true" aos="0.5" delay="1200ms">
              <div class="box-background"></div>
              <div class="box-text">
                <p>Our strategy is to provide standardization across your systems.</p>
              </div>
            </section>

            <section class="support-box box" wmAnimate="landing" speed="normal" once="true" aos="0.5" delay="1500ms">
              <div class="box-background"></div>
              <div class="box-text">
                <p>Our tools reduce your replacement costs.</p>
              </div>
            </section>

          </div>
        </div>

      </section>

      <section class="more-bottom-margin">
        <div class="section-inner">
          <div class="transparetn-block-columns">
            <div class="transparent-block flipped-bg  style-2-left" once="true" speed="fast" wmAnimate="fadeInUp" delay="250ms" aos="0.25">
              <div class="transparent-block-text single-block">
                <img src="/assets/images/iPaaS_to_ClientSystems.png" alt="iPaaS to Client Systems diagram">
                <h2>Migrate Your iPaaS<br>with Minimal Disruption</h2>
                <p>Moving your APIs to decentralized client systems will help you future-proof your technology and reduce your costs significantly.</p>
                <a role="button" [routerLink]="'/our-strategy'" class="text-button dark-bg bigger" rel="noopener noreferrer">See our strategy</a>
              </div>
            </div>
            <div class="transparent-block   style-1-right" once="true" speed="fast" wmAnimate="fadeInUp" delay="250ms" aos="0.25">
              <div class="transparent-block-text space-evenly">
                <h3><strong>Smart Code</strong> works with companies to reduce their total cost of ownership.</h3>
                <div style="display: flex; justify-content: center; gap: 20px;">
                  <button [routerLink]="'/calculating-the-cost'" class="secondary no-bg lighter smaller-button">Calculating the Cost</button>
                  <button [routerLink]="'/migration-risk-assessment'" class="secondary no-bg lighter smaller-button">Migration Risk Assessment</button>
                </div>
              </div>
            </div>
          </div>
        </div>


      </section>


      <section class="">
        <div class="section-inner">
          <h2 once="true" speed="fast" wmAnimate="fadeInUp" aos="0.25" class="no-bottom-margin">
            Generate your development with <strong>Smart Code</strong>.<br>Deploy <span class="blue-underline">anywhere</span>.
          </h2>


          <div class="logos-bar" >
            <ul>
              <li>
                <figure>
                  <div class="img-container">
                    <img src="/assets/images/third-party/aws-logo-color.png" alt="AWS Logo" class="mouseout-gray">
                    <img src="/assets/images/third-party/aws-logo-blue.png" alt="AWS Logo" class="mouseout-gray">
                  </div>
                  <figcaption>AWS</figcaption>
                </figure>
              </li>
              <li>
                <figure>
                  <div class="img-container">
                    <img src="/assets/images/third-party/azure-logo-color.png" class="mouseout-gray" alt="Azure Logo">
                    <img src="/assets/images/third-party/azure-logo-blue.png" class="mouseout-gray" alt="Azure Logo">
                  </div>
                  <figcaption>Azure</figcaption>
                </figure>
              </li>
              <li>
                <figure>
                  <div class="img-container">
                    <img src="/assets/images/third-party/google-cloud-logo-color.png" class="mouseout-gray" alt="Google Cloud Logo">
                    <img src="/assets/images/third-party/google-cloud-logo-blue.png" class="mouseout-gray" alt="Google Cloud Logo">
                  </div>
                  <figcaption>GCP</figcaption>
                </figure>
              </li>
              <li>
                <figure>
                  <div class="img-container">
                    <img src="/assets/images/third-party/cloud-native-logo-color.png" class="mouseout-gray" alt="Cloud Native Logo">
                    <img src="/assets/images/third-party/cloud-native-logo-blue.png" class="mouseout-gray" alt="Cloud Native Logo">
                  </div>
                  <figcaption>Cloud Native</figcaption>
                </figure>
              </li>
              <li>
                <figure>
                  <div class="img-container">
                    <img src="/assets/images/third-party/bare-metail-color.png" class="mouseout-gray" alt="Bare Metal Logo">
                    <img src="/assets/images/third-party/bare-metail-blue.png" class="mouseout-gray" alt="Bare Metal Logo">
                  </div>
                  <figcaption>Bare Metal</figcaption>
                </figure>
              </li>
            </ul>





          </div>
        </div>
      </section>


      <section class="more-bottom-margin">
        <div class="section-inner">
          <h2 once="true" speed="fast" wmAnimate="fadeInUp" aos="0.25" class="no-bottom-margin">Featured Products</h2>

          <div class="transparent-block flipped-bg style-1-right sub-blocks sub-blocks-side-by-side margin-bottom">
            <div class="sub-block icon-top-left even-layout">
              <fa-icon class="inline-product-icon" [icon]="codeIcon"></fa-icon>
              <div>
                <h2>REST Generator</h2>
                <h5>Generates production-ready APIs with easy language selection for MuleSoft or Spring Boot Java.</h5>
                <a role="button" class="text-button lighter no-bg external-link dark-container" target="_blank" [href]="generatorLink">Explore the product</a>
              </div>

            </div>

            <div class="horizontal-line"></div>

            <div class="sub-block icon-top-left even-layout">
              <fa-icon class="inline-product-icon" [icon]="faToolsIcon"></fa-icon>
              <div>
                <h2>UTIL Generator</h2>
                <h5>Rich set of utilities to generate and convert API Specifications with extensive RAML support.</h5>
                <a role="button" class="text-button lighter no-bg external-link dark-container" target="_blank" [href]="toolsLink">Explore the product</a>
                <h5>Generate Postman Collections.</h5>
                <a role="button" class="text-button lighter no-bg external-link dark-container" target="_blank" [href]="toolsLink">Explore the product</a>

              </div>
            </div>

          </div>
        </div>


      </section>


      <section class="platform-diagram">
        <div class="section-inner">
          <div class="platform-diagram-columns">
            <div class="input-column">
              <h4>iPaaS</h4>
              <ul class="diagram-inputs-list">
                <li>Document Portal</li>
                <li>Connectors</li>
                <li>Schemas</li>
                <li>Code Generator</li>
                <li>development Tools</li>
              </ul>
            </div>

            <div class="arrows">
              <ul>
                <li class="arrow"></li>
                <li class="arrow"></li>
                <li class="arrow"></li>
                <li class="arrow"></li>
                <li class="arrow"></li>
                <li class="arrow"></li>
              </ul>
            </div>

            <div class="smartcode-column">
              <h4><img src="/assets/images/smartcode-logo-white-light-transparent.png" alt=""></h4>
              <ul class="groups">
                <li class="group">
                  <ul>
                    <li>Documents Generated from Code</li>
                    <li>Hosted on <strong>Smart Code</strong></li>
                    <li>Imported to Customer Portal</li>
                  </ul>
                </li>

                <li class="group">
                  <ul>
                    <li>Parsed Schemas For Company Use</li>
                    <li>Hosted Schemas</li>
                  </ul>
                </li>

                <li class="group">
                  <ul>
                    <li>Interoperability Between Schemas</li>
                    <li>Upgrades RAML 0.8 Schemas</li>
                  </ul>
                </li>

                <li class="group">
                  <ul>
                    <li>Patented Code Generator</li>
                    <li>Hosted on iPaaS, AWS, Azure, GCP, Cloud Native or Bare Metal</li>
                  </ul>
                </li>

                <li class="group">
                  <ul>
                    <li>Automates Scripts for Testing</li>
                    <li>Deployment Scripts for Pipelines</li>
                    <li>Deployment Scripts for Infrastructure</li>
                  </ul>
                </li>
              </ul>
            </div>

            <div class="arrows">
              <ul>
                <li class="arrow"></li>
                <li class="arrow"></li>
                <li class="arrow"></li>
                <li class="arrow"></li>
                <li class="arrow"></li>
                <li class="arrow"></li>
              </ul>

            </div>

            <div class="client-systems-columns">
              <h4><span class="smaller">Decentralized</span><span>Client Systems</span></h4>
              <ul class="client-icons">
                <li>
                  <div class="img-container">
                    <img class="clients-icon" src="/assets/images/client-systems/confluence-original.png" alt="">
                    <img class="clients-icon" src="/assets/images/client-systems/confluence-blue.png" alt="">
                  </div>
                  <span class="clients-name">Confluence</span>
                </li>
                <li>
                  <div class="img-container">
                    <img class="clients-icon" src="/assets/images/client-systems/sharepoint-original.png" alt="">
                    <img class="clients-icon" src="/assets/images/client-systems/sharepoint-blue.png" alt="">
                  </div>
                  <span class="clients-name">Sharepoint</span>
                </li>
                <li>
                  <div class="img-container">
                    <img class="clients-icon" src="/assets/images/client-systems/azure-original.png" alt="">
                    <img class="clients-icon" src="/assets/images/client-systems/azure-blue.png" alt="">
                  </div>
                  <span class="clients-name">Azure</span>
                </li>
                <li>
                  <div class="img-container">
                    <img class="clients-icon" src="/assets/images/client-systems/raml-original.png" alt="">
                    <img class="clients-icon" src="/assets/images/client-systems/raml-blue.png" alt="">
                  </div>
                  <span class="clients-name">RAML</span>
                </li>
                <li>
                  <div class="img-container">
                    <img class="clients-icon" src="/assets/images/client-systems/openapi-original.png" alt="">
                    <img class="clients-icon" src="/assets/images/client-systems/openapi-blue.png" alt="">
                  </div>
                  <span class="clients-name">OpenAPI</span>
                </li>
                <li>
                  <div class="img-container">
                    <img class="clients-icon" src="/assets/images/client-systems/aws-original.png" alt="">
                    <img class="clients-icon" src="/assets/images/client-systems/aws-blue.png" alt="">
                  </div>
                  <span class="clients-name">AWS</span>
                </li>
                <li>
                  <div class="img-container">
                    <img class="clients-icon" src="/assets/images/client-systems/mulesoft-original.png" alt="">
                    <img class="clients-icon" src="/assets/images/client-systems/mulesoft-blue.png" alt="">
                  </div>
                  <span class="clients-name">MuleSoft</span>
                </li>
                <li>
                  <div class="img-container">
                    <img class="clients-icon" src="/assets/images/client-systems/springboot-original.png" alt="">
                    <img class="clients-icon" src="/assets/images/client-systems/springboot-blue.png" alt="">
                  </div>
                  <span class="clients-name">Spring Boot</span>
                </li>
                <li>
                  <div class="img-container">
                    <img class="clients-icon" src="/assets/images/client-systems/tyk-original.png" alt="">
                    <img class="clients-icon" src="/assets/images/client-systems/tyk-blue.png" alt="">
                  </div>
                  <span class="clients-name">TYK</span>
                </li>
                <li>
                  <div class="img-container">
                    <img class="clients-icon" src="/assets/images/client-systems/postman-original.png" alt="">
                    <img class="clients-icon" src="/assets/images/client-systems/postman-blue.png" alt="">
                  </div>
                  <span class="clients-name">Postman</span>
                </li>
                <li>
                  <div class="img-container">
                    <img class="clients-icon" src="/assets/images/client-systems/tyraform-original.png" alt="">
                    <img class="clients-icon" src="/assets/images/client-systems/tyraform-blue.png" alt="">
                  </div>
                  <span class="clients-name">Terraform</span>
                </li>
                <li>
                  <div class="img-container">
                    <img class="clients-icon" src="/assets/images/client-systems/git-original.png" alt="GIT">
                    <img class="clients-icon" src="/assets/images/client-systems/git-blue.png" alt="GIT">
                  </div>
                  <span class="clients-name">GIT</span>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </section>
    </main>

  </article>
</div>
