<div class="filters-container" *ngIf="appliedFilters?.length > 0">
    <h4 *ngIf="appliedFilters?.length > 0" [class.active]="appliedFilters?.length > 0">Applied Filters</h4>
    <ul class="applied-filters" *ngIf="appliedFilters?.length > 0">
        <li 
            *ngFor="let filter of appliedFilters"
            class="applied-filter">
            <span class="filter-name">{{filter.filterName}}</span>
            <span class="filter-value" [innerHTML]="filterSelectedDisplayText(filter)"></span>
            <span class="clear-button" (click)="clearFilter(filter)">clear</span>
        </li>
    </ul>
</div>