<section class="subpage-content" [libLoading]="loading">
    <header class="app-page-header">
        <h2>
            <fa-icon [icon]="faLicenseIcon"></fa-icon>
            Product Licenses
        </h2>
    </header>

    <article>

        <app-tabs-list>
            <app-tabs-shared></app-tabs-shared>

            <app-tab-item [label]="'Active'">
                <app-tab-label>Active</app-tab-label>
                <app-tab-body>
                    <!-- <div *ngIf="licenseListsReady"> -->
                        <lib-ng-list [tableId]="'activeLicenses'" [listsService]="newLicenseList1" [filters]="newLicenseList1.listParameters.filters"
                        (buttonClicked)="licenseButtonClicked($event)" [noResultsText]="'There are no active licenses.'"></lib-ng-list>
                    <!-- </div> -->
                </app-tab-body>
            </app-tab-item>

            <app-tab-item [label]="'Expired'">
                <app-tab-label>Expired</app-tab-label>
                <app-tab-body>
                    <div *ngIf="licenseListsReady">
                        <lib-ng-list [tableId]="'expiredLicenses'" [listsService]="newLicenseList2" [filters]="newLicenseList2.listParameters.filters"
                        (buttonClicked)="licenseButtonClicked($event)" [noResultsText]="'There are no expired licenses.'"></lib-ng-list>
                    </div>
                </app-tab-body>
            </app-tab-item>

            <app-tab-item [label]="'Available'">
                <app-tab-label>Available</app-tab-label>
                <app-tab-body>
                    <div class="products-toggle-container">
                        <!-- <p class="input-instruction" *ngIf="usedProductsVisible">Filtering out products alreadu in use.</p>
                        <p class="input-instruction" *ngIf="!usedProductsVisible">Showing all available products.</p> -->
                        <!-- <div class="products-toggle">

                            <lib-toggle-switch [checked]="usedProductsVisible" (change)="toggleProductsInUse()" [labelOff]="'Show All'" [labelOn]="'Hide Active'"></lib-toggle-switch>

                        </div> -->
                    </div>

                    <lib-ng-list [tableId]="'products list'" [listsService]="productListService" [filters]="productListService.listParameters.filters" (buttonClicked)="productListButtonClick($event)"
                        [noResultsText]="'There are no products available for new license.'"></lib-ng-list>
                </app-tab-body>
            </app-tab-item>

        </app-tabs-list>





        <!-- <lib-box-container
            id="license"
            [lessPadding]="true"
            [boxHeading]="'Active Licenses'">
            <lib-ng-list [listsService]="licenseList" [filters]="licenseList.listParameters.filters"
                (buttonClicked)="productListButtonClick($event)"></lib-ng-list>
        </lib-box-container> -->

        <!-- <lib-box-container
            id="license"
            [lessPadding]="true"
            [boxHeading]="'Active Licenses'">
            <lib-ng-list [listsService]="newLicenseList1" [filters]="newLicenseList1.listParameters.filters"
                (buttonClicked)="productListButtonClick($event)"></lib-ng-list>
        </lib-box-container> -->

        <!-- <lib-box-container
            id="license"
            [lessPadding]="true"
            [boxHeading]="'Active Licenses'">
            <lib-ng-list [listsService]="newLicenseList2" [filters]="newLicenseList2.listParameters.filters"
                (buttonClicked)="productListButtonClick($event)"></lib-ng-list>
        </lib-box-container> -->

        <!-- <lib-box-container id="availableProducts" [lessPadding]="true" [boxHeading]="'Available Products'" [lessPadding]="true">
            <ul *ngIf="productService.apiProducts">
                <li [class.has-license]="pd.hasLicense" *ngFor="let pd of productService.apiProducts">
                    <p>{{pd.name}}</p>
                    <p>has license: {{pd.hasLicense}}</p>
                    <p *ngIf="pd.status === 'trial'">Trial</p>
                    <button *ngIf="!pd.hasLicense || pd.status === 'trial'" class="text-button" (click)="showQuoteForm(pd.id)">Get Quote</button>
                </li>
            </ul>
        </lib-box-container> -->



        <!-- <app-tabs-list>
            <app-tabs-shared>
                <p>Shared</p>
            </app-tabs-shared>

            <app-tab-item>
                <app-tab-label>Licenses</app-tab-label>
                <app-tab-body>
                    <lib-ng-list [listsService]="licenseList" [filters]="licenseList.listParameters.filters"
                    (buttonClicked)="productListButtonClick($event)"></lib-ng-list>
                </app-tab-body>
            </app-tab-item>

            <app-tab-item>
                <app-tab-label>Available products</app-tab-label>
                <app-tab-body>
                    <div>
                    <ul *ngIf="productService.apiProducts">
                        <li *ngFor="let pd of productService.apiProducts">
                            <p>{{pd.name}}</p>
                        </li>
                    </ul>
                </div>
                </app-tab-body>
            </app-tab-item>

        </app-tabs-list> -->


    </article>

</section>

<ng-template #licenseDetailsNew>
    <div *ngIf="licenseDetailsDataNew">

        <!-- <p>{{licenseDetailsDataNew | json}}</p> -->
        <div *ngIf="licenseDetailsDataNew.licenseParams.type.toString() === 'FreeLicense'; else noFree">
            <p>This product is free to use and does not require any license.</p>

            <div class="border-with-title">
                <h4>License Data</h4>
                <div class="centered-modal-table centered-value">
                    <table>
                        <tr>
                            <td class="label">Start date</td>
                            <td class="value">{{licenseDetailsDataNew.start | date:'longDate'}} <span class="preposition">at</span> {{licenseDetailsDataNew.start | date:'shortTime'}}</td>
                        </tr>
                        <tr>
                            <td class="label">Uses used</td>
                            <td class="value">{{licenseDetailsDataNew.uses}}</td>
                        </tr>
                        <tr>
                            <td class="label">Deployment</td>
                            <td class="value">{{licenseDetailsDataNew.deploymentType}}</td>
                        </tr>
                        <tr>
                            <td class="label">File</td>
                            <td [class.not-applicable]="licenseDetailsDataNew.licenseFilename === 'N/A'" class="value">
                                <!-- <a target="_blank" [href]="licenseDetailsDataNew.licenseUrl">{{licenseDetailsDataNew.licenseFilename}}</a> -->
                                <lib-file-download [fileUrl]="licenseDetailsDataNew.licenseUrl" [fileName]="licenseDetailsDataNew.licenseFilename"></lib-file-download>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <ng-template #noFree>
            <!-- <p>NO FREE</p>
            <json-formatter [data]="licenseDetailsDataNew"></json-formatter> -->
            <!-- <p>{{licenseDetailsDataNew | json}}</p> -->

            <h3 class="license-status" [innerHtml]="detailsLicenseType"></h3>
            <p class="time-counter" *ngIf="detailsShowCounter"><span class="red-highlight"><countdown [config]="{stopTime: licenseEndsDate}"></countdown></span></p>
            <div class="border-with-title">
                <h4>License Dates</h4>
                <div class="centered-modal-table centered-value">
                    <table>
                        <tr>
                            <td class="label">Start date</td>
                            <td class="value">{{licenseDetailsDataNew.start | date:'longDate'}} <span class="preposition">at</span> {{licenseDetailsDataNew.start | date:'shortTime'}}</td>
                        </tr>
                        <tr *ngIf="licenseDetailsDataNew.licenseParams.type.toString() !== 'UsageLicense'">
                            <td class="label">End date</td>
                            <td class="value">{{licenseDetailsDataNew.end | date:'longDate'}} <span class="preposition">at</span> {{licenseDetailsDataNew.end | date:'shortTime'}}</td>
                        </tr>
                        <tr *ngIf="licenseDetailsDataNew.licenseParams.type.toString() !== 'UsageLicense'">
                            <td class="label">License Period</td>
                            <td class="value">{{selectedLicensePeriod}}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="border-with-title"  *ngIf="licenseDetailsDataNew.licenseParams.type.toString() === 'UsageLicense'">
                <h4>Uses information</h4>
                <div class="centered-modal-table centered-value">
                    <table>
                        <tr>
                            <td class="label">Used</td>
                            <td class="value">{{licenseDetailsDataNew.uses}}</td>
                        </tr>
                        <tr>
                            <td class="label">Available</td>
                            <td class="value">{{licenseDetailsDataNew.totalUses - licenseDetailsDataNew.uses}}</td>
                        </tr>
                        <tr class="top-border">
                            <td class="label">Total</td>
                            <td class="value">{{licenseDetailsDataNew.totalUses}}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="border-with-title">
                <h4>License parameters</h4>
                <div class="centered-modal-table centered-value">
                    <table>
                        <tr>
                            <td class="label">Deployment</td>
                            <td class="value">{{licenseDetailsDataNew.deploymentType}}</td>
                        </tr>
                        <tr *ngIf="licenseDetailsDataNew.licenseParams?.type !== 'TrialLicense'">
                            <td class="label">Purchase Order</td>
                            <td class="value">
                                <lib-clipboard-copy
                                    [text]="licenseDetailsDataNew.purchaseOrder"
                                    [value]="licenseDetailsDataNew.purchaseOrder"
                                    [message]="'The PURCHASE ORDER was copied to clipboard.'"></lib-clipboard-copy>
                            </td>
                            <!-- <td *ngIf="licenseDetailsDataNew.licenseParams?.type === 'TrialLicense'" class="value not-applicable">{{licenseDetailsDataNew.purchaseOrder}}</td> -->
                        </tr>
                        <tr *ngIf="licenseDetailsDataNew.licenseParams?.type !== 'TrialLicense'" class="top-border">
                            <td class="label">Invoice</td>
                            <td class="value">
                                <lib-clipboard-copy
                                    [text]="licenseDetailsDataNew.invoiceNumber"
                                    [value]="licenseDetailsDataNew.invoiceNumber"
                                    [message]="'The INVOICE was copied to clipboard.'"></lib-clipboard-copy>
                            </td>
                        </tr>
                        <tr>
                            <td class="label">File</td>
                            <td [class.not-applicable]="licenseDetailsDataNew.licenseFilename === 'N/A'" class="value">
                                <!-- <a target="_blank" [href]="licenseDetailsDataNew.licenseUrl">{{licenseDetailsDataNew.licenseFilename}}</a> -->
                                <lib-file-download [fileUrl]="licenseDetailsDataNew.licenseUrl" [fileName]="licenseDetailsDataNew.licenseFilename"></lib-file-download>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>



            <!-- <div class="centered-modal-table centered-value">
                <table>
                    <tr>
                        <td class="label">Deployment</td>
                        <td class="value">{{licenseDetailsDataNew.deploymentType}}</td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <div>
                                <span>Uses</span>
                                <ul>
                                    <li>
                                        <span class="label">Total</span>
                                        <span class="value">{{licenseDetailsDataNew.totalUses}}</span>
                                    </li>
                                    <li>
                                        <span class="label">Used</span>
                                        <span class="value">{{licenseDetailsDataNew.totalUses - licenseDetailsDataNew.usesAvailable}}</span>
                                    </li>
                                    <li>
                                        <span class="label">Available</span>
                                        <span class="value">{{licenseDetailsDataNew.usesAvailable}}</span>
                                    </li>
                                </ul>
                            </div>
                        </td>

                    </tr>
                </table>

            </div> -->
        </ng-template>
    </div>
</ng-template>


<ng-template #productDetails>
  <div *ngIf="productDetailsData">
    <div *ngIf="productDetailsData.status === 'free'">
      <p>This product is free to use and does not require any license.</p>
    </div>

    <div *ngIf="productDetailsData.status === 'trial'">
      <p>This product requires Premium License</p>
      <p>
        <span class="label">Current license status of this product</span>
        <span class="value">{{productDetailsData.statusFromLicense}}</span>
      </p>
    </div>
  </div>
</ng-template>


<ng-template #licenseDetails>
    <div *ngIf="licenseDetailsData">
        <!-- <header>
            <h2>{{licenseDetailsData.name}}</h2>
        </header> -->

        <!-- FREE PRODUCT -->

        <div *ngIf="licenseDetailsData.license_type.toString() === 'free'">
            <h5>This product is free to use and does not require any license.</h5>

            <div class="centered-modal-table centered-value">
                <ul>

                </ul>
            </div>

        </div>

        <!-- TRIAL VALID -->

        <div *ngIf="licenseDetailsData.license_type.toString() === 'trial' && ['valid','expiring'].includes(licenseDetailsData.status)">
            <h5 class="centered valid" *ngIf="licenseDetailsData.status.toString() === 'valid'">You have active <span class="highlight">trial</span> license for <span class="blue-highlight">{{licenseDetailsData.name}}</span>.</h5>

            <!-- <h5 class="centered valid" *ngIf="licenseDetailsData.status.toString() === 'expiring'">You have active <span class="highlight">trial</span> license for <span class="blue-highlight">{{licenseDetailsData.name}}</span>.</h5> -->
            <h5 class="centered warning" *ngIf="licenseDetailsData.status.toString() === 'expiring'">Your
                <span class="highlight">{{licenseDetailsData.license_type}}</span>
                license is expiring in
                <span *ngIf="expireInDays" class="red-highlight">{{daysString}}</span>
                <span *ngIf="expireInDays < 1" class="red-highlight"><countdown [config]="{stopTime: licenseEndsDate}"></countdown></span>

                .</h5>

            <!-- <p class="centered" *ngIf="expireInDays">The trial period will end in <span class="blue-highlight">{{daysString}}</span>.</p> -->
            <!-- <p class="centered" *ngIf="expireInDays < 1">The trial will end in <span class="blue-highlight">{{hoursString}}</span> and <span class="blue-highlight">{{minutesString}}.</span></p> -->
            <p class="centered">You have <span class="blue-highlight">{{licenseDetailsData.code_generations_limit - licenseDetailsData.code_generations_used}}</span> code generations left.</p>
            <div class="centered-modal-table centered-value">
                <ul>
                    <li>
                        <span class="label">Trial started</span>
                        <span class="value">
                            {{licenseDetailsData.license_starts | date:'longDate'}}
                            <span class="preposition">at</span>
                            {{licenseDetailsData.license_starts | date:'shortTime'}}
                            <a class="datePickerIcon">
                                <input disabled="disabled" placement="top" [isDisabled]="false" [bsConfig]="bsConfig" type="text"  #dp="bsDaterangepicker" bsDaterangepicker [(ngModel)]="bsRangeValue">
                                <fa-icon (click)="dp.toggle()" [icon]="calendarIcon"></fa-icon>
                            </a>
                        </span>
                    </li>

                    <li>
                        <span class="label">Trial ends</span>
                        <span class="value">{{licenseDetailsData.license_ends | date:'longDate'}} <span class="preposition">at</span> {{licenseDetailsData.license_ends | date:'shortTime'}}</span>
                    </li>

                    <li>
                        <span class="label">Generations used</span>
                        <span class="value">{{licenseDetailsData.code_generations_used}} <span class="preposition">of</span> {{licenseDetailsData.code_generations_limit}}</span>
                    </li>
                </ul>
            </div>

            <div class="modal-buttons-container">
                <button class="secondary">Schedule demo</button>
                <button class="primary">Get Estimate</button>
            </div>
        </div>


        <!-- TRIAL - EXPIRED -->

        <div *ngIf="licenseDetailsData.license_type.toString() === 'trial' && licenseDetailsData.status === 'expired'">
            <h5 class="centered no-valid">Your <span class="highlight">trial</span> of this product has <span class="red-highlight">ended</span>.</h5>

            <div class="centered-modal-table centered-value">
                <ul>
                    <li>
                        <span class="label">Trial ended</span>
                        <span class="value">{{licenseDetailsData.license_ends | date:'longDate'}} <span class="preposition">at</span> {{licenseDetailsData.license_ends | date:'shortTime'}}
                            <a class="datePickerIcon">
                                <input disabled="disabled" placement="top" [isDisabled]="false" [bsConfig]="bsConfig" type="text"  #dp="bsDaterangepicker" bsDaterangepicker [(ngModel)]="bsRangeValue">
                                <fa-icon (click)="dp.toggle()" [icon]="calendarIcon"></fa-icon>
                            </a>
                        </span>
                    </li>
                </ul>
            </div>
            <!-- <div class="datePickerIcon">
                <input disabled="disabled" placement="top" [isDisabled]="false" [bsConfig]="bsConfig" type="text"  #dp="bsDaterangepicker" bsDaterangepicker [(ngModel)]="bsRangeValue">
                <fa-icon (click)="dp.toggle()" [icon]="calendarIcon"></fa-icon>
            </div> -->

            <div class="modal-buttons-container">
                <!-- <button class="text-button">Contact Sales</button> -->
                <button class="secondary">Schedule demo</button>
                <button class="primary">Get Estimate</button>
            </div>
        </div>

        <!-- NO LICENSE -->

        <div *ngIf="licenseDetailsData.license_type.toString() === 'no_license'">
            <p class="centered"><span class="highlight">{{licenseDetailsData.name}}</span> is a premium product.</p>
            <!-- <p class="centered">To purchase a license for it, please fill out <button class="text-button">get quote</button>form.</p> -->
            <!-- <p>or you can <button class="text-button">schedule a demo</button> of the producs.</p> -->
            <!-- <p class="centered">Alternatively, You can sign up for <button class="text-button">14 day trial</button> of the product.</p> -->
            <div class="modal-buttons-container">
                <!-- <button class="text-button">Contact Sales</button> -->
                <button class="secondary">14 day trial</button>
                <button class="secondary">Schedule Demo</button>
                <button class="primary">Get Quote</button>
            </div>
        </div>


        <!-- PROJECT VALID -->

        <div *ngIf="['project','enterprise'].indexOf(licenseDetailsData.license_type.toString()) > -1 && ['valid','expiring'].indexOf(licenseDetailsData.status.toString()) > -1">
            <h5 class="centered valid" *ngIf="licenseDetailsData.status.toString() === 'valid'">Your <span class="highlight">{{licenseDetailsData.license_type}}</span> license is <span class="blue-highlight">active</span>.</h5>
            <h5 class="centered warning" *ngIf="licenseDetailsData.status.toString() === 'expiring'">Your
                <span class="highlight">{{licenseDetailsData.license_type}}</span>
                license is expiring in
                <span *ngIf="expireInDays" class="red-highlight">{{daysString}}</span>
                <span *ngIf="expireInDays < 1" class="red-highlight">
                    <countdown [config]="{stopTime: licenseEndsDate}"></countdown>
                </span>
                .</h5>
            <p class="centered" *ngIf="licenseDetailsData.license_type.toString() === 'project' && licenseDetailsData.code_generations_limit - licenseDetailsData.code_generations_used >= 1">You have <span class="blue-highlight">{{licenseDetailsData.code_generations_limit - licenseDetailsData.code_generations_used}}</span> code generations left.</p>
            <p class="centered warning" *ngIf="licenseDetailsData.license_type.toString() === 'project' && licenseDetailsData.code_generations_limit - licenseDetailsData.code_generations_used < 1">You have user all of your code generations.</p>
            <div class="centered-modal-table centered-value">
                <ul>
                    <li>
                        <span class="label">License started</span>
                        <span class="value">{{licenseDetailsData.license_starts | date:'longDate'}} <span class="preposition">at</span> {{licenseDetailsData.license_starts | date:'shortTime'}}</span>
                    </li>

                    <li>
                        <span class="label">License ends</span>
                        <span class="value">{{licenseDetailsData.license_ends | date:'longDate'}} <span class="preposition">at</span> {{licenseDetailsData.license_ends | date:'shortTime'}}</span>
                    </li>

                    <li *ngIf="licenseDetailsData.license_type.toString() === 'project'">
                        <span class="label">Generations used</span>
                        <span class="value">{{licenseDetailsData.code_generations_used}} <span class="preposition">of</span> {{licenseDetailsData.code_generations_limit}}</span>
                    </li>

                    <li>
                        <span class="label">PO number</span>
                        <span class="value">{{licenseDetailsData.PO_number}}<a (click)="copyToClipboard(licenseDetailsData.PO_number)"><fa-icon class="copy-to-clipboard" [icon]="copyIcon"></fa-icon></a></span>
                    </li>
                </ul>
            </div>
            <div class="modal-buttons-container">
                <button *ngIf="licenseDetailsData.status.toString() === 'expiring'" class="secondary">Renew license</button>
                <button class="primary" *ngIf="licenseDetailsData.license_type.toString() === 'project'">Upgrade license</button>
            </div>
        </div>

        <div *ngIf="['project','enterprise'].indexOf(licenseDetailsData.license_type.toString()) > -1 && licenseDetailsData.status.toString() === 'expired'">
            <h5 class="centered no-valid">Your <span class="highlight">{{licenseDetailsData.license_type}}</span> license has <span class="red-highlight">expired</span>.</h5>
            <p class="centered">Please renew your lincense in order to continue using<br><span class="blue-highlight">{{licenseDetailsData.name}}</span>.</p>
            <div class="centered-modal-table centered-value">
                <ul>
                    <li>
                        <span class="label">License started</span>
                        <span class="value">{{licenseDetailsData.license_starts | date:'longDate'}} <span class="preposition">at</span> {{licenseDetailsData.license_starts | date:'shortTime'}}</span>
                    </li>

                    <li>
                        <span class="label">License ends</span>
                        <span class="value">{{licenseDetailsData.license_ends | date:'longDate'}} <span class="preposition">at</span> {{licenseDetailsData.license_ends | date:'shortTime'}}</span>
                    </li>

                    <li *ngIf="licenseDetailsData.license_type.toString() === 'project'">
                        <span class="label">Generations used</span>
                        <span class="value">{{licenseDetailsData.code_generations_used}} <span class="preposition">of</span> {{licenseDetailsData.code_generations_limit}}</span>
                    </li>

                    <li>
                        <span class="label">PO number</span>
                        <span class="value">{{licenseDetailsData.PO_number}}<a (click)="copyToClipboard(licenseDetailsData.PO_number)"><fa-icon class="copy-to-clipboard" [icon]="copyIcon"></fa-icon></a></span>
                    </li>
                </ul>
            </div>
            <div class="modal-buttons-container">
                <button class="primary">Renew license</button>
            </div>
        </div>


    </div>

</ng-template>



