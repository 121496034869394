<section class="subpage-content">
  <header class="app-page-header">
    <h2>
      <!-- <span class="uses-icon"> -->
        <!-- <fa-icon [icon]="usesIcon"></fa-icon> -->

      <!-- </span> -->
      <span class="downloads-icon">
        <fa-icon class="icon-1" [icon]="downloadIcon"></fa-icon>
        <fa-icon class="icon-2" [icon]="downloadIcon"></fa-icon>
      </span>
      Downloads
      <!-- Uses List -->
    </h2>
  </header>

  <article>
    <lib-box-container
      id="usesList" [lessPadding]="true">
      <lib-ng-list [tableId]="'uses list'" [listsService]="usesListService" (buttonClicked)="actionButtonClicked($event)"></lib-ng-list>
      <lib-ng-pagination [listsService]="usesListService"></lib-ng-pagination>
    </lib-box-container>
  </article>
</section>


<ng-template #toolsUseDetails>
  <div *ngIf="useDetails" class="use-downloads-modal">
      <h2><span class="label">Project name</span><span class="project-name">{{useDetails.projectName}}</span></h2>
      <h3 class="tool-name">{{useDetails.productName}}</h3>
      <!-- <h4 class="status" ngClass="'status-{{useDetails.status}}">{{useDetails.status}}</h4> -->
      <div class="border-with-title accordion-border">
          <h4>Summary</h4>
          <div class="centered-modal-table centered-value">
              <table>
                  <tr>
                      <td class="label">Date and Time</td>
                      <td class="value">{{useDetails.started | date:'short'}}</td>
                  </tr>
                  <!-- <tr *ngIf="useDetails.completed">
                      <td class="label">Completed</td>
                      <td class="value">{{useDetails.completed | date: 'longDate'}}</td>
                  </tr> -->
                  <tr>
                      <td class="label">User</td>
                      <td class="value">{{useDetails.user.userName}}</td>
                  </tr>
                  <tr>
                      <td class="label">Status</td>
                      <td class="value">{{useDetails.status}}</td>
                  </tr>
              </table>
          </div>
      </div>
      <div class="border-with-title" *ngIf="useDetails.status === 'completed' && useDetails.results.completedFile">
          <h4>Results</h4>
          <div class="centered-modal-table centered-value">
              <table>
                  <tr>
                      <td class="label">Result file</td>
                      <td class="value">
                          <lib-file-download [fileUrl]="useDetails.results.completedFileDownload" [fileName]="useDetails.results.completedFile"></lib-file-download>
                      </td>
                  </tr>
              </table>
          </div>
      </div>
      <div class="border-with-title" *ngIf="useDetails.productId === 'jsontoramlconverter' && (useDetails.params.requestFile || useDetails.params.responseFile)">
          <h4>Input Params</h4>
          <div class="centered-modal-table centered-value">
              <table>
                  <tr *ngIf="useDetails.params.requestFile">
                      <td class="label">Request File</td>
                      <td class="value">
                          <lib-file-download [fileUrl]="useDetails.params.requestFileDownload" [fileName]="useDetails.params.requestFile"></lib-file-download>
                      </td>
                  </tr>
                  <tr *ngIf="useDetails.params.responseFile">
                    <td class="label">Response File</td>
                    <td class="value">
                        <lib-file-download [fileUrl]="useDetails.params.responseFileDownload" [fileName]="useDetails.params.responseFile"></lib-file-download>
                    </td>
                  </tr>
              </table>
          </div>
      </div>

      <div class="border-with-title" *ngIf="useDetails.productId === 'ramltojsonconverter' && useDetails.params.inputFile">
        <h4>Input Params</h4>
        <div class="centered-modal-table centered-value">
            <table>
                <tr *ngIf="useDetails.params.inputFile">
                    <td class="label">Input File</td>
                    <td class="value">
                        <lib-file-download [fileUrl]="useDetails.params.inputFileDownload" [fileName]="useDetails.params.inputFile"></lib-file-download>
                    </td>
                </tr>
            </table>
        </div>
      </div>

      <div class="border-with-title" *ngIf="useDetails.productId === 'oastoramlconverter' && useDetails.params.inputFile">
        <h4>Input Params</h4>
        <div class="centered-modal-table centered-value">
            <table>
                <tr *ngIf="useDetails.params.inputFile">
                    <td class="label">Input File</td>
                    <td class="value">
                        <lib-file-download [fileUrl]="useDetails.params.inputFileDownload" [fileName]="useDetails.params.inputFile"></lib-file-download>
                    </td>
                </tr>
            </table>
        </div>
      </div>

      <div class="border-with-title" *ngIf="useDetails.productId === 'ramltooasconverter' && useDetails.params.inputFile">
        <h4>Input Params</h4>
        <div class="centered-modal-table centered-value">
            <table>
                <tr *ngIf="useDetails.params.format">
                  <td class="label">Format</td>
                  <td class="value">{{useDetails.params.format}}</td>
                </tr>
                <tr *ngIf="useDetails.params.inputFile">
                    <td class="label">Input File</td>
                    <td class="value">
                        <lib-file-download [fileUrl]="useDetails.params.inputFileDownload" [fileName]="useDetails.params.inputFile"></lib-file-download>
                    </td>
                </tr>
            </table>
        </div>
      </div>

      <div class="border-with-title" *ngIf="useDetails.productId === 'restgen'">
        <h4>Input Params</h4>
        <div class="centered-modal-table centered-value">
            <table>
                <tr>
                    <td class="label">Platform</td>
                    <td class="value">{{useDetails.params.platform}}</td>
                </tr>
                <tr>
                    <td class="label">Generated Type</td>
                    <td class="value">{{useDetails.params.generatedType}}</td>
                </tr>
                <tr *ngIf="useDetails.params.generatedContract">
                    <td class="label">Generated Contract</td>
                    <td class="value">
                        <lib-file-download [fileUrl]="useDetails.params.generatedContractDownload" [fileName]="useDetails.params.generatedContract"></lib-file-download>
                    </td>
                </tr>
                <tr>
                    <td class="label">Target Type</td>
                    <td class="value">{{useDetails.params.targetType}}</td>
                </tr>
                <tr>
                    <td class="label">Target Contract</td>
                    <td class="value">
                        <lib-file-download [fileUrl]="useDetails.params.targetContractDownload" [fileName]="useDetails.params.targetContract"></lib-file-download>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <div>
                            <span class="label">Mapping</span>
                            <json-formatter [data]="useDetails.params.mapping"></json-formatter>
                        </div>

                    </td>
                </tr>
                <tr>
                </tr>
            </table>
        </div>
      </div>

      <div class="border-with-no-title smaller">
        <div class="centered-modal-table">
          <table>
            <tr *ngIf="useDetails.params.correlationId">
              <td class="label">Tracking Id</td>
              <td class="value">
                <span class="ellipsis">{{useDetails.params.correlationId}}</span>
                <a (click)="copyToClipboard(useDetails.params.correlationId)" class="copy-to-clipboard" title="Copy to clipboard">
                  <fa-icon [icon]="copyIcon"></fa-icon>
                </a>
              </td>

            </tr>
          </table>
        </div>
      </div>
  </div>
</ng-template>
