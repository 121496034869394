import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { faSmile } from '@fortawesome/free-regular-svg-icons';
import { faCogs, faTools, faHandHolding, faCog, faCaretDown, faBug, faShippingFast, faRobot, faExternalLinkAlt, faCode} from '@fortawesome/free-solid-svg-icons';
import { ApiService } from 'projects/nine-gold-lib/src/lib/services/api.service';
import { AppSettingsService } from 'projects/nine-gold-lib/src/lib/services/app-settings.service';
import { AuthService } from 'projects/nine-gold-lib/src/lib/services/auth.service';
import { UserInterfaceService } from 'projects/nine-gold-lib/src/lib/services/user-interface.service';
import { UserService } from 'projects/nine-gold-lib/src/lib/services/user.service';
import { ConfirmDialogService, CounterComponent, NotificationService } from 'projects/nine-gold-lib/src/public-api';
import { fadeInAnimation } from '../../animations/fade-in.animation';
import { ProductService } from 'projects/nine-gold-lib/src/lib/services/product.service';
import { DatePipe } from '@angular/common';
import { FormResultMessageFormat } from 'projects/nine-gold-lib/src/lib/shared/notification/notification';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  faCogsIcon = faCogs;
  faToolsIcon = faTools;
  faHandHoldingIcon = faHandHolding;
  faCogIcon = faCog;

  bugIcon = faBug;
  smileIcon = faSmile;
  fastIcon = faShippingFast;
  autoIcon = faRobot;

  codeIcon = faCode;

  outerLinkIcon = faExternalLinkAlt;

  isLoggedIn = false;
  userCanTrial = false;
  canQuote = false;
  restgentLicese = false;

  test: boolean;
  pathLength: number;
  percentageText: number = 0;
  counters:CounterComponent[] = [];
  // @ViewChild('path') pathElement: any;
  @ViewChild('numbersList') numbersList: ElementRef;
  @ViewChild('counter1') counter1: CounterComponent;
  @ViewChild('counter2') counter2: CounterComponent;
  @ViewChild('counter3') counter3: CounterComponent;
  @ViewChild('counter4') counter4: CounterComponent;
  showListItem: boolean[] = [false, false, false, false];
  checked = false;

  generatorLink: string;
  toolsLink: string;
  activatedRoute: ActivatedRoute;


  constructor(
    private uiService: UserInterfaceService,
    private datePipe: DatePipe,
    private renderer: Renderer2,
    private settings: AppSettingsService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private productService: ProductService,
    private confirmService: ConfirmDialogService,
    private notif: NotificationService,
    private http: HttpClient,
    private userService:UserService
    ) {
    this.uiService.showHeader();
    this.isLoggedIn = this.authService.userLoggedIn;
    if(this.isLoggedIn) {
      this.userCanTrial = this.productService.apiProductCategories.find(p => p.id === 'rest_generator').canTrial;
      this.canQuote = this.productService.apiProductCategories.find(p => p.id === 'rest_generator').canQuote;
    }
    this.restgentLicese = productService.checkIfProductValid('rest_generator');
    productService.licensesUpdated.subscribe(l => {
      this.restgentLicese = productService.checkIfProductValid('rest_generator');
      this.userCanTrial = this.productService.apiProductCategories.find(p => p.id === 'rest_generator').canTrial;
      this.canQuote = this.productService.apiProductCategories.find(p => p.id === 'rest_generator').canQuote;
    });
    authService.loginChangeEvent.subscribe(loggedIn => {
      this.isLoggedIn = loggedIn;
    });

  }



  ngOnInit(): void {
    // debugger;
    this.generatorLink = this.settings.baseSetting.projectUrls['generator'];
    this.toolsLink = this.settings.baseSetting.projectUrls['tools'];
    let register = this.route.snapshot.queryParamMap.get('r');

    // this.isLoggedIn = this.userService.userLoggedIn();

    if (register === '1') {
      // alert('you have beed successfully registered and logged in');
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: null,
          // queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
        // TODO: test this code (after new user registration); if ok, remove http.get call below (commented out)
        this.apiService.getUser().subscribe(response => {
        });
    }
    // this.uiService.showHeader();
  }

  detectElms() {
    if (!this.checked &&  isInViewport(this.numbersList.nativeElement)) {
      // this.counter.start();
      let i = 0;
      this.counters.forEach((counter, index) => {
        var co = counter;

        setTimeout(() => {
          counter.start();
          this.showListItem[index] = true;
        }, 1000 * i);
        i++;
      })

      this.percentageText = 0.8
    }
  }
  ngAfterViewInit(): void {
    this.counters = [this.counter1,this.counter2,this.counter3,this.counter4];

    const genNumber = () => {
    };

  }

  getTrial(): void {
    this.confirmService.confirm(this.uiService.getConfirmMessage('setTrial',['REST Generator']))
        .subscribe((answer) => {
          if(answer) {
            this.apiService.createTrialLicense('restgen',this.userService.currentUser.company.id).subscribe({
              next: data => {
                let endDate = this.datePipe.transform(data.end, 'fullDate');
                this.notif.success('Trial License Created',`You trial license just started and will end at <strong>${endDate}</strong>.`,FormResultMessageFormat.popup,12000,null,true);
              },
              error: err => {}
            });
          }
        }
      );
  }

  getPercentText(): string {
    return this.percentageText + '%';
  }

  RegisterWithTrial() {
    this.authService.getRegistrationLink(true, null).then(link => window.location.href = link);
  }

  animateNumber() {
    var time = 0;
    var diff = 80;

    var minTime = 0;
    var maxTime = 2000;

    // http://upshots.org/actionscript/jsas-understanding-easing
    /*
        @t is the current time (or position) of the tween. This can be seconds or frames, steps, seconds, ms, whatever – as long as the unit is the same as is used for the total time [3].
        @b is the beginning value of the property.
        @c is the change between the beginning and destination value of the property.
        @d is the total time of the tween.
    */
    function easeInOutQuad(t, b, c, d) {
      if ((t /= d / 2) < 1) return c / 2 * t * t + b;
      return -c / 2 * ((--t) * (t - 2) - 1) + b;
    }

    function easeOutQuad(t, b, c, d) {
      return -c * (t /= d) * (t - 2) + b;
    }

    function easeInQuad(t, b, c, d) {
      return c * (t /= d) * t + b;
    }

    for (var i = 0, len = diff; i <= len; i++) {
      ((s) => {
        setTimeout(() => {
          this.percentageText = Math.round(s);
        }, time);
      })(i);
      time = easeInOutQuad(i, minTime, maxTime, diff);
    }
  }

}



function isInViewport (elm) {
  var elementTop = elm.getBoundingClientRect().top;
  // var elementBottom = elementTop + elm.clientHeight;
  var elementBottom = elementTop + 500;

  // in this specific case the scroller is document.documentElement (<html></html> node)
  var viewportTop = document.documentElement.scrollTop;
  // console.log(viewportTop + " === " + elementBottom);
  var viewportBottom = viewportTop + document.documentElement.clientHeight;

  return elementBottom < document.documentElement.clientHeight;

  // return elementBottom > viewportTop && elementTop < viewportBottom;
}

