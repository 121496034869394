import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { faBars, faCopy, faDownload } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { Uses } from 'projects/nine-gold-lib/src/lib/models/uses';
import { AppSettingsService } from 'projects/nine-gold-lib/src/lib/services/app-settings.service';
import { ButtonTypes } from 'projects/nine-gold-lib/src/lib/shared/listComponents/ng-list/ng-list-data';
import { FormResultMessageFormat } from 'projects/nine-gold-lib/src/lib/shared/notification/notification';
import { NotificationService } from 'projects/nine-gold-lib/src/public-api';
import { UsesListService } from '../services/uses-list.service';

@Component({
  selector: 'app-uses',
  templateUrl: './uses.component.html',
  styleUrls: ['./uses.component.scss']
})
export class UsesComponent implements OnInit {
  useDetails: Uses = null;
  loading = false;

  usesIcon = faBars;
  downloadIcon = faDownload;
  copyIcon = faCopy;

  @ViewChild('toolsUseDetails') toolsUseDetails: TemplateRef<any>;

  constructor(
    public usesListService: UsesListService,
    private notif: NotificationService,
    private appSettings: AppSettingsService,
    private toastr: ToastrService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.usesListService.LoadPage(1, true);
    }, 100);

  }

  private textToClipboard (text) {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    // TODO: find altertative to execCommand above
    document.body.removeChild(dummy);
  }

  protected copyToClipboard(code: string): void {
    this.textToClipboard(code);

    this.toastr.info( "Copied to clipboard", "The CODE was copied to clipboard", {timeOut: 6000, enableHtml: false, positionClass: 'toast-bottom-right' });
  }

  actionButtonClicked(data:{}): void {
    if(data['button'].type === ButtonTypes.info) {
      let useId = data['row'].rowIdentifier;
      this.useDetails = this.usesListService.apiResutls.find(el => el.id === useId);
      // this.notif.info(`Project name: ${this.useDetails.projectName}`,"", FormResultMessageFormat.modalHtml,0,this.restGenUseDetails);
      this.notif.info(null,"", FormResultMessageFormat.modalHtml,0,this.toolsUseDetails);
    } else if([ButtonTypes.cell_download,ButtonTypes.download].includes(data['button'].type) ) {
      this.loading = true;
      let useId = data['row'].rowIdentifier;
      this.useDetails = this.usesListService.apiResutls.find(el => el.id === useId);
      let useFileUrl = this.useDetails?.results?.completedFileDownload;
      document.body.click();

      if(useFileUrl) {
        this.http.get(this.appSettings.baseSetting.apiUrl + useFileUrl, {withCredentials: true, responseType: 'arraybuffer'}).subscribe((response) => {
          let blob = new Blob([response],{type: 'application/octet-stream'});
          let newUrl = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = newUrl;
          a.download = this.useDetails.results.completedFile;
          a.click();
          this.loading = false;
        },
        (error:any) => {
          this.loading = false;
          this.notif.error('Error', 'There was an Error loading the file.',FormResultMessageFormat.popup,5000,null);
        })
      }



    }
  }

}
