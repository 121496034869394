<div class="content-wrapper">

  <article class="page-content">



    <header class="our-strategy-banner" role="banner">
      <div class="banner-inner">
        <h1 wmAnimate="landing" speed="normal" delay="500ms">Calculating<br><span class="smaller">the</span> Cost</h1>
      </div>
    </header>
    <main>
    <section class="blue-bg-summary">
      <div class="section-inner">
        <p>The cost of replacing custom code can become a major deterrent for companies considering migrating from one platform to another, even if their current platform is expensive.  Smart Code helps companies mitigate the risk at every step of the process.</p>
        <table class="solutions-table">
          <thead>
            <tr>
              <th></th>
              <th>The Cost of Replacement</th>
              <th>The Smart Code Solution</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Initial Investment in Custom Code</td>
              <td>Many companies invested heavily in custom integrations and code that are tightly coupled with their current platform.  Rewriting and refactoring this code for the new platform requires significant time, specialized skills and resources.</td>
              <td>Smart Code has generators that accelerate the replacement of custom integrations and code for the new platform, rapidly upskilling engineers and developers with the specialized skills required to complete the code.</td>
            </tr>
            <tr>
              <td>Disruption to Business Operations</td>
              <td>Moving to a new platform requires careful transition and potentially a complete reworking of how integration code is structured, deployed and managed.  This introduces risk to business operations, especially if the integration touches mission-critical operations.</td>
              <td>Smart Code offers risk assessments to help plan and mitigate the risk of disruption to business operations.  By taking a phased approach, companies can take a conservative approach to replacing their platform while running their existing platform in parallel.</td>
            </tr>
            <tr>
              <td>Specialized Skills and Retraining</td>
              <td>Platforms like the iPaaS have specific architectures and integration patterns.  Engineers and developers trained for that platform may not directly translate to other platforms.  Shifting to a new platform may require training new staff and hiring new talent with expertise in the new system, leading to additional costs and time delays.</td>
              <td>Smart Code assists in retraining by generating code with built-in best practices and integration patterns specific to the language being output.  By standardizing the format of the code across languages, engineers and developers can easily shift their skillset and adapt to the new platform at a faster rate without having to start from scratch.</td>
            </tr>
            <tr>
              <td>Technical Debt and Complexity</td>
              <td>Custom code on an established platform often creates technical debt that is hard to untangle.  If integrations contain complex workflows, a migration could mean not only rewriting the code, but re-engineering business logic and integration patterns to fit the new platform’s capabilities.</td>
              <td>Smart Code generates new integration code to fit the new platform’s capabilities taking into account best practices and performance benchmarking specific to the language being output.  This reduces the complexity of the migration leaving only business-specific logic for the developer to convert.</td>
            </tr>
            <tr>
              <td>Compliance and Regulatory Considerations</td>
              <td>For many industries, compliance and security standards are embedded in the existing integrations.  Recreating these compliance measures on a new platform may require reassessment and re-certification, which is costly and complex.</td>
              <td>Smart Code is configured to automatically include security standards and data-handling protocols in a standardized and consistent format.  By generating code that adheres to established security and compliance requirements, Smart Code reduces the need for extensive re-certification.</td>
            </tr>
            <tr>
              <td>Total Cost of Ownership (TCO)</td>
              <td>When companies analyze their TCO they might find despite the high cost, the existing platform can still be more cost effective in the long term compared to the direct and indirect costs of migration, such as re-engineering, potential downtime and retraining.</td>
              <td>Smart Code enables companies with high TCO reduced direct and indirect costs for re-engineering, potential downtime and retraining, giving companies the freedom to move to a centralized or decentralized architecture with open-source options.</td>
            </tr>
          </tbody>
        </table>
      </div>

    </section>


    <!-- <section class="more-bottom-margin more-top-margin">
      <div class="section-inner">

        <div class="transparent-block style-1-left margin-bottom">
          <div>
            <div>
              <h2>Use Case</h2>
              <h5>A mid-sized enterprise with 5 v-Cores and 3 developers moves their integrations off the iPaaS and onto Spring Boot Java.</h5>
              <ul>
                <li>10x reduction on licensing costs by eliminating iPaaS licensing costs and purchasing Smart Code</li>
                <li>7x reduction in support costs by selecting VMWare Commercial Support</li>
                <li>72% ROI on development with <strong>Smart Code</strong></li>
                <li>Estimated annual saving of 79% per year</li>
              </ul>
              <a role="button" style="margin-left: -.5em;" href="https://calendly.com/diamondedgeit/30min" target="_blank" class="text-button lighter narrower no-bg external-link dark-container" rel="noopener noreferrer">Get your cost comparison</a>
            </div>

          </div>


        </div>
      </div>
    </section>

	 <section class="more-bottom-margin more-top-margin">
      <div class="section-inner">

        <div class="transparent-block style-1-left margin-bottom">
          <div>
            <div>
              <h2>Use Case</h2>
              <h5>A fixed price project with a strict 6 week deadline for a financial services company.</h5>
              <ul>
                <li>Completed 22 APIs with one resource in 2 weeks by implementing with Smart Code</li>
                <li>Freed up time for custom project work and documentation</li>
                <li>Delivered 8x faster with <strong>Smart Code</strong></li>
                <li>Increased profit margin by over 87%</li>
              </ul>
              <a role="button" style="margin-left: -.5em;" href="https://calendly.com/diamondedgeit/30min" target="_blank" class="text-button lighter narrower no-bg external-link dark-container" rel="noopener noreferrer">Get your cost comparison</a>
            </div>

          </div>


        </div>
      </div>
    </section> -->


    <section class="more-bottom-margin">
      <div class="section-inner">
        <h2 class="no-bottom-margin">Use Cases</h2>
        <div class="transparetn-block-columns">
          <div class="transparent-block flipped-bg  style-2-left" once="true" speed="fast" wmAnimate="fadeInUp" delay="250ms" aos="0.25">
            <div class="transparent-block-text single-block">
              <h5>A mid-sized enterprise with 5 v-Cores and 3 developers moves their integrations onto Spring Boot Java.</h5>
              <ul>
                <li>10x reduction on licensing costs by eliminating iPaaS licensing costs and purchasing Smart Code</li>
                <li>7x reduction in support costs by selecting VMWare Commercial Support</li>
                <li>72% ROI on development with <strong>Smart Code</strong></li>
                <li>Estimated annual saving of 79% per year</li>
              </ul>
              <a role="button" style="margin-left: -.5em;" href="https://calendly.com/diamondedgeit/30min" target="_blank" class="text-button lighter narrower no-bg external-link dark-container" rel="noopener noreferrer">Get your cost comparison</a>
            </div>
          </div>
          <div class="transparent-block   style-1-right" once="true" speed="fast" wmAnimate="fadeInUp" delay="250ms" aos="0.25">
            <div class="transparent-block-text space-evenly">
              <h5>A fixed price project with a strict 6 week deadline for a financial services company using MuleSoft.</h5>
              <ul>
                <li>Completed 22 APIs with one resource in 2 weeks by implementing with Smart Code</li>
                <li>Freed up time for custom project work and documentation</li>
                <li>Delivered 8x faster with <strong>Smart Code</strong></li>
                <li>Increased profit margin by over 87%</li>
              </ul>
              <a role="button" style="margin-left: -.5em;" href="https://calendly.com/diamondedgeit/30min" target="_blank" class="text-button lighter narrower no-bg external-link dark-container" rel="noopener noreferrer">Get your cost comparison</a>
            </div>
          </div>
        </div>
      </div>
    </section>


  </main>
  </article>

</div>
