import { ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UserInterfaceService } from 'projects/nine-gold-lib/src/lib/services/user-interface.service';

@Component({
  selector: 'app-ccpa',
  templateUrl: './ccpa.component.html',
  styleUrls: ['./ccpa.component.scss']
})
export class CcpaComponent implements AfterViewInit {

  constructor(
    private viewposrScroller: ViewportScroller,
    private uiService: UserInterfaceService
  ) { }

  ngAfterViewInit(): void {
    this.viewposrScroller.setOffset([0,90]);
    setTimeout(() => {
      this.uiService.minHeader = true;  
    }, 100);
    
  }

  public onClick(elementId: string): void {
    this.viewposrScroller.scrollToAnchor(elementId);
  }

  ngOnDestroy(): void {
    this.uiService.minHeader = false;
  }

}
