<section class="subpage-content" [libLoading]="loading" [fullScreen]="true">
  <header class="app-page-header header-with-actions">
    <h2><fa-icon [icon]="actionsIcon"></fa-icon>Actions History</h2>
  </header>

  <article>
    <lib-box-container
      id="actions"
      [lessPadding]="true">

      <lib-ng-list [listsService]="actionsListService" (buttonClicked)="listButtonClick($event)" [refreshButton]="true" (refreshEvent)="refreshList()"></lib-ng-list>
      <lib-ng-pagination [listsService]="actionsListService"></lib-ng-pagination>
    </lib-box-container>
  </article>
</section>


<ng-template #actionDetails>
  <div *ngIf="actionDetailsData">

    <div class="border-with-title">
      <h4>Action Summary</h4>
      <div class="centered-modal-table centered-value">
        <table>
          <tr>
            <td class="label">Action</td>
            <td class="value">{{actionDetailsData.actionDescription}}</td>
          </tr>
          <tr>
            <td class="label">Date</td>
            <td class="value">{{actionDetailsData.when | date : 'medium'}}</td>
          </tr>
          <tr>
            <td class="label">Outcome</td>
            <td class="value">{{actionDetailsData.outcome}}</td>
          </tr>
          <tr>
            <td class="label">User</td>
            <td class="value">{{actionDetailsData.user.fullName}}</td>
          </tr>
        </table>
      </div>
    </div>

    <!-- <div class="border">
      <h3>Action Summary</h3>
      <p>
        <span class="label">Action</span>
        <span class="value">{{actionDetailsData.actionDescription}}</span>
      </p>
      <p>
        <span class="label">Date</span>
        <span class="value">{{actionDetailsData.when | date : 'medium'}}</span>
      </p>
      <p>
        <span class="label">Outcome</span>
        <span class="value">{{actionDetailsData.outcome}}</span>
      </p>
      <p>
        <span class="label">User</span>
        <span class="value">{{actionDetailsData.user.fullName}}</span>
      </p>
      <p>
        <span class="label">Request Trace Id</span>
        <span class="value">{{actionDetailsData.id}}</span>
      </p>
      <p>
        <span class="label">Reply Trace Id</span>
        <span class="value">{{actionDetailsData.messageId}}</span>
      </p>
    </div> -->

    <div class="border-with-title">
      <h4>Details - In</h4>
      <json-formatter [data]="actionDetailsData.in"></json-formatter>
    </div>

    <div class="border-with-title">
      <h4>Details - Out</h4>
      <json-formatter [data]="actionDetailsData.out"></json-formatter>
    </div>

    <div class="border-with-no-title smaller">
      <div class="centered-modal-table">
        <table>
          <tr *ngIf="actionDetailsData.messageId">
            <td class="label">Tracking Id</td>
            <td class="value">
              <span class="ellipsis">{{actionDetailsData.messageId}}</span>
              <a (click)="copyToClipboard(actionDetailsData.messageId)" class="copy-to-clipboard" title="Copy to clipboard">
                <fa-icon [icon]="copyIcon"></fa-icon>
              </a>
            </td>

          </tr>
        </table>
      </div>
    </div>

  </div>
</ng-template>


<ng-template #userDetails>
  <div *ngIf="userDetailsData">

    <header class="initial-with-role">
      <div class="initials-icon">{{userDetailsData.initials}}</div>
      <div class="role-and-username">
        <user-role-icon [userRole]="userDetailsRoleIcon" [isGlobalAdmin]="(userDetailsData.roles.includes('administrator') ? true : false)"></user-role-icon>
        <span class="user-name">{{userDetailsData.username}}</span>
      </div>
    </header>

    <div class="centered-modal-table">

      <ul>
        <li>
          <span class="label">Full Name</span>
          <span class="value">{{userDetailsData.fullName}}</span>
        </li>
        <li>
          <span class="label">User email</span>
          <span class="value">{{userDetailsData.email}}
            <a class="mailto-icon" href="mailto:{{userDetailsData.email}}" title="Send email">
              <fa-icon [icon]="emailIcon"></fa-icon>
            </a>
          </span>
        </li>
        <li>
          <span class="label">Phone</span>
          <span class="value">{{userDetailsData.displayPhone}}
            <a class="call-icon" title="Call to {{userDetailsData.rawPhone}}" href="tel:{{userDetailsData.rawPhone}}">
              <fa-icon [icon]="phoneIcon"></fa-icon>
            </a>
          </span>
        </li>
        <li>
          <span class="label">Company</span>
          <span class="value">{{userDetailsData.company.name}}</span>
        </li>
        <li>
          <span class="label">Job Position</span>
          <span class="value">{{userDetailsData.jobTitle}}</span>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
