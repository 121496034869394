<div class="tabs-container">
  <div class="tabs-header">
    <div
      class="tab-label"
      [class.active]="activeTab === item"
      [class.switching]="activeTab === item && switchingTabs"
      *ngFor="let item of tabItems$ | async">
      <!-- <ng-container (click)="selectTab(item)" *ngIf="item.labelComponent"> -->
        <header *ngIf="item.labelComponent">
          <div class="header-outer">
            <div class="header-inner">
              <h2 (click)="selectTab(item)">
                {{item?.label}}
              </h2>
            </div>
          </div>
        </header>      
        <!-- <ng-container *ngTemplateOutlet="item.labelContent">
        </ng-container> -->
      <!-- </ng-container> -->
      <ng-container *ngIf="!item.labelComponent">
        {{ item.label }}
      </ng-container>
    </div>
  </div>
  <div class="tabs-content-container" [class.switching]="switchingTabs">
    <div class="shared-content" *ngIf="showShared">
      <ng-container *ngTemplateOutlet="tabShared.sharedContent"></ng-container>
    </div>

    <div class="tabs-body">
      <div  *ngFor="let tab of tabItems$ | async">
        <div *ngIf="activeTab && tab && activeTab === tab" [@slideInOut]="{value: '*', params: { from: fromAnimation, to: toAnimation}}">
          <ng-container *ngTemplateOutlet="activeTab.bodyComponent.bodyContent">
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #labelContent>
  <header>
    <div class="header-outer">
      <div class="header-inner">
        <h2 (click)="selectTab(item)">
          <ng-content></ng-content>
        </h2>
      </div>
    </div>
  </header>
</ng-template>
