<div *ngIf="contactForm" [libLoading]="loading">
    <form novalidate (submit)="sendContactMessage()" [formGroup]="contactForm">
        <!-- <h2>Contact Us</h2> -->

        <lib-form-field
            [minBottomMargin]="true"
            [rightSideErrors]="true"
            [formGroupParameter]="contactForm"
            [fieldSettings]="contactFormSettings['fullName']"
            [value]="contactForm.value['fullName']">
        </lib-form-field>

        <lib-form-field
            [minBottomMargin]="true"
            [rightSideErrors]="true"
            [formGroupParameter]="contactForm"
            [fieldSettings]="contactFormSettings['email']"
            [value]="contactForm.value['email']">
        </lib-form-field>

        <lib-form-field
            [minBottomMargin]="true"
            [rightSideErrors]="true"
            [formGroupParameter]="contactForm"
            [fieldSettings]="contactFormSettings['phone']"
            [value]="contactForm.value['phone']">
        </lib-form-field>

        <lib-form-field
            [minBottomMargin]="true"
            [rightSideErrors]="true"
            [formGroupParameter]="contactForm"
            [fieldSettings]="contactFormSettings['message']"
            [value]="contactForm.value['message']">
        </lib-form-field>

        <div class="recaptcha-container">
            <!-- siteKey from tutorial -->
            <re-captcha
                name="captcha"
                required
                formControlName="captcha"
                siteKey="6LcYsIIUAAAAAC4v-rzZ4zovoSM3oDPbLan3KbwM">
            </re-captcha>

            <!-- Diamond Edge Site key -->
            <!-- <re-captcha
                name="captcha"
                required
                size="invisible"
                formControlName="captcha"
                siteKey="6LczaMYaAAAAAFpV0GKCakH_c56pIoQrxxP0VWdl">
            </re-captcha> -->


        </div>

        <div class="form-group form-button-container multiple-buttons">
            <!-- <button (click)="cancel()" type="button" class="secondary">Cancel</button> -->
            <button [disabled]="!contactForm.valid" class="primary" type="submit">Submit</button>
            <button [disabled]="contactForm.pristine" (click)="reset()" class="secondary" type="button">Clear</button>
        </div>
    </form>
</div>
