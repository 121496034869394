import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons';
import { faPlay, faUser } from '@fortawesome/free-solid-svg-icons';

const popupContainerAnimation = trigger(
  'popupContainerAnimation', [
    transition(':enter', [
      style({opacity:0}),
      animate('.5s linear', style({opacity:1}))
    ]),
    transition(':leave', [
      style({opacity:1}),
      animate('.5s', style({opacity:0}))
    ])
  ]
);

const popupAnimation = trigger(
  'popupAnimation', [
    transition(':enter', [
      style({opacity:0}),
      animate('.5s .5s linear', style({opacity:1}))
    ])
  ]
);

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  animations:[popupContainerAnimation,popupAnimation]
})
export class AboutComponent implements OnInit {
  userIcon = faUser;
  videoPopup: boolean;
  popupInner: boolean;
  playIcon = faPlayCircle;

  listenerFn: () => void;

  constructor(
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.videoPopup = false;
    this.popupInner = false;
  }

  ClosePopup(): void {
    this.popupInner = false;
    this.videoPopup = false;
    this.listenerFn();
  }

  showVideo():void {
    this.videoPopup = !this.videoPopup;
    if(this.videoPopup) {
      setTimeout(() => {
        this.popupInner = true;
        this.listenerFn = this.renderer.listen('window', 'keydown',(e:KeyboardEvent)=>{
          if(e.key === 'Escape') {
            this.popupInner = false;
            this.videoPopup = false;
            this.listenerFn();
          }
        })
      }, 501);
    } else {
      this.popupInner = false;
      this.listenerFn();
    }
  }

}
