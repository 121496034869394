import { Component, OnInit } from '@angular/core';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { UserManagementService } from '../../user-management.service';

@Component({
  selector: 'app-user-edit-sidebar',
  templateUrl: './user-edit-sidebar.component.html',
  styleUrls: ['./user-edit-sidebar.component.scss']
})
export class UserEditSidebarComponent implements OnInit {
  penIcon = faPen;

  constructor(
    public userManager: UserManagementService
  ) { }

  ngOnInit() {
  }

  toggleEditable(): void {
    this.userManager.userInfoEditablePreview = !this.userManager.userInfoEditablePreview;
  }

}
