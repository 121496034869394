<article class="legal-content">
    <div class="page-inner">
        <header>
            <h1>Diamond Edge IT Privacy Notice for California Residents</h1>
            <h3 class="updated">Last updated on January 24<sup>th</sup>, 2024</h3>
        </header>

        <aside>
            <section class="intro">
                <p>This Privacy Notice for California Residents supplements the information contained in DIAMOND EDGE IT's <a [routerLink]="['/privacy']">Privacy Statement</a> and applies solely to all visitors, users, and others who reside in the State of California ("consumers" or "you").</p>
                <p>We adopt this notice to comply with the California Consumer Privacy Act of 2018 (CCPA) and any terms defined in the CCPA have the same meaning when used in this Notice.</p>
                <p>This Notice does not apply to employment-related personal information collected from California-based employees, job applicants, contractors, or similar individuals.</p>
                <p>Where noted in this Notice, the CCPA temporarily exempts personal information reflecting a written or verbal business-to-business communication ("B2B personal information") from some its requirements.</p>
            </section>

            <section class="toc">
                <p>This Privacy Policy is broken down into the following Sections for your convenience:</p>
                <nav class="scroll-navigation">
                    <ol>
                        <li><a (click)="onClick('Information_Collected')" rel="noreferrer">Information We Collect</a></li>
                        <li><a (click)="onClick('PI_Use')" rel="noreferrer">Use of Personal Information</a></li>
                        <li><a (click)="onClick('Sharing_PI')" rel="noreferrer">Sharing Personal Information</a></li>
                        <li><a (click)="onClick('Disclosures_PI')" rel="noreferrer">Disclosures of Personal Information for a Business Purpose</a></li>
                        <li><a (click)="onClick('Sales_of_PI')" rel="noreferrer">Sales of Personal Information</a></li>
                        <li><a (click)="onClick('Rights_of_Choice')" rel="noreferrer">Your Rights and Choices</a></li>
                        <li><a (click)="onClick('Access')" rel="noreferrer">Access to Specific Information and Data Portability Rights</a></li>
                        <li><a (click)="onClick('Deletion_Request')" rel="noreferrer">Deletion Request Rights</a></li>
                        <li><a (click)="onClick('Excercising_Rights')" rel="noreferrer">Exercising Access, Data Portability, and Deletion Rights</a></li>
                        <li><a (click)="onClick('Response_Timing')" rel="noreferrer">Response Timing and Format</a></li>
                        <li><a (click)="onClick('Personal_Information')" rel="noreferrer">Personal Information Sales Opt-Out and Opt-In Rights</a></li>
                        <li><a (click)="onClick('Non_Discrimination')" rel="noreferrer">Non-Discrimination</a></li>
                        <li><a (click)="onClick('Other_CCPA')" rel="noreferrer">Other California Privacy Rights</a></li>
                        <li><a (click)="onClick('Changes')" rel="noreferrer">Changes to Our Privacy Notice</a></li>
                        <li><a (click)="onClick('Contact_Information')" rel="noreferrer">Contact Information</a></li>
                    </ol>
                </nav>
            </section>
        </aside>

        <main>
            <ol class="main-content">
                <li class="top-level">
                    <h2 id="Information_Collected">Information We Collect</h2>
                    <p>Our Website collects information that identifies, relates to, describes, references, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer, household, or device ("personal information").</p>
                    <p>Personal information does not include:</p>

                    <ul>
                        <li>Publicly available information from government records.</li>
                        <li>Deidentified or aggregated consumer information.</li>
                        <li>Information excluded from the CCPA's scope, like:</li>
                        <ul>
                            <li>health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data;</li>
                            <li>personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FCRA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994.</li>
                        </ul>
                    </ul>

                    <p>In particular, our Website has collected the following categories of personal information from its consumers within the last twelve (12) months:</p>

                    <table>
                        <thead>
                            <tr>
                                <th>Category</th>
                                <th>Examples</th>
                                <th>Collected</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>A. Identifiers.</td>
                                <td>A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, Social Security number, driver's license number, passport number, or other similar identifiers.</td>
                                <td>YES</td>
                            </tr>
                            <tr>
                                <td>B. Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).</td>
                                <td>A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver's license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some personal information included in this category may overlap with other categories.</td>
                                <td>YES</td>
                                </tr>
                            <tr>
                                <td>C. Protected classification characteristics under California or federal law.</td>
                                <td>Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).</td>
                                <td>NO</td>
                            </tr>
                            <tr>
                                <td>D. Commercial information.</td>
                                <td>Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</td>
                                <td>YES</td>
                            </tr>
                            <tr>
                                <td>E. Biometric information.</td>
                                <td>Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.</td>
                                <td>NO</td>
                            </tr>
                            <tr>
                                <td>F. Internet or other similar network activity.</td>
                                <td>Browsing history, search history, information on a consumer's interaction with a website, application, or advertisement.</td>
                                <td>YES</td>
                            </tr>
                            <tr>
                                <td>G. Geolocation data.</td>
                                <td>Physical location or movements. </td>
                                <td>NO</td>
                            </tr>
                            <tr>
                                <td>H. Sensory data.</td>
                                <td>Audio, electronic, visual, thermal, olfactory, or similar information.</td>
                                <td>NO</td>
                            </tr>
                            <tr>
                                <td>I. Professional or employment-related information.</td>
                                <td>Current or past job history or performance evaluations.</td>
                                <td>NO</td>
                            </tr>
                            <tr>
                                <td>J. Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</td>
                                <td>Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records. </td>
                                <td>NO</td>
                            </tr>
                            <tr>
                                <td>K. Inferences drawn from other personal information.</td>
                                <td>Profile reflecting a person's preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</td>
                                <td>NO</td>
                            </tr>
                        </tbody>
                    </table>

                    <p>Our Website obtains the categories of personal information listed above from the following categories of sources:</p>
                    <ul>
                        <li>Directly from you. For example, from forms you complete or products and services you purchase.</li>
                        <li>Indirectly from you. For example, from observing your actions on our Website.</li>
                    </ul>
                </li>

                <li class="top-level">
                    <h2 id="PI_Use">Use of Personal Information</h2>
                    <p>We may use, sell, or disclose the personal information we collect for one or more of the following purposes:</p>

                    <ul>
						<li>To fulfill or meet the reason you provided the information. For example, if you share your name and contact information to request a price quote or ask a question about our products or services, we will use that personal information to respond to your inquiry. If you provide your personal information to purchase a product or service, we will use that information to process your payment and facilitate delivery. We may also save your information to facilitate new product orders or process returns.</li>
                        <li>To provide, support, personalize, and develop our Website, products, and services.</li>
						<li>To create, maintain, customize, and secure your account with us.</li>
						<li>To process your requests, purchases, transactions, and payments and prevent transactional fraud.</li>
						<li>To provide you with support and to respond to your inquiries, including to investigate and address your concerns and monitor and improve our responses.</li>
						<li>To personalize your Website experience and to deliver content and product and service offerings relevant to your interests, including targeted offers and ads through our Website, third-party sites, and via email or text message (with your consent, where required by law).</li>
						<li>To help maintain the safety, security, and integrity of our Website, products and services, databases and other technology assets, and business.</li>
						<li>For testing, research, analysis, and product development, including to develop and improve our Website, products, and services.</li>
						<li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</li>
						<li>As described to you when collecting your personal information or as otherwise set forth in the CCPA.</li>
						<li>To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us  about our Website users is among the assets transferred.</li>
					</ul>

                    <p>We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.</p>
                </li>

                <li class="top-level">
                    <h2 id="Sharing_PI">Sharing Personal Information</h2>

                    <p>We do not disclose your personal information to a third party for a business purpose or sell your personal information, subject to your right to opt-out of those sales (see the section titled <a (click)="onClick('Personal_Information')" rel="noreferrer">Personal Information Sales Opt-Out and Opt-In Rights</a>). </p>
                    <p>The CCPA prohibits third parties who purchase the personal information we hold from reselling it unless you have received explicit notice and an opportunity to opt-out of further sales.</p>
                    <p>We share your personal information with the following categories of third parties: </p>

                    <ul>
						<li>We do not share any information with third parties.</li>
					</ul>
                </li>

                <li class="top-level">
                    <h2 id="Disclosures_PI">Disclosures of Personal Information for a Business Purpose</h2>

                    <p>In the preceding twelve (12) months, Company has not disclosed personal information for a business purpose: </p>
					<ul>
						<li>Category A: Identifiers.</li>
						<li>Category B: Personal information categories listed in the California Customer Records statute.</li>
						<li>Category C: Protected classification characteristics under California or federal law.</li>
						<li>Category D: Commercial information.</li>
						<li>Category E: Biometric information.</li>
						<li>Category F: Internet or other similar network activity.</li>
						<li>Category G: Geolocation data.</li>
						<li>Category H: Sensory data.</li>
						<li>Category I: Professional or employment-related information.</li>
						<li>Category J: Non-public education information (per the Family Educational Rights and Privacy Act.</li>
						<li>Category K: Inferences drawn from other personal information.</li>
					</ul>
                </li>

                <li class="top-level">
                    <h2 id="Sales_of_PI">Sales of Personal Information</h2>

                    <p>In the preceding twelve (12) months, Company had not sold personal information: </p>
                    <ul>
						<li>Category A: Identifiers.</li>
						<li>Category B: Personal information categories listed in the California Customer Records statute.</li>
						<li>Category C: Protected classification characteristics under California or federal law.</li>
						<li>Category D: Commercial information.</li>
						<li>Category E: Biometric information.</li>
						<li>Category F: Internet or other similar network activity.</li>
						<li>Category G: Geolocation data.</li>
						<li>Category H: Sensory data.</li>
						<li>Category I: Professional or employment-related information.</li>
						<li>Category J: Non-public education information (per the Family Educational Rights and Privacy Act.</li>
						<li>Category K: Inferences drawn from other personal information.</li>
					</ul>
					<p>We sell your personal information to the following categories of third parties: </p>
					<ul>
						<li>Company does not sell personal information.</li>
					</ul>
                </li>

                <li class="top-level">
                    <h2 id="Rights_of_Choice">Your Rights and Choices</h2>

                    <p>The CCPA provides consumers (California residents) with specific rights regarding their personal information.</p>
                    <p>This section describes your CCPA rights and explains how to exercise those rights.</p>
                </li>

                <li class="top-level">
                    <h2 id="Access">Access to Specific Information and Data Portability Rights</h2>

                    <p>You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months.</p>
                    <p>Once we receive and confirm your verifiable consumer request (see the section titled <a (click)="onClick('Excercising_Rights')" rel="noreferrer">Exercising Access, Data Portability, and Deletion Rights</a>), we will disclose to you:</p>
                    <ul>
						<li>The categories of personal information we collected about you.</li>
						<li>The categories of sources for the personal information we collected about you.</li>
						<li>Our business or commercial purpose for collecting or selling that personal information.</li>
						<li>The categories of third parties with whom we share that personal information.</li>
						<li>The specific pieces of personal information we collected about you (also called a data portability request).</li>
						<li>If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:</li>
						<ul>
							<li>sales, identifying the personal information categories that each category of recipient purchased; and</li>
							<li>disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.</li>
						</ul>
					</ul>
					<p>We do not provide these access and data portability rights for B2B personal information.</p>
                </li>

                <li class="top-level">
                    <h2 id="Deletion_Request">Deletion Request Rights</h2>

                    <p>You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions. </p>
                    <p>Once we receive and confirm your verifiable consumer request (see the section titled <a (click)="onClick('Excercising_Rights')" rel="noreferrer">Exercising Access, Data Portability, and Deletion Rights</a>), we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies. </p>
                    <p>We may deny your deletion request if retaining the information is necessary for us or our service provider(s) to: </p>
					<ol>
						<li>Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, or otherwise perform our contract with you.</li>
						<li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</li>
						<li>Debug products to identify and repair errors that impair existing intended functionality.</li>
						<li>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.</li>
						<li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 <em>et. seq.</em>).</li>
						<li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information&#39;s deletion may likely render impossible or seriously impair the research&#39;s achievement, if you previously provided informed consent.</li>
						<li>Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.</li>
						<li>Comply with a legal obligation.</li>
						<li>Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</li>
					</ol>
					<p>We do not provide these deletion rights for B2B personal information.</p>
                </li>

                <li class="top-level">
                    <h2 id="Excercising_Rights">Exercising Access, Data Portability, and Deletion Rights</h2>

                    <p>To exercise the access, data portability, and deletion rights described above, please submit a verifiable consumer request to us by either: </p>
                    <ul>
                        <li>Calling us at <a href="tel:+18553761150" target="_blank" rel="noreferrer">(855) 376-1150</a></li>
                        <li>Emailing us at <a href="mailto:support@diamondedge-it.com" target="_blank" rel="noreferrer">support@diamondedge-it.com</a>.</li>
                        <li>Visiting <a href="https://www.google.com/url?q=https://www.diamondedge-it.com&amp;source=gmail-html&amp;ust=1639072420058000&amp;usg=AOvVaw2mS6UOLZT4Ihfs_ptjItiM" target="_blank" rel="noreferrer">https://www.diamondedge-it.com</a>.</li>
						<li>Accessing the Diamond Edge IT <a href="https://smartcodeplatform.com/(sidePopup:supportrequest)" target="_blank" rel="noreferrer">Support Request</a>.</li>
                    </ul>
                    <p>Only you, or someone legally authorized to act on your behalf, may make a verifiable consumer request related to your personal information.</p>
                    <p>You may only make a verifiable consumer request for access or data portability twice within a 12-month period. The verifiable consumer request must:</p>
					<ul>
						<li>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative, which may include:</li>
						<li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li>
					</ul>
					<p>We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you. </p>
					<p>Making a verifiable consumer request does not require you to create an account with us. However, we do consider requests made through your password protected account sufficiently verified when the request relates to personal information associated with that specific account.</p>
					<p>We will only use personal information provided in a verifiable consumer request to verify the requestor&#39;s identity or authority to make the request. </p>
					<p>For instructions on exercising sale opt-out rights, see the section titled <a (click)="onClick('Personal_Information')" rel="noreferrer">Personal Information Sales Opt-Out and Opt-In Rights</a>.</p>
                </li>

                <li class="top-level">
                    <h2 id="Response_Timing">Response Timing and Format</h2>

                    <p>We endeavor to respond to a verifiable consumer request within forty-five (45) days of its receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing.</p>
                    <p>If you have an account with us, we will deliver our written response to that account. If you do not have an account with us, we will deliver our written response by mail or electronically, at your option. </p>
                    <p>Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request&#39;s receipt. The response we provide will also explain the reasons we cannot comply with a request, if applicable. For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.</p>
					<p>We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request. </p>
                </li>

                <li class="top-level">
                    <h2 id="Personal_Information">Personal Information Sales Opt-Out and Opt-In Rights</h2>

                    <p>If you are 16 years of age or older, you have the right to direct us to not sell your personal information at any time (the &quot;right to opt-out&quot;). We do not sell the personal information of consumers we actually know are less than 16 years of age. Consumers who opt-in to personal information sales may opt-out of future sales at any time. </p>
                    <p>To exercise the right to opt-out, you (or your authorized representative) may submit a request to us by visiting the following Internet Web page link: </p>
                    <p><a href="https://www.google.com/url?q=https://smartcodeplatform.com/support&amp;source=gmail-html&amp;ust=1639072420058000&amp;usg=AOvVaw3SD3iUAlomtS_UjJpsIGi0" target="_blank" rel="noreferrer">Do Not Sell My Personal Information</a></p>
					<p>Once you make an opt-out request, we will wait at least twelve (12) months before asking you to reauthorize personal information sales. However, you may change your mind and opt back in to personal information sales at any time by:</p>
					<p><a href="https://www.google.com/url?q=https://smartcodeplatform.com/support&amp;source=gmail-html&amp;ust=1639072420058000&amp;usg=AOvVaw3SD3iUAlomtS_UjJpsIGi0" target="_blank" rel="noreferrer">Opt In Instructions</a></p>
					<p>You do not need to create an account with us to exercise your opt-out rights. We will only use personal information provided in an opt-out request to review and comply with the request. </p>
                </li>

                <li class="top-level">
                    <h2 id="Non_Discrimination">Non-Discrimination</h2>

                    <p>We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:</p>
                    <ul>
						<li>Deny you goods or services.</li>
						<li>Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.</li>
						<li>Provide you a different level or quality of goods or services.</li>
						<li>Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
					</ul>
					<p>However, we may offer you certain financial incentives permitted by the CCPA that <strong>can result</strong> in different prices, rates, or quality levels. Any CCPA-permitted financial incentive we offer will reasonably relate to your personal information&#39;s value and contain written terms that describe the program&#39;s material aspects. Participation in a financial incentive program requires your prior opt in consent, which you may revoke at any time. We currently do not provide financial incentives.</p>
                </li>

                <li class="top-level">
                    <h2 id="Other_CCPA">Other California Privacy Rights</h2>

                    <p>California&#39;s &quot;Shine the Light&quot; law (Civil Code Section § 1798.83) permits users of our Website that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an email to <a href="mailto:support@smartcodedplatform.com" target="_blank" rel="noreferrer">support@smartcodeplatform.com</a> or write us at: </p>
					<address>
                        1065 SW 8th St #251<br>
                        Miami, FL 33130<br>
                    </address>
                </li>

                <li class="top-level">
                    <h2 id="Changes">Changes to Our Privacy Notice</h2>

                    <p>We reserve the right to amend this privacy notice at our discretion and at any time. When we make changes to this privacy notice, we will post the updated notice on the Website and update the notice&#39;s effective date. <strong>Your continued use of our Website following the posting of changes constitutes your acceptance of such changes.</strong></p>
                </li>

                <li class="top-level">
                    <h2 id="Contact_Information">Contact Information</h2>
                    <p>If you have any questions or comments about this notice, the ways in which DIAMOND EDGE IT collects and uses your information described here and in the <a [routerLink]="'/privacy/'" rel="noreferrer">Privacy Statement</a>, your choices and rights regarding such use, or wish to exercise your rights under California law, please do not hesitate to contact us at:</p>
					<p>Diamond Edge IT, Inc.</p>
					<address>
                        1065 SW 8th St #1668<br>
                        Miami, FL 33130<br>
                    </address>
                    <p>Phone: <a href="tel:+18553761150" target="_blank" rel="noreferrer">(855) 376-1150</a></p>
                    <p>Email: <a href="mailto:support@diamondege-it.com" target="_blank" rel="noreferrer">support@diamondege-it.com</a></p>
                </li>
            </ol>
        </main>
    </div>
</article>
<lib-scroll-to-top></lib-scroll-to-top>
