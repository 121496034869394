import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { faDownload, faFingerprint, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApiUser } from 'projects/nine-gold-lib/src/lib/models/user';
import { ApiService } from 'projects/nine-gold-lib/src/lib/services/api.service';
import { AuthService } from 'projects/nine-gold-lib/src/lib/services/auth.service';
import { UserService } from 'projects/nine-gold-lib/src/lib/services/user.service';
import { FormResultMessageFormat } from 'projects/nine-gold-lib/src/lib/shared/notification/notification';
import { AccordionComponent, NotificationService } from 'projects/nine-gold-lib/src/public-api';

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss']
})
export class SecurityComponent implements OnInit {
  faSecurityIcon = faFingerprint;
  changePasswordLink: string;
  downloadJsonHref: SafeUrl;
  modalRef: BsModalRef;

  currentUser: ApiUser;

  userDataLoading = false;
  userDataLoaded = false;

  userDeleteConfirmMessage = "";

  userToDelete: ApiUser = null;
  allowDeleteUser = false;
  optOut = false;

  @ViewChild('userDeleteConfirm')
   private userDeleteConfirm: TemplateRef<any>;

  @ViewChild('userDataDownload')
    private userDataDownload: TemplateRef<any>;

  @ViewChild('donwloadInlineBody') private donwloadInlineBody: TemplateRef<any>;

  @ViewChild('donwloadInline') private donwloadInline: AccordionComponent;

  @ViewChild('userDeleteFooter') private userDeleteFooter: TemplateRef<any>;

  @ViewChild('userDeleteConfirmation') private userDeleteConfirmation: TemplateRef<any>;

  counter: string;
  

  downloadFileIcon = faDownload;
  infoIcon = faInfoCircle;
  ccpaInfo = 'All of your information will be removed from all places. Only username timestamp of your request and timestamp of removal action will be stored as a proof of user accound removal';

  constructor(
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private notif: NotificationService,
    private modalService: BsModalService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.userService.currentUser;
    this.userService.userUpdated.subscribe(u => this.currentUser = u);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  ChangePassword() {
    this.authService.getPasswordChangeLink().then(link => window.location.href = link);
  }

  SetUpTwoFactor() {
    this.authService.getSetupTwoFactorLink().then(link => window.location.href = link);
  }

  RequestData(inline = false): void {
    this.userDataLoading = true;
    this.apiService.downloadUserData().subscribe(result => {
      let theJSON = JSON.stringify(result, null, 2);
      var uri = this.sanitizer.bypassSecurityTrustUrl("data:text/json;charset=UTF-8," + encodeURIComponent(theJSON));
      this.downloadJsonHref = uri;
      this.userDataLoading = false;

      this.userDataLoaded = true;
      if(inline) {
        var link = document.createElement("a");
        link.download = `user_transcript_${this.userToDelete.username}.json`;
        var data = "text/json;charset=UTF-8," + encodeURIComponent(theJSON);
        link.href = "data:" + data;
        link.click();
      } else {
        this.openModal(this.userDataDownload);
      }
    })
  }

 
  toggleOptOut(): void {
    this.optOut = !this.optOut;
  }

  toggleAllowDeleteUser(): void {
    this.allowDeleteUser = !this.allowDeleteUser;
  }

  DeleteUser(): void {
    
    this.notif.hide();
    this.apiService.deleteCurrentUser(this.optOut).subscribe(result => {
      this.userDeleteConfirmMessage = (this.optOut ? 'Your request was sent succesfully. Your user will be fully removed within 7 days.' : 'Your user was removed.');
      let title = (this.optOut ? 'Request Sent' : 'User removed');
      this.userToDelete = null;
      this.allowDeleteUser = false;
      this.optOut = false;
      this.userDataLoaded = false;
      setTimeout(() => {
        this.notif.info(title, "", FormResultMessageFormat.modalHtmlNoClose, 0, this.userDeleteConfirmation, false,null,null);
        this.timer(5);
      }, 200);
      
      
    })
  }

  RequestDeletion(): void {
    this.userToDelete = this.userService.currentUser;
    this.allowDeleteUser = false;
    this.optOut = false;
    this.userDataLoaded = false;
    this.notif.info('Deleting Current User', "", FormResultMessageFormat.modalHtml, 0, this.userDeleteConfirm,false,null,this.userDeleteFooter);
    
  }

  timer(seconds) {
    this.counter = `You will be logged out in ${seconds} ${(seconds > 1 ? 'seconds' : 'second')}.`;

    const timer = setInterval(() => {
      seconds--;

      this.counter = `You will be logged out in ${seconds} ${(seconds > 1 ? 'seconds' : 'second')}.`;

      if (seconds == 0) {
        clearInterval(timer);
        this.authService.new_logout();
        this.notif.hide();
      }
    }, 1000);
  }

}
