import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { AnimateModule } from '@wizdm/animate';
// import { AppconfigModule } from 'dist/nine-gold-lib/lib/services/appconfig/appconfig.module';
// import { NotificationModule } from 'dist/nine-gold-lib/lib/shared/notification/notification.module';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { RegisterRedirectCallbackComponent } from 'projects/nine-gold-lib/src/lib/common/auth-pages/register-redirect-callback.component';
import { SigninRedirectCallbackComponent } from 'projects/nine-gold-lib/src/lib/common/auth-pages/signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from 'projects/nine-gold-lib/src/lib/common/auth-pages/signout-redirect-callback.component';
import { NineGoldLibModule } from 'projects/nine-gold-lib/src/lib/nine-gold-lib.module';
import { ApiInterceptorService } from 'projects/nine-gold-lib/src/lib/services/api-interceptor.service';
import { AppSettingsService } from 'projects/nine-gold-lib/src/lib/services/app-settings.service';
import { AppconfigModule } from 'projects/nine-gold-lib/src/lib/services/appconfig/appconfig.module';
import { NotificationModule } from 'projects/nine-gold-lib/src/lib/shared/notification/notification.module';
import { Tabs1Module } from 'projects/nine-gold-lib/src/lib/shared/tabs/tabs.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserInfoComponent } from './common/user/user-info/user-info.component';
import { AboutComponent } from './pages/about/about.component';
import { CcpaComponent } from './pages/ccpa/ccpa.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { NewLicenseComponent } from './pages/sidebar-pages/new-license/new-license.component';
import { QuoteFormComponent } from './pages/sidebar-pages/quote-form/quote-form.component';
import { StyleguideComponent } from './pages/styleguide/styleguide.component';
import { TermsComponent } from './pages/terms/terms.component';
import { ActionsComponent } from './pages/user/actions/actions.component';
import { SecurityComponent } from './pages/user/security/security.component';
import { SubusersSidebarComponent } from './pages/user/subusers/subusers-sidebar/subusers-sidebar.component';
import { SubusersComponent } from './pages/user/subusers/subusers.component';
import { NewLicenseFormComponent } from './pages/user/super-admin/forms/new-license-form/new-license-form/new-license-form.component';
import { SuperAdminSidebarComponent } from './pages/user/super-admin/super-admin-sidebar/super-admin-sidebar.component';
import { SuperAdminComponent } from './pages/user/super-admin/super-admin.component';
import { UserEditSidebarComponent } from './pages/user/user-edit/user-edit-sidebar/user-edit-sidebar.component';
import { UserEditComponent } from './pages/user/user-edit/user-edit.component';
import { UserLicenseComponent } from './pages/user/user-license/user-license.component';
import { UserComponent } from './pages/user/user.component';
import { NineGoldSettingsService } from './services/nine-gold-settings.service';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { UsesComponent } from './pages/user/uses/uses.component';
import { FaqComponent } from './pages/faq/faq.component';
import { SupportRequestComponent } from './pages/sidebar-pages/support-request/support-request.component';
import { InvitationResponseComponent } from './pages/user/invitation-response/invitation-response/invitation-response.component';
import { DemoFormComponent } from './pages/sidebar-pages/demo-form/demo-form.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { environment } from '../environments/environment';
// import { PressComponent } from './pages/press/press.component';
import { RegisterCallbackComponent } from 'projects/nine-gold-lib/src/lib/common/auth-pages/register-callback.component';
import { OurStrategyComponent } from './pages/our-strategy/our-strategy.component';
import { CalculatingTheCostComponent } from './pages/calculating-the-cost/calculating-the-cost.component';
import { MigrationRiskAssessmentComponent } from './pages/migration-risk-assessment/migration-risk-assessment.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    // PressComponent,
    ContactComponent,
    StyleguideComponent,
    UserInfoComponent,
    UserComponent,
    UserEditComponent,
    UserLicenseComponent,
    ActionsComponent,
    SubusersComponent,
    UsesComponent,
    SecurityComponent,
    SigninRedirectCallbackComponent,
    SignoutRedirectCallbackComponent,
    RegisterRedirectCallbackComponent,
    RegisterCallbackComponent,
    TermsComponent,
    PrivacyComponent,
    UserEditSidebarComponent,
    SubusersSidebarComponent,
    SuperAdminComponent,
    SuperAdminSidebarComponent,
    CcpaComponent,
    NewLicenseComponent,
    NewLicenseFormComponent,
    QuoteFormComponent,
    FaqComponent,
    SupportRequestComponent,
    InvitationResponseComponent,
    DemoFormComponent,
    OurStrategyComponent,
    CalculatingTheCostComponent,
    MigrationRiskAssessmentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NineGoldLibModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    CollapseModule.forRoot(),
    AccordionModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    NgSelectModule,
    FontAwesomeModule,
    NotificationModule,
    AppconfigModule,
    FormsModule,
    AnimateModule,
    Tabs1Module,
    LazyLoadImageModule,
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    NgxGoogleAnalyticsRouterModule
  ],
  providers: [DatePipe,Title,{ provide: AppSettingsService, useClass: NineGoldSettingsService },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptorService, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
