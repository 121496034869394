import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faCog, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { IFormField } from 'projects/nine-gold-lib/src/lib/models/formField';
import { InputTypes } from 'projects/nine-gold-lib/src/lib/models/formField';
import { ApiUser, ChangeTypes, IUser, manager, UserRoles } from 'projects/nine-gold-lib/src/lib/models/user';
import { FormsService } from 'projects/nine-gold-lib/src/lib/services/forms.service';
import { UserService } from 'projects/nine-gold-lib/src/lib/services/user.service';
import { UserManagementService } from '../user-management.service';
import { ApiService } from 'projects/nine-gold-lib/src/lib/services/api.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserEditComponent implements OnInit {
  user: ApiUser;

  faSettingsIcon = faCog;
  emailIcon = faPaperPlane;

  industryPreForm: IFormField[] = [
    {
      inputName: "industry",
      id: "industryId",
      placeholder: "Select Industry",
      label: "Industry",
      value: "test",
      inputType: InputTypes.select,
      required: false
    }
  ];

  industryFormSettings:{} = {};

  industryForm: FormGroup;

  fieldSettings = {
    firstName: [{
      errorMessagesList: {
        maxlength: "No more than 50 characters."
      },
      inputName: "firstName",
      id: 'firstNameId',
      placeholder: "Your First Name",
      label: "First Name",
      value: "",
      inputType: InputTypes.text,
      required: true,
      requiredErrorMessage: "First Name is required",
      validationRules: [
        {
          type: "required",
          errorMessage: "First Name is required",
          value: true
        },
        {
          type: "maxLength",
          parameter: "50",
          errorMessage: "No longer than 50 characters"
        }
      ]
    }],
    lastName: [{
      errorMessagesList: {
        maxlength: "No more than 50 characters."
      },
      inputName: "lastName",
      id: 'lastNameId',
      placeholder: "Your Last Name",
      label: "Last Name",
      value: "",
      inputType: InputTypes.text,
      required: true,
      requiredErrorMessage: "Last Name is required",
      validationRules: [
        {
          type: "required",
          errorMessage: "Last Name is required",
          value: true
        },
        {
          type: "maxLength",
          parameter: "50",
          errorMessage: "No longer than 50 characters"
        }
      ]
    }],
    company: [{
      inputName: "company",
      id: 'companyId',
      placeholder: "Company Name",
      label: "Company Name",
      value: "",
      inputType: InputTypes.text,
      propertyMapping: ['company','name'],
      required: true,
      requiredErrorMessage: "Company is required",
      validationRules: [
        {
          type: "required",
          errorMessage: "Company name is required",
          value: true
        }
      ]
    }],
    jobTitle: [{
      inputName: "jobTitle",
      id: 'jobTitleId',
      placeholder: "Job Title",
      label: "Job Title",
      value: "",
      inputType: InputTypes.text,
      required: false,
    }],
    email: [{
      errorMessagesList: {
        email: "Wrong Email format."
      },
      inputName: "email",
      id: "emailId",
      placeholder: "Your Email Address",
      label: "Email",
      value: "",
      inputType: InputTypes.email,
      required: true,
      requiredErrorMessage: "Email is required",
      validationRules: [
        {
          type: "required",
          errorMessage: "Full Name is required",
          value: true
        },
        {
          type: "email",
          message: "Wrong Email format",
          value: true
        }
      ]
    }],
    phone: [{
      inputName: "phone",
      id: "phoneId",
      placeholder: "Your Phone Number",
      label: "Phone",
      value: "",
      inputType: InputTypes.tel,
      required: false
    }]
  }

  companyAdministrators: manager[] = [];
  companyOwner: manager = null;

  constructor(
    public userService: UserService,
    public userManager: UserManagementService,
    private formService: FormsService,
    private fb: FormBuilder,
    private apiService: ApiService
    ) { }

  ngOnInit(): void {
    this.userService.userUpdated.subscribe(
      (user) => {
        this.user = user;
        this.setIndustry();
      }
    );
    this.user = this.userService.getUserInfo();



    // * Creating form for Industry
    let industryForm = this.formService.createForm('industry', this.industryPreForm);

    industryForm.forEach(el => {
      this.industryFormSettings[el.inputName] = el;
    });

    let formReactive = this.formService.convertToReactiveForm(industryForm);

    this.industryForm = this.fb.group(formReactive);


    this.setManagers();
  }

  ngAfterViewInit(): void {
    this.setIndustry();
  }


  setManagers(): void {
    // TODO: Move this to user service
    // TODO: clean this up
    this.apiService.getUsersList(this.user.company.id).subscribe(m => {
      if (m['companyOwner']) {
        this.companyOwner = {
          fullName: m['companyOwner'].fullName,
          role: UserRoles.company_owner,
          email: m['companyOwner'].email
        }
        if (m['companyAdministrators']?.length > 0) {
          m['companyAdministrators'].filter(a => !a.roles.includes(UserRoles.company_owner)).forEach(a => {
            this.companyAdministrators.push({
              fullName: a.fullName,
              role: UserRoles.company_administrator,
              email: a.email
            });
          });
        }
      } else {
        if(m['user'].roles.includes(UserRoles.company_owner)) {
          this.companyOwner = {
            fullName: m['user'].fullName,
            role: UserRoles.company_owner,
            email: m['user'].email
          };
          m['membersList'].filter(a => (a.roles.includes(UserRoles.company_administrator) && !a.roles.includes(UserRoles.company_owner))).forEach(a => {
            this.companyAdministrators.push({
              fullName: a.fullName,
              role: UserRoles.company_administrator,
              email: a.email
            });
          });
        } else {
           let owner = m['membersList'].find(el => el.roles.includes(UserRoles.company_owner));
           if(owner) {
            this.companyOwner = {
              fullName: owner.fullName,
              role: UserRoles.company_owner,
              email: owner.email
            };
           }
           this.companyAdministrators.push({
            fullName: m['user'].fullName,
            role: UserRoles.company_administrator,
            email: m['user'].email
           });
           m['membersList'].filter(a => (a.roles.includes(UserRoles.company_administrator) && !a.roles.includes(UserRoles.company_owner))).forEach(a => {
            this.companyAdministrators.push({
              fullName: a.fullName,
              role: UserRoles.company_administrator,
              email: a.email
            });
          });
        }

      }
    });
  }

  setIndustry():void {
    setTimeout(() => {
      if(this.user.industry) {
        console.log(this.user.industry)
        this.industryForm.get('industry').patchValue(this.user.industry);
        this.industryForm.get('industry').updateValueAndValidity();
      }
    }, 500);
  }

  saveCompanyValue(value: string, fieldName: string): void {
    console.log(value)
    let label = (fieldName === 'name' ? 'Company Name' : 'Industry')
    this.userService.updateCompany({propertyName: fieldName, value: value, changeType: ChangeTypes.replace,propertyLabel: label});
  }


  saveValue(value: string, fieldName: string):void {
    if(fieldName === 'company') {
      this.user.company.name = value;
    } else {
      this.user[fieldName] = value;
    }

    this.userService.updateCurrentUser({propertyName: fieldName, value: value, changeType: ChangeTypes.replace, propertyLabel: this.fieldSettings[fieldName][0].label})
  }

}
