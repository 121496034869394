import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AuthGuard } from 'projects/nine-gold-lib/src/lib/services/auth.guard';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { StyleguideComponent } from './pages/styleguide/styleguide.component';
import { UserInfoComponent } from './common/user/user-info/user-info.component';
import { SiteHomeComponent } from 'projects/nine-gold-lib/src/lib/layouts/site-home/site-home.component';
import { SiteContentComponent } from 'projects/nine-gold-lib/src/lib/layouts/site-content/site-content.component';
import { UserComponent } from './pages/user/user.component';
import { UserEditComponent } from './pages/user/user-edit/user-edit.component';
import { UserLicenseComponent } from './pages/user/user-license/user-license.component';
import { SubusersComponent } from './pages/user/subusers/subusers.component';
import { SecurityComponent } from './pages/user/security/security.component';
import { SigninRedirectCallbackComponent } from 'projects/nine-gold-lib/src/lib/common/auth-pages/signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from 'projects/nine-gold-lib/src/lib/common/auth-pages/signout-redirect-callback.component';
import { NewAuthGuard } from 'projects/nine-gold-lib/src/lib/services/newauth.guard';
import { RegisterRedirectCallbackComponent } from 'projects/nine-gold-lib/src/lib/common/auth-pages/register-redirect-callback.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { PasswordChangeCallbackComponent } from 'projects/nine-gold-lib/src/lib/common/auth-pages/password-change-callback.component';
import { InvitationResponseComponent } from './pages/user/invitation-response/invitation-response/invitation-response.component';
import { SuperAdminComponent } from './pages/user/super-admin/super-admin.component';
import { CcpaComponent } from './pages/ccpa/ccpa.component';
import { NewLicenseComponent } from './pages/sidebar-pages/new-license/new-license.component';
import { QuoteFormComponent } from './pages/sidebar-pages/quote-form/quote-form.component';
import { CanDeactivateGuard } from 'projects/nine-gold-lib/src/lib/services/guards/can-deactivate.guard';
import { ActionsComponent } from './pages/user/actions/actions.component';
import { UsesComponent } from './pages/user/uses/uses.component';
import { FaqComponent } from './pages/faq/faq.component';
import { SupportRequestComponent } from './pages/sidebar-pages/support-request/support-request.component';
import { DemoFormComponent } from './pages/sidebar-pages/demo-form/demo-form.component';
// import { PressComponent } from './pages/press/press.component';
import { RegisterCallbackComponent } from 'projects/nine-gold-lib/src/lib/common/auth-pages/register-callback.component';
import { OurStrategyComponent } from './pages/our-strategy/our-strategy.component';
import { CalculatingTheCostComponent } from './pages/calculating-the-cost/calculating-the-cost.component';
import { MigrationRiskAssessmentComponent } from './pages/migration-risk-assessment/migration-risk-assessment.component';
// import { AuthService } from 'dist/nine-gold-lib/lib/services/auth.service';


const routes: Routes = [

  { path: 'about', component: AboutComponent },
  // { path: 'press', component: PressComponent},
  { path: 'contact', component: ContactComponent },
  { path: 'our-strategy', component: OurStrategyComponent},
  { path: 'calculating-the-cost', component: CalculatingTheCostComponent},
  { path: 'migration-risk-assessment', component: MigrationRiskAssessmentComponent},
  { path: 'styleguide', component: StyleguideComponent },
  { path: 'newlicense', component: NewLicenseComponent, outlet: 'sidePopup'},
  { path: 'license/:companyId/:productId/:action/:licenseId', component: NewLicenseComponent, outlet: 'sidePopup'},
  { path: 'license/:companyId/:productId/:action', component: NewLicenseComponent, outlet: 'sidePopup'},
  { path: 'license/:companyId/:productId', component: NewLicenseComponent, outlet: 'sidePopup'},
  { path: 'license/:companyId', component: NewLicenseComponent, outlet: 'sidePopup'},
  { path: 'quote/:id/:action', component: QuoteFormComponent, outlet: 'sidePopup',canDeactivate: [CanDeactivateGuard]},
  { path: 'supportrequest', component: SupportRequestComponent, outlet: 'sidePopup',canActivate: [NewAuthGuard]},
  { path: 'demo/:productName', component: DemoFormComponent, outlet: 'sidePopup'},
  // { path: 'demo', component: DemoFormComponent, outlet: 'sidePopup'},
  // { path: 'userinfo', component: UserInfoComponent, outlet: 'userPopup'},
  { path: 'user', component: UserComponent, data: {layout: 'user'}, children: [
    { path: 'info', component: UserEditComponent, canActivate: [NewAuthGuard]},
    // { path: 'license', component: UserLicenseComponent, canActivate: [NewAuthGuard], data: {permissionNeeded: 'canViewLicense'} },
    { path: 'license', component: UserLicenseComponent, canActivate: [NewAuthGuard] },
    // { path: 'license', component: UserLicenseComponent},
    { path: 'subusers', component: SubusersComponent, canActivate: [NewAuthGuard] },
    { path: 'security', component: SecurityComponent, canActivate: [NewAuthGuard] },
    { path: 'downloads', component: UsesComponent, canActivate: [NewAuthGuard] },
    { path: 'actions', component: ActionsComponent, canActivate: [NewAuthGuard], data: {permissionNeeded: 'canViewActions'} },
    // { path: 'super-admin', component: SuperAdminComponent, canActivate: [NewAuthGuard], data: {permissionNeeded: 'canListCompanies'} }
    { path: 'super-admin', component: SuperAdminComponent, canActivate: [NewAuthGuard] }
  ]},

  { path: 'signin-callback', component: SigninRedirectCallbackComponent },
  { path: 'register-callback-trial', component: RegisterCallbackComponent, data: {layout: 'auth'} },
  { path: 'register-callback', component: RegisterRedirectCallbackComponent, data: {layout: 'auth'} },
  { path: 'signout-callback', component: SignoutRedirectCallbackComponent },
  { path: 'invitation-response/:code/:company-name/:invitee-email/:inviter-name', component: InvitationResponseComponent, data: {layout: 'auth'} },

  { path: 'faq', component: FaqComponent },
  { path: 'password-change-callback', component: PasswordChangeCallbackComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'california-consumer-privacy-statement', component: CcpaComponent },


  { path: '', component: HomeComponent },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
