import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { faCopy, faHistory, faPaperPlane, faPhoneSquare, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { ActionFull } from 'projects/nine-gold-lib/src/lib/models/action';
import { ApiUser, UserTypesData } from 'projects/nine-gold-lib/src/lib/models/user';
import { ApiService } from 'projects/nine-gold-lib/src/lib/services/api.service';
import { UserService } from 'projects/nine-gold-lib/src/lib/services/user.service';
import { ButtonTypes } from 'projects/nine-gold-lib/src/lib/shared/listComponents/ng-list/ng-list-data';
import { FormResultMessageFormat } from 'projects/nine-gold-lib/src/lib/shared/notification/notification';
import { NotificationService } from 'projects/nine-gold-lib/src/public-api';
import { ActionsListService } from '../services/actions-list.service';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {

  downloadJsonHref: SafeUrl;

  user: ApiUser;
  userPermissions: {} = null;

  loading = false;
  phoneIcon = faPhoneSquare;
  emailIcon = faPaperPlane;

  copyIcon = faCopy;

  refreshIcon = faSyncAlt;

  actionsIcon = faHistory;

  actionDetailsData: ActionFull = null;
  @ViewChild('actionDetails')
    private actionDetails: TemplateRef<any>;

  // User details modal
  @ViewChild('userDetails')
    private userDetails: TemplateRef<any>

  userDetailsData: ApiUser = null;
  userDetailsRoleIcon: UserTypesData;



  constructor(
    private notif: NotificationService,
    private apiService: ApiService,
    public actionsListService: ActionsListService,
    public userService: UserService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.userService.userUpdated.subscribe(
      (user) => {
        this.user = user;
        this.userPermissions = this.userService.getPermissions();
      }
    );
    this.user = this.userService.getUserInfo();

    this.actionsListService.LoadPage(1,true);
  }

  private textToClipboard (text) {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    // TODO: find altertative to execCommand above
    document.body.removeChild(dummy);
  }

  protected copyToClipboard(code: string): void {
    this.textToClipboard(code);

    this.toastr.info( "Copied to clipboard", "The CODE was copied to clipboard", {timeOut: 6000, enableHtml: false, positionClass: 'toast-bottom-right' });
  }

  listButtonClick(data:{}): void {
    if(data['button'].type === ButtonTypes.info) {
      this.loading = true;

      this.apiService.getActionDetails(data['row'].rowIdentifier).subscribe(
        (action:ActionFull) => {
          this.actionDetailsData = action;
          this.loading = false;
          this.notif.info('',"",FormResultMessageFormat.modalLargeHtml,0,this.actionDetails);
        }
      );
    } else if(data['button'].type === ButtonTypes.user) {
      let userId = this.actionsListService.GetRawDataById('id',data['row'].rowIdentifier)?.user.id;
      if(userId) {
        this.apiService.getUser(userId).subscribe((user:ApiUser) => {
          this.userDetailsData = user;
          this.userDetailsData.initials = this.userService.setInitials(user.firstName, user.lastName);

          // TODO: replace with user service function once it's created
          if (this.userDetailsData.phone) {
            let p = JSON.parse(this.userDetailsData.phone);
            this.userDetailsData.rawPhone = p.e164Number;
            this.userDetailsData.displayPhone = `(${p.countryCode}) ${p.dialCode} ${p.nationalNumber}`;
          }

          this.userDetailsRoleIcon = this.userService.getHighestRoleFromArray(this.userDetailsData.roles);
          this.loading = false;


          this.notif.info('', "", FormResultMessageFormat.modalHtml, 0, this.userDetails);
        });
      }

    } else if(data['button'].type === ButtonTypes.download) {
      this.loading = true;

      this.apiService.getActionDetails(data['row'].rowIdentifier).subscribe(
        (action:ActionFull) => {
          this.generateDownloadJsonUri(action);
          this.loading = false;
        }
      )
    }
  }

  private generateDownloadJsonUri(jsonObject: {}) {
    var link = document.createElement("a");
    var theJSON = JSON.stringify(jsonObject,null,2);
    link.download = "action.json";
    var data = "text/json;charset=UTF-8," + encodeURIComponent(theJSON);
    link.href = "data:" + data;
    link.click();
  }

  refreshList():void {
    this.actionsListService.LoadPage(1,true);
  }

}
