<article class="legal-content">
    <div class="page-inner">
        <header>
            <h1>Diamond Edge IT, Inc Privacy Statement</h1>
            <h2>Your Privacy Matters</h2>
            <h3 class="updated">Last updated on January 24<sup>th</sup>, 2024</h3>
        </header>
        <aside>
            <section class="intro">
                <p>Please check back regularly for updates. We will also provide you with an email notification of any changes if you have a user account with us. A summary of the changes can be found here.</p>
                <p>This Privacy Policy explains how Diamond Edge IT, Inc. ("Diamond Edge IT", "Company", "us", "our", or "we") collects, uses and discloses personal data collected from and about you when you visit smartcodeplatform.com, restgenerator.com, utilgenerator.com, diamondedge-it.com or any related sub-domains that belong to Diamond Edge IT, utilize any subscription services offered by Diamond Edge IT, or utilize any other services provided by Diamond Edge IT ("Services").</p>
                <p>When you use Services provided by Diamond Edge IT, you trust us with your data. Diamond Edge IT is committed to keeping that trust and believes the commitment starts with being very transparent and up-front about how we collect, use and disclose your personal data. This Privacy Policy also describes the rights and controls you have over your personal data and how to contact us with any questions, comments, concerns and requests relating to your personal data.</p>
            </section>
            <section class="toc">
                <p>This Privacy Policy is broken down into the following Sections for your convenience:</p>
                <nav class="scroll-navigation">
                    <ol>
                        <li><a (click)="onClick('Data_Controller')">Data Controller</a></li>
                        <li><a (click)="onClick('Data_Collection')">The Data We Collect From You</a></li>
                        <li><a (click)="onClick('Data_Use')">How We Use Your Data</a></li> 
                        <li><a (click)="onClick('Data_Sharing')">Who We Share Your Data With</a></li>   
                        <li><a (click)="onClick('Data_Retention')">How Long We Retain Your Data</a></li>  
                        <li><a (click)="onClick('Data_Rights')">Your Rights Relating to the Use of Your Data</a></li>
                        <li><a (click)="onClick('Data_Security')">How We Secure Your Data</a></li>
                        <li><a (click)="onClick('Data_EU')">Special Information for Individuals Located in the European Economic Area</a></li>
                        <li><a (click)="onClick('Data_International')">International Data Transfers</a></li>
                        <li><a (click)="onClick('Cookies')">Policy on Cookies and Similar Technologies</a></li>
                        <li><a (click)="onClick('Links')">Links to Third Party Sites</a></li>
                        <li><a (click)="onClick('Age')">Age Restrictions; Parental Consent</a></li>
                        <li><a (click)="onClick('Changes')">Changes to this Privacy Statement</a></li>
                        <li><a (click)="onClick('Contact')">How to Contact Us</a></li>
                    </ol>
                </nav>
            </section>
            <section>
                <p>Please review this Privacy Statement carefully to understand our policies and practices regarding your data and how we will treat it. If you do not agree with our policies and practices, you cannot use our Services. By accessing or using our Services, you agree to this privacy statement. This policy may change from time to time (see Changes to this Privacy Statement). Your continued use of our Services after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.</p>
                <p>If you have any questions, comments or concerns about any aspect of this Policy, please do not hesitate to contact us by using the details set forth below in the "How To Contact Us" Section</p>
            </section>
        </aside>
        <main>
            <ol class="main-content">
                <li class="top-level">
                    <h2 id="Data_Controller">Data Controller</h2>
                    <p>Diamond Edge IT provides Services to users throughout the world. However, Diamond Edge IT processes personal data inside of the United State only. Diamond Edge IT does not transfer data of its users outside of the United States.</p>
                    <p>Diamond Edge IT's data controller is:</p><br/>
                    <p>Diamond Edge IT, Inc.</p>
                    <address>
                        1065 SW 8th St #251<br>
                        Miami, FL 33130<br>
                    </address>
                    <p>Phone: <a href="tel:+18553761150">(855) 376-1150</a></p>
                    <p>Email: <a href="mailto:support@diamondege-it.com">support@diamondege-it.com</a></p><br/>
                    <p>Questions, comments and complaints about Diamond Edge IT's data practices can be submitted to Diamond Edge IT at <a href="mailto:support@diamondedge-it.com.">support@diamondedge-it.com</a>.</p>
                    <p><strong>In Summary...</strong></p>
                    <p>Regardless of your location, Diamond Edge IT is the only controller of your personal data all your data is located within the United States, and none of it is transferred outside of the United States.</p>
                </li>

                <li class="top-level">
                    <h2 id="Data_Collection">The Data We Collect from You</h2>
                    <p>In order for you to utilize our Services, including but not limited to, creating a user account and signing up for a subscription service, we collect the following data from you:</p>
                    <ol>
                        <li>
                            <strong>Data You Provide</strong>
                            <ul>
                                <li><strong>Account Creation:</strong> Diamond Edge IT collects certain personal data from you when you create or update your account and/or sign-up for the Services. This may include contact information (such as your full legal name, email address), company name, and purchase order information.</li>
                                <li><strong>Correspondence with Diamond Edge IT:</strong>  Diamond Edge IT may collect personal data from you if you contact us. The purpose of collecting this data is for us to better help us assist you in resolving any issues or answering any questions you may have. This may include information such as your email address or contact number for follow-up conversations. </li>
                            </ul>
                        </li>
                        <li>
                            <strong>Data We Automatically Collect When You Use the Services</strong>
                            <p>When you access or use our Services, we automatically collect data about your use of the Services, including:</p>
                            <ul>
                                <li><strong>Log Data:</strong> We want to inform you that whenever you user our Services, we collect data that your browser sends to us that is called Log Data. This Log Data may include data such as your computer's Internet Protocol ("IP") address, browser version, pages of our website that you visit, the time and date of your visit, the time spent on those pages, and other statistics.</li>
                                <li><strong>Device Data:</strong> We collect and store data about the computer or mobile device you use to access our Services, including the hardware model, operating system and version. </li>
                            </ul>
                            <p>In order to collect this data automatically, we use certain analytics tools incorporating tracking technologies that recognize your device and compiles data about you. One example includes the use of cookies. For more information, please see the section titled <a (click)="onClick('Cookies')">Policy on Cookies and Similar Technologies</a>.</p>
                            <p><strong>In Summary...</strong></p>
                            <p>We collect personal data from you when you use our Services, create a user account or engage in any communications with us. We also automatically collect certain data when you visit or otherwise use our Services through the use of cookies or other similar tracking technologies. Part of the data automatically collected includes data from your device and network.</p>
                        </li>
                    </ol>
                </li>

                <li class="top-level">
                    <h2 id="Data_Use">How We Use Your Data</h2>
                    <p>We collect, store and use the data you provide to us for the following purposes:</p>
                    <ol>
                        <li>
                            <strong>Account Administration</strong>
                            <p>Your personal data is necessary to allow you to utilize the basic functionality of the Services, which includes: (i) creating and updating your user account; (ii) verifying your identity; (iii) providing you notices about your account, such as for renewal and expirations; (iv) notifying you about changes to our Services; (v) providing customer support, such as responding to emails, questions, comments, requests and/or complaints sent by you to Diamond Edge IT; (vi) performing internal operations necessary to provide our Services, including to troubleshoot software bugs and operational problems; (vii) investigating or addressing claims or disputes relating to your use of the Services, or as otherwise allowed by applicable law, or as requested by regulators, government entities, and official inquiries; (viii) storing information about your preferences so that we may customize our Services according to your individual interests; and (ix) recognizing you when you return to our Services. Please be aware that you cannot opt-out of receiving service messages from us, including service announcements, security alerts, update notices, or other administrative messages.</p>
                        </li>
                        <li>
                            <strong>Subscription Services</strong>
                            <p>Your personal data is necessary to allow us to provide the Services to you, including but not limited to, administering any subscription services you utilize with Diamond Edge IT.</p>
                        </li>
						<li>
                            <strong>Support Services</strong>
                            <p>Your personal data is necessary to allow us to provide the Services to you, which includes: (i) updating tickets relating to your user account; (ii) updating tickets relating to your user metadata; (iii) notification of subscription and payment terms about your account, such as purchase order details; (iv) distribution of user license keys.</p>
                        </li>
                        <li>
                            <strong>Company Communications</strong>
				            <p>We will contact you through email, mobile phone, notices posted on our websites or applications, messages to your account, and other ways through our Services, including text messages and push notifications. We will send you messages about the availability of our Services, security, or other service-related issues. You may change your communication preferences at any time. However, please be aware that you cannot opt-out of receiving service messages from us, including service announcements, security alerts, update notices, or other administrative messages.</p>
                        </li>
                        <li>
                            <strong>Development of the Services</strong>
                            <p>We use the private data we receive, including feedback, to: (i) monitor and analyze trends; (ii) further develop our Services in order to provide you and others with a better, more intuitive and personalized experience; (iii) drive membership growth and engagement on our Services; (iv) to speed up your searches; and (v) to estimate our audience size and usage patterns.</p>
                        </li>
                        <li>
                            <strong>Marketing & Advertising Communications</strong>
                            <p>Currently, we do not utilize your private data for any advertising or marketing purposes. In the future, we may send emails to you for marketing and advertising purposes, such as for newsletters, new product offerings, special discounts, event notifications, and special third-party offers. If we do decide to send any promotional materials, such communications will contain explicit instructions on how to opt out, and you may also contact Diamond Edge IT’s <a href="https://smartcodeplatform.com/(sidePopup:supportrequest)" target="_blank">Support Request</a> to opt-out of any such emails.</p>
                            <p>Please be aware that you cannot opt-out of receiving service messages from us, including service announcements, security alerts, update notices, or other administrative messages.</p>
                        </li>
				    </ol>
                    <p><strong>In Summary...</strong></p>
    				<p>We use your data to help provide the Services to you, to further develop and personalize the Services, and to be able to communicate with you. Currently, we do not send out promotional emails, but if this changes in the future, you may always opt out.</p>
                </li>

                <li class="top-level">
                    <h2 id="Data_Sharing">Who We Share Your Data With</h2>
                    <ol>
                        <li>
                            <strong>With Third Party Service Providers</strong>
                            <p>We share the data we collect from you with third party service providers. This may include:</p>
                            <ul>
                                <li>Payment processors and facilitators - Currently, we use Quickbooks for all payment and processing needs associated with facilitating our Services. Quickbooks has its own separate privacy statement that can be found at <a href="https://quickbooks.intuit.com/eu/privacy-policy" target="_blank">https://quickbooks.intuit.com/eu/privacy-policy</a>;</li>
                                <li>Cloud storage providers - Currently, we use Amazon Web Services (also known as AWS) for all of our cloud computing needs. AWS has its own separate privacy statement that can be found at <a href="https://aws.amazon.com/privacy" target="_blank">https://aws.amazon.com/privacy</a>;</li>
                                <li>Consultants, lawyers, accountants and other professional service providers; and</li>
                                <li>Data analytics providers.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>With Investigators, Governmental Bodies, Authorities or Law Enforcement Agencies </strong>
                            <p>We may share your data if required by applicable law, regulation, legal process or governmental request, or where the disclosure is otherwise appropriate due to a safety or similar concern. This includes sharing your data with law enforcement officials, government authorities, or other third parties as necessary to enforce our Terms of Service or other policies, as well as to investigate and protect Diamond Edge IT or its users from fraudulent, abusive, inappropriate, or unlawful acts.</p>
                        </li>
                        <li>
                            <strong>With Potential Buyers or Investors</strong>
                            <p>We may share your data with others in connection with, or during negotiations of, any merger, sale of company assets, consolidation or restricting, financing, or acquisition of all or a portion of our business by or into any another company, or as part of bankruptcy, liquidation, or similar proceeding, in which personal data held by Diamond Edge IT about its users is among the assets transferred.</p>
                        </li>
                        <li>
                            <strong>With Other Parties You Consent To</strong>
                            <p>We may share your data with any other third parties other than as described in this policy if we notify you and you consent to the sharing.</p>
                        </li>
                        <li>
                            <strong>With Marketers and Advertisers</strong>
                            <p>Diamond Edge IT does not sell or share your data with third parties for marketing or advertisement purposes. If this changes in the future, you will be immediately notified and this policy will be revised accordingly to provide you with the right to opt-out of any such marketing and/or advertisement.</p>
                        </li>
                    </ol>
                    <p><strong>In Summary...</strong></p>
    				<p>We may share your data with any third parties who help us provide our Services. We may also share your data when we believe it is required by law or to help protect the rights and safety of you, us or others. We may also share your data when our business is sold to others or subject to any change in ownership. We may also share your data with any other third parties to whom you expressly consent to sharing such data with. We do not share your data with any marketers or advertisers.</p>
                </li>

                <li class="top-level">
                    <h2 id="Data_Retention">How Long We Retain Your Data</h2>
                    <p>Diamond Edge IT only retains user profile and other data as long as you have a user account and utilize the Services. You may request deletion of your account at any time by calling Diamond Edge IT at the phone number provided in Section 1 above and making such request to Diamond Edge IT’s <a href="https://smartcodeplatform.com/(sidePopup:supportrequest)" target="_blank">Support Request</a>. Upon receipt of a request to delete your account and data, Diamond Edge IT will delete your account within thirty (30) days.</p>
                    <p>However, since we are subject to compliance with different laws in place around the world relating to regulatory, tax, insurance or other requirements in the field in which it operates, Diamond Edge IT will maintain data related to the foregoing, which may include transaction history, for seven (7) years. Thereafter, Diamond Edge IT will delete or anonymize such data in accordance with applicable laws.</p>
                    <p><strong>In Summary...</strong></p>
                    <p>We maintain your personal data as long as you have an active user account. We can delete your user account upon request, but will still be required to maintain certain personal data to comply with legal obligations for a period of seven (7) years after removal of your account.</p>
                </li>

                <li class="top-level">
                    <h2 id="Data_Rights">Your Rights Relating to the use of Your Data</h2>
                    <p>We at Diamond Edge IT want you to have complete control of your data, and therefore, you always have the right to:</p>
                    <ul>
                        <li>Access any of your personal data we hold about you;</li>
                        <li>Ask us to erase any personal data we hold about you;</li>
                        <li>Ask us to not use your personal data for direct-marketing purposes;</li>
                        <li>Restrict the way we process your personal data;</li>
                        <li>Have your personal data provided to a third-party provider of services;</li>
                        <li>Have a copy of any personal data we hold about you; and</li>
                        <li>Object to our processing of you personal data.</li>
                    </ul>
                    <p>You may access, correct, change or delete certain personal data through your online account. If you need help or wish to exercise any of the above rights or have questions about them, please do not hesitate to contact Diamond Edge IT’s <a href="https://smartcodeplatform.com/(sidePopup:supportrequest)" target="_blank">Support Request</a>. If you wish to request a copy of all your personal data we hold for you, you may do so by downloading your data from our Website or by contacting Diamond Edge IT’s <a href="https://smartcodeplatform.com/(sidePopup:supportrequest)" target="_blank">Support Request</a>.</p>
                    <p>If you would like to deactivate your account in our system, you can do so through your Account Profile Deactivate Page (a link at the bottom, "Deactivate", will allow you to do this) or by contacting Diamond Edge IT’s <a href="https://smartcodeplatform.com/(sidePopup:supportrequest)" target="_blank">Support Request</a>.</p>
                    <p>If your account is deactivated or you ask to close your account, you will no longer be able to use the Services. We will use commercially reasonable efforts to honor your request; however, certain data may persist internally or for our administrative purposes subject to the records retention policy discussed in <a (click)="onClick('Data_Retention')">Section 5</a>.</p>
                    <p><strong>In Summary...</strong></p>
                    <p>You can access, update and/or deactivate your personal data by visiting your Account Profile, emailing us or even calling us by phone. You have many choices about how your data is collected, used and shared and we will help facilitate any of those choices you wish to pursue. Even if you deactivate your account, however, certain personal data may be kept for record retention purposes as previously discussed in <a (click)="onClick('Data_Retention')">Section 5</a>.</p>
                </li>

                <li class="top-level">
                    <h2 id="Data_Security">How We Secure Your Data</h2>
                    <p>We maintain physical, electronic and procedural safeguards in connection with the collection, storage and disclosure of personally identifiable customer data. When our accounting software processes purchase orders, the purchse order is subject to strong security measures against unauthorized access, destruction, loss, alteration or misuse. Our security procedures require us in some cases to request proof of identity before disclosing personal information to you.</p>
                    <p>Although we use reasonable organizational, technical and administrative measures to protect your personal data, it is unfortunately true that no data transmission over the Internet can be guaranteed to be 100% secure. Consequently, please note that while we do our best to protect your personal data, we cannot fully guarantee the security of any personal data you transfer over the Internet. Any transmission of personal data is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained in our Services.</p>
                    <p>If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that the security of your account has been compromised), please contact us immediately at <a href="mailto:support@diamondege-it.com">support@diamondege-it.com</a>.</p>
                    <p><strong>In Summary...</strong></p>
                    <p>Our data transmissions are secured through encryption, and we also monitor for and try to prevent security breaches. However, no data transmissions over the Internet can ever be guaranteed to be 100% secured. Please use the security features available through our Services and do not hesitate to contact Diamond Edge IT’s <a href="https://smartcodeplatform.com/(sidePopup:supportrequest)" target="_blank">Support Request</a> if you believe your account security has been compromised.</p>
                </li>

                <li class="top-level">
                    <h2 id="Data_EU">Special Information for Individuals Located in the European Economic Area</h2>
                    <p>Beginning May 25, 2018, the processing of personal data of users within the European Economic Area (EEA) is subject to the EU General Data Protection Regulation ("GDPR"). This section summarizes Diamond Edge IT's grounds for processing personal data under the GDPR, and the rights of such users as it relates to Diamond Edge IT's handling of personal data.</p>
                    <ol class="capital-letters">
                        <li>
                            <strong>EU User Rights</strong>
                            <p>If you are located within the EEA, you have the following rights with respect to how Diamond Edge IT handles your data:</p>
                            <ul>
                                <li><strong>Requesting Explanations of What Data of Yours Diamond Edge IT Stores and How It Is Used -</strong> You have the right to request an explanation of the data that Diamond Edge IT has about you and how Diamond Edge IT uses that data.</li>
                                <li><strong>Requesting Copies of Your Data -</strong> You have the right to receive a copy of the data that Diamond Edge IT IT collects about you if collected on the basis of consent or because Diamond Edge IT IT requires the data to provide the Services that you request.</li>
                                <li><strong>Requesting Correction to Your Data -</strong> If Diamond Edge IT has data about you that you believe is inaccurate, you have the right to request correction of your data. </li>
                                <li><strong>Requesting Your Data to be Deleted -</strong> You may request deletion of your user account at any time by contacting Diamond Edge IT’s <a href="https://smartcodeplatform.com/(sidePopup:supportrequest)" target="_blank">Support Request</a>. We may retain certain data about you as required by law and for legitimate business purposes permitted by law, which is further discussed in <a (click)="onClick('Data_Retention')">Section 5</a>.</li>
                                <li><strong>Filing an Objection or Complaint -</strong> Users in the EAA have the right to object to Diamond Edge IT's processing of personal data, including for marketing purposes based on profiling and/or automated decision-making. Diamond Edge IT IT may continue to process your data notwithstanding the objection to the extent permitted under GDPR. Users in the EAA also have the right to file a complaint relating to Diamond Edge IT's handling of your personal data with their local Data Protection Authority ("DPA"). To find the best DPA to contact and lodge your complaint, please visit <a href="http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm" target="_blank">http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm</a>. You may also submit complaints or objections directly to Diamond Edge IT by contacting Diamond Edge IT’s <a href="https://smartcodeplatform.com/(sidePopup:supportrequest)" target="_blank">Support Request</a>. </li>
                            </ul>
                        </li>
                        <li>
                            <strong>Legal Basis for Processing Your Data</strong>
                            <p>The GDPR requires that companies processing the personal data of EEA users do so on the basis of specific legal grounds. As such, Diamond Edge IT will only process your personal data in the following situations:</p>
                            <ul>
                                <li><strong>The Processing Is Necessary to Perform Our Contract With You.</strong> Diamond Edge IT must collect and use certain data in order to provide its Services that you have contracted with Diamond Edge IT for. This includes user data that is necessary to establish and maintain your account, facilitate your subscription and process payments. Collection and use of this data is a requirement for using Diamond Edge IT's Services.</li>
                                <li><strong>The Processing is Necessary for Diamond Edge IT's Legitimate Interests.</strong> Diamond Edge IT collects and uses personal data to the extent necessary for its legitimate interests, which includes: (i) to provide customer support; (ii) to optimize our service and develop new services; (iii) for research and analytical purposes; (iv) to enforce Diamond Edge IT's Terms of Service; and (v) to provide you notices about changes to our Services.</li>
                                <li><strong>Your Explicit Consent Is Given.</strong> Diamond Edge IT may collect and use your data on the basis of your consent. For example, we rely on your consent for us to use your personal data to send you marketing information (such as our newsletters and promotional offers) by email, SMS, or telephone.  You may revoke your consent at any time. If you revoke your consent, you will not be able to use any part of our Services that requires collection or use of the data we collected or used on the basis of your consent.</li>
                            </ul>
                        </li>
                    </ol>
                    <p><strong>In Summary...</strong></p>
                    <p>You have a right to: (i) requesting explanations of what data of yours Diamond Edge IT stores and how it is used; (ii) request copies of your personal data; (iii) request your data to be deleted; or (iv) file a complaint about the use of your data. Our legal basis for collecting, using and sharing your data include consent, contract and legitimate interest. If the legal basis is consent, then you may withdraw your consent at any time.</p>
                </li>

                <li class="top-level">
                    <h2 id="Data_International">International Data Transfers</h2>
                    <p>The personal data we collect may be transferred to, and processed and stored in, countries outside of the jurisdiction you are located in. For example, if you are located in a country with the EEA, your personal data may be processed in the United States. All international transfers of your personal data are made:</p>
                    <ul>
                        <li>To a country or territory ensuring an adequate level of protection for the rights and freedoms of data subjects in relation to the processing of personal data as determined by the European Commission;</li>
                        <li>To a the third party that is a member of a compliance scheme recognized as offering adequate protection for the rights and freedoms of data subjects as determined by the European Commission; or</li>
                        <li>Pursuant to appropriate safeguards, such as the Standard Contractual Clauses (processors) approved by European Commission Decision C(2010)593 or any subsequent version thereof released by the European Commission or the EU-U.S. Privacy Shield.</li>
                    </ul>
                    <p>If you wish to enquire further about these safeguards used, please contact Diamond Edge IT’s <a href="https://smartcodeplatform.com/(sidePopup:supportrequest)" target="_blank">Support Request</a>.</p>
                    <p><strong>In Summary...</strong></p>
                    <p>All of our data is processed in the United States. If you are located outside of the United States, then your data is safely transferred to our processors located within the United States in accordance with all applicable laws.</p>
                </li>

                <li class="top-level">
                    <h2 id="Cookies">Policy on Cookies and Similar Technologies</h2>
                    <p>Diamond Edge IT and its partners may use cookies on our websites and emails for purposes described in this policy.</p>
                    <ol class="capital-letters">
                        <li>
                            <strong>What are cookies?</strong>
                            <p>A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Services. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Services.</p>
                        </li>
                        <li>
                            <strong>What are cookies used for?</strong>
                            <p>Some examples of what these tracking technologies are used for by Diamond Edge IT may include:</p>
                            <ul>
                                <li>Authenticating users;</li>
                                <li>Analyzing traffic to our Services; and</li>
                                <li>Generally reviewing and understanding the behaviors of people who have an interest in or utilize our Services. </li>
                            </ul>
                        </li>
                        <li>
                            <strong>How long will cookies stay on my device?</strong>
                            <p>The length of time a cookie will stay on your computer or mobile device depends on whether it is a "persistent" or "session" cookie. Session cookies will only stay on your device until you stop browsing. Persistent cookies stay on your computer or mobile device until they expire or are deleted.</p>
                        </li>
                        <li>
                            <strong>Who Does the Cookies Belong To?</strong>
                            <p>First-party cookies are cookies that belong to Diamond Edge IT, third-party cookies are cookies that another party accesses or places on your device through our Services. Third-party cookies may be placed on your device by someone providing a service for Diamond Edge IT, for example, to help us understand how our Services is being used. These companies' use of cookies and similar technologies is subject to their own privacy policies, not the Diamond Edge IT Privacy Statement.</p>
                        </li>
                        <li>
                            <strong>Types of Cookies Use.</strong>
                            <p>The cookies we use generally fall into one of the following categories:</p>
                            <ul>
                                <li><strong>Technical/Required -</strong> These cookies are essential for our Services to function properly. Like other cookies we use, technical cookies may be either first-party cookies or third-party cookies.</li>
                                <li><strong>Analytics -</strong> We use these cookies to help us understand and improve our Services. For example, we can use these cookies to learn more about which features are the most popular with our users and where we may need to make improvements.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>How to Control Cookies or Other Collection Mechanisms</strong>
                            <p>You have the right to choose whether or not to accept cookies. However, please be advised that if you set your browser to disable cookies or other tracking technologies, you may not be able to access certain parts of our Services or the functionality of certain aspects of the Services may be limited. In addition, you have other choices with respect to how cookies are used:</p>
                            <ul>
                                <li>Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject browser cookies. To do so, please follow the instructions provided by your browser which are usually located within the "Help" or "Preferences" menu. Some third parties also provide the ability to refuse their cookies directly by clicking on an opt-out link.</li>
                                <li>Removing or rejecting browser cookies does not necessarily affect third-party flash cookies which may be used by us or our partners in connection with our Services. To delete or disable flash cookies please visit Adobe's website located at <a href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html" target="_blank">https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html</a>. For further information about cookies, including how to see what cookies have been set on your device and how to manage and delete them, you can visit <a href="http://youradchoices.com/" target="_blank">http://youradchoices.com/</a> and <a href="http://www.youronlinechoices.eu/" target="_blank">http://www.youronlinechoices.eu/</a> for EU visitors.</li>
                                <li>For mobile users, you should have controls on your device that enables you to choose whether to allow cookies. For information on controlling your mobile choices, you can visit <a href="http://www.networkadvertising.org/mobile-choices" target="_blank">http://www.networkadvertising.org/mobile-choices</a>.</li>
                                <li>To help control or block certain ads in mobile applications, you may choose to download and utilize the DAA mobile app, <a href="http://youradchoices.com/appchoices" target="_blank">http://youradchoices.com/appchoices</a>. </li>
                            </ul>
                        </li>
                        <li>
                            <strong>G. Web Beacons</strong>
                            <p>In addition to Cookies, pages of our website or emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit Diamond Edge IT, for example, to count users who have visited those pages or emails and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).</p>
                        </li>
                        <li>
                            <strong>Why Does Diamond Edge IT Use Cookies and Web Beacons?</strong>
                            <p>We use cookies and similar technologies to show you relevant content, improve your experience, and help protect Diamond Edge IT and our users. We may use these technologies to deliver the service, provide you with a service that is easy to use, enable you to move around the Services and use its features, such as accessing secure areas, and storing data so that Diamond Edge IT can respond faster. We may use these technologies to collect data about how you use our Services, for example which pages you go to most often and if you get error messages from certain pages. We may use these technologies to allow us to remember choices you make (such as single sign on and generation metrics) and tailor the Services to provide enhanced features and content for you. These technologies can remember that your device has visited a website, and may also be able to track your device's browsing activity on other sites or services other than Diamond Edge IT. We may use these technologies to understand, improve, and research our Services.</p>
                        </li>
                    </ol>
                    <p><strong>In Summary...</strong></p>
                    <p>We use tracking technologies such as cookies and web beacons to help provide the Services to you. If you wish to not utilize cookies, then you can opt out of such usages. However, if you do decide to opt out, then certain aspects of the Services and/or their functionality may be limited or unusable.</p>
                </li>

                <li class="top-level">
                    <h2 id="Links">Links to Third Party Sites</h2>
                    <p>Our Services may contain links to other sites. If you click on a third-party link, you will be directed to that site. We do not operate these external sites. Therefore, we strongly advise you to review the privacy statement and any terms of use for any such websites, as those websites may collect personal data from you or have their own policies regarding the use of tracking cookies and related technology. We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
                    <p><strong>In Summary...</strong></p>
                    <p>We may have links that take you to sites other than ours. If you click on one of those links, you will be bound by that website's privacy policies.</p>
                </li>

                <li class="top-level">
                    <h2 id="Age">Age Restrictions; Parental Consent</h2>
                    <p>Our Services are intended for users of all ages; however, unfortunately, no one under the age of 13 may use the Services unless such person has a parent or guardian who first provides verifiable parental consent. We do not knowingly collect personal data from children under 13, unless such an individual has provided explicit verifiable parental consent. If you are under 13, do not use our Services or provide any data about yourself to us, including your name, email address, company or any screen name or user name you may use, without first having your parent contact us to provide verifiable consent. If we learn we have collected or received personal data from a child under 13 without verification of parental consent, we will delete that data. If you believe we might have any data from or about a child under 13 without having the necessary parental consent, please contact Diamond Edge IT’s <a href="https://smartcodeplatform.com/(sidePopup:supportrequest)" target="_blank">Support Request</a>. To request a parental verification, please contact Diamond Edge IT’s <a href="https://smartcodeplatform.com/(sidePopup:supportrequest)" target="_blank">Support Request</a>.</p>
                    <p><strong>In Summary...</strong></p>
                    <p>Our Services are not for children under the age of 13, unless we have first obtained verifiable parental consent. To request a parental verification, please contact Diamond Edge IT’s <a href="https://smartcodeplatform.com/(sidePopup:supportrequest)" target="_blank">Support Request</a>.</p>
                </li>

                <li class="top-level">
                    <h2 id="Changes">Changes to this Privacy Statement</h2>
                    <p>The date this Privacy Statement was last revised is identified at the top of this agreement. If we make changes to this Privacy Statement about how we treat our users' personal data, we will notify you by email to the primary email address specified in your account. We will also post the updated privacy statement on our website in the same location as the previous version. You are responsible for ensuring we have an up-to-date, active and deliverable email address for you, and for periodically visiting our website and this Privacy Statement to check for any changes. If we make material changes to this Privacy Statement, we will notify you by email at least fifteen (15) days prior to the date on which such changes take effect.</p>
                    <p><strong>In Summary...</strong></p>
                    <p>We may update this Privacy Statement from time to time. You will be notified of any updates by email, and copies of the updated Privacy Statement will be located on our website.</p>
                </li>

                <li class="top-level">
                    <h2 id="Contact">How to Contact Us</h2>
                    <p>We are here to help and be as transparent as possible regarding how we use, store and transfer your data. If you have any questions regarding this Privacy Statement, or if you wish to exercise any of your rights in relation to your personal data, or if you want to object to the collection or processing of your data by Diamond Edge IT, whether entirely or partially, please contact Diamond Edge IT’s <a href="https://ssmartcodeplatform.com/(sidePopup:supportrequest)" target="_blank">Support Request</a>.</p>
                    <p><strong>In Summary...</strong></p>
                    <p>Please contact us with any questions, comments or concerns you may have at <a href="mailto:support@smartcodeplatform.com">support@smartcodeplatform.com</a>.</p>
                </li>
            </ol>
        </main>
    </div>
</article>
<lib-scroll-to-top></lib-scroll-to-top>