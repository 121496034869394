import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ListsService } from '../../../services/lists.service';

@Component({
  selector: 'lib-ng-items-per-page',
  templateUrl: './ng-items-per-page.component.html',
  styleUrls: ['./ng-items-per-page.component.scss']
})
export class NgItemsPerPageComponent implements OnInit {
  // @Input() listOptions: [{text: string, value: string}];
  // @Input() selectedOption: string;
  @Output() InputChanged: EventEmitter<string> = new EventEmitter<string>();
  listOptions: any[];
  selectedOption: number;

  @Input() listsService: ListsService;
  

  constructor(
  ) { }

  ngOnInit(): void {
    this.listOptions = this.listsService.GetItemsPerPageList();
    this.selectedOption = this.listsService.GetItemsPerPage();
  }

  selectorUpdated(): void {
    this.listsService.SetItemsPerPage(this.selectedOption);
    // this.InputChanged.emit(this.selectedOption);
  }

}
