import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { UserInterfaceService } from 'projects/nine-gold-lib/src/lib/services/user-interface.service';
import { UserService } from 'projects/nine-gold-lib/src/lib/services/user.service';
import { ConfirmDialogService } from 'projects/nine-gold-lib/src/public-api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements AfterViewInit {

  userLoggedIn: boolean;

  constructor(
    private viewportScroller: ViewportScroller,
    private uiService: UserInterfaceService,
    private router: Router,
    private userService: UserService,
    private confirmService: ConfirmDialogService
    ) { }

  ngOnInit(): void {
    this.userLoggedIn = this.userService.userLoggedIn();
  }

  ngAfterViewInit(): void {
    this.viewportScroller.setOffset([0,90]);
    setTimeout(() => {
      this.uiService.minHeader = true;
    }, 100);
  }

  public onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnDestroy(): void {
    this.uiService.minHeader = false;
  }

  suportRequest(): void{
    this.confirmService.confirm(this.uiService.getConfirmMessage('supportToContactUs')).subscribe((answer) => {
      if(answer) {
        this.router.navigate(['/']);
      }
    }
  );
  }

}
