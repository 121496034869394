import { Component, HostListener, ViewEncapsulation, Renderer2 } from '@angular/core';
import { NgNavigation, MenuItemType, NgNavigationApps } from 'projects/nine-gold-lib/src/lib/shared/ng-navigation/ng-navigation';
import { FormsModule }   from '@angular/forms';
import { faCogs, faTools, faHandHolding, faCog, faCaretDown} from '@fortawesome/free-solid-svg-icons';
import { UserInterfaceService } from 'projects/nine-gold-lib/src/lib/services/user-interface.service';
import { fadeInAnimation } from './animations/fade-in.animation';
import { RouterOutlet, Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { slideInOutAnimation } from './animations/slide-in-out.animation';
import { UserService } from 'projects/nine-gold-lib/src/lib/services/user.service';
import { AuthService } from 'projects/nine-gold-lib/src/lib/services/auth.service';
import { ApiUser, IUser } from 'projects/nine-gold-lib/src/lib/models/user';
import { NotificationService } from 'projects/nine-gold-lib/src/lib/shared/notification/notification.service';
import { FormResultMessageFormat } from 'projects/nine-gold-lib/src/lib/shared/notification/notification';
import { NineGoldLibService } from 'projects/nine-gold-lib/src/public-api';
import { NineGoldSettingsService } from './services/nine-gold-settings.service';
import { Title } from '@angular/platform-browser';
import { ProductService } from 'projects/nine-gold-lib/src/lib/services/product.service';
import { AppSettingsService } from 'projects/nine-gold-lib/src/lib/services/app-settings.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

function SmoothScroll(target, speed, smooth) {
	if (target === document)
		target = (document.scrollingElement
              || document.documentElement
              || document.body.parentNode
              || document.body) // cross browser support for document scrolling

	var moving = false
	var pos = target.scrollTop
  var frame = target === document.body
              && document.documentElement
              ? document.documentElement
              : target // safari is the new IE

	target.addEventListener('mousewheel', scrolled, { passive: false })
	target.addEventListener('DOMMouseScroll', scrolled, { passive: false })

	function scrolled(e) {
		e.preventDefault(); // disable default scrolling

		var delta = normalizeWheelDelta(e)

		pos += -delta * speed
		pos = Math.max(0, Math.min(pos, (target.scrollHeight - frame.clientHeight) + (smooth * 2))) // limit scrolling

		if (!moving) update()
	}

	function normalizeWheelDelta(e){
		if(e.detail){
			if(e.wheelDelta)
				return e.wheelDelta/e.detail/40 * (e.detail>0 ? 1 : -1) // Opera
			else
				return -e.detail/3 // Firefox
		}else
			return e.wheelDelta/120 // IE,Safari,Chrome
	}

	function update() {
		moving = true

		var delta = (pos - target.scrollTop) / smooth

		target.scrollTop += delta

		if (Math.abs(delta) > 0.5)
			requestFrame(update)
		else
			moving = false
	}

	var requestFrame = function() { // requestAnimationFrame cross browser
		return (
			window.requestAnimationFrame ||
			// window.webkitRequestAnimationFrame ||
			// window.mozRequestAnimationFrame ||
			// window.oRequestAnimationFrame ||
			// window.msRequestAnimationFrame ||
			function(func) {
				window.setTimeout(func, 1000 / 50);
			}
		);
	}()
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInAnimation]
})
export class AppComponent {
  title = 'Smart Code Platform';
  faCogsIcon = faCogs;
  faToolsIcon = faTools;
  faHandHoldingIcon = faHandHolding;
  scrolledDown = false;
  scrolling = false;
  offsetY: number = 0;
  scrollingUp = false;
  faCogIcon = faCog;
  faCaretDownIcon = faCaretDown;
  hideFooter = false;

  noHeader = false;
  scrollableHeader = true;
  userPages = false;
  appLayout = false;
  authLayout = false;

  headerNavItems: NgNavigation[];
  productNavItems: NgNavigation[];
  solutionsNavItems: NgNavigation[];
  resourcesNavItems: NgNavigation[];
  footerNavItems: NgNavigation[];
  appNavItems: NgNavigationApps[];

  constructor(
    public uiService: UserInterfaceService,
    private renderer: Renderer2,
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private notifications: NotificationService,
    // private appSettings: NineGoldSettingsService,
    private appSettings: AppSettingsService,
    private titleService: Title,
    private activeRoute: ActivatedRoute,
    private productService: ProductService,
    protected gaService: GoogleAnalyticsService
    ){
      this.headerNavItems = appSettings.getTopNav();
      this.productNavItems = appSettings.getProductLinks();
      this.solutionsNavItems = appSettings.getSolutionsLinks();
      this.resourcesNavItems = appSettings.getResourcesLinks();
      this.footerNavItems = appSettings.getFooterNav();
      this.appNavItems = appSettings.getAppLinks();

      this.uiService.sideMenuWide = (window.innerWidth > 800);

      let userPagesTimeout;


      router.events.subscribe((val) => {

        if(val instanceof NavigationStart) {
          this.hideFooter = true;
          document.body.style.height = document.body.clientHeight + "px";
          document.body.classList.add('navigation-transition');
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
          let spinnerContainer = document.body.getElementsByClassName('app-loading-spinner-container')[0];
          if(spinnerContainer) {
            spinnerContainer.classList.remove('active');
            setTimeout(() => {
              spinnerContainer.remove();
            }, 1000);
          }
        }
        if(val instanceof NavigationEnd) {

          this.gaService.pageView(val.url, this.title)

          setTimeout(() => {
            this.hideFooter = false;
            document.body.style.height = "";
            document.body.classList.remove('navigation-transition');
          }, 1000);

          if(val.url.startsWith('/login')) {
            this.noHeader = true;
          } else {
            this.noHeader = false;
          }
          setTimeout
          if(val.url.startsWith('/user') || activeRoute.root.firstChild.snapshot.data['layout'] === 'user') {
            this.userPages = true;
            this.appLayout = true;
            this.uiService.sideMenu = true;
            if(userPagesTimeout) {
              clearTimeout(userPagesTimeout);
            }
            // this.uiService.sideMenuWide = (window.innerWidth > 800);
          } else {
            userPagesTimeout = setTimeout(() => {
              this.userPages = false;

            }, 500);

            this.appLayout = false;
            this.uiService.sideMenu = false;
            this.uiService.sideMenuWide = (window.innerWidth > 800);
          }
          if(activeRoute.root.firstChild.snapshot.data['layout'] === 'auth') {
            this.noHeader = true;
            this.hideFooter = true;
            this.authLayout = true;
          } else {
            this.noHeader = false;
            this.hideFooter = false;
            this.authLayout = false;
          }
          if(val.url === '/') {
            this.scrollableHeader = true;
          } else {
            this.scrollableHeader = false;
          }
        }
    });
  }

  ngOnInit(): void {
    this.uiService.showDemo = true;
    this.uiService.showQuote = false;
    this.titleService.setTitle(this.title);
    let token: string;
    this.userService.clearUser();
    token = this.userService.getToken();
    this.productService.productsUpdated.subscribe(products => {
      this.appNavItems = this.appSettings.getAppLinks();
    });

    this.productService.setProductsListNew();
    this.productService.setProductCategories();
    // ! After Noman fixes API this will run correctly

    if(token) {
      this.authService.getUserInfo(token).subscribe({
        next: result => {
          let user = new ApiUser;
          user = this.userService.map(user, result);
          this.userService.setUser(user);
          this.notifications.success('User Logged In', `User: ${user.username} was successfully logged in.`,FormResultMessageFormat.popup, null, null);
          this.productService.setLicensesFromApi();
        },
        error: err => console.log(err)
      })
    }
    // new SmoothScroll(document,120,12);
  }


  @HostListener('window:scroll',['$event']) onScroll(event) {
    const verticalOffset = window.pageYOffset
          || document.documentElement.scrollTop
          || document.body.scrollTop || 0;
    this.scrollingUp = verticalOffset < this.offsetY;
    this.scrolling = verticalOffset > 50
    this.scrolledDown = verticalOffset > 100;
    this.offsetY = verticalOffset;
  }



  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  onPageSwitch(event): void {
  }

  onDeactivateMain(event):void {
    // this.uiService.setUserDropdownStatus(false, true);
  }

  onActivate(event):void {
    this.renderer.addClass(document.body, 'modal-open');
  }
  onDeactivate(event):void {
    this.uiService.setUserDropdownStatus(false);
    this.renderer.removeClass(document.body, 'modal-open');
  }
}
