import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component,ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { map } from 'rxjs/operators';
import { InviteAndCompanyDetails } from '../../models/invites';
import { ApiUser } from '../../models/user';
import { ApiService } from '../../services/api.service';
import { AppSettingsService } from '../../services/app-settings.service';
import { UserService } from '../../services/user.service';
import { FormResultMessageFormat } from '../../shared/notification/notification';
import { NotificationService } from '../../shared/notification/notification.service';

@Component({
    selector: 'app-signing-callback',
  templateUrl: './register-redirect-callback.component.html'
})

export class RegisterRedirectCallbackComponent implements AfterViewInit {

    @ViewChild('modal', { static: false }) autoShownModal: ModalDirective;
    modalRef: BsModalRef;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private http: HttpClient,
        private appSettings: AppSettingsService,
        private notif: NotificationService,
        private apiService: ApiService,
        private userService: UserService
    ) { }

    inviteDetails: InviteAndCompanyDetails;
    inviteCode: string;

    ngAfterViewInit() {
      this.activatedRoute.data.subscribe(data => {
        this.GetInvite();
      });
    }

    private getInvitationInfo(): void {
      let inviteCode = this.activatedRoute?.queryParams['value']?.state;
      this.inviteCode = this.activatedRoute?.queryParams['value']?.state;
      if(inviteCode) {
          this.apiService.getInvitation(inviteCode).subscribe({
              next: (result: InviteAndCompanyDetails) => {
                  this.inviteDetails = result;
              },
              error: err => console.log(err)
          });
      } else {
        this.router.navigate(["/"], { replaceUrl: false });
      }
    }

    GetInvite(): void {
      if(this.userService.currentUser) {
        this.getInvitationInfo();
      } else {
        this.userService.userUpdated.subscribe(user => {
          this.getInvitationInfo();
        });
      }
    }

    declineInvite(): void {
        // this.modalRef.hide();
        setTimeout(() => {
            this.router.navigate(["/"], { replaceUrl: false });
        }, 500);
    }

    acceptInvite(): void {
        this.apiService.acceptInvitation(this.inviteCode).subscribe({
            next: result => {
                this.notif.success('Invitation accepted', 'Your have accepted your invitaion successfully', FormResultMessageFormat.popup, 3000, null);
                setTimeout(() => {
                    this.http.get(this.appSettings.getApi('currentUser'), {withCredentials: true}).pipe(
                        map((receivedData: ApiUser) => {
                          this.userService.setUser(receivedData, true);
                        })
                      ).subscribe(
                        response => {
                            this.router.navigate(["/"], { replaceUrl: true });
                        },
                        error => {}
                      );

                }, 1000);
            },
            error: err => {
                console.log(err);
                setTimeout(() => {
                    this.router.navigate(["/"], { replaceUrl: true });
                }, 1000);
            }
        })
    }
}
