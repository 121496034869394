import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { slideInOutAnimation } from 'projects/nine-gold-lib/src/lib/animations/slide-in-out.animation';
import { UserInterfaceService } from 'projects/nine-gold-lib/src/lib/services/user-interface.service';

function SmoothScroll(target, speed, smooth) {
	if (target === document)
		target = (document.scrollingElement
              || document.documentElement
              || document.body.parentNode
              || document.body) // cross browser support for document scrolling

	var moving = false
	var pos = target.scrollTop
  var frame = target === document.body
              && document.documentElement
              ? document.documentElement
              : target // safari is the new IE

	target.addEventListener('mousewheel', scrolled, { passive: false })
	target.addEventListener('DOMMouseScroll', scrolled, { passive: false })

	function scrolled(e) {
		e.preventDefault(); // disable default scrolling

		var delta = normalizeWheelDelta(e)

		pos += -delta * speed
		pos = Math.max(0, Math.min(pos, (target.scrollHeight - frame.clientHeight) + (smooth * 2))) // limit scrolling

		if (!moving) update()
	}

	function normalizeWheelDelta(e){
		if(e.detail){
			if(e.wheelDelta)
				return e.wheelDelta/e.detail/40 * (e.detail>0 ? 1 : -1) // Opera
			else
				return -e.detail/3 // Firefox
		}else
			return e.wheelDelta/120 // IE,Safari,Chrome
	}

	function update() {
		moving = true

		var delta = (pos - target.scrollTop) / smooth

		target.scrollTop += delta

		if (Math.abs(delta) > 0.5)
			requestFrame(update)
		else
			moving = false
	}

	var requestFrame = function() { // requestAnimationFrame cross browser
		return (
			window.requestAnimationFrame ||
			// window.webkitRequestAnimationFrame ||
			// window.mozRequestAnimationFrame ||
			// window.oRequestAnimationFrame ||
			// window.msRequestAnimationFrame ||
			function(func) {
				window.setTimeout(func, 1000 / 50);
			}
		);
	}()
}

@Component({
  selector: 'app-new-license',
  templateUrl: './new-license.component.html',
  styleUrls: ['./new-license.component.scss'],
  animations: [slideInOutAnimation],
  host: { '[@slideInOutAnimation]': '' },
  encapsulation: ViewEncapsulation.None
})
export class NewLicenseComponent implements OnInit {
  daTimesIcon = faTimes;
  redirectUrl: string;
  open = false;
  productCode: string;
  companyId: string;
  action: string;
  licenseId: string;

  smoothScroll: () => void;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private uiService: UserInterfaceService
  ) { }

  ngOnInit() {
    this.smoothScroll = new SmoothScroll(document.getElementsByTagName('app-new-license-form')[0],120,12);
    this.redirectUrl = this.route.snapshot.queryParams['returnUrl'];
    this.productCode = this.route.snapshot.params['productId'] || 'rest_generator';
    this.companyId = this.route.snapshot.params['companyId'];
    this.action = this.route.snapshot.params['action'] || 'create'
    this.licenseId = this.route.snapshot.params['licenseId'] || '';
  }

  ngAfterViewInit():void {
    this.open = true;
  }

  ngOnDestroy(): void {
    this.open = false;
    this.smoothScroll = null;
  }

  goBack(reload?: boolean): void {
    // this.location.back();
    // this.router.navigate(['pricing']);
    setTimeout(() => {
      this.uiService.fireCustomEvent(reload || false);
    }, 200);
    this.router.navigate(
			[
        "../",
				// NOTE: No relative-path navigation is required because we are accessing
				// the parent's "activatedRoute" instance. As such, this will be executed
				// as if we were doing this in the parent view component.
				{
					outlets: {
            sidePopup: null
					}
				}
      ],
      // {
      //   queryParams: null
      // },
			{
				relativeTo: this.route.parent // <--- PARENT activated route.
			}
		);

  }

}
