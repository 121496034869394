<section class="subpage-content" [libLoading]="loading" [fullScreen]="true">
  <header class="app-page-header">
    <h2>
      <fa-icon [icon]="superAdminIcon"></fa-icon>Global Admin
    </h2>
  </header>

  <article>

    <lib-box-container
      id="super-admin"
      [firstOnTheTop]="true"
      [lessPadding]="true">
      <lib-ng-list [detailsTemplate]="companyDetailsTemplate" (buttonClicked)="buttonClick($event)" (accordionAction)="setCompanyDetails($event)"
          [listsService]="companiesListNewService" [expandable]="true" [firstColExpand]="true" [refreshButton]="true"></lib-ng-list>
      <lib-ng-pagination [listsService]="companiesListNewService"></lib-ng-pagination>
    </lib-box-container>











  </article>
</section>


<ng-template #userDetails>
  <div *ngIf="userDetailsData">

    <header class="initial-with-role">
      <div class="initials-icon">{{userDetailsData.initials}}</div>
      <div class="role-and-username">
        <user-role-icon [userRole]="userDetailsRoleIcon" [isGlobalAdmin]="(userDetailsData.roles.includes('administrator') ? true : false)"></user-role-icon>
        <span class="user-name">{{userDetailsData.username}}</span>
      </div>
    </header>

    <div class="centered-modal-table">

      <ul>
        <li>
          <span class="label">Full Name</span>
          <span class="value">{{userDetailsData.fullName}}</span>
        </li>
        <li>
          <span class="label">User email</span>
          <span class="value">
            <!-- {{userDetailsData.email}} -->
            <lib-clipboard-copy
              [text]="userDetailsData.email"
              [value]="userDetailsData.email"
              [message]="'The EMAIL ADDRESS was copied to clipboard.'"></lib-clipboard-copy>
            <a class="mailto-icon" href="mailto:{{userDetailsData.email}}" title="Send email">
              <fa-icon *ngIf="userDetailsData.email" [icon]="emailIcon"></fa-icon>
            </a>
          </span>
        </li>
        <li>
          <span class="label">Phone</span>
          <span class="value">{{userDetailsData.displayPhone}}
            <a class="call-icon" title="Call to {{userDetailsData.rawPhone}}" href="tel:{{userDetailsData.rawPhone}}">
              <fa-icon *ngIf="userDetailsData.displayPhone" [icon]="phoneIcon"></fa-icon>
            </a>
          </span>
        </li>
        <!-- <li>
          <span class="label">Company</span>
          <span class="value">{{userDetailsData.company.name}}</span>
        </li> -->
        <li>
          <span class="label">Job Position</span>
          <span class="value">{{userDetailsData.jobTitle}}</span>
        </li>
      </ul>
    </div>
  </div>
</ng-template>

<!-- <ng-template #expandedRow>

  <div class="expanded-row">
    <lib-ng-list [internalTable]="true" [listsService]="companyLicenseList"></lib-ng-list>
  </div>

</ng-template> -->

<ng-template #companyDetailsTemplate>
  <div class="global-admin-company-details">
    <!-- <h3>{{companyDetails.name}}</h3> -->

    <div class="inline-multiple-label-value">
      <p>
        <!-- <fa-icon [icon]="regUsersIcon"></fa-icon> -->
        <span class="label">Registered Users</span>
        <span class="value">{{companyDetails.registeredUsers}}</span>
      </p>
      <p>
        <!-- <fa-icon [icon]="maxUsersIcon"></fa-icon> -->
        <span class="label">Max Users</span>
        <span class="value">{{companyDetails.maxUsers}}</span>
      </p>
    </div>


    <div *ngIf="companyDetails.premiumLicenses?.length > 0" class="bottom-margin">
      <table class="grid-table company-licenses-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Length</th>
            <th>Max Uses</th>
            <th>Used Uses</th>
            <!-- <th>Remaining</th> -->
            <th>Start</th>
            <th>End</th>
            <th>Status</th>
            <!-- <th>PO #</th>
            <th>Invoice</th> -->
            <th>
              <!-- CogIcon here to settings -->
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="list-title" colspan="9"><span>Premium License</span></td>
          </tr>
          <tr *ngFor="let l of companyDetails.premiumLicenses; let i = index" [class.no-license]="l.status==='No License'">
            <td class="left-aligned"><span [innerHTML]="l.productCategory?.name || l.productName"></span></td>
            <ng-container *ngIf="l.status !=='No License'">
              <td>{{l.licenseTypeText}}</td>
              <td>{{l.licensePeriodString}}</td>
              <td>{{l.licenseParams.maxUses}}</td>
              <td>{{l.uses}}</td>
              <!-- <td [innerHTML]="l.licenseTiming"></td> -->
              <td>{{l.start  | date}}</td>
              <td>{{l.end  | date}}</td>
              <td>
                <div class="status-with-icon" [ngClass]="'status-'+l.status"
                [tooltip]="l.licenseTiming" tooltipHtml="true" animation-duration="500" tooltip-class="input-tooltip" hide-delay="500" autoPlacement="false" hideDelayAfterClick="1000" trigger="hover" placement="top" max-width="300">
                  <span>{{l.status}}</span>
                </div>
              </td>
              <!-- <td>
                <div class="cell-with-copy"
                  [tooltip]="l.purchaseOrder" tooltipHtml="true" animation-duration="500" tooltip-class="input-tooltip" hide-delay="500" autoPlacement="false" hideDelayAfterClick="1000" trigger="hover" placement="top" max-width="300">
                  <span>{{l.purchaseOrder}}</span>
                  <button *ngIf="l.purchaseOrder && l.purchaseOrder !== 'N/A'" (click)="copyToClipboard(l.purchaseOrder,'Po number')" class="copyToClipboard icon-button"><fa-icon [icon]="copyIcon"></fa-icon></button>
                </div>
              </td> -->
              <!-- <td>
                <div class="cell-with-copy"
                  [tooltip]="l.invoiceNumber" tooltipHtml="true" animation-duration="500" tooltip-class="input-tooltip" hide-delay="500" autoPlacement="false" hideDelayAfterClick="1000" trigger="hover" placement="top" max-width="300">
                  <span>{{l.invoiceNumber}}</span>
                  <button *ngIf="l.invoiceNumber && l.invoiceNumber !== 'N/A'" (click)="copyToClipboard(l.invoiceNumber,'Invoice number')" class="copyToClipboard icon-button"><fa-icon [icon]="copyIcon"></fa-icon></button>
                </div>
              </td> -->
            </ng-container>
            <ng-container *ngIf="l.status ==='No License'">
              <td colspan="7" class="no-license">
                <span>{{l.status}}</span>
              </td>
            </ng-container>

            <td class="actions-cell">
              <span class="actions" [class.open]="openLicenseMenu[i]">
                <ul>
                  <li *ngIf="l.addNew">
                    <button title="Create a License" class="icon-no-background icon-button" (click)="newLicenseForm(companyDetails.id,l.productId,'new')"><fa-icon [icon]="addLicenseButton"></fa-icon></button>
                  </li>
                  <li *ngIf="l.renew">
                    <button title="Renew the License" class="icon-no-background icon-button" (click)="newLicenseForm(companyDetails.id,l.productId,'renew',l.id)"><fa-icon [icon]="renewIcon"></fa-icon></button>
                  </li>
                  <li>
                    <button title="See / Edit the License Note" (click)="editLicenseNotes(l)" class="icon-no-background icon-button"><fa-icon [icon]="noteIcon"></fa-icon></button>
                  </li>
                  <li>
                    <button title="Download the License File: {{l.licenseFilename}}" [disabled]="!l.licenseUrl" (click)="downloadLicense(l.licenseUrl,l.licenseFilename)" class="icon-no-background icon-button"><fa-icon [icon]="downloadIcon"></fa-icon></button>
                  </li>
                  <li>
                    <button (click)="deleteLicenseConfirmation({companyId: companyDetails.id, licenseId: l.id, companyName: companyDetails.name, productName: l.productCategory.name})" title="Delete the License" class="icon-no-background danger-button icon-button"><fa-icon [icon]="deleteIcon"></fa-icon></button>
                  </li>
                </ul>
                <span class="open-close">
                  <button title="More Actions" class="icon-no-background icon-button" (click)="openCloseMenu(i)"><fa-icon [icon]="openButtons"></fa-icon></button>
                </span>
              </span>
            </td>


          </tr>
          <ng-container *ngIf="!companiesListNewService.hideFreeLicenses">

            <tr>
              <td class="list-title" colspan="9"><span>Free-Tier License</span></td>
            </tr>
            <tr *ngFor="let l of companyDetails.freeLicenses">
              <td class="left-aligned"><span [innerHTML]="l.productCategory.name"></span></td>
              <td>Free Tier</td>
              <!-- <td></td>
              <td></td> -->
              <td colspan="7">{{l.start  | date}}</td>
              <!-- <td></td>
              <td></td> -->
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>


    <div class="flex-columns" style="display: none;">
      <div class="flex-rows">
        <p class="property">
          <span class="label">Deployment</span>
          <span class="value">{{(companyDetails.deployment ? 'On Prem' : 'Could')}}</span>
        </p>
        <p *ngIf="companyDetails.maxUsers > 1" [libLoading]="loadingUsers" class="property">
          <span class="label">Users</span>
          <span class="value">{{companyDetails.registeredUsers}}<span class="prep">of</span>{{companyDetails.maxUsers}}</span>
          <!-- <button class="secondary">All Users</button> -->
        </p>
        <p class="property" *ngIf="companyDetails.maxUsers > 1">
          <span class="label">Invites</span>
          <span class="value">{{companyDetails.invitesNumber}}</span>
          <!-- <button class="secondary">All Invites</button> -->
        </p>
      </div>

      <div>
        <p class="table-title">Free Licenses</p>
        <table class="grid-table">
          <thead>
            <tr>
              <th>Product</th>
              <th>Start Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let l of companyDetails.freeLicenses">
              <td>{{l.productName}}</td>
              <td>{{l.start | date}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="form-button-container right-aligned">
      <button class="primary impersonate-button" (click)="impersonateCompany(companyDetails.id,companyDetails.name)"><fa-icon [icon]="impersonateIcon"></fa-icon> Impersonate</button>
      <button class="primary add-license-button" (click)="newLicenseForm(companyDetails.id)"><fa-icon [icon]="addIcon"></fa-icon>Add License</button>
    </div>

    <div class="form-button-container" style="display: none;">
      <button [disabled]="true" *ngIf="companyDetails.maxUsers > 1" class="text-button">Users</button>
      <button [disabled]="true" *ngIf="companyDetails.maxUsers > 1" class="text-button">Invites</button>
      <button [disabled]="true" class="text-button">Actions</button>
      <button [disabled]="true" class="text-button">Downloads</button>
      <button [disabled]="true" class="text-button">Licenses</button>
      <button *ngIf="companyDetails.canAddLicense" class="primary">Create License</button>
    </div>




  </div>
</ng-template>

<ng-template #deleteLicenseConfirm>
  <div>
    <div class="modal-header">
      <h2 class="modal-title pull-left">License Deletion Confirmation</h2>
      <button class="close-popup-button close-button modal-close" (click)="modalRef.hide()">close</button>
    </div>
    <div class="modal-body">
      <p class="centered">You are about to remove a <strong>{{removeLicenseConfirm.productName}}</strong> license from the company: <strong>{{removeLicenseConfirm.companyName}}</strong>.</p>
      <form #deleteConfirmForm="ngForm" (submit)="noSubmit()">
        <div class="form-group min-bottom-margin">
          <label for="confirmProductName" class="required">Product name</label>
          <p class="input-instruction">Type <strong>Product name </strong> <i>(case sensitive)</i> and click <strong>Confirm Removal</strong>.</p>
          <div class="input-container" [class.is-valid]="deleteConfirmForm.valid" [class.is-invalid]="!deleteConfirmForm.valid && deleteConfirmForm.touched">
            <input class="form-control" [pattern]="confirmProductPattern" type="text" name="confirmProductName" autocomplete="off" required [(ngModel)]="confirmProductName">
          </div>
        </div>


        <div class="modal-footer">
          <button type="reset" class="secondary" (click)="modalRef.hide()">Cancel</button>
          <button type="button" [disabled]="!deleteConfirmForm.valid" class="primary danger" (click)="deleteLicense(removeLicenseConfirm)">Confirm Removal</button>
        </div>

      </form>

      <!-- <p class="centered">You can see your results in <a class="pointer" (click)="closeModal()" >Downloads List</a></p> -->
    </div>
  </div>
</ng-template>


<ng-template #deleteCompanyConfirm>
  <div>
    <div class="modal-header">
      <h2 class="modal-title pull-left"><span style="color: red">DELETE</span> a company <strong>{{removeCompanyDate.companyName}}</strong>.</h2>
      <button class="close-popup-button close-button modal-close" (click)="modalRef.hide()">close</button>
    </div>
    <div class="modal-body">
      <p class="centered">You are about to remove a company: <strong>{{removeCompanyDate.companyName}}</strong>.</p>
      <form #deleteConfirmForm="ngForm" (submit)="noSubmit()">
        <div class="form-group min-bottom-margin">
          <p class="input-instruction">Type <strong>Company name </strong> <i>(case sensitive)</i> and click <strong>Confirm Removal</strong>.</p>
          <div class="input-container" [class.is-valid]="deleteConfirmForm.valid" [class.is-invalid]="!deleteConfirmForm.valid && deleteConfirmForm.touched">
            <input class="form-control" [pattern]="confirmCompanyRemovalPattern" type="text" name="confirmCompanyName" autocomplete="off" required [(ngModel)]="confirmCompanyName">
          </div>
        </div>


        <div class="modal-footer">
          <button type="reset" class="secondary" (click)="modalRef.hide()">Cancel</button>
          <button type="button" [disabled]="!deleteConfirmForm.valid" class="primary danger" (click)="deleteCompany(removeCompanyDate.companyId,removeCompanyDate.companyName)">Confirm Removal</button>
        </div>

      </form>

    </div>
  </div>
</ng-template>



<ng-template #licensNotesTemplate>
  <div>
    <div class="modal-header">
      <h2 class="modal-title pull-left">{{licenseNotes.productName}}</h2>
      <button class="close-popup-button close-button modal-close" (click)="modalRef.hide()">close</button>
    </div>
    <div class="modal-body">
      <form #editNotesForm="ngForm">
        <div class="form-group min-bottom-margin">
          <label for="notesField">Notes</label>
          <div class="input-container">
            <textarea name="notesField" id="notesField" cols="30" rows="10" [(ngModel)]="licenseNotes.notes"></textarea>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="reset" class="secondary" (click)="modalRef.hide()">Cancel</button>
      <button type="button" [disabled]="true" class="primary" (click)="updateNotes(l)">Save</button>
    </div>
  </div>
</ng-template>

