import { ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { faqs } from 'projects/nine-gold-lib/src/lib/models/other';
import { ApiService } from 'projects/nine-gold-lib/src/lib/services/api.service';

@Component({
  selector: 'lib-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FaqComponent implements OnInit, AfterViewInit {
  faqsList: faqs[] = [];

  constructor(
    private apiService: ApiService,
    private viewportScroller: ViewportScroller,
  ) { }

  ngOnInit() {
    this.apiService.getFaqs().subscribe({
      next: result => {
        this.faqsList = result;
        this.faqsList.forEach(el=>{
          el.id = el.title.replace(/\W/g,'_');
        });
      },
      error: err => console.log(err)
    })
  }



  ngAfterViewInit(): void {
    setTimeout(() => {
      this.viewportScroller.setOffset([0,90]);
      if(window.location.hash) {
        let hash = window.location.hash.substring(1);
        this.viewportScroller.scrollToAnchor(hash);
        // Fragment exists
      }
    }, 500);

  }

}
