<div class="page-with-footer">
  <header class="faq-header">
    <div class="header-inner">
      <h2>FAQ: Frequently Asked Questions</h2>
    </div>
  </header>
  <section class="questions">
    <div class="inner">
      <article>
        <ul>
          <li *ngFor="let faq of faqsList" [id]="faq.id">
            <div class="bullseye-arrow">
              <img src="/assets/images//bullseye.svg" alt="">
            </div>
            <section class="q-and-a">
              <header class="faq-question">
                <h2>{{faq.title}}</h2>
              </header>
              <div class="faq-answer" [innerHTML]="faq.details"></div>
            </section>
          </li>
        </ul>
    </article>
    </div>
  </section>


</div>
