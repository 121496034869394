import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { faBiking, faCar, faCheckSquare, faCog, faComment, faCommentDots, faComments, faEllipsisH, faEnvelope, faFilePdf, faPlane, faProjectDiagram, faQuestion, faStore, faSubway, faWalking } from '@fortawesome/free-solid-svg-icons';
// import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { BsDropdownConfig, BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AppSettingsService } from 'projects/nine-gold-lib/src/lib/services/app-settings.service';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: BsDropdownConfig, useValue: { autoClose: true } }]
})
export class ContactComponent implements OnInit {
  cogIcon = faCog;
  commentsIcon = faComments;
  com = faCommentDots;
  ellipsisIcon = faEllipsisH;
  checkIcon = faCheckSquare;
  questionIcon = faQuestion;
  contactIcon = faEnvelope;
  pricingPageIcon = faStore;
  brochureIcon = faFilePdf;
  useCaseIcon = faProjectDiagram;

  knowledgeBaseLink:string;
  platformSupportLink:string;
  pricingLink:string;

  carIcon = faCar;
  bikeIcon = faBiking;
  planeIcon = faPlane;
  walkingIcon = faWalking;
  subwayIcon = faSubway;

  constructor(
    private appSettings: AppSettingsService
  ) {
    this.pricingLink = appSettings.getFooterNav().find(l=>l.name === 'pricing')?.link;
    // this.knowledgeBaseLink = appSettings.getFooterNav().find(l => l.name === 'knowledgeBase').link;
    // this.platformSupportLink = appSettings.getFooterNav().find(l => l.name === 'support').link;
  }

  ngOnInit(): void {
  }



}
