import { Component, Input, OnInit } from '@angular/core';
import { ListsService } from '../../../services/lists.service';
import { ColumnFilter, columnFilterTypes } from '../ng-list/ng-list-data';

@Component({
  selector: 'lib-ng-applied-filters',
  templateUrl: './ng-applied-filters.component.html',
  styleUrls: ['./ng-applied-filters.component.scss']
})
export class NgAppliedFiltersComponent implements OnInit {
  @Input() listsService: ListsService;
  appliedFilters: ColumnFilter[];

  constructor() { }

  ngOnInit(): void {
    this.appliedFilters = this.listsService.GetAppliedFilters();
    this.listsService.parametersUpdated.subscribe(
      (params) => {
        this.appliedFilters = this.listsService.GetAppliedFilters();
      }
    )
  }

  filterSelectedDisplayText(filters: ColumnFilter): string {
    if(filters.filterType === columnFilterTypes.autocomplete) {
      let filterSelected = filters.optionSelected;
      return filterSelected || "";
    } else if(filters.filterType === columnFilterTypes.text) {
      let filterSelected = filters.optionSelected;
      return `.. ${filterSelected} ...` || "";
    } else {
      let filterSelected = filters.filterOptions.find(x => x.value === filters.optionSelected);
      return filterSelected?.text || "";
    }

  }

  clearFilter(filter: ColumnFilter): void {
    this.listsService.clearFilter(filter);
  }

}
