import { Component, OnInit } from '@angular/core';
import { UserService } from 'projects/nine-gold-lib/src/lib/services/user.service';
import { UsersListService } from '../../services/users-list.service';

@Component({
  selector: 'app-subusers-sidebar',
  templateUrl: './subusers-sidebar.component.html',
  styleUrls: ['./subusers-sidebar.component.scss']
})
export class SubusersSidebarComponent implements OnInit {

  constructor(
    public userService: UserService,
    public usersListService: UsersListService
  ) { }

  ngOnInit(): void {
  }

}
