<section class="subpage-content" [libLoading]="loading" [fullScreen]="true">
    <header class="app-page-header">
        <h2><fa-icon [icon]="usersManagementIcon"></fa-icon>User Management</h2>
    </header>

    <article>
        <app-tabs-list>
            <app-tabs-shared >
              <div class="form-group form-button-container">
                <form *ngIf="userService.userPermissions['canSendInvitations']">
                  <div class="flex-columns right-aligned-inline-form center-aligned bottom-margin">
                  <lib-form-field [value]="inviteForm.value['email']" [noBottomMargin]="true" [inlineLayout]="true" *ngIf="email" [small]="true" [formGroupParameter]="inviteForm" [fieldSettings]="email[0]"></lib-form-field>

                  <div class="button-container multiple-buttons">
                    <button
                      [disabled]="inviteFormLoading || !inviteForm.valid || inviteForm.value['email'] === ''"
                      (click)="inviteNewUser()"
                      class="primary"
                      [class.loading]="inviteFormLoading"
                      type="submit">Send</button>
                    <button
                      class="secondary"
                      type="reset"
                      (click)="clearInvitationForm()"
                      [disabled]="inviteFormLoading || inviteForm.pristine || inviteForm.value['email'] === ''">Clear</button>
                  </div>

                </div>
                </form>

              </div>
            </app-tabs-shared>

            <app-tab-item [label]="'Existing Users'">
              <app-tab-label>Existing Users</app-tab-label>
              <app-tab-body>
                <lib-ng-list [tableId]="'subusersList'" (buttonClicked)="usersListButtonClick($event)"
                  [filters]="usersListService.listParameters.filters"
                  [listsService]="usersListService"></lib-ng-list>
                <lib-ng-pagination [listsService]="usersListService"></lib-ng-pagination>
              </app-tab-body>
            </app-tab-item>

            <app-tab-item *ngIf="userService.userPermissions['canViewInvitations']" [label]="'Invites Sent'">
              <app-tab-label>Invites Sent</app-tab-label>
              <app-tab-body>
                <lib-ng-list [tableId]="'invitationsList'" (buttonClicked)="invitesListButtonClick($event)"
                  [filters]="invitesListService.listParameters.filters"
                  [listsService]="invitesListService">
                </lib-ng-list>
                <lib-ng-pagination [listsService]="invitesListService"></lib-ng-pagination>
              </app-tab-body>
            </app-tab-item>

          </app-tabs-list>


    </article>
</section>


<ng-template #userDetails>
  <div *ngIf="userDetailsData">

    <header class="initial-with-role">
      <div class="initials-icon">{{userDetailsData.initials}}</div>
      <div class="role-and-username">
        <user-role-icon [userRole]="userDetailsRoleIcon" [isGlobalAdmin]="(userDetailsData.roles.includes('administrator') ? true : false)"></user-role-icon>
        <span class="user-name" [innerHTML]="userDetailsData.username"></span>
      </div>
    </header>

    <div class="centered-modal-table">

      <ul>
        <li>
          <span class="label">Full Name</span>
          <span class="value">{{userDetailsData.fullName}}</span>
        </li>
        <li>
          <span class="label">User email</span>

          <span class="value">
            <!-- {{userDetailsData.email}} -->
            <lib-clipboard-copy
              [text]="userDetailsData.email"
              [value]="userDetailsData.email"
              [message]="'The EMAIL ADDRESS was copied to clipboard.'"></lib-clipboard-copy>
            <a class="mailto-icon" href="mailto:{{userDetailsData.email}}" title="Send email">
              <fa-icon [icon]="emailIcon"></fa-icon>
            </a>
          </span>
        </li>
        <li *ngIf="userDetailsData.displayPhone">
          <span class="label">Phone</span>
          <span class="value">{{userDetailsData.displayPhone}}
            <a class="call-icon" title="Call to {{userDetailsData.rawPhone}}" href="tel:{{userDetailsData.rawPhone}}">
              <fa-icon [icon]="phoneIcon"></fa-icon>
            </a>
          </span>
        </li>
        <li *ngIf="userDetailsData.company">
          <span class="label">Company</span>
          <span class="value">{{userDetailsData.company.name}}</span>
        </li>
        <li>
          <span class="label">Job Position</span>
          <span class="value">{{userDetailsData.jobTitle}}</span>
        </li>
      </ul>
    </div>
  </div>
</ng-template>

<ng-template #inviteDetails>
  <div *ngIf="inviteDetailsData">
    <div class="border">
      <h3>Invite info</h3>
      <p>
        <span class="label">Invitee email</span>
        <span class="value">{{inviteDetailsData.email}}</span>
      </p>
      <p>
        <span class="label">Created</span>
        <span class="value">{{inviteDetailsData.created | date : 'medium'}}</span>
      </p>
      <p>
        <span class="label">Sent to</span>
        <span class="value">{{inviteDetailsData.sent | date : 'medium'}}</span>
      </p>
    </div>


    <div class="border">
      <h3>Sender information</h3>
      <p>
        <span class="label">Name</span>
        <span class="value">{{inviteDetailsData.from.fullName}}</span>
      </p>
      <p>
        <span class="label">Email</span>
        <span class="value">{{inviteDetailsData.from.email}}</span>
      </p>
      <p>
        <span class="label">Phone</span>
        <span class="value">{{inviteDetailsData.from.displayPhone}}</span>
      </p>
      <p>
        <span class="label">Job title</span>
        <span class="value">{{inviteDetailsData.from.jobTitle}}</span>
      </p>
      <p class="line-with-right-button">
        <span class="label">Code</span>
        <span class="value ellipsis">{{inviteDetailsData.code}}</span>
        <span class="button-container">
          <button (click)="copyToClipboard(inviteDetailsData.code)" class="icon-button primary copy-to-clipboard">
            <fa-icon [icon]="copyIcon"></fa-icon>
          </button>
        </span>
      </p>

    </div>

  </div>
</ng-template>

