import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { FormResultMessageFormat } from '../shared/notification/notification';
import { NotificationService } from '../shared/notification/notification.service';
import { AuthService } from './auth.service';
import { ProductService } from './product.service';
import { UserService } from './user.service';

@Injectable({providedIn: 'root'})
export class NewAuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router, private userService: UserService, private notif: NotificationService, private productService: ProductService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Promise<boolean>|boolean {
        let url = state.url;
        function checkForPermissionAndLicense(route: ActivatedRouteSnapshot, userService: UserService, notif: NotificationService, productService: ProductService):boolean {
            let permissionNeeded = route.data.permissionNeeded as string;
            let licenseCheck = route.data.licenseCheck as string;
            let canGo = true;
            if(permissionNeeded) {
                let permission = userService.getPermissions();
                if(permission[permissionNeeded] !== true) {
                    notif.error('No Permission', 'You don\'t have permission to view this page', FormResultMessageFormat.popup, 6000, null);
                    canGo = false;
                }
            }
            if(licenseCheck) {
                let productName = route.data.productName;
                if(productName) {
                    if(!productService.checkIfProductValid(productName)) {
                        canGo = false;
                        notif.error('No License', 'You don\'t have active license to view this page', FormResultMessageFormat.popup, 6000, null);
                    }
                }

                // TODO: when license API updated, fix checkUserLicense and uncomment code below
                // let licenseValid = userService.checkUserLicense();
                // if(!licenseValid) {
                //     canGo = false;
                // }
            }
            return canGo;
        }
        return this.authService.isLoggedIn().then(async loggedIn => {
            if (loggedIn) {
                let canLoad = false;
                let licenseCheck = route.data.licenseCheck as string;
                let productName = route.data.productName;

                if(licenseCheck && productName) {
                    if(this.productService.apiLicenses) {
                        canLoad = checkForPermissionAndLicense(route, this.userService, this.notif, this.productService);
                    } else {
                        await this.productService.licensesUpdated.pipe(first()).toPromise();
                        canLoad = checkForPermissionAndLicense(route, this.userService, this.notif, this.productService);
                    }
                } else {
                    if(this.userService.currentUser) {
                        canLoad =  checkForPermissionAndLicense(route, this.userService, this.notif, this.productService);
                    } else {
                        await this.authService.getCurrentUser().toPromise();
                        canLoad = checkForPermissionAndLicense(route, this.userService, this.notif, this.productService);
                    }
                }

                if(canLoad) {
                    return true;
                } else {
                    if(this.router.routerState.snapshot.url === "" || this.router.routerState.snapshot.url.startsWith('/signin-callback')) {
                        this.router.navigate(['/'], { replaceUrl: true })
                    }
                    return false;
                }
            } else {
                this.authService.login(url);
                return false;
            }
        });
    }
}
