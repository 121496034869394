<div class="page-with-footer">
    <header class="banner">
        <h1>Contact Us</h1>
    </header>
<main class="content-page contact-page-content">
    <div class="inner">
    <article>
        <div class="inner">

            <div class="contact-info">

                <div class="flex-columns auto-columns">
                    <div class="form-container">
                        <h3>Contact Sales</h3>
                        <lib-ng-contact></lib-ng-contact>
                    </div>

                    <div class="address-container">
                        <h3>Office Location</h3>
                        <p>Diamond Edge IT <br><i>Headquarters</i></p>
                        <address><a target="_blank" href="https://goo.gl/maps/Lha6egjieM4MdEn57">
                            1065 SW 8th St #251
                            <br>
                            Miami, FL 33130
                            </a>
                        </address>
                        <p>
                            <span>tel:</span>
                            <a role="button" class="text-button" href="tel:+1 855 376 1150">(855) 376-1150</a>
                        </p>
                    </div>
                </div>
            </div>




        </div>

    </article>

</div>
<div class="map-container" >
    <div class="marker-container">
        <a class="marker"></a>
        <div class="info-popup">
            <h4>Directions to Diamond Edge IT</h4>
            <p>How to get to our office</p>
            <ul>
                <li>
                    <a target="_blank" href="https://www.google.com/maps/dir//1065+SW+8th+St,+Miami,+FL+33130,+USA/@25.7660753,-80.2146799,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x88d9b6f30a1f3d19:0xeba1c69d23b4f4da!2m2!1d-80.2124912!2d25.7660753!3e0">
                        <fa-icon [icon]="carIcon"></fa-icon>
                    </a>
                </li>
                <li>
                    <a target="_blank" href="https://www.google.com/maps/dir//1065+SW+8th+St,+Miami,+FL+33130,+USA/@25.7660753,-80.2146799,17z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x88d9b6f30a1f3d19:0xeba1c69d23b4f4da!2m2!1d-80.2124912!2d25.7660753!3e3">
                        <fa-icon [icon]="subwayIcon"></fa-icon>
                    </a>
                </li>
                <li>
                    <a target="_blank" href="https://www.google.com/maps/dir//1065+SW+8th+St,+Miami,+FL+33130,+USA/@25.7660753,-80.2146799,17z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x88d9b6f30a1f3d19:0xeba1c69d23b4f4da!2m2!1d-80.2124912!2d25.7660753!3e1">
                        <fa-icon [icon]="bikeIcon"></fa-icon>
                    </a>
                </li>
                <li>
                    <a target="_blank" href="https://www.google.com/maps/dir//1065+SW+8th+St,+Miami,+FL+33130,+USA/@25.7660753,-80.2146799,17z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x88d9b6f30a1f3d19:0xeba1c69d23b4f4da!2m2!1d-80.2124912!2d25.7660753!3e2">
                        <fa-icon [icon]="walkingIcon"></fa-icon>
                    </a>
                </li>
                <li>
                    <a target="_blank" href="https://www.google.com/maps/dir//1065+SW+8th+St,+Miami,+FL+33130,+USA/@25.7660753,-80.2146799,17z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x88d9b6f30a1f3d19:0xeba1c69d23b4f4da!2m2!1d-80.2124912!2d25.7660753!3e4">
                        <fa-icon [icon]="planeIcon"></fa-icon>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
</main>
</div>
