import { Component, OnInit } from '@angular/core';
import { UserInterfaceService } from 'projects/nine-gold-lib/src/lib/services/user-interface.service';

@Component({
  selector: 'app-styleguide',
  templateUrl: './styleguide.component.html',
  styleUrls: ['./styleguide.component.scss']
})
export class StyleguideComponent implements OnInit {

  constructor(private uiService: UserInterfaceService) {
    this.uiService.hideHeader();
   }

  ngOnInit(): void {
  }

}
