import { Injectable } from '@angular/core';
import { ApiCompany } from 'projects/nine-gold-lib/src/lib/models/company';
import { ApiLicenseStatus, ApiLicenseType } from 'projects/nine-gold-lib/src/lib/models/product';
import { UserRoles } from 'projects/nine-gold-lib/src/lib/models/user';
import { ListsService } from 'projects/nine-gold-lib/src/lib/services/lists.service';
import { ButtonTypes, columnFilterTypes, ColumnTypes, ListUiParams, NgListDataCell, NgListDataRow, sortingOrder, TypesOfCompare } from 'projects/nine-gold-lib/src/lib/shared/listComponents/ng-list/ng-list-data';

type listApiDataType = ApiCompany;

@Injectable({
  providedIn: 'root'
})
export class CompaniesListNewService extends ListsService {

  public hideFreeLicenses = false;

  listData: NgListDataRow[] = [];
  rawListData: NgListDataRow[] = [];

  licenseTypesText = {
    'Free': 'Free',
    'FreeLicense': 'Free Premium',
    'TrialLicense': 'Trial',
    'ProjectLicense': 'Project',
    'UsageLicense': 'Usage',
    'EnterpriseLicense': 'Enterprise'
  };


  uiSortFilterPage = true;

  listParameters:ListUiParams = {
    numberOfPages: 1,
    currentPage: 1,
    itemsPerPage: 10,
    filters: [],
    sorting: {
      field: "companyName",
      order: sortingOrder.asc
    }
  }

  // constructor() { }

  setApiName(): void {
    this.listApiName = 'companiesList';
    this.ApiFunctionName = 'getCompaniesList';
  }

  setSortSettings(): void {
    this.uiSortFilterPage = true;
  }

  setInitialColumns(): void {
    this.columns = [
      {
        columnName: 'id',
        columnText:'',
        sortable: false,
        filters: null,
        notVisible: true,
        customMapping: ['id'],
        rowIdentifier: true,
        columnType: ColumnTypes.text
      },
      {
        columnName: 'companyName',
        columnType: ColumnTypes.text,
        columnOrder: 0,
        customMapping: ['name'],
        sortable: true,
        sortColumnName: 'companyName',
        filters: {
          filterName: 'companyName',
          filterPlaceholder: 'Type Company Name',
          filterType: columnFilterTypes.text,
          optionSelected: null,
          compareType: TypesOfCompare.partialEqual,
          autocompleteList: []
        },
        columnText: 'Company Name',
        titleColumn: true
      },
      {
        columnName: 'industry',
        columnType: ColumnTypes.text,
        columnText: 'Industry',
        sortable: true,
        filters: null, // will add type filters later
        notVisible: false,
        sortColumnName: 'industry',
        canShowHide: true,
        tdHtml: true
      },
      {
        columnName: 'companyCreatedDate',
        columnType: ColumnTypes.date,
        columnOrder: 1,
        sortable: true,
        sortColumnName: 'companyCreatedDate',
        columnText: 'Created',
        filters: null,
        canShowHide: true,
      },
      {
        columnName: 'companyOwnerUsername',
        columnText: 'Username',
        columnOrder: 2,
        customMapping: ['owner','username'],
        sortable: true,
        sortColumnName: 'companyOwnerUsername',
        filters: {
          filterName: 'User name',
          filterType: columnFilterTypes.autocomplete,
          filterPlaceholder: 'Type Username',
          optionSelected: null,
          autocompleteList: [],
          compareType: TypesOfCompare.equal
        },
        columnType: ColumnTypes.text_info,
        buttons: [
          {
            buttonText: 'User Info',
            type: ButtonTypes.user,
            value: ''
          }
        ]
      },
      {
        columnName: 'companyOwnerName',
        columnText: 'Owner',
        columnOrder: 3,
        customMapping: ['owner','fullName'],
        sortable: true,
        sortColumnName: 'companyOwnerName',
        filters: null,
        columnType: ColumnTypes.text,
        canShowHide: true,
        // buttons: [
        //   {
        //     buttonText: 'User Info',
        //     type: ButtonTypes.user,
        //     value: ''
        //   }
        // ]
      },
      {
        columnName: 'companyOwnerId',
        notVisible: true,
        customMapping: ['owner','id'],
        columnType: ColumnTypes.text,
        columnText: '',
        sortable: false,
        filters: null
      },
      {
        columnName: 'licenseType',
        columnType: ColumnTypes.text,
        columnText: 'License Type',
        sortable: false,
        notVisible: false,
        specialHandling: true,
        filters: {
          optionSelected: null,
          filterId: 'licenseType',
          filterName: 'License Type',
          filterPlaceholder: 'All',
          filterType: columnFilterTypes.options,
          filterOptions: [
            {value: 'premium', text: 'Premium'},
            {value: 'trial', text: 'Trial'},
            {value: 'premiumExpired', text: 'Premium Expired'},
            {value: 'premiumScheduled', text: 'Premium Scheduled'},
            {value: 'free', text: 'Free Only (no Premium License)'}
            // TODO: add scheduled filter
          ]
        }
      },
      {
        // TODO: this property will be handled by API
        columnName: 'warnings',
        columnType: ColumnTypes.text,
        columnText: 'Warnings',
        canShowHide: true,
        sortable: false,
        filters: null, // will add type filters later
        notVisible: true
      },
      {
        columnName: 'maxUsers',
        columnType: ColumnTypes.text,
        columnText: 'Max Users',
        sortColumnName: 'maxUsers',
        sortable: true,
        filters: null, // will add type filters later
        notVisible: false,
        specialHandling: true,
        canShowHide: true
      },
      {
        columnName: 'currentLicenses',
        columnType: ColumnTypes.text,
        columnText: 'Licenses',
        sortable: false,
        filters: null,
        notVisible: true,
        subColumnParent: true,
        subColumns: [
          {
            columnName: 'id',
            columnText: '',
            columnType: ColumnTypes.text,
            sortable: false,
            filters: null,
            columnOrder: null,
            rowIdentifier: true,
            notVisible: true
          },
          {
            columnName: 'productName',
            columnText: 'Name',
            columnType: ColumnTypes.text,
            sortable: true,
            filters: null,
            columnOrder: null,
            rowIdentifier: false
          },
        ]
      },
      {
        columnName: 'actions',
        columnType: ColumnTypes.buttons,
        buttons: [
          {
            buttonText: 'Impersonate Company',
            type: ButtonTypes.impersonate,
            value: '',
            buttonCustomClass: 'icon-no-background'
          },
          {
            buttonText: 'Add new license',
            type: ButtonTypes.license_add,
            value: '',
            buttonCustomClass: 'license-add icon-no-background icon-add'
          },
          {
            buttonText: 'Delete Company',
            type: ButtonTypes.delete,
            value: '',
            buttonCustomClass: 'icon-no-background delete-company no-background',
            disabled: false
          }
        ],
        columnOrder: 4,
        sortable: false,
        filters: null,
        columnText: 'Actions'
      }
    ]
  }

  canAddLicense(row: listApiDataType): boolean {
    let canAdd = false;
    // TODO: true if :
    // TODO: there is no restgen license at all
    // TODO: current license trial
    // TODO: current license is expired, about to expire or exhaused - it will be renew license
    let restGenLicense = row.currentLicenses.find(l => l.productId === 'restgen');
    if (!restGenLicense) {
      canAdd = true;
    } else {
      if (restGenLicense.licenseParams.type === 'TrialLicense') {
        canAdd = true;
      } else {

      }
    }
    return canAdd;
  }


  specialHandling(el: NgListDataCell, row: listApiDataType): void {

    if(el.columnName === 'licenseType') {
      let premiumLicense = row.currentLicenses.find(l => [ApiLicenseType.EnterpriseLicense,ApiLicenseType.ProjectLicense,ApiLicenseType.UsageLicense].includes(l.licenseParams.type) && l.status === ApiLicenseStatus.valid);
      let premiumLicenseExpired = row.currentLicenses.find(l => [ApiLicenseType.EnterpriseLicense,ApiLicenseType.ProjectLicense,ApiLicenseType.UsageLicense].includes(l.licenseParams.type) && [ApiLicenseStatus.expired, ApiLicenseStatus.exhausted].includes(l.status) );
      let trialLicense = row.currentLicenses.find(l=>l.licenseParams.type === ApiLicenseType.TrialLicense && l.status === ApiLicenseStatus.valid);
      let premiumScheduled = row.currentLicenses.find(l=>[ApiLicenseType.EnterpriseLicense,ApiLicenseType.ProjectLicense,ApiLicenseType.UsageLicense].includes(l.licenseParams.type) && l.status === ApiLicenseStatus.scheduled);
      if(premiumLicense) {
        el.value = "premium";
        el.columnClasses += ' premium-license-type';
        el.display = "Premium";
      } else if(premiumLicenseExpired) {
        el.value = "premiumExpired";
        el.columnClasses += ' premium-license-expired';
        el.display = "Expired";
      } else if(trialLicense) {
        el.value = "trial";
        el.columnClasses += ' trial-license';
        el.display = "Trial";
      } else if(premiumScheduled) {
        el.value = "premiumScheduled";
        el.columnClasses += ' premium-scheduled';
        el.display = "Scheduled";
      } else {
        el.value = "free";
        // el.columnClasses += ' premium-license-type';
        el.display = "Basic";
      }

      // TODO: add scheduled type once it's in API
      // let restGenLicense = row.currentLicenses.find(l => l.productId === 'restgen');
      // if(restGenLicense) {
      //   el.value = restGenLicense.licenseParams.type;
      //   el.columnClasses += ' premium-license-type';
      // } else {
      //   el.value = 'Free';
      // }

      // el.display = this.licenseTypesText[el.value];

    // TODO add number of users to here once it is part of API

    // } else if(el.columnName === 'maxUsers') {
    //   let restGenLicense = row.currentLicenses.find(l => l.productId === 'restgen');
    //   if(restGenLicense && restGenLicense.licenseParams?.numberOfUsers) {

    //   }
    } else if(el.columnName === 'maxUsers') {
      let maxUsers = 1;
      let premiumLicense = row.currentLicenses.filter(l => [ApiLicenseType.EnterpriseLicense,ApiLicenseType.ProjectLicense,ApiLicenseType.UsageLicense].includes(l.licenseParams.type) && l.status === ApiLicenseStatus.valid);
      if(premiumLicense) {
        premiumLicense.forEach(l => {
          if(l.licenseParams.maxUsers && l.licenseParams.maxUsers > maxUsers) maxUsers = l.licenseParams.maxUsers;
        });
      }
      el.value = maxUsers;
      el.display = maxUsers.toString();

    }

  }

}
