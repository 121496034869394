<label for="itemsPerPageSelector">Items per page</label>
<ng-select
    appearance="outline" 
    labelForId="itemsPerPageSelector"
    [clearable]="false"
    [(ngModel)]="selectedOption"
    [searchable]="false"
    (change)="selectorUpdated()">
    <ng-option *ngFor="let option of listOptions" [value]="option.value">{{option.text}}</ng-option>
</ng-select>
