<div class="">
    <label [for]="id">Sort by:</label>
    
    <ng-select [(ngModel)]="selectedSort"
        class="" 
        appearance="outline" 
        [id]="id"
        placeholder="'Sort by'"
        [searchable]="false" 
        [clearable]="false"
        (change)="sortSelected(selectedSort)">
        <ng-option *ngFor="let option of sortOptions" [value]="option.value">{{option.text}}</ng-option>
    </ng-select>
</div>