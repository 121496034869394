import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUser, IUser, UserRoles } from 'projects/nine-gold-lib/src/lib/models/user';
import { FilterPipe } from 'projects/nine-gold-lib/src/lib/pipes/filter.pipe';
import { AppSettingsService } from 'projects/nine-gold-lib/src/lib/services/app-settings.service';
import { ListsService } from 'projects/nine-gold-lib/src/lib/services/lists.service';
import { UserService } from 'projects/nine-gold-lib/src/lib/services/user.service';
import { ButtonTypes, columnFilterTypes, ColumnTypes, ListUiParams, NgListButton, NgListColumns, NgListDataCell, NgListDataRow, sortingOrder } from 'projects/nine-gold-lib/src/lib/shared/listComponents/ng-list/ng-list-data';
// import { ButtonTypes, columnFilterTypes, ColumnTypes, ListUiParams, NgListButton, NgListColumns, NgListDataCell, NgListDataRow, sortingOrder } from 'projects/nine-gold-lib/src/lib/shared/ng-list/ng-list-data';
import { OrderByPipe } from 'projects/nine-gold-lib/src/public-api';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { UserApiDataFull, UserApiDataPartial } from './userApiData';

// type listApiDataType = UserApiDataPartial;
type listApiDataType = ApiUser;

@Injectable({
  providedIn: 'root'
})

export class UsersListService extends ListsService {

  listData: NgListDataRow[] = [];
  rawListData: NgListDataRow[] = [];
  columns: NgListColumns[];

  uiSortFilterPage = true;

  listApiName = 'currentCompanyUsersList';
  ApiUrl: string;

  listParameters:ListUiParams = {
    numberOfPages: 1,
    currentPage: 1,
    itemsPerPage: 10,
    filters: [],
    sorting: {
      field: "username",
      order: sortingOrder.asc
    }
  }

  // constructor(
  //   userService: UserService,
  //   http: HttpClient,
  //   sortPipe: OrderByPipe,
  //   filterPipe: FilterPipe,
  //   settings: AppSettingsService
  //   ) {
  //     super(userService, settings, sortPipe, filterPipe, http);
  //     this.ApiUrl = this.settings.getApi(this.listApiName);
  //     // this.updateListParams();
  //     // this.columns = this.CreateColumns();
  //     // this.setFakeUsers();
  //     // this.LoadTableData(true);

  // }

    setApiName(): void {
      this.listApiName = 'currentCompanyUsersList';
      this.ApiFunctionName = 'getUsersList';
      if(this.settings.impersonatedCompany) {
        this.ApiParameter = this.settings.impersonatedCompany.id;
      } else {
        this.ApiParameter = this.userService.getCompanyId();
      }
    }

    setSortSettings(): void {
      this.uiSortFilterPage = true;
    }

    HighlightCell(value: any): boolean {
      if(value === this.userService.getUserInfo().username) return true;
      return false;
    }

    setInitialColumns(): void {
      this.columns  = [
        {
          columnName: 'id',
          columnText: 'Id',
          columnType: ColumnTypes.text,
          sortable: false,
          filters: null,
          notVisible: true,
          rowIdentifier: true
        },
        {
          columnName: 'username',
          columnText: 'Username',
          columnType: ColumnTypes.text,
          columnOrder: 0,
          sortable: true,
          filters: null,
          sortColumnName: 'username',
          // filters: {
          //   filterName: 'User Role',
          //   filterType: columnFilterTypes.options,
          //   filterPlaceholder: 'All Roles',
          //   optionSelected: null,
          //   filterOptions: [
          //     {
          //       value: UserRoles.registered,
          //       text: 'User'
          //     },
          //     {
          //       value: UserRoles.company_administrator,
          //       text: 'Administrator'
          //     },
          //     {
          //       value: UserRoles.company_owner,
          //       text: 'Owner'
          //     }
          //   ]
          // },
        },
        {
          columnName: 'fullName',
          columnText: 'Full Name',
          columnType: ColumnTypes.text,
          sortable: true,
          sortColumnName: 'fullName',
          filters: null,
          columnOrder: 1,
          canShowHide: true,
        },
        {
          columnName: 'email',
          columnText: 'Email',
          columnType: ColumnTypes.text,
          sortable: true,
          sortColumnName: 'email',
          filters: null,
          columnOrder: 2,
          canShowHide: true,
          // filters: {
          //   filterName: 'User Role',
          //   filterType: columnFilterTypes.options,
          //   filterPlaceholder: 'All Roles',
          //   optionSelected: null,
          //   filterOptions: [
          //     {
          //       value: UserRoles.registered,
          //       text: 'User'
          //     },
          //     {
          //       value: UserRoles.company_administrator,
          //       text: 'Administrator'
          //     },
          //     {
          //       value: UserRoles.company_owner,
          //       text: 'Owner'
          //     }
          //   ]
          // },
        },
        {
          columnName: 'jobTitle',
          columnText: 'Job Title',
          columnType: ColumnTypes.text,
          sortable: true,
          sortColumnName: 'jobTitle',
          filters: null,
          columnOrder: 5,
          notVisible: true,
          canShowHide: true,
        },
        {
          columnName: 'roles',
          columnText: 'Role',
          columnType: ColumnTypes.user_role,
          columnClasses: 'user-roles-column',
          sortable: false,
          filters: {
            filterId: 'roles',
            filterName: 'User Role',
            filterType: columnFilterTypes.options,
            filterPlaceholder: 'All Roles',
            optionSelected: null,
            filterOptions: [
              {
                value: UserRoles.registered,
                text: 'User'
              },
              {
                value: UserRoles.company_administrator,
                text: 'Administrator'
              },
              {
                value: UserRoles.company_owner,
                text: 'Owner'
              }
            ]
          },
          columnOrder: 3
        },
        {
          columnName: 'phone',
          columnText: 'Phone',
          columnType: ColumnTypes.text,
          sortable: false,
          sortColumnName: '',
          filters: null,
          columnOrder: 4,
          notVisible: true,
          canShowHide: true,
          displayFunction: 'getPhoneNumber'
        },
        {
          columnName: 'info',
          columnText: '',
          columnType: ColumnTypes.info,
          sortable: false,
          filters: null
        },
        {
          columnName: 'action',
          columnText: 'Actions',
          columnType: ColumnTypes.buttons,
          columnClasses: 'fit-content',
          sortable: false,
          columnOrder: 10,
          filters: null,
          buttons: [
            // {
            //   buttonText: 'Info',
            //   value: null,
            //   type: ButtonTypes.info
            // },
            {
              buttonText: 'Promote to Company Admin',
              value: null,
              type: ButtonTypes.user,
              subType: 'promote-user',
              permission: 'canPromoteAdmin',
              userRole: [UserRoles.registered],
              buttonCustomClass: 'promote-user green'
            },
            {
              buttonText: 'Demote from Company Admin',
              value: null,
              type: ButtonTypes.user,
              subType: 'demote-user',
              permission: 'canDemoteAdmin',
              userRole: [UserRoles.company_administrator],
              buttonCustomClass: 'demote-user red'
            },
            {
              buttonText: 'Remove from Company',
              value: null,
              type: ButtonTypes.user_remove,
              permission: 'canRemoveUser',
              userRole: [UserRoles.registered, UserRoles.company_administrator],
              buttonCustomClass: 'red'
            }
          ]
        }
      ];
    }

    setApiDataType(): void {
      type listApiDataType = ApiUser;
    }

  updateListParams(): void {
    this.listParameters = {
      numberOfPages: 1,
      currentPage: 1,
      itemsPerPage: 10,
      filters: [],
      sorting: {
        field: "fullName",
        order: sortingOrder.asc
      }
    }
  }

  buttonClick(data: {}): void {
  }

  getUserInfo(): void {

  }

  getUserDetails(userId: string): any {
    return this.rawListData.find(x => x.rowIdentifier === userId).listRow;
  }

  NewMapData(resultsData: listApiDataType[], columns: NgListColumns[]): NgListDataRow[] {
    let rows: NgListDataRow[] = [];

    resultsData.forEach(resultRow => {
      let row = <NgListDataRow>{};
      row['listRow'] = [];
      // loop through columns and assign value from resultsData based on mapValue property
      // same for buttons
    });

    return rows;
  }

  getPhoneNumber(p: any): string {
    if (p) {
      let number = JSON.parse(p);
      if(number) {
        if(number.nationalNumber) {
          return `(${number.countryCode}) ${number.dialCode} ${number.nationalNumber}`;
        } else {
          return `(${number.countryCode}) ${number.dialCode} ${number.number}`;
        }
      } else {
        return '';
      }

    } else {
      return '';
    }
  }

  MapData(resultsData: listApiDataType[], columns: NgListColumns[]): NgListDataRow[] {
    let rows: NgListDataRow[] = [];
    resultsData.forEach(resultRow => {
      let row = <NgListDataRow>{};
      row['listRow'] = [];
      Object.keys(resultRow).forEach(key => {
        let element = <NgListDataCell>{};
        let column = columns.find(x => x.columnName === key);

        if(column) {
          element.columnName = column.columnName;
          element.columnOrder = column.columnOrder;
          element.type = column.columnType;
          if(column.notVisible) element.notVisible = true;

          if(column.columnType === ColumnTypes.buttons) {
            element.buttons = [];
            column.buttons?.forEach(btn => {
              let cellBtn = <NgListButton>{};
              cellBtn.buttonText = btn.buttonText;
              cellBtn.type = btn.type;
              // cellBtn.value = resultRow.code;
              element.buttons.push(cellBtn);
            });
          } else {
            let tempValue = resultRow;
            if(column.customMapping) {
              column.customMapping.forEach(x => {
                tempValue = tempValue[x];
              });
              element.value = tempValue;
              element.display = tempValue.toString();
            } else {
              element.value = resultRow[key];
              element.display = resultRow[key];
            }

          }
          row.listRow.push(element);
        }
      });
      row.listRow.sort((a, b) => a.columnOrder - b.columnOrder );
      // row.rowIdentifier = resultRow.code;
      rows.push(row);
    });
    return rows;
  }

  MapParameters(params: Object): any {
    return null;
  }


  async LoadTableData(fromApi = false):Promise<void> {
    setTimeout(() => {
      this.loadResultsStarted.emit();
    }, 100);

    this.setApiName();

    await this.apiService[this.ApiFunctionName]((this.ApiParameter ? this.ApiParameter : null)).toPromise().then(
      (results:listApiDataType[]) => {
        this.apiResutls = results['membersList'] || [];
        results['companyAdministrators']?.forEach(ca => {
          ca.phone = null;
          ca.username = (ca.roles.includes('company_owner') ? '* Company Owner' : '* Company Administrator');
          this.apiResutls.push(ca);
        });
        if(results['companyOwner'] && !this.apiResutls.find(u => u.id === results['companyOwner'].id) ) {
          results['companyOwner'].phone = null;
          results['companyOwner'].username = '* Company Owner';
          this.apiResutls.push(results['companyOwner']);
        }
        if (!this.settings.impersonatedCompany) this.apiResutls.push(results['user']);
        this.hardLoadTimestamp = new Date();
        this.rawListData = this.MapDataNew<listApiDataType>(this.apiResutls,this.columns);
        this.rawListData.forEach(row => {
          let uName = row.listRow.find(col => col.columnName === 'username');
          if (uName && uName.value === "* Company Owner") {
            uName.display = '<i>Company Owner</i>';
            uName.tdHtml = true;
          }
          if (uName && uName.value === "* Company Administrator") {
            uName.display = '<i>Company Administrator</i>';
            uName.tdHtml = true;
          }
        });

      }
    );
    let unorderedList = this.inlineFilter(this.rawListData, this.listParameters.filters);
    this.listParameters.numberOfPages = Math.ceil(unorderedList.length / this.listParameters.itemsPerPage);
    this.listData = this.inlineSort(unorderedList, this.listParameters.sorting.field, this.listParameters.sorting.order);

    setTimeout(() => {
      this.loadResultsEnded.emit();
      this.parametersUpdated.emit(this.listParameters);
    }, 200);
  }
}
