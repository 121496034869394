import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from 'projects/nine-gold-lib/src/public-api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  public userInfoEditablePreview = false;

  constructor(private http: HttpClient, private notif: NotificationService) { }

  public getSubUsers(): void {

  }


}
