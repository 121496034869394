import { Component, OnInit, Renderer2, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faClone } from '@fortawesome/free-regular-svg-icons';
import { faPhoneSquare, faUser, faUsers, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { InputTypes } from 'projects/nine-gold-lib/src/lib/models/formField';
import { ApiUser, ChangeTypes, UserTypesData, UserUpdateData } from 'projects/nine-gold-lib/src/lib/models/user';
import { ApiService } from 'projects/nine-gold-lib/src/lib/services/api.service';
import { AuthService } from 'projects/nine-gold-lib/src/lib/services/auth.service';
import { FormsService } from 'projects/nine-gold-lib/src/lib/services/forms.service';
import { UserInterfaceService } from 'projects/nine-gold-lib/src/lib/services/user-interface.service';
import { UserService } from 'projects/nine-gold-lib/src/lib/services/user.service';
import { ButtonTypes } from 'projects/nine-gold-lib/src/lib/shared/listComponents/ng-list/ng-list-data';
import { FormResultMessageFormat } from 'projects/nine-gold-lib/src/lib/shared/notification/notification';
import { ConfirmDialogService, NotificationService } from 'projects/nine-gold-lib/src/public-api';
import { InvitesListService } from '../services/invites-list.service';
import { UsersListService } from '../services/users-list.service';
import { UserManagementService } from '../user-management.service';

@Component({
  selector: 'app-subusers',
  templateUrl: './subusers.component.html',
  styleUrls: ['./subusers.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubusersComponent implements OnInit {
  usersManagementIcon = faUsers;
  user: ApiUser;
  userPermissions: {} = null;
  modalRef: BsModalRef;

  loading = false;

  // Icons for template
  userIcon = faUser;
  phoneIcon = faPhoneSquare;
  emailIcon = faPaperPlane;
  copyIcon = faClone;

  // New Invitation formm data
  inviteForm: FormGroup;
  inviteFormLoading = false;
  initialFormValues = {};

  // email field
  email: any =  [{
    errorMessagesList: {
      email: "Wrong Email format."
    },
    inputName: "email",
    id: "emailId",
    placeholder: "Invitee Email Address",
    label: "Invite new user",
    value: "",
    inputType: InputTypes.email,
    validationRules: [
      {
        type: "email",
        message: "Wrong Email format",
        value: true
      }
    ]
  }];


  // Invitation details modal
  @ViewChild('inviteDetails')
    private inviteDetails: TemplateRef<any>;

  inviteDetailsData: any;
  // TODO: replace any with ApiInvite (type - create it first)


  // User details modal
  @ViewChild('userDetails')
    private userDetails: TemplateRef<any>

  userDetailsData: ApiUser = null;
  userDetailsRoleIcon: UserTypesData;


  constructor(
    private fb: FormBuilder,
    private rd: Renderer2,
    private formsService: FormsService,
    private notif: NotificationService,
    private confirmService: ConfirmDialogService,
    private userManagement: UserManagementService,
    private authService: AuthService,
    private apiService: ApiService,
    private uiService: UserInterfaceService,
    public usersListService: UsersListService,
    public invitesListService: InvitesListService,
    public userService: UserService
  ) {

  }

  ngOnInit(): void {
    this.userService.userUpdated.subscribe(
      (user) => {
        this.user = user;
        this.userPermissions = this.userService.getPermissions();
      }
    );
    this.user = this.userService.getUserInfo();

    this.inviteForm = this.fb.group(this.formsService.convertToReactiveForm(this.email));
    this.initialFormValues = this.inviteForm.value;

    this.usersListService.LoadPage(1, true);

    if(this.userService.currentUser) {
      if(this.userService.userPermissions['canViewInvitations']) {
        this.invitesListService.LoadPage(1,true);
      }
    } else {
      this.userService.userUpdated.subscribe(user => {
        if(this.userService.userPermissions['canViewInvitations']) {
          this.invitesListService.LoadPage(1,true);
        }
      });
    }

  }


  // openModal(template: TemplateRef<any>) {
  //   this.modalRef = this.modalService.show(template);
  // }



  // copy text to clipboard
  // TODO: maybe move to UI service
  private textToClipboard (text) {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    // TODO: find altertative to execCommand above
    document.body.removeChild(dummy);
  }


  // button click action used for userListButtonClick
  private buttonAction(userId: string, confirmName: string, apiFunction: string, notificationName: string, change?: UserUpdateData): void {
    let userData = this.usersListService.GetRawDataById('id' ,userId);
    let username = userData?.username;
    let company = this.userService.getCompanyName();
    this.confirmService.confirm(this.uiService.getConfirmMessage(confirmName, [username, company]))
      .subscribe((answer) => {
        if(answer) {
          if(apiFunction === 'updateUser') {
            this.userService.updateUser(change,userData).subscribe({
              next: x => {
                if(x) {
                  this.notif.showNotification(this.uiService.getNotificationMessage(notificationName,[username,company]));
                  this.usersListService.LoadTableData(true);
                }
              },
              error: error => console.log(error)
            });
          } else {
            this.apiService[apiFunction](userId).subscribe({
              next: x => {
                if(x) {
                  this.notif.showNotification(this.uiService.getNotificationMessage(notificationName));
                  this.usersListService.LoadTableData(true);
                }
              },
              error: error => console.log(error)
            });
          }

        }
      });
  }



  /* PUBLIC FUNCTIONS */

  // Template click functions

  // Invites table - click on Action buttons
  invitesListButtonClick(data: {}): void {
    if(data['button'].type === ButtonTypes.info) {
      let code = data['row'].rowIdentifier;

      this.inviteDetailsData = this.invitesListService.getInviteDetails(code);
      this.notif.info('Invitation Details', "", FormResultMessageFormat.modalHtml, 0, this.inviteDetails);

    } else if(data['button'].type === ButtonTypes.delete) {
      this.confirmService.confirm(this.uiService.getConfirmMessage('removeInvite'))
        .subscribe((answer) => {
          if(answer) {
            data['button'].loading = true;
            let code = data['row'].rowIdentifier;
            let inviteDetails = this.invitesListService.getInviteDetails(code);
            this.apiService.deleteInvite(code).subscribe({
              next: x=>{
                data['button'].loading = false;
                this.notif.showNotification(this.uiService.getNotificationMessage('invitationRemoved',[inviteDetails.email]));
                this.invitesListService.LoadTableData(true);
              },
              error: err=>{console.log(err)}
            });

          }
        }
      );
    } else if(data['button'].type === ButtonTypes.resend_invite) {
      let code = data['row'].rowIdentifier;
      this.inviteDetailsData = this.invitesListService.getInviteDetails(code);
      this.sendInvitation(this.inviteDetailsData.email, true);
    }
  }


  // Users table - click on Action buttons
  usersListButtonClick(data: {}): void {
    if(data['button'].type === ButtonTypes.info) {

      // this.loading = true;

      this.userDetailsData = this.usersListService.apiResutls.find(urs => urs.id === data['row'].rowIdentifier);
      this.userDetailsData.initials = this.userService.setInitials(this.userDetailsData.firstName, this.userDetailsData.lastName);
      if(this.userDetailsData.username.startsWith('*')) {
        this.userDetailsData.username = '<i>' + this.userDetailsData.username.substring(1) + '</i>';
      }
      // this.apiService.getUser(data['row'].rowIdentifier).subscribe((user:ApiUser) => {
        // this.userDetailsData = user;
        // this.userDetailsData.initials = this.userService.setInitials(user.firstName, user.lastName);

        // TODO: replace with user service function once it's created
        if (this.userDetailsData.phone) {
          let p = JSON.parse(this.userDetailsData.phone);
          this.userDetailsData.rawPhone = p.e164Number;
          if(p.nationalNumber) {
            this.userDetailsData.displayPhone = `(${p.countryCode}) ${p.dialCode} ${p.nationalNumber}`;
          } else {
            this.userDetailsData.displayPhone = `(${p.countryCode}) ${p.dialCode} ${p.number}`;
          }

        }

        this.userDetailsRoleIcon = this.userService.getHighestRoleFromArray(this.userDetailsData.roles);
        // this.loading = false;


        this.notif.info('', "", FormResultMessageFormat.modalHtml, 0, this.userDetails);
      // });

    } else if(data['button'].type === ButtonTypes.user_remove) {

      this.buttonAction(data['row'].rowIdentifier, 'removeUser', 'removeUser','userRemoved');

    } else if(data['button'].type === ButtonTypes.user && data['button'].subType === 'promote-user') {

      this.buttonAction(data['row'].rowIdentifier,'promoteUser','updateUser','userPromoted',{propertyName: 'roles', value: 'company_administrator', changeType: ChangeTypes.add});

    } else if(data['button'].type === ButtonTypes.user && data['button'].subType === 'demote-user') {

      this.buttonAction(data['row'].rowIdentifier,'demoteUser','updateUser','userDemoted',{propertyName: 'roles', value: 'company_administrator', changeType: ChangeTypes.remove});

    }
  }


  // Creates InviteLink when clicked in the invite detail button
  // !To be removed or archived, once the functionality is in the back end
  public createInviteLink(code: string, invitee: string): void {
    let companyName = this.user.company.name;
    let link = this.authService.getInviteResponseLink(code, companyName, this.user.fullName, invitee);
    this.textToClipboard(link);
  }

  // copy invitation code to clipboard
  protected copyToClipboard(code: string): void {
    this.textToClipboard(code);
    this.notif.info("Copied to clipboard", "The CODE was copied to clipboard", FormResultMessageFormat.popup, 3000);
  }


  /* New invite form actions */

  // create new invitation
  inviteNewUser(): void {
    this.sendInvitation(this.inviteForm.value['email'], false)
    // this.inviteFormLoading = true;
    // this.userManagement.inviteUser(this.inviteForm.value['email']).subscribe(
    //   (result: any) => {
    //     this.inviteFormLoading = false;
    //     this.notif.success("Invite Sent", `Your Invitation was successfully sent to ${this.inviteForm.value['email']}.`, FormResultMessageFormat.popup, 3000, null);
    //     this.clearInvitationForm();
    //     this.invitesListService.LoadTableData(true);
    //     const element = this.rd.selectRootElement('#' + this.email[0].id);
    //     element.focus();
    //   }
    // );
  }

  sendInvitation(email: string, resend: boolean): void {
    let title = (resend ? 'Invitation Resent' : 'Invitation Sent')
    let message = (resend ? `Your Invitation was successfully sent again to ${email}.` : `Your Invitation was successfully sent to ${email}.`)
    this.inviteFormLoading = true;
    this.apiService.createInvite(email).subscribe(
      (result: any) => {
        this.inviteFormLoading = false;
        this.notif.success(title, message, FormResultMessageFormat.popup, 6000, null);
        this.clearInvitationForm();
        this.invitesListService.LoadTableData(true);
        const element = this.rd.selectRootElement('#' + this.email[0].id);
        element.focus();
      }
    );
  }

  // clear the form
  clearInvitationForm(): void {
    this.inviteForm.reset(this.initialFormValues);
    this.inviteForm.markAsPristine();
    this.inviteForm.markAsUntouched();
  }


}
