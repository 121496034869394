<ng-template>
  <header>
    <div class="header-outer">
      <div class="header-inner">
        <h2 (click)="selectTab(item)">
          <ng-content></ng-content>
        </h2>
      </div>
    </div>
  </header>
</ng-template>