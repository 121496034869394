import { DatePipe } from '@angular/common';
import { AfterContentInit, AfterViewInit, Component, Inject, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { faCalendarAlt, faClone, faIdCard } from '@fortawesome/free-regular-svg-icons';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ApiLicenseExtended, ApiLicenseStatus, ApiLicenseType, ApiLicenseTypeExtended, ApiProduct, IProductExtendedNew, LicenseTypes } from 'projects/nine-gold-lib/src/lib/models/product';
import { AppSettingsService } from 'projects/nine-gold-lib/src/lib/services/app-settings.service';
import { ProductService } from 'projects/nine-gold-lib/src/lib/services/product.service';
import { UserInterfaceService } from 'projects/nine-gold-lib/src/lib/services/user-interface.service';
import { ButtonTypes, TypesOfCompare } from 'projects/nine-gold-lib/src/lib/shared/listComponents/ng-list/ng-list-data';
// import { ButtonTypes } from 'projects/nine-gold-lib/src/lib/shared/ng-list/ng-list-data';
import { FormResultMessageFormat } from 'projects/nine-gold-lib/src/lib/shared/notification/notification';
import { ConfirmDialogService, NotificationService } from 'projects/nine-gold-lib/src/public-api';
import { NewLicenseListService } from '../services/new-license-list.service';
import { ProductListService } from '../services/product-list.service';
import { formatDistance, formatDuration, intervalToDuration, subDays } from 'date-fns'
import { ApiService } from 'projects/nine-gold-lib/src/lib/services/api.service';
import { UserService } from 'projects/nine-gold-lib/src/lib/services/user.service';
// import { ApiProduct } from 'dist/nine-gold-lib/lib/models/product';


@Component({
  selector: 'app-user-license',
  templateUrl: './user-license.component.html',
  styleUrls: ['./user-license.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {provide: 'licenseList1', useClass: NewLicenseListService},
    {provide: 'licenseList2', useClass: NewLicenseListService}
  ]
})
export class UserLicenseComponent implements OnInit, AfterContentInit, AfterViewInit {

  loading = false;

  selectedLicensePeriod: string = '';

  detailsLicenseType: string;
  detailsShowCounter: boolean;

  @ViewChild('licenseDetails')
    private licenseDetails: TemplateRef<any>;

  @ViewChild('licenseDetailsNew')
    private licenseDetailsNew: TemplateRef<any>;

  @ViewChild('productDetails')
    private productDetails: TemplateRef<any>;

  faLicenseIcon = faIdCard;
  copyIcon = faClone;

  licenseDetailsData: IProductExtendedNew = null;
  licenseDetailsDataNew: ApiLicenseExtended = null;

  productDetailsData: ApiProduct = null;

  expireInDays: number;
  daysString: string;
  expireInHours: number;
  hoursString: string;
  expireInMinutes: number;
  minutesString: string;
  licenseEndsDate: Date;
  licenseStartsDate: Date;

  bsConfig?: Partial<BsDatepickerConfig>;

  calendarIcon = faCalendarAlt;

  bsRangeValue: Date[];

  usedProductsVisible = true;

  licenseListsReady = false;

  constructor(
    private router: Router,
    private datePipe: DatePipe,
    @Inject('licenseList1') public newLicenseList1: NewLicenseListService,
    @Inject('licenseList2') public newLicenseList2: NewLicenseListService,
    public productListService: ProductListService,
    private uiService: UserInterfaceService,
    public date: DatePipe,
    public productService: ProductService,
    private notif: NotificationService,
    private settings: AppSettingsService,
    private apiService: ApiService,
    private userService: UserService,
    private confirmService: ConfirmDialogService
    ) {
      this.newLicenseList1.inputStatus = [ApiLicenseStatus.valid,ApiLicenseStatus.free];
      this.newLicenseList2.inputStatus = [ApiLicenseStatus.expired];
      // this.licenseListsReady = true;
   }

  ngOnInit(): void {
    this.bsConfig = { containerClass: 'theme-dark-blue', isAnimated: true, displayMonths: 1 };
  }

  ngAfterViewInit(): void {
    // this.newLicenseList1.inputStatus = [ApiLicenseStatus.valid,ApiLicenseStatus.free];
    setTimeout(() => {
      this.licenseListsReady = true;
    }, 100);

    this.newLicenseList1.LoadPage(1,this.settings.impersonatedCompany!==null);
    // this.newLicenseList2.inputStatus = [ApiLicenseStatus.expired];
    this.newLicenseList2.LoadPage(1,this.settings.impersonatedCompany!==null);
    this.productListService.listParameters.filters.push({field: 'hasLicense', value: false, type: TypesOfCompare.equal});
    this.productListService.LoadPage(1);
  }

  ngAfterContentInit(): void {
    // this.newLicenseList1.inputStatus = [ApiLicenseStatus.valid,ApiLicenseStatus.free];
    // this.newLicenseList1.LoadPage(1);
    // this.newLicenseList2.inputStatus = [ApiLicenseStatus.expired];
    // this.newLicenseList2.LoadPage(1);
    // this.productListService.listParameters.filters.push({field: 'hasLicense', value: false});
    // this.productListService.LoadPage(1);
  }

  licenseButtonClicked(data: {}): void {
    if(data['button'].type === ButtonTypes.info) {
      let licenseId = data['row'].rowIdentifier;
      this.licenseDetailsDataNew = this.productService.getLicenseById(licenseId);
      console.log(this.licenseDetailsDataNew);

      // this.licenseDetailsDataNew.licensePeriod = JSON.parse(this.licenseDetailsDataNew.licensePeriodString);

      if(this.licenseDetailsDataNew.licenseParams.type !== ApiLicenseType.FreeLicense) {

        if(this.licenseDetailsDataNew.licenseParams.type !== ApiLicenseType.UsageLicense) {
          this.selectedLicensePeriod = formatDuration(this.licenseDetailsDataNew.licensePeriod);
          this.licenseEndsDate = new Date(this.licenseDetailsDataNew.end);
          this.detailsLicenseType = `<span class="license-type">${this.uiService.licenseTypesDropdown[this.licenseDetailsDataNew.licenseParams.type].text}</span>
         ${this.validForText(this.licenseDetailsDataNew.end, this.licenseDetailsDataNew.status, true)}`;
        } else {
          this.detailsLicenseType = `<span class="license-type">${this.uiService.licenseTypesDropdown[this.licenseDetailsDataNew.licenseParams.type].text}</span>`;
        }
      }



      this.notif.info(this.licenseDetailsDataNew.productName, "", FormResultMessageFormat.modalHtml, 0, this.licenseDetailsNew);
    } else if(data['button'].type === ButtonTypes.quote) {
      let licenseId = data['row'].rowIdentifier;
      let licenseDetails = this.productService.getLicenseById(licenseId);
      if(licenseDetails) {
        this.router.navigate([{ outlets: { sidePopup: [ 'quote',licenseDetails.productId,'new' ] } }]);
      }
    }
    else if(data['button'].type === ButtonTypes.renew) {
      let licenseId = data['row'].rowIdentifier;
      let licenseDetails = this.productService.getLicenseById(licenseId);
      if(licenseDetails) {
        this.router.navigate([{ outlets: { sidePopup: [ 'quote',licenseDetails.productId,'renew' ] } }]);
      }
    }
  }

  toggleProductsInUse(): void {
    this.usedProductsVisible = !this.usedProductsVisible;
    if(!this.usedProductsVisible) {
      this.productListService.listParameters.filters = this.productListService.listParameters.filters.filter(el => el.field !== 'hasLicense');
    } else {
      this.productListService.listParameters.filters.push({field: 'hasLicense', value: false, type: TypesOfCompare.equal});
    }
    this.productListService.LoadPage(1);

  }

  private validForText(endDateString: string, status: string, html?: boolean): string {
    let text = '';
    var day = 1000 * 60 * 60 * 24;
    let endDate = new Date(endDateString);
    // let endDate = moment(endDateString);
    let  months = 0;
    let weeks = 0;
    let days = 0;
    let hours = 0;
    let minutes = 0;

    let now = new Date();
    // let now = moment();
    // let diff = Math.abs(endDate.getTime() - now.getTime());
    // days = Math.floor(diff/day);
    // months = Math.floor(days/31);



    // console.log(formatDistance(endDate, now));
    // console.log(intervalToDuration({start: endDate, end: now}))
    // console.log(formatDuration(intervalToDuration({start: endDate, end: now}),{format: ['years','months','days']}));
    // console.log(formatDuration(intervalToDuration({start: endDate, end: now}),{format: ['days']}));

    if(status !== 'expired') {
      let rD = intervalToDuration({start: endDate, end: now});
      this.detailsShowCounter = !rD.days && !rD.months && !rD.years && !rD.weeks;
      // text = `valid for another ${months} months and ${days} days`;
      text = (!html ? `valid for another ${formatDistance(endDate, now)}` : `valid for another <span class="time-date">${formatDistance(endDate, now)}</span>`);
      // console.log(text);
      let n = text.match(/\d+/);
      // console.log(text.substring(0,n.index));
      // console.log(text.substring(n.index,n.index + n[0].length));
      // console.log(text.substring(n[0].length + n.index));

      // console.log(text.match(/\d+/));

      text = text.substring(0,n.index) + "<span class='number'>" + text.substring(n.index,n.index + n[0].length) + "</span>" + text.substring(n[0].length + n.index);

    } else {
      // text = `expired for ${months} months and ${days} days`;
      text = `has expired ${formatDistance(now, endDate)} ago.`;
      this.detailsShowCounter = false;
    }
    return text;
  }





  productListButtonClick(data: {}): void {
    console.log('test');
    if(data['button'].type === ButtonTypes.info) {
      let productCode = data['row'].rowIdentifier;
      this.productDetailsData = this.productService.getProductCategoryById(productCode);
      // console.log(this.productDetails);
      this.notif.info(this.productDetailsData.name, "", FormResultMessageFormat.modalHtml, 0, this.productDetails);
      return;
      // console.log(this.licenseDetailsData);

      this.expireInDays = 0;
      this.expireInHours = 0;
      this.expireInMinutes = 0;

      let now = new Date();

      this.licenseEndsDate = new Date(this.licenseDetailsData.license_ends);
      this.licenseStartsDate = new Date(this.licenseDetailsData.license_starts);

      var diff = Math.abs(this.licenseEndsDate.getTime() - now.getTime());
      this.expireInDays = Math.floor(diff / (1000 * 3600 * 24));

      this.bsRangeValue = [this.licenseStartsDate, this.licenseEndsDate];

      if(this.expireInDays < 1) {
        this.expireInHours = Math.floor(diff / (1000 * 3600));
        this.hoursString = (this.expireInHours === 1 ? `${this.expireInHours} hour` : `${this.expireInHours} hours`);
        this.expireInMinutes = Math.floor(diff / (1000 * 60)) - (this.expireInHours * 60);
        this.minutesString = (this.expireInMinutes === 1 ? `${this.expireInMinutes} minute` : `${this.expireInMinutes} minutes`);



      } else if(this.expireInDays === 1) {
        this.daysString = `${this.expireInDays} day`;
      } else {
        this.daysString = `${this.expireInDays} days`;
      }

      this.notif.info(this.licenseDetailsData.name, "", FormResultMessageFormat.modalHtml, 0, this.licenseDetails);
    } else if(data['button'].type === ButtonTypes.renew || data['button'].type === ButtonTypes.upgrade || data['button'].type === ButtonTypes.quote) {

      this.router.navigate([{ outlets: { sidePopup: [ 'quote','restgen','new' ] } }]);

      // let link = `${this.productService.GetProductLink(data['row'].rowIdentifier)}/(sidePopup:quote)?returnUrl=%2F&pcode=${data['row'].rowIdentifier}`;
      // window.open(link, "_blank");
    } else if(data['button'].type === ButtonTypes.demo) {
      console.log(data['row'].listRow.find(col=>col.columnName === 'name')?.display)
      this.router.navigate([{ outlets: { sidePopup: [ 'demo',data['row'].listRow.find(col=>col.columnName === 'name')?.display ] } }]);
    } else if(data['button'].type === ButtonTypes.trial) {
      let productName = data['row'].listRow.find(col => col.columnName === "name")?.display;
      this.confirmService.confirm(this.uiService.getConfirmMessage('setTrial',[productName]))
        .subscribe((answer) => {
          if(answer) {

            let productCode = data['row'].rowIdentifier;
            this.loading = true;
            this.apiService.createTrialLicense(productCode,this.userService.currentUser.company.id).subscribe({
              next: data => {
                let endDate = this.datePipe.transform(data.end, 'fullDate');
                this.notif.success('Trial License Created',`You trial license just started and will end at <strong>${endDate}</strong>.`,FormResultMessageFormat.popup,12000,null,true);
                this.productService.licensesLoaded = false;
                this.productService.setLicensesFromApi();
                this.newLicenseList1.LoadPage(1,true);
                this.newLicenseList2.LoadPage(1,true);
                this.productListService.LoadPage(1,true);
                this.loading = false;
              },
              error: err => {}
            });

          }
        }
      );

    }
  }

  textToClipboard (text) {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
}

  copyToClipboard(code: string): void {
    this.textToClipboard(code);
    this.notif.info("Copied to clipboard", "PO number was copied to clipboard", FormResultMessageFormat.popup, 3000);
  }

  showQuoteForm(prCode: string): void {
    // let link = `${this.productService.GetProductLink('restgen')}/(sidePopup:quote)?returnUrl=%2F&pcode=${'restgen'}`;
    this.router.navigate([{ outlets: { sidePopup: [ 'quote',prCode,'new' ] } }]);
  }


}
