import { Injectable } from '@angular/core';
import { Action } from 'projects/nine-gold-lib/src/lib/models/action';
import { ListsService } from 'projects/nine-gold-lib/src/lib/services/lists.service';
import { ButtonTypes, ColumnTypes, ListUiParams, NgListColumns, NgListDataRow, sortingOrder } from 'projects/nine-gold-lib/src/lib/shared/listComponents/ng-list/ng-list-data';

type listApiDataType = Action;

@Injectable({
  providedIn: 'root'
})
export class ActionsListService extends ListsService {

  listData: NgListDataRow[] = [];
  rawListData: NgListDataRow[] = [];
  fakeData: listApiDataType[] = [];
  columns: NgListColumns[];

  uiSortFilterPage = true;

  listApiName = 'actionsList';
  ApiUrl: string;

  listParameters:ListUiParams = {
    numberOfPages: 1,
    currentPage: 1,
    itemsPerPage: 10,
    filters: [],
    sorting: {
      field: "when",
      order: sortingOrder.desc
    }
  }

// constructor() { }

  setApiName(): void {
    this.listApiName = 'currentCompanyActionsList';
    this.ApiFunctionName = 'getActionsList';
  }

  setSortSettings(): void {
    this.uiSortFilterPage = true;
  }

  setInitialColumns(): void {
    this.columns = [
      {
        columnName: 'id',
        columnText: 'Id',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,
        notVisible: true,
        rowIdentifier: true
      },
      {
        columnName: 'actionDescription',
        columnText: 'Action',
        columnType: ColumnTypes.text,
        sortable: true,
        filters: null,
        sortColumnName: 'actionDescription',
        clickForInfo: false
      },
      {
        columnName: 'userId',
        columnText: '',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,
        notVisible: true,
        customMapping: ['user','id']
      },
      {
        columnName: 'userName',
        columnText: 'Performed by',
        columnType: ColumnTypes.text,
        sortable: true,
        filters: null,
        customMapping: ['user','username'],
        sortColumnName: 'userName'
      },
      {
        columnName: 'outcome',
        columnText: 'Outcome',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null
      },
      {
        columnName: 'messageId',
        columnText: '',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,
        notVisible: true
      },
      {
        columnName: 'when',
        columnText: 'Date/Time',
        columnType: ColumnTypes.date,
        sortable: true,
        filters: null,
        sortColumnName: 'when'
      },
      {
        columnName: 'info',
        columnText: '',
        columnType: ColumnTypes.info,
        sortable: false,
        filters: null
      },
      {
        columnName: 'action',
        columnText: 'Actions',
        columnType: ColumnTypes.buttons,
        sortable: false,
        filters: null,
        buttons: [
          // {
          //   buttonText: 'Info',
          //   value: null,
          //   type: ButtonTypes.info
          // },
          {
            buttonText: 'User Info',
            value: null,
            type: ButtonTypes.user
          },
          {
            buttonText: 'Export',
            value: null,
            type: ButtonTypes.download
          }
        ]
      }
    ];
  }

  setApiDataType(): void {
    type listApiDataType = Action;
  }


  updateListParams(): void {
    this.listParameters = {
      numberOfPages: 1,
      currentPage: 1,
      itemsPerPage: 10,
      filters: [],
      sorting: {
        field: "when",
        order: sortingOrder.desc
      }
    }
  }

}
