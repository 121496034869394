<div *ngIf="!editMode && data" (click)="setToEdit()" tabindex="0" class="cell" [class.show-editable]="showEditable" (keydown.enter)="setToEdit()" (keydown.space)="setToEdit()">
  <span *ngIf="settings[0].inputType.toString() !== 'tel'">{{data}}</span>
  <span *ngIf="settings[0].inputType.toString() === 'tel'">{{displayPhone}}</span>

  <fa-icon [icon]="penIcon"></fa-icon>
</div>
<div *ngIf="!editMode && !data" class="add-button-container">
  <button (click)="setToEdit()" title="Add value" class="primary add-button">
    <fa-icon [icon]="addIcon"></fa-icon>
  </button>
</div>
<form class="inline-edit-form" *ngIf="editMode" [@enterAnimation] (keydown.esc)="cancel()" (keydown.shift.tab)="cancel()" (blur)="cancel()" (submit)="updateValue()" [formGroup]="inlineForm">
  <input
    *ngIf="!settings"
    type="text"
    class="form-control form-control-lg"
    id="{{fieldName}}Id"
    formControlName="inlineInput1"
    (keydown.esc)="cancel()">


    <lib-form-field *ngIf="settings && settings[0].inputType !== 'select'" [small]="true" [formGroupParameter]="inlineForm" [fieldSettings]="settings[0]"></lib-form-field>

    <ng-select
                #Selecter
                *ngIf="settings[0].inputType === 'select'"
                class="form-control form-control-lg"
                appearance="outline"
                placeholder="{{settings[0].placeholder}}"
                labelForId="settings[0].label"
                formControlName="{{settings[0].inputName}}"
                [ngClass]="{'is-invalid': (settings[0].errorMessages && settings[0].errorMessages.length > 0) || requiredInvalid() }" >
                <ng-option *ngFor="let option of settings[0].options" [value]="option.value">{{option.text}}</ng-option>

            </ng-select>


    <!-- <input
    *ngIf="settings"
    type="text"
    class="form-control form-control-lg"
    id="{{fieldName}}Id"
    [class.is-valid]="fieldValid === true"
    [ngClass]="{'is-invalid': (settings[0].errorMessages && settings[0].errorMessages.length > 0) || requiredInvalid() }"
    formControlName="{{settings[0].inputName}}"
    (keydown.esc)="cancel()"> -->

    <div class="buttons-container">
      <!-- <button  type="submit">Save</button> -->
      <button [disabled]="!inlineForm.valid || inlineForm.pristine" class="primary" type="submit">
        <fa-icon [icon]="saveIcon"></fa-icon>
        <!-- Save -->
      </button>
      <button type="button" (click)="cancel()" class="secondary" (keydown.tab)="cancel()">
        <fa-icon [icon]="cancelIcon"></fa-icon>
        <!-- Cancel -->
      </button>
    </div>
</form>
<!-- <input *ngIf='editMode' (focusout)="onFocusOut()" (keydown.enter)='$event.target.blur()' [(ngModel)]='data' class='cellInput'
  appAutofocus type='text' (keydown.enter)='$event.target.blur()' (focusout)="editMode=false"> -->
