<div class="sidebar-section">

    <div class="input-container toggle-container" [class.min]="">
      <input
        type="checkbox"
        class="toggle"
        id="toggleEditable"
        (click)="toggleFreeLicenses()"
        [checked]="companiesListService.hideFreeLicenses" />
      <label for="toggleEditable" class="toggle-label right-sided">
        <!-- <fa-icon [icon]="penIcon"></fa-icon> -->
        <span class="text">Hide free licenses</span>
      </label>
    </div>

  <!-- </div> -->

</div>

<div class="sidebar-section applied-filters-section mobile-sidebar-hidden">
  <lib-ng-applied-filters [listsService]="companiesListService"></lib-ng-applied-filters>
</div>

<div class="sidebar-section mobile-sidebar-hidden">
  <lib-ng-items-per-page [listsService]="companiesListService"></lib-ng-items-per-page>
  <lib-sort-select [id]="'download-sort-selector'" [listsService]="companiesListService"></lib-sort-select>

</div>
<div class="sidebar-section mobile-sidebar-hidden" *ngIf="companiesListService.resutlsText">
  <p class="results-summary" [innerHTML]="usersListService.resutlsText"></p>
</div>
