<!-- <div class="sidebar-section">
    <button class="secondary no-bg full-width dark-container">
        <fa-icon></fa-icon>
        <span class="text">New Invite</span>
    </button>
</div> -->
<div class="sidebar-section applied-filters-section mobile-sidebar-hidden">
    <lib-ng-applied-filters [listsService]="usersListService"></lib-ng-applied-filters>
</div>

<div class="sidebar-section mobile-sidebar-hidden">
    <lib-ng-items-per-page [listsService]="usersListService"></lib-ng-items-per-page>
    <lib-sort-select [id]="'download-sort-selector'" [listsService]="usersListService"></lib-sort-select>
    
</div>
<div class="sidebar-section mobile-sidebar-hidden" *ngIf="usersListService.resutlsText">
    <p class="results-summary" [innerHTML]="usersListService.resutlsText"></p>
</div>