<div class="page-with-footer">
    <header class="about-page-header">
        <!-- <div class="header-inner"> -->
          <!-- <h2 (click)="showVideo()">
              <span>Take a Tour of the Platform</span><fa-icon [icon]="playIcon"></fa-icon>

            <span class="first-line"><span>Demo</span><fa-icon [icon]="playIcon"></fa-icon></span>
            <span class="reflection"><span>Demo</span><fa-icon [icon]="playIcon"></fa-icon></span>
          </h2> -->
            <!-- <h2 (click)="showVideo()"><a ></a> Watch our demo here</h2>
            <div class="blueprint-video">
              <img src="/assets/images/DemoLink.jpg" alt="training video screenshot image" (click)="showVideo()">
            </div> -->
        <!-- </div> -->

    </header>
<section class="content-page centered-flex">
    <div class="inner">
        <!-- <h2>About</h2> -->
        <article>
            <p class="qoute">We joke Smart <strong>Code</strong> was inspired from laziness, but in fact it is a way of generating repeatable patterns.</p>
            <p class="floated-image">
                <!-- <img src="/assets/images/JulieRussell.jpg" alt="Julie Russell Photo"> -->
                Diamond Edge IT is proud to release Smart Code, developer tools designed for system integration projects. Smart Code is a patented innovation developed to address the concerns voiced by our 50+ customers who are dealing with deadline pressure and the constant upward costs of ownership.  Diamond Edge IT specializes in helping customers migrate their integrations, providing architectural reviews and risk assessments.  Our decentralized strategy puts the power back in the customer hands, giving them more control over their pocketbook.
            </p>
        </article>
    </div>
</section>
<section class="content-page centered-flex">
    <div class="team">
        <h3>Meet the Team.</h3>
        <ul class="human-resources-list">
          <li>
              <div class="pic-outer">
                  <div class="pic-inner">
                      <img src="/assets/images/JulieRussell.jpg" alt="picture of Julie Russell">
                  </div>
              </div>

              <h4 class="employee-name">Julie Russell</h4>
              <span class="job-title">CEO & Integration Architect</span>
          </li>
          <li>
              <div class="pic-outer">
                  <div class="pic-inner">
                      <img src="/assets/images/team/FilipWitkowski_whiteBG.png" alt="picture of Filip Witkowski">
                  </div>
              </div>
              <h4 class="employee-name">Filip Witkowski</h4>
              <span class="job-title">UI & Frontend Developer</span>
          </li>
          <li>
              <div class="pic-outer">
                  <div class="pic-inner">
                      <img src="/assets/images/Suleman_Bashir.png" alt="picture of Suleman Bashir">
                  </div>
              </div>
              <h4 class="employee-name">Suleman Bashir</h4>
              <span class="job-title">Spring Architect</span>
          </li>
          <li>
            <div class="pic-outer">
                <div class="pic-inner">
                    <img src="/assets/images/team/Muhammad_Noman.png" alt="picture of Muhammad Noman">
                </div>
            </div>
            <h4 class="employee-name">Muhammad Noman</h4>
            <span class="job-title">Full Stack Developer</span>
          </li>

          <li>
            <div class="pic-outer">
                <div class="pic-inner">
                    <img src="/assets/images/team//simon.jpg" alt="picture of Muhammad Noman">
                </div>
            </div>
            <h4 class="employee-name">Simon Falzon</h4>
            <span class="job-title">VP Operations</span>
          </li>

          <li>
            <div class="pic-outer">
                <div class="pic-inner">
                    <img src="/assets/images/team/cat.png" alt="picture of Muhammad Noman">
                </div>
            </div>
            <h4 class="employee-name">Cat Huegler</h4>
            <span class="job-title">Advisor</span>
          </li>

          <li>
            <div class="pic-outer">
                <div class="pic-inner">
                    <img src="/assets/images/team/kurt.jpg" alt="picture of Muhammad Noman">
                </div>
            </div>
            <h4 class="employee-name">Kurt Clesle</h4>
            <span class="job-title">Advisor</span>
          </li>
        </ul>
    </div>
</section>
<div class="video-popup-container" *ngIf="videoPopup" [@popupContainerAnimation] (click)="showVideo()">
    <div class="video-popup" [class.show]="popupInner">
        <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/IlQ3vRfd_f8?start=35&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
        <!-- <iframe width="560" height="315" src="https://youtube.com/embed/IlQ3vRfd_f8?t=35&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
        <!-- <iframe width="560" height="315" src="https://youtu.be/UZ4yQmucwMg?autoplay=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
        <iframe width="560" height="315" src="https://www.youtube.com/embed/YSBALJiEsBo?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
    <button (click)="ClosePopup()" class="close-popup-button close-button">close</button>
</div>
<!-- <lib-footer></lib-footer> -->
</div>
