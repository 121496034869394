import { Injectable } from '@angular/core';
import { ApiLicenseType, ApiProductExtended, ApiProductStatus } from 'projects/nine-gold-lib/src/lib/models/product';
import { ListsService } from 'projects/nine-gold-lib/src/lib/services/lists.service';
import { ButtonTypes, columnFilterTypes, ColumnTypes, ListUiParams, NgListDataRow, sortingOrder } from 'projects/nine-gold-lib/src/lib/shared/listComponents/ng-list/ng-list-data';

type listApiDataType = ApiProductExtended;

@Injectable({
  providedIn: 'root'
})
export class ProductListService extends ListsService{

  listParameters:ListUiParams = {
    numberOfPages: 1,
    currentPage: 1,
    itemsPerPage: 10,
    filters: [],
    sorting: {
      field: "name",
      order: sortingOrder.asc
    }
  }

  customOnInit(): void {
    this.productService.productsUpdated.subscribe(x => {
      let data = this.productService.getProductCategoriesList();
      this.setProductsFromData(data);
    });
  }

  setInitialColumns(): void {
    this.columns = [
      {
        columnName: 'id',
        columnText: '',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: null,
        columnOrder: null,
        // customMapping: ['id'],
        rowIdentifier: true,
        notVisible: true
      },
      {
        columnName: 'name',
        columnText: 'Product Name',
        columnType: ColumnTypes.text,
        sortable: true,
        sortColumnName: 'name',
        filters: null,
        columnOrder: 0,
        clickForInfo: false
      },
      {
        columnName: 'status',
        columnText: 'Type',
        columnType: ColumnTypes.text,
        sortable: false,
        filters: {
          filterId: 'status',
          filterName: 'type',
          filterType: columnFilterTypes.options,
          filterPlaceholder: 'All types',
          optionSelected: null,
          filterOptions: [
            {value: 'free', text: 'Free Tier'},
            {value: 'trial', text: 'Premium'}
          ]
        },
        displayFunction: 'setProductType'
      },
      // TODO: statusFromLicense needs to be updated based on licenses list, after they load
      // {
      //   columnName: 'statusFromLicense',
      //   columnText: 'Status',
      //   columnType: ColumnTypes.text,
      //   sortable: false,
      //   filters: null,
      //   columnOrder: 2,
      //   notVisible: false,
      //   canShowHide: true
      // },
      {
        columnName: 'hasLicense',
        columnText: 'Licensed',
        columnType: ColumnTypes.boolean,
        sortable: false,
        filters: null,
        columnOrder: null,
        notVisible: true
      },
      {
        columnName: 'trialUsed',
        columnText: '',
        columnType: ColumnTypes.boolean,
        notVisible: true,
        sortable: false,
        filters: null
      },
      // {
      //   columnName: 'demoUsed',
      //   columnText: '',
      //   columnType: ColumnTypes.boolean,
      //   notVisible: true,
      //   sortable: false,
      //   filters: null
      // },
      {
        columnName: 'info',
        columnText: '',
        columnType: ColumnTypes.info,
        sortable: false,
        filters: null
      },
      {
        columnName: 'actions',
        columnText: 'Actions',
        columnType: ColumnTypes.buttons,
        sortable: false,
        filters: null,
        buttons: [
          // {
          //   buttonText: 'Details',
          //   type: ButtonTypes.info,
          //   value: ''
          // },
          {
            buttonText: 'Start Trial',
            type: ButtonTypes.trial,
            value: '',
            showButtonFunction: 'canTrial',
            permission: 'CanGetTrial'
          },
          {
            buttonText: 'Get Quote',
            type: ButtonTypes.quote,
            value: '',
            showButtonFunction: 'canQuote'
          },
          {
            buttonText: 'Demo',
            type: ButtonTypes.demo,
            value: '',
            showButtonFunction: 'canQuote'
          }
          // TODO: add demo, renew, upgrade
          // TODO: add functions for CanTrial, CanDemo, CanQuote, CanDemo, CanRenew, CanUpgrade
        ]
      }
    ];
  }

  updateListParams(): void {
    this.listParameters = {
      numberOfPages: 1,
      currentPage: 1,
      itemsPerPage: 10,
      filters: [],
      sorting: {
        field: "name",
        order: sortingOrder.asc
      }
    }
  }

  setProductType(value: ApiProductStatus): string {
    return this.productService.getProductTypeText(value);
  }

  private setProductsFromData(data: listApiDataType[]):void {
    this.rawListData = this.MapDataNew(data,this.columns);
    let unorderedList = this.inlineFilter(this.rawListData, this.listParameters.filters);
    this.listData = this.inlineSort(unorderedList, this.listParameters.sorting.field, this.listParameters.sorting.order);

    setTimeout(() => {
      this.loadResultsEnded.emit();
      this.parametersUpdated.emit(this.listParameters);
    }, 200);

    let licenses = this.productService.getLicenseList();
    if(licenses.length > 0) {

    } else {
      this.productService.licensesUpdated.subscribe(x => {
        let licenses = this.productService.getLicenseList();

      });
    }
  }

  async LoadTableData(fromApi?: boolean): Promise<void> {
    let data: listApiDataType[] = [];
    data = this.productService.getProductCategoriesList();
    if(data.length > 0) {
      this.setProductsFromData(data);
    // } else {
    //   this.productService.productsUpdated.subscribe(x => {
    //     data = this.productService.getProductsListNew();
    //     // this.productService.productsUpdated.unsubscribe();
    //     console.log('products updated')
    //     this.setProductsFromData(data);
    //   });
    }
  }

  canTrial(row: listApiDataType): boolean {
    return row.canTrial;
  }

  canQuote(row: listApiDataType): boolean {
    // This is placeholder for child service implementation
    return row.canQuote;
  }

}
