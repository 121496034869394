import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { IFormField, InputTypes } from '../../../models/formField';
import { NotificationService } from '../../notification/notification.service';
import { FormsService } from '../../../services/forms.service';
import { ConfirmDialog } from '../../confirm-dialog/confirm-dialog';
import { ConfirmDialogService } from '../../confirm-dialog/confirm-dialog.service';
import { UserInterfaceService } from '../../../services/user-interface.service';
import { ApiService } from '../../../services/api.service';
import { UserService } from '../../../services/user.service';
import { FormResultMessageFormat } from '../../notification/notification';

@Component({
  selector: 'lib-ng-contact',
  templateUrl: './ng-contact.component.html',
  styleUrls: ['./ng-contact.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NgContactComponent implements OnInit {

  contactFormSettings: {} = {};
  contactForm: FormGroup;
  formReady = false;
  formCreated = false;

  loading = false;

  contactPreForm: IFormField[] = [
    {
      inputName: "fullName",
      id: "fullNameId",
      placeholder: "Your First and Last Name",
      label: "Full Name",
      value: "",
      inputType: InputTypes.text,
      countCharacters: true
    },
    {
      inputName: "phone",
      id: "phoneId",
      placeholder: "212 555 5555",
      label: "Phone Number",
      value: "",
      inputType: InputTypes.tel
    },
    {
      inputName: "email",
      id: "emailId",
      placeholder: "email@gmail.com",
      label: "Email Address",
      value: "",
      inputType: InputTypes.email
    },
    {
      inputName: "message",
      id: "messageId",
      placeholder: "Your message here...",
      label: "Your Message",
      value: "",
      inputType: InputTypes.textarea
    },
    {
      inputName: "captcha",
      id: "captchaId",
      label: "",
      value: "",
      inputType: InputTypes.recaptcha
    }
  ];


  private initialFormValues = {};

  _notificationSvc: NotificationService;

  constructor(
    private fb: FormBuilder,
    private formService: FormsService,
    private confirmService: ConfirmDialogService,
    private uiService: UserInterfaceService,
    private apiService: ApiService,
    private userService: UserService,
    private notif: NotificationService
  ) {
    this._notificationSvc = new NotificationService();

   }

  ngOnInit(): void {
    let settings = {};

    let form = this.formService.createForm('contact', this.contactPreForm);

    form.forEach(el => {
      this.contactFormSettings[el.inputName] = el;
    });

    this.contactForm = this.fb.group(this.formService.convertToReactiveForm(form));
    this.formReady = true;
    this.initialFormValues = this.contactForm.value;
    this.formCreated = true;
  }

  sendContactMessage():void {

    this.loading = true;
    let payload = new FormData();
    payload.append('type','contact');

    payload.append('contact.fullName',this.contactForm.value['fullName']);
    payload.append('contact.email',this.contactForm.value['email']);
    payload.append('contact.phone',JSON.stringify(this.userService.setDisplayPhone(this.contactForm.value['phone'])));
    payload.append('subject',`Contact message from ${this.contactForm.value['fullName']}`);

    let content = {message: this.contactForm.value['message']};
    payload.append('content',JSON.stringify(content));

    let messageBody = {
      contact: {
        fullName: this.contactForm.value['fullName'],
        phone: JSON.stringify(this.userService.setDisplayPhone(this.contactForm.value['phone'])),
        email: this.contactForm.value['email'],
      },
      type: 'contact',
      content: {
        message: this.contactForm.value['message']
      },
      subject: `Conact message from ${this.contactForm.value['fullName']}`
    }
    this.apiService.sendSupportMessage(payload).subscribe({
      next: result => {
        this.loading = false;
        this.notif.success('Message sent','Your message was sent successfully. You will be contacted back soon.',FormResultMessageFormat.modal,6000,null);
        setTimeout(() => {
          this.resetForm();
        }, 1000);
      },
      error: err => {console.log(err)}
    })
    // console.log('sending message');
  }

  resetForm(): void {
    this.contactForm.reset(this.initialFormValues);
    this.contactForm.markAsPristine();
    this.contactForm.markAsUntouched();
  }

  reset() {
    this.confirmService.confirm(this.uiService.getConfirmMessage('clearTheForm'))
      .subscribe((answer) => {
        if(answer) {
          this.resetForm();
        }
      });
  }

}
