import { DatePipe } from '@angular/common';
import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { addDays, formatDistance, formatDuration, intervalToDuration, isPast } from 'date-fns';
import { FormSummary } from 'projects/nine-gold-lib/src/lib/directives/form-summary/form-summary';
import { IFormField, InputTypes, ValidationRuleTypes } from 'projects/nine-gold-lib/src/lib/models/formField';
import { LicensePeriodType } from 'projects/nine-gold-lib/src/lib/models/product';
import { SimpleFilterPipe } from 'projects/nine-gold-lib/src/lib/pipes/simple-filter.pipe';
import { ApiService } from 'projects/nine-gold-lib/src/lib/services/api.service';
import { FormsService } from 'projects/nine-gold-lib/src/lib/services/forms.service';
import { ProductService } from 'projects/nine-gold-lib/src/lib/services/product.service';
import { UserService } from 'projects/nine-gold-lib/src/lib/services/user.service';
import { AccordionComponent } from 'projects/nine-gold-lib/src/lib/shared/accordion/accordion.component';
import { FormResultMessageFormat, NotificationPosition } from 'projects/nine-gold-lib/src/lib/shared/notification/notification';
import { NotificationService } from 'projects/nine-gold-lib/src/lib/shared/notification/notification.service';

@Component({
  selector: 'app-new-license-form',
  templateUrl: './new-license-form.component.html',
  styleUrls: ['./new-license-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewLicenseFormComponent implements OnInit, OnDestroy, AfterContentInit {

  @Output() closeForm = new EventEmitter;

  licenseFormSettings: {} = {};
  licenseForm: FormGroup;
  formReady = false;

  @Input() companyId: string;
  companyName: string;

  @Input() productCode: string = 'rest_generator';
  productName: string;

  @Input() action: string = 'Create';
  @Input() licenseId: string = '';

  loading = false;

  customGenerations = false;

  summaryPopupClosed = false;
  summaryPopupActive = false;

  customStartDate = false;
  customProjectLength = false;
  lengthType = 'predefined';

  lengthValueMin = 1;
  lengthValueMax = 10;

  enterpriseDeployment:{val:string,text:string} = {val:'cloud',text:'Cloud'};

  @ViewChild('ProjectAccordion')
    ProjectAccordion:AccordionComponent;

  @ViewChild('ProjectAccordionBody')
    private ProjectAccordionBody: TemplateRef<any>;

  @ViewChild('FreeAccordion')
    FreeAccordion:AccordionComponent;

  @ViewChild('FreeAccordionBody')
    private FreeAccordionBody: TemplateRef<any>;

  @ViewChild('UsageAccordion')
    UsageAccordion:AccordionComponent;

  @ViewChild('UsageAccordionBody')
    private UsageAccordionBody: TemplateRef<any>;

  @ViewChild('EnterpriseAccordion')
    EnterpriseAccordion:AccordionComponent;

  @ViewChild('EnterpriseAccordionBody')
    private EnterpriseAccordionBody: TemplateRef<any>;


  @ViewChild('submitConfirmation') submitConfirmation: TemplateRef<any>;
  @ViewChild('submitConfirmationFooter') submitConfirmationFooter: TemplateRef<any>;


  fieldsSummary: {[key:string]:{label: string,text:string,value:any,valid:boolean}} = {
    licenseType: {label: 'License Type',text:'',valid:false,value:''},
    deployment: {label: 'Deployment',text:'',valid:false,value:''},
    uses: {label: 'Uses',text:'',valid:false,value:0},
    startDate: {label: 'Start Date',text:'',valid:false,value:''},
    licenseLength: {label: 'License Length',text:'',valid:false,value:null},
    endDate: {label: 'End Date',text:'',valid:false,value:''},
    numberOfUsers: {label: 'Team Size',text: '', valid: false, value: ''}
  }

  fieldsSummaryNew: FormSummary[] = [
    {name: 'licenseType',label: 'License Type',text:'',valid:false,value:''},
    {name: 'uses',label: 'Uses',text:'',valid:false,value:0},
    {name: 'startDate',label: 'Start Date',text:'',valid:false,value:''},
    {name: 'endDate',label: 'End Date',text:'',valid:false,value:''},
    {name: 'licenseLength',label: 'License Length',text:'',valid:false,value:null},
    {name: 'numberOfUsers', label: 'Team Size', text: '', value:'', valid: true, hidden: true},
    {name: 'deployment',label: 'Deployment',text:'',valid:false,value:''}
  ];

  licensePreForm: IFormField[] = [
    {
      inputName: "productCode",
      id: "productCodeId",
      placeholder: "Select Product",
      label: "Product",
      value: "",
      disabled: true,
      inputType: InputTypes.select
    },
    {
      inputName: "licenseType",
      id: "licenseTypeId",
      placeholder: "Select Project or Enterprise License Type",
      label: "License Type",
      value: "",
      inputType: InputTypes.select
    },
    {
      inputName: "uses",
      id: "usesId",
      label: "Number of Uses",
      inputType: InputTypes.select,
      value: null,
      placeholder: "Choose number of uses."
    },
    {
      inputName: 'usesFreeField',
      inputType: InputTypes.number,
      id: 'usesFreeFieldId',
      value: '',
      placeholder: 'Custom uses number',
      label: 'Custom Uses',
      instruction: "Minimum is 1 and Maximum is 250."
    },
    {
      inputName: "licenseLength",
      id: "licenseLengthId",
      label: "Project Length",
      inputType: InputTypes.select,
      value: "",
      placeholder: "Choose a length"
    },
    {
      inputName: "startDate",
      id: "startDateId",
      placeholder: "Select License Start Date",
      label: "License Start Date",
      value: "",
      inputType: InputTypes.date,
      dateOptions: {
        enableWeekends: true
      }
    },
    {
      inputName: "lengthTerm",
      id: "lengthTermId",
      label: "Term",
      inputType: InputTypes.select,
      value: LicensePeriodType.month,
      placeholder: "Choose length term",
      required: false,
      requiredErrorMessage: 'Need to choose a term'
    },
    {
      inputName: "customLength",
      id: "customLengthId",
      label: "",
      inputType: InputTypes.select,
      placeholder:"",
      value: ""
    },
    {
      inputName: "lengthValue",
      id: "lengthValueId",
      label: "Number of days",
      inputType: InputTypes.number,
      required: false,
      placeholder: 'Put a number',
      requiredErrorMessage: "This is required.",
      errorMessagesList: [
        {min: "Minimum value is"},
        {max: "Maximum value is"}
      ],
      validationRules: [
        {
          type: ValidationRuleTypes.required,
          errorMessage: "Last Name is required",
          value: true
        },
        {
          type: ValidationRuleTypes.min,
          parameter: 1,
          errorMessage: "Min number is 1",
          value: true
        },
        {
          type: ValidationRuleTypes.min,
          parameter: 1,
          errorMessage: "Max number is 100",
          value: true
        }
      ],
      value: ""
    },
    {
      inputName: "numberOfUsers",
      id: "numberOfUsersId",
      label: "Number of Users",
      inputType: InputTypes.select,
      required: false,
      placeholder: "Choose number of users.",
      value: ""
    },
    {
      inputName: 'numberOfUsersFreeField',
      inputType: InputTypes.number,
      id: 'numberOfUsersFreeFieldId',
      value: '',
      placeholder: 'Custom team size',
      label: 'Custom Team Size',
      instruction: "Minimum is 1 and Maximum is 500."
    },
    {
      inputName: "endDate",
      id: "endDateId",
      placeholder: "Select License End Date",
      label: "License End Date",
      value: "",
      inputType: InputTypes.date,
      dateOptions: {
        enableWeekends: true,
        // minDate: new Date()
      }
    },
    {
      inputName: "deployment",
      id: "deploymentId",
      label: "Deployment",
      inputType: InputTypes.radio,
      value: ""
    },
    {
      inputName: "poNumber",
      id: "poNumberId",
      label: "PO Number",
      inputType: InputTypes.text,
      value: "",
      placeholder: "Purchase Order number"
    },
    {
      inputName: "invoiceNumber",
      id: "invoiceNumberId",
      label: "Invoice Number",
      inputType: InputTypes.text,
      value: "",
      placeholder: "Put paid invoice number."
    },
    {
      inputName: "notes",
      id: "notesId",
      label: "Notes",
      inputType: InputTypes.textarea,
      value: "",
      placeholder: "Notes for Global Admin only."
    }
  ];

  _notificationSvc: NotificationService;

  fieldsPerLicenseType: {[key:string]:{name:string,showSummary:boolean, summaryVal?:any, summaryText?:string, required:boolean}[]} = {
    ProjectLicense: [
      {name: 'startDate',showSummary: true,required: true},
      {name: 'endDate',showSummary: true,required: true},
      {name: 'licenseLength',showSummary: true,required: true},
      {name: 'numberOfUsers',showSummary: true,required: true, summaryText:'', summaryVal: null},
      {name: 'uses',showSummary: false,required: false},
      {name: 'deployment',showSummary: true,required: true,summaryVal:'cloud',summaryText:'Cloud'},
    ],
    UsageLicense: [
      {name: 'startDate',showSummary: true,required: true},
      {name: 'endDate',showSummary: false,required: false},
      {name: 'licenseLength',showSummary: false,required: false},
      {name: 'numberOfUsers',showSummary: false,required: false},
      {name: 'uses',showSummary: true,required: true},
      {name: 'deployment',showSummary: true,required: true,summaryVal:'cloud',summaryText:'Cloud'},
    ],
    EnterpriseLicense: [
      {name: 'startDate',showSummary: true,required: true},
      {name: 'endDate',showSummary: true,required: true},
      {name: 'licenseLength',showSummary: true,required: true},
      {name: 'numberOfUsers',showSummary: true,required: true, summaryText:'Unlimited', summaryVal: -1},
      {name: 'uses',showSummary: true,required: true, summaryText: 'Unmilited', summaryVal: -1},
      {name: 'deployment',showSummary: true,required: true}
    ],
    FreeLicense: [
      {name: 'startDate',showSummary: true,required: true},
      {name: 'endDate',showSummary: true,required: true},
      {name: 'licenseLength',showSummary: true,required: true},
      {name: 'numberOfUsers',showSummary: false,required: false},
      {name: 'uses',showSummary: false,required: false},
      {name: 'deployment',showSummary: true,required: true,summaryVal:'cloud',summaryText:'Cloud'},
    ]
  }

  constructor(
    private fb: FormBuilder,
    private formService: FormsService,
    private datePipe: DatePipe,
    private apiService: ApiService,
    private notif: NotificationService,
    public renderer: Renderer2,
    public productService: ProductService
    // TODO - check if public or private needed
  ) {
    this._notificationSvc = new NotificationService();
  }

  ngOnInit(): void {
    let form = this.formService.createForm('license',this.licensePreForm);

    form.forEach(el => {
      this.licenseFormSettings[el.inputName] = el;
    });

    let formReactive = this.formService.convertToReactiveForm(form);

    this.licenseForm = this.fb.group(formReactive);

    setTimeout(() => {
      this.summaryPopupActive = true;
    }, 2000);

    this.licenseFormSettings['licenseLength'].options = this.productService.getLicenseOptions();
    this.formReady = true;
  }


  ngAfterContentInit(): void {
    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.
    // console.log(this.productCode)
    if(this.productCode) {
      this.licenseForm.get('productCode').setValue(this.productCode);
      this.licenseForm.get('productCode').updateValueAndValidity();
      this.productName = this.licenseFormSettings['productCode'].options.find(o => o.value === this.productCode).text;
    }
    this.apiService.getCompany(this.companyId).subscribe(res=>{this.companyName = res.name});
    if(this.action==='renew' && !!this.licenseId) {
      this.apiService.getCompanyLicense(this.companyId,this.licenseId).subscribe(res=>{
        // console.log(res)
        this.licenseForm.get('licenseType').setValue(res.licenseParams.type);
        this.licenseForm.get('licenseType').updateValueAndValidity();
        this.LicenseTypeSelectet(res.licenseParams.type);
        // TODO: check for license Type
        // *  If end date is part, new start date left as  today
        if(!isPast(new Date(res.end))) {
          this.licenseForm.get('startDate').setValue(addDays(new Date(res.end),1));
          this.customStartDate = true;
          this.customStartDateUpdated();
          let length = intervalToDuration({end: new Date(res.end), start: new Date(res.start)});
          // console.log(length);
          if(!length.days && !length.weeks) {
            let months = 12*length.years + length.months;
            if ([3,6,9,12].includes(months)) {
              this.lengthType = 'predefined';
              this.toggleProjectLengthValue('predefined');
              this.licenseForm.get('licenseLength').setValue(JSON.stringify({"term":"months","value":months}));
              this.licenseForm.get('licenseLength').updateValueAndValidity();
              this.setLicenseDates();
              // TODO: set predefined length
            } else {
              this.lengthType = 'custom';
              this.toggleProjectLengthValue('custom');

              this.licenseForm.get('lengthValue').setValue(months);
              this.licenseForm.get('lengthValue').updateValueAndValidity();
              this.customProjectValue();
              // TODO: set custom term (no more than 24)
            }
          } else {
            // TODO: calculate end date from lenght (year, month, weeks, days)
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    // TODO: remove all event subscriptions and clean data
  }

  protected usersUpdated(value: any) {
    // console.log(this.licenseForm)
    if(!value) {
      this.updateFieldsSummary('numberOfUsers','','',false);
    } else {
      this.updateFieldsSummary('numberOfUsers',(value === -1 ? 'Unlimited' : value), value, true);
    }
  }

  public toggleSummaryPopup():void {
    this.summaryPopupClosed = !this.summaryPopupClosed;
  }

  private updateFieldsSummary(key: string, text: string, value: any, valid: boolean): void {
    this.fieldsSummaryNew.find(f=>f.name === key).text = text;
    this.fieldsSummaryNew.find(f=>f.name === key).value = value;
    this.fieldsSummaryNew.find(f=>f.name === key).valid = valid;
    this.fieldsSummary[key].valid = valid;
    this.fieldsSummary[key].text = text;
    this.fieldsSummary[key].value = value;
  }

  private hideFieldSummary(key: string): void {
    this.fieldsSummaryNew.find(f=>f.name === key).hidden = true;
  }

  private showFieldSummary(key: string): void {
    this.fieldsSummaryNew.find(f=>f.name === key).hidden = false;
  }

  UpdateProduct(event: any): void {
    this.productCode = event;
    this.productName = this.licenseFormSettings['productCode'].options.find(o => o.value === event).text;
  }


  private setRequired(required: boolean, fieldName: string, requiredErrorMessage?: string):void {
    if(required) {
      this.licenseFormSettings[fieldName].required = true;
      if(!this.licenseFormSettings[fieldName].requiredErrorMessage && requiredErrorMessage) {
        this.licenseFormSettings[fieldName].requiredErrorMessage = requiredErrorMessage;
      }
      this.licenseForm.get(fieldName).addValidators(Validators.required);
      this.licenseForm.get(fieldName).markAsUntouched();
    } else {
      this.licenseFormSettings[fieldName].required = false;
      this.licenseForm.get(fieldName).removeValidators(Validators.required);
    }
    this.licenseForm.get(fieldName).updateValueAndValidity();
  }

  protected usesUpdated(value: any): void {
    if(!value) {
      this.updateFieldsSummary('uses','',0,false);
    } else {
      this.updateFieldsSummary('uses',(value === -1 ? 'Unlimited':value),value,true);
    }
  }

  public toggleStartDateValue(value: string) {
    this.customStartDate = value === 'custom';
    if(this.customStartDate) {
      this.setRequired(true,'startDate');
      // this.quoteForm.get('startDate').addValidators(Validators.required);
      // this.quoteFormSettings['startDate'].required = true;
      if(this.licenseForm.get('startDate').invalid || this.licenseForm.get('startDate').pristine) {
        const element = this.renderer.selectRootElement('#startDateId');
        setTimeout(() => element.focus(), 10);
        setTimeout(() => element.click(), 50);
      }

    } else {

      this.setRequired(false,'startDate');
      // this.quoteFormSettings['startDate'].required = false;
      // this.quoteForm.get('startDate').removeValidators(Validators.required);
    }
    if(this.lengthType === 'endDate') {
      let date = new Date();
      date.setDate(this.licenseForm.value['startDate'].getDate() + 1);
      // console.log(date)
      this.licenseFormSettings['endDate'].dateOptions['minDate'] = date;
    }
    this.setLicenseDates();
    // this.quoteForm.get('startDate').updateValueAndValidity();
  }

  public customStartDateUpdated():void {
    this.setLicenseDates();
  }

  public customEndDateUpdated(): void {
    this.setLicenseDates();
  }

  toggleDeployment(val: string, text: string): void {
    this.enterpriseDeployment = {val: val, text: text};
    this.licenseForm.get('deployment').setValue(val);
    this.licenseForm.get('deployment').updateValueAndValidity();
    this.updateFieldsSummary('deployment',text,val,true);
  }

  setLicenseDates(setToAsap?: boolean):void {
    // console.log(this.licenseForm.get('licenseLength').value);

    let startDate:{value:string,text:string} = {value:"",text:""};
    let endDate:{value:string,text:string} = {value:"",text:""};
    let lengthObject:{term:string,value:number} = {term:"",value:0};

    if(setToAsap) {
      this.customStartDate = false;
      this.licenseForm.get('startDate').setValue(null);
    }

    if(this.customStartDate) {
      if(this.licenseForm.get('startDate').value) {
        let date = new Date(this.licenseForm.get('startDate').value);
        startDate.value = date.toISOString().split('.')[0]+"Z";
        startDate.text = this.datePipe.transform(date, 'MMM dd, yyyy');
      }

      this.updateFieldsSummary('startDate',startDate.text,startDate.value,!!this.licenseForm.get('startDate').value);

    } else {

      let today = new Date();
      startDate.value = today.toISOString().split('.')[0]+"Z";
      startDate.text = this.datePipe.transform(today, 'MMM dd, yyyy');

      this.updateFieldsSummary('startDate',startDate.text,startDate.value,true);

    }

    if(startDate.value) {
      this.licenseForm.get('startDate').setValue(new Date(startDate.value));
      this.licenseForm.get('startDate').updateValueAndValidity();

      if(this.lengthType === 'endDate') {
        if(this.licenseForm.get('endDate').value) {
          let tempDate = new Date(this.licenseForm.get('endDate').value);
          endDate.value = tempDate.toISOString().split('.')[0]+"Z";
          endDate.text = this.datePipe.transform(tempDate,'MMM dd, yyyy');
          let length = intervalToDuration({start: new Date(startDate.value),end: new Date(endDate.value)});
          if(length) {
            let lengthText = formatDuration(length, { format: ['years','months', 'weeks', 'days'] });
            this.updateFieldsSummary('licenseLength',lengthText,length,!!length);
          }

        }

      } else {
        lengthObject = this.setLicenseLenght();
        let tempDate = new Date(startDate.value);
        if(lengthObject.value && this.licenseForm.get('lengthTerm').valid) {
          switch(lengthObject.term) {
            case LicensePeriodType.day:
              tempDate.setDate(tempDate.getDate() + lengthObject.value);
              // add days
              break;
            case LicensePeriodType.week:
              tempDate.setDate(tempDate.getDate() + lengthObject.value * 7);
              // add weeks
              break;
            case LicensePeriodType.month:
              tempDate.setMonth(tempDate.getMonth() + lengthObject.value);
              // add months
              break;
            case LicensePeriodType.year:
              tempDate.setFullYear(tempDate.getFullYear() + lengthObject.value);
              // add years
              break;
          }
          endDate.value = tempDate.toISOString().split('.')[0]+"Z";
          endDate.text = this.datePipe.transform(tempDate, 'MMM dd, yyyy');
          this.licenseForm.get('endDate').setValue(new Date(endDate.value));
          this.licenseForm.get('endDate').updateValueAndValidity();

        }
      }



    } else {
      lengthObject = this.setLicenseLenght();
    }
    this.updateFieldsSummary('endDate',endDate.text,endDate.value,!!endDate.value);
  }

  private setLicenseLenght():{term:string,value:number} {
    let length = {text:"",value:""};
    let lengthObject:{term:string,value:number} = {term:"",value:0};
    if(this.lengthType === 'predefined') {
      length.value = this.licenseForm.get('licenseLength').value;
      if(length.value) {
        lengthObject = JSON.parse(length.value);
      }

    } else if(this.lengthType === 'custom') {
      lengthObject.value = +this.licenseForm.get('lengthValue').value;
      if(lengthObject.value && this.licenseForm.get('lengthValue').valid) {
        lengthObject.term = this.licenseForm.get('lengthTerm').value;
        length.value = JSON.stringify(lengthObject);
      }
    } else {

    }

    if(length.value) {
      length.text = `${lengthObject.value} ${this.productService.setTermWord(lengthObject.value,lengthObject.term)}`;
    }
    this.updateFieldsSummary('licenseLength',length.text,length.value,!!length.value);

    return lengthObject;
  }

  public customProjectLenghtSelected(value: string, setFocus = true):void {
    let term = this.productService.getLicenseTermSettings(value);

    this.licenseFormSettings['lengthValue'].label = term.valueLabel;

    this.licenseFormSettings['lengthValue'].min = term.min;
    this.licenseFormSettings['lengthValue'].max = term.max;
    this.lengthValueMin = term.min;
    this.lengthValueMax = term.max;
    this.licenseFormSettings['lengthValue'].placeholder = `${term.min} to ${term.max}`;
    this.licenseFormSettings['lengthValue'].instruction = `Number between ${term.min} & ${term.max}`;
    this.licenseFormSettings['lengthValue'].errorMessagesList['min'] = `Min ${term.min} number of ${term.type}s.`;
    this.licenseFormSettings['lengthValue'].errorMessagesList['max'] = `Max ${term.max} number of ${term.type}s.`;

    this.licenseForm.get('lengthValue').setValue(null);
    this.licenseForm.get('lengthValue').markAsPristine();
    this.licenseForm.get('lengthValue').markAsUntouched();
    this.licenseForm.get('lengthValue').updateValueAndValidity();

    if(setFocus) {
      const element = this.renderer.selectRootElement('#lengthValueId');
      setTimeout(() => element.focus(), 300);
    }
    this.setLicenseDates();

  }

  public customProjectValue():void {
      this.setLicenseDates();
  }

  toggleProjectLengthValue(value: string) {
    this.customProjectLength = value === 'custom';
    this.lengthType = value;

    this.setRequired(value === 'predefined','licenseLength','Project Length is required!');
    this.setRequired(value === 'custom','lengthValue','Project Length is required!');
    this.setRequired(value === 'endDate','endDate','Project Length is required!');

    if(value==='endDate') {
      let date = new Date(this.licenseForm.value['startDate']);
      date.setDate(date.getDate() + 1);
      // console.log(date)
      this.licenseFormSettings['endDate'].dateOptions['minDate'] = date;
      if(this.licenseForm.get('endDate').invalid || this.licenseForm.get('endDate').pristine) {
        const element = this.renderer.selectRootElement('#endDateId');
        setTimeout(() => element.focus(), 10);
        setTimeout(() => element.click(), 50);
      }
    }

    this.customProjectLenghtSelected(this.licenseForm.get('lengthTerm').value);

    this.setLicenseDates();
  }

  setEnterpriseDates():void {
    let startDate:{value:string,text:string} = {value:"",text:""};
    let endDate:{value:string,text:string} = {value:"",text:""};
    let lengthObject:{term:string,value:number} = {term:"",value:0};
    let length:{value:string,text:string} = {text:"",value:""};

    let date = new Date();
    startDate.value = date.toISOString();
    startDate.text = this.datePipe.transform(date, 'MMM dd, yyyy');

    lengthObject = {term: 'years', value: 1};
    length.value = JSON.stringify(lengthObject);
    length.text = `${lengthObject.value} ${this.productService.setTermWord(lengthObject.value,lengthObject.term)}`;

    date.setFullYear(date.getFullYear() + lengthObject.value);

    endDate.value = date.toISOString();
    endDate.text = this.datePipe.transform(date, 'MMM dd, yyyy');

    this.licenseForm.get('startDate').setValue(new Date(startDate.value));
    this.licenseForm.get('startDate').updateValueAndValidity();
    this.licenseForm.get('endDate').setValue(new Date(endDate.value));
    this.licenseForm.get('endDate').updateValueAndValidity();
    this.licenseForm.get('licenseLength').setValue(length.value);
    this.licenseForm.get('licenseLength').updateValueAndValidity();

    this.updateFieldsSummary('startDate',startDate.text,startDate.value,true);
    this.updateFieldsSummary('endDate',endDate.text,endDate.value,true);
    this.updateFieldsSummary('licenseLength',length.text,length.value,true);
  }

  LicenseTypeSelectet(eventTarget: string) {
    /**
     * Based on license type selected, show project license settings or enterprise license settings; and change requirement settings for these fields
     * Input string for 'project' or 'enterprise'
     */
    this.fieldsPerLicenseType[eventTarget].forEach(f => {
      this.setRequired(f.required,f.name);
      if(f.showSummary) this.showFieldSummary(f.name)
        else this.hideFieldSummary(f.name);
      if(f.summaryVal !== undefined) {
        this.updateFieldsSummary(f.name,f.summaryText,f.summaryVal,f.required)
        this.licenseForm.get(f.name).patchValue(f.summaryVal);
        this.licenseForm.get(f.name).updateValueAndValidity();
      } else if(eventTarget==='EnterpriseLicense' && f.name==='deployment') {
        this.updateFieldsSummary(f.name,this.enterpriseDeployment.text,this.enterpriseDeployment.val,f.required);
      }
    });

    this.FreeAccordion.closeAccordion();
    this.UsageAccordion.closeAccordion()
    this.ProjectAccordion.closeAccordion();
    this.EnterpriseAccordion.closeAccordion();


    switch(eventTarget) {
      case 'ProjectLicense':
        this.usesUpdated(-1); //TODO: check if this is right
        this.ProjectAccordion.openAccordion();
        if(this.licenseFormSettings['numberOfUsers'].options.some(o=>o.value === -1)) {
          this.licenseFormSettings['numberOfUsers'].options = this.licenseFormSettings['numberOfUsers'].options.filter(o=>o.value !== -1);
        }
        // console.log(this.licenseFormSettings['numberOfUsers'].options);
        this.setLicenseDates(true);
        break;
      case 'UsageLicense':
        this.UsageAccordion.openAccordion();
        this.usesUpdated(this.licenseForm.get('uses').value || null);
        this.setLicenseDates(true);
        break;
      case 'FreeLicense':
        this.setLicenseDates(true);
        this.FreeAccordion.openAccordion();
        break;
      case 'EnterpriseLicense':
        this.setEnterpriseDates();
        // if(this.licenseFormSettings['numberOfUsers'].options.every(o=>o.value !== -1)) {
        //   this.licenseFormSettings['numberOfUsers'].options.push({value: -1,text:'Unlimited Users'});
        // }
        this.licenseForm.get
        this.EnterpriseAccordion.openAccordion();
        break;
      default:
        break;
    }

    let chosenOption = this.licenseFormSettings['licenseType'].options.find(o => o.value === eventTarget);
    if(chosenOption) {
      this.updateFieldsSummary('licenseType',chosenOption.text,chosenOption.value,true);
    }
  }


  submitLicense() {
    this.notif.hide();
    this.loading = true;

    let messageBody = {
      productCategoryId: "rest_generator", //this.licenseForm.value['productCode'],
      start: new Date(this.fieldsSummary['startDate'].value).toISOString().split('.')[0]+"Z",
      licenseParams: {
        type: this.fieldsSummary['licenseType'].value,
      },
    }

    if(this.licenseForm.value['licenseType'] !== 'UsageLicense') {
      messageBody['end'] = new Date(this.fieldsSummary['endDate'].value).toISOString().split('.')[0]+"Z";
    }
    // TODO: replace with switch-case
    switch(this.licenseForm.value['licenseType']) {
      case 'ProjectLicense':
        messageBody.licenseParams['maxUsers'] = this.fieldsSummary['numberOfUsers'].value;
        break;
      case 'UsageLicense':
        messageBody.licenseParams['maxUses'] = this.fieldsSummary['uses'].value;
        break;
      case 'EnterpriseLicense':
        // messageBody.licenseParams['maxUsers'] = this.fieldsSummary['numberOfUsers'].value;
        messageBody.licenseParams['maxUsers'] = -1;
        messageBody.licenseParams['onPremExecutionId'] = this.uuidv4();
        break;
      case 'FreeLicense':
        break;
      default:
        break;
    }

    if(this.licenseForm.value['poNumber']) messageBody['purchaseOrder'] = this.licenseForm.value['poNumber'];
    if(this.licenseForm.value['notes']) messageBody['notes'] = this.licenseForm.value['notes'];
    if(this.licenseForm.value['invoiceNumber']) messageBody['invoiceNumber'] = this.licenseForm.value['invoiceNumber'];

    this.apiService.createLicense(this.companyId,messageBody).subscribe({
      next: result =>{
        this.loading = false;
        // TODO: replace notification with custom modal
        // TODO: close form button (clear and close)
        // TODO: ok button (to just close modal)
        // TODO: clear form - to clear form, but not close
        this.notif.success('License Created','New license was created for company: ' + this.companyName + '.',FormResultMessageFormat.popup,6000,null);
        setTimeout(() => {
          setTimeout(() => {
            this.closeForm.emit(true);
            this.productService.fireLicenseCreated(this.companyId);
          }, 100);

        }, 100);

      },
      error: err => {
        this.loading = false;
        this.formService.formError(err, this.licenseForm, this.licenseFormSettings, null);
      }
    });
  }

  private search(a, v) {
    if(a[0]['value'] > v['value']) {
        return 0;
    }
    var i=1;
    while (i<a.length && a[i]['value']!==-1 && !(a[i]['value'] > v['value'] && a[i-1]['value'] <= v['value'])) {
        i=i+1;
        }
    return i;
  }

  protected addCustomUsesNumber(value: any): void {
    if(!this.licenseFormSettings['uses'].options.find(x => x.value === +value)) {
      let optionText = `${value} uses`;

      this.licenseFormSettings['uses'].options.splice(this.search(this.licenseFormSettings['uses'].options,{value: +value, text: optionText}),0,{text: optionText,value: +value});
    }
    this.licenseForm.get('uses').setValue(+value);
    this.usesUpdated(+value);
  }

  protected addCustomTeamSize(value: any): void {
    if(!this.licenseFormSettings['numberOfUsers'].options.find(x => x.value === +value)) {
      let optionText = `${value} Users`;
      this.licenseFormSettings['numberOfUsers'].options.splice(this.search(this.licenseFormSettings['numberOfUsers'].options,{value: +value, text: optionText}),0,{text: optionText,value: +value});
    }
    this.licenseForm.get('numberOfUsers').setValue(+value);
    this.usersUpdated(+value);
  }

  protected daysToLicenseStart(): string {
    let startDate = this.fieldsSummaryNew.find(f=>f.name === 'startDate').value;
    return formatDistance(new Date(startDate), new Date());
  }

  protected checkIfLicenseInFuture(): boolean {
    let startDate = this.fieldsSummaryNew.find(f=>f.name === 'startDate').value;
    return startDate && new Date() < new Date(startDate);
  }

  protected checkIfLicenseInPast(): boolean {
    let endDate = this.fieldsSummaryNew.find(f=>f.name === 'endDate').value;
    return endDate && new Date() > new Date(endDate);
  }

  AddCutomGenerations(event: any): void {

    if(!this.licenseFormSettings['uses'].options.includes(event)) {
      let index = 0;
      this.licenseFormSettings['uses'].options.forEach(el => {
        if(event > el.value) index = this.licenseFormSettings['uses'].options.indexOf(el);
      })
      this.licenseFormSettings['uses'].options.splice(index,0,{
        value: event,
        text: `${event} Generations`
      });
      this.licenseForm.patchValue({'uses':event});
    }

  }

  confirmBeforeSubmit(): void {
    this.notif.info("Confirm Submission","",FormResultMessageFormat.modalHtml,0,this.submitConfirmation,true,NotificationPosition.bottom,this.submitConfirmationFooter);
  }


  cancel() {
    this.closeForm.emit();
  }

  private uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

}
