<p>styleguide works!</p>
<br><br><br>
<accordion [isAnimated]="true">
    <accordion-group>
        <div accordion-heading>
            Headings
        </div>
        <div>
            <h1>Heading 1 (H1)</h1>
            <h2>Heading 2 (H2)</h2>
            <h3>Heading 3 (H3)</h3>
            <h4>Heading 4 (H4)</h4>
            <h5>Heading 5 (H5)</h5>
        </div>
    </accordion-group>
    <accordion-group>
        <div accordion-heading>
            Fonts
        </div>
        <div>

        </div>
    </accordion-group>
    <accordion-group>
        <div accordion-heading>
            Colors
        </div>
        <div>
            
        </div>
    </accordion-group>
    <accordion-group>
        <div accordion-heading>
            Buttons
        </div>
        <div>
            <button class="primary">Primary</button>
            <button class="secondary">Secondary</button>
            <button class="text-button">Text Button</button>
            <button class="icon-button">+</button>
        </div>
    </accordion-group>
    <accordion-group>
        <div accordion-heading>
            Input Fieleds
        </div>
        <div>
            
        </div>
    </accordion-group>
    <accordion-group>
        <div accordion-heading>
            Logos
        </div>
        <div>
            
        </div>
    </accordion-group>

    
    
</accordion>