<div class="side-modal-backdrop" (click)="cancel()"></div>


<div class="popup-summary" [class.active]="summaryPopupActive" [class.closed]="summaryPopupClosed" (click)="toggleSummaryPopup()">

  <div class="summary-inner">
    <div class="summary-button">
      <p>
        <span>Form Summary</span>
        <span class="close-button-container">
          <button title="close form" class="popup-summary-close-button  ">&#xab;</button>
        </span>
      </p>

    </div>

    <lib-form-summary
      [title]="productName"
      [subTitle]="'Create License'"
      [fields]="fieldsSummaryNew"
      [leftBorder]="true">
    </lib-form-summary>

  </div>
</div>


<div *ngIf="licenseForm" class="side-form-container" [fullScreen]="true" [libLoading]="loading">
  <form id="licenseForm" stickyScroll scrollContainerId="licenseContainer" bottomOffset="10" novalidate (submit)="confirmBeforeSubmit()" [formGroup]="licenseForm">
    <div class="scrollable-fields-container">
      <h2>{{productName}}</h2>
      <h3><span class="action">{{action}}</span> a License for <span class="company-name">{{companyName}}</span></h3>

      <div class="fields-group blue-background">
        <lib-form-field [noBottomMargin]="true" (dropdownChanged)="UpdateProduct($event.value)" [formGroupParameter]="licenseForm" [fieldSettings]="licenseFormSettings['productCode']"></lib-form-field>
      </div>

      <div [hidden]="!productCode" class="fields-group blue-background">

        <lib-form-field [inlineLayout]="false" [minBottomMargin]="true" (dropdownChanged)="LicenseTypeSelectet($event.value)" [formGroupParameter]="licenseForm" [fieldSettings]="licenseFormSettings['licenseType']"></lib-form-field>

        <lib-accordion [insideGroup]="true" #UsageAccordion [accordionBody]="UsageAccordionBody" [hideHeader]="true"></lib-accordion>

        <lib-accordion [insideGroup]="true" #FreeAccordion [accordionBody]="FreeAccordionBody" [hideHeader]="true"></lib-accordion>

        <lib-accordion [insideGroup]="true" #ProjectAccordion [accordionBody]="ProjectAccordionBody" [hideHeader]="true"></lib-accordion>

        <lib-accordion [insideGroup]="true" #EnterpriseAccordion [accordionBody]="EnterpriseAccordionBody" [hideHeader]="true"></lib-accordion>

      </div>

      <ng-template #UsageAccordionBody>
        <div class="accordion-content-header">
          <h4>Usage License</h4>
          <p class="input-instruction">Usage License is based on numer of uses of the product with no time restriction</p>

        </div>

        <ng-container *ngTemplateOutlet="startDateTemplate"></ng-container>

        <ng-container *ngTemplateOutlet="usesTemplate"></ng-container>

      </ng-template>

      <ng-template #FreeAccordionBody>
        <div class="top-margin">
          <h4>Free License</h4>
          <p class="input-instruction">Free License is given to Beta Testers; it has start and end dates.</p>

          <ng-container *ngTemplateOutlet="startDateTemplate"></ng-container>

          <ng-container *ngTemplateOutlet="endDateTemplate"></ng-container>

        </div>
      </ng-template>


      <ng-template #ProjectAccordionBody>
        <div class="top-margin">

          <h4>Project License</h4>
          <p class="input-instruction">Project License type requires selecting Number of Generations and Project Length.</p>

          <ng-container *ngTemplateOutlet="startDateTemplate"></ng-container>

          <ng-container *ngTemplateOutlet="endDateTemplate"></ng-container>

          <ng-container *ngTemplateOutlet="teamTemplate"></ng-container>

          <p class="field-display-value">
            <span class="label">Deployment</span>
            <span class="value">Cloud</span>
          </p>

        </div>
      </ng-template>

      <ng-template #EnterpriseAccordionBody>
        <div class="accordion-content-header">
          <h4>Enterprise License</h4>
          <p class="input-instruction">Annual Subscription Only</p>
        </div>

        <!-- <ng-container *ngTemplateOutlet="teamTemplate"></ng-container> -->

        <p class="field-display-value">
          <span class="label">Team size</span>
          <span class="value">Unlimited</span>
        </p>


        <div class="fields-group border">
          <h4>Deployment</h4>

          <div class="btn-group" style="margin: 1em 0;" btnRadioGroup>
            <label class="btn btn-success mb-0" [class.active]="enterpriseDeployment.val==='cloud'" btnRadio="Cloud" value="cloud" (click)="toggleDeployment('cloud','Cloud')">Cloud</label>
            <label class="btn btn-success mb-0" [class.active]="enterpriseDeployment.val=='onPrem'" btnRadio="On Prem" value="onPrem" (click)="toggleDeployment('onPrem','On Prem')">On Prem</label>
          </div>
        </div>

      </ng-template>

      <div [hidden]="!productCode" class="top-margin">

        <lib-form-field [inlineLayout]="false" [minBottomMargin]="false" [formGroupParameter]="licenseForm" [fieldSettings]="licenseFormSettings['poNumber']"></lib-form-field>

        <lib-form-field [inlineLayout]="false" [minBottomMargin]="false" [formGroupParameter]="licenseForm" [fieldSettings]="licenseFormSettings['invoiceNumber']"></lib-form-field>

        <lib-form-field [inlineLayout]="false" [minBottomMargin]="false" [formGroupParameter]="licenseForm" [fieldSettings]="licenseFormSettings['notes']"></lib-form-field>

      </div>

    </div>

    <div class="form-group bottom-absolute">
      <button (click)="cancel()" type="button" class="secondary">Cancel</button>
      <button [disabled]="!licenseForm.valid" class="primary" type="submit">Submit</button>
  </div>

  </form>
</div>


<!-- *Form field groups templates -->

<ng-template #startDateTemplate>
  <div class="fields-group border">
    <h4>License Start Date</h4>

    <div class="btn-group" style="margin: 1em 0;" btnRadioGroup>
      <label class="btn btn-success mb-0" [class.active]="!customStartDate" btnRadio="asap" value="asap" (click)="toggleStartDateValue('asap')">ASAP</label>
      <label class="btn btn-success mb-0" [class.active]="customStartDate" btnRadio="custom" value="custom" (click)="toggleStartDateValue('custom')">Custom</label>
    </div>

    <div [class.hidden]="customStartDate">
      <p class="value">Start Project <strong>ASAP</strong></p>
    </div>

    <div [class.hidden]="!customStartDate">
      <div [class.valid]="licenseForm.get('startDate').valid && licenseForm.get('startDate').touched" class="column-content" [class.not-active]="!customStartDate">
        <lib-form-field [noWeekend]="false" [disableUsHolidays]="false" [noMinDate]="true" [noMaxDate]="true" (blurEvent)="customStartDateUpdated()" [daysFromToday]="maxStartDateDays" [small]="true" [inlineLayout]="true" [noBottomMargin]="true" [formGroupParameter]="licenseForm" [fieldSettings]="licenseFormSettings['startDate']"></lib-form-field>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #endDateTemplate>
  <div class="fields-group border">

    <h4>License Ends</h4>

    <div class="btn-group" style="margin: 1em 0;" btnRadioGroup>
      <label class="btn btn-success mb-0" [class.active]="lengthType === 'predefined'" btnRadio="predefined" value="predefined" (click)="toggleProjectLengthValue('predefined')">Predefined List</label>
      <label class="btn btn-success mb-0" [class.active]="lengthType === 'custom'" btnRadio="custom" value="custom" (click)="toggleProjectLengthValue('custom')">Custom Term</label>
      <label class="btn btn-success mb-0" [class.active]="lengthType === 'endDate'" btnRadio="endDate" value="endDate" (click)="toggleProjectLengthValue('endDate')">End Date</label>
    </div>

    <div [class.hidden]="lengthType !== 'predefined'">
      <lib-select-form-field [inlineLayout]="true" (dropdownChanged)="setLicenseDates()" [noBottomMargin]="true" [formGroupParameter]="licenseForm" [fieldSettings]="licenseFormSettings['licenseLength']"></lib-select-form-field>
    </div>

    <div [class.hidden]="lengthType !== 'endDate'">
      <div [class.valid]="licenseForm.get('endDate').valid && licenseForm.get('endDate').touched" class="column-content" [class.not-active]="lengthType === 'endDate'">
        <!-- <lib-form-field [noWeekend]="false" [minDate]="licenseFormSettings['endDate'].dateOptions.minDate" [disableUsHolidays]="false" (blurEvent)="customStartDateUpdated()" [daysFromToday]="maxStartDateDays" [small]="true" [inlineLayout]="true" [noBottomMargin]="true" [formGroupParameter]="licenseForm" [fieldSettings]="licenseFormSettings['endDate']"></lib-form-field> -->
        <lib-form-field [noWeekend]="false" [noMinDate]="true" [disableUsHolidays]="false" (blurEvent)="customStartDateUpdated()" [daysFromToday]="maxStartDateDays" [small]="true" [inlineLayout]="true" [noBottomMargin]="true" [formGroupParameter]="licenseForm" [fieldSettings]="licenseFormSettings['endDate']"></lib-form-field>
      </div>
    </div>

    <div [class.hidden]="lengthType !== 'custom'">
      <lib-form-field [value]="licenseForm.value['lengthValue']" (onKey)="customProjectValue()" (blurEvent)="customProjectValue()" (onChange)="customProjectValue()" [minNumber]="lengthValueMin" [maxNumber]="lengthValueMax" [inlineLayout]="true" [noBottomMargin]="true" [formGroupParameter]="licenseForm" [fieldSettings]="licenseFormSettings['lengthValue']"></lib-form-field>
    </div>


  </div>
</ng-template>

<ng-template #usesTemplate>
  <div class="fields-group border">
    <lib-select-form-field
        [addValueUseCustomFunction]="true"
        (dropdownChanged)="usesUpdated($event.value)"
        (valueAdded)="addCustomUsesNumber($event)"
        [customValue]="true"
        [noBottomMargin]="true"
        [addValueButtonText]="'Add & Select'"
        [textFieldSettings]="licenseFormSettings['usesFreeField']"
        [formGroupParameter]="licenseForm"
        [fieldSettings]="licenseFormSettings['uses']">
    </lib-select-form-field>
  </div>
</ng-template>

<ng-template #teamTemplate>
  <div class="fields-group border">
    <h4>Team size</h4>
    <lib-select-form-field
      (dropdownChanged)="usersUpdated($event.value)"
      [inlineLayout]="true"
      [noBottomMargin]="true"
      [formGroupParameter]="licenseForm"
      [fieldSettings]="licenseFormSettings['numberOfUsers']"
      (valueAdded)="addCustomTeamSize($event)"
      [addValueUseCustomFunction]="true"
      [customValue]="true"
      [addValueButtonText]="'Add & Select'"
      [textFieldSettings]="licenseFormSettings['numberOfUsersFreeField']">
    </lib-select-form-field>
  </div>
</ng-template>


<ng-template #submitConfirmation>
  <div class="submit-confirmation">
    <h5>Check the data below and confirm your new license submission.</h5>

    <p>
      <span class="label">Company name</span>
      <span class="value">{{companyName}}</span>
    </p>
    <p>
      <span class="label">Product</span>
      <span class="value">{{productName}}</span>
    </p>

    <ng-container *ngFor="let item of fieldsSummaryNew">
      <p *ngIf="!item.hidden">
        <span class="label">{{item.label}}</span>
        <span *ngIf="!item.html" class="value">{{item.text}}</span>
        <span *ngIf="!!item.html" class="value 2" [innerHTML]="item.text"></span>
      </p>
    </ng-container>

    <p *ngIf="licenseForm.value['poNumber']">
      <span class="label">PO Number</span>
      <span class="value">{{licenseForm.value['poNumber']}}</span>
    </p>

    <p *ngIf="licenseForm.value['invoiceNumber']">
      <span class="label">Invoice Number</span>
      <span class="value">{{licenseForm.value['invoiceNumber']}}</span>
    </p>

    <p class="warning" *ngIf="checkIfLicenseInPast()">Warning: License will be <strong>expired</strong> when created.</p>

    <p class="warning" *ngIf="checkIfLicenseInFuture()">Warning: License starts in the <strong>future</strong>: in <strong>{{daysToLicenseStart()}}</strong>.</p>

    <p *ngIf="!licenseForm.value['poNumber']" class="warning">Warning: <strong>PO Number</strong> is left blank.</p>

    <p *ngIf="!licenseForm.value['invoiceNumber']" class="warning">Warning: <strong>Invoice Number</strong> is left blank.</p>


    <!-- <p>
      <span class="label">License Type</span>
      <span class="value">{{fieldsSummary['licenseType'].text}}</span>
    </p>
    <p>
      <span class="label">Start Date</span>
      <span class="value">{{fieldsSummary['startDate'].text}}</span>
    </p>
    <p *ngIf="fieldsSummary['licenseType'].text !== 'usageLicense'">
      <span class="label">End Date</span>
      <span class="value">{{fieldsSummary['endDate'].text}}</span>
    </p>
    <p *ngIf="fieldsSummary['licenseType'].text !== 'usageLicense'">
      <span class="label">License Length</span>
      <span class="value">{{fieldsSummary['licenseLength'].text}}</span>
    </p> -->
  </div>
</ng-template>

<ng-template #submitConfirmationFooter>
  <div class="bottom-buttons">
    <button type="reset" class="secondary" (click)="notif.hide()">Cancel</button>
    <button type="submit" class="primary" (click)="submitLicense()">Confirm and Submit</button>
  </div>
</ng-template>

