// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  baseUrl: '${REPLACE_BASEURL_PLATFORM}',
  stsData: {
    url: '${REPLACE_STSDATA_URL}',
    clientName:'${REPLACE_STSDATA_CLIENTNAME_PLATFORM}',
    scope: '${REPLACE_STSDATA_SCOPE}'
  },
  projectUrls: {
    platform: '${REPLACE_PROJECTURLS_PLATFORM}',
    generator: '${REPLACE_PROJECTURLS_GENERATOR}',
    tools: '${REPLACE_PROJECTURLS_TOOLS}'
  },
  apiUrl: '${REPLACE_APIURL}',
  supportUrl: '${REPLACE_SUPPORTURL}',
  ga: '${REPLACE_GA_PLATFORM}'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
