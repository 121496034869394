import { Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { faBars, faCog, faDownload, faFingerprint, faHistory, faIdCard, faInfoCircle, faPowerOff, faTh, faTools, faUsers, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'projects/nine-gold-lib/src/lib/services/auth.service';
import { UserInterfaceService } from 'projects/nine-gold-lib/src/lib/services/user-interface.service';
import { UserService } from 'projects/nine-gold-lib/src/lib/services/user.service';
import { ConfirmDialogService } from 'projects/nine-gold-lib/src/public-api';
import { ApiUser, IUser, UserTypesData } from 'projects/nine-gold-lib/src/lib/models/user';
import { ConfirmDialog } from 'projects/nine-gold-lib/src/lib/shared/confirm-dialog/confirm-dialog';
import { animate, group, style, transition, trigger } from '@angular/animations';
import { fadeInAnimation } from 'projects/nine-gold-lib/src/lib/animations/fade-in.animation';
import { NgNavigation, NgNavigationApps } from 'projects/nine-gold-lib/src/lib/shared/ng-navigation/ng-navigation';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { AppSettingsService } from 'projects/nine-gold-lib/src/lib/services/app-settings.service';

const slideInOut  = trigger(
  'enterAnimation', [
    transition(':enter', [
      style({opacity: 0, height: 0, overflow: 'hidden'}),
      group([
        animate('.01s 0.5s', style({height: '*', overflow: 'visible'})),
        animate('.5s .51s', style({opacity: 1}))
      ]),

    ]),
    transition(':leave', [
      style({opacity: 1}),
      animate('.5s', style({opacity: 0}))
    ])
  ]
);

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInAnimation,slideInOut]
})
export class UserComponent implements OnInit {
  faHelpIcon = faInfoCircle;
  faAppsIcon = faTh;
  supportIcon = faTools;
  navIcons = {};


  faPowerOffIcon = faPowerOff;
  faSecurityIcon = faFingerprint;
  faSettingsIcon = faCog;
  faLicenseIcon = faIdCard;
  faSubusersIcon = faUsers;

  userInfo: ApiUser;
  userPermissions: {};
  userLoggedIn: boolean;
  userLinks: NgNavigation[];

  userRoleIcon: UserTypesData;

  @ViewChild('leftSidebar') leftSidebar: ElementRef;
  @ViewChild('sidebarToggleButton') sidebarToggleButton: ElementRef;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.uiService.sideMenuWide = (event.target.innerWidth > 800);
  }

  @HostListener('document:click', ['$event'])
  onGlobalClick(event): void {
    if (!this.leftSidebar.nativeElement.contains(event.target)
      && !this.sidebarToggleButton.nativeElement.contains(event.target)) {
      this.closeSidebar();
    }
  }

  infoNavLinks: NgNavigation[];

  constructor(
    private authService: AuthService,
    public uiService: UserInterfaceService,
    public userService: UserService,
    private confirmService: ConfirmDialogService,
    public router: Router,
    private appSettings: AppSettingsService
    ) { }

  ngOnInit(): void {
    this.navIcons  = {
      'user-info': {
        icon: faCog
      },
      'user-license': {
        icon: faIdCard
      },
      'user-subusers': {
        icon: faUsers
      },
      'user-security': {
        icon: faFingerprint
      },
      'global-admin': {
        icon: faUserShield
      },
      'actions': {
        icon: faHistory
      },
      'uses': {
        icon: faDownload
      },

    };
    this.userInfo = this.userService.getUserInfo();
    this.userPermissions = this.userService.getPermissions();
    this.userRoleIcon = this.userService.getHighestRole();
    this.userService.userUpdated.subscribe(
      (user) => {
        this.userInfo = this.userService.getUserInfo();
        this.userLoggedIn = this.userService.userLoggedIn();
        this.userPermissions = this.userService.getPermissions();
        this.userRoleIcon = this.userService.getHighestRole();
      }
    );
    this.userLoggedIn = this.userService.userLoggedIn();
    this.infoNavLinks = this.appSettings.getInfoLinks();
  }

  Logout() {
    this.confirmService.confirm(this.uiService.getConfirmMessage('userLogout'))
      .subscribe((answer) => {
        if(answer) {
          this.authService.new_logout();
        }
      }
    );
  }

  closeSidebar(): void {
    this.uiService.sideMenuWide = !(window.innerWidth <= 800 || !this.uiService.sideMenuWide)
  }

}
