<section class="space-filler blue-gradient"></section>

<ng-template #modal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Password changed</h4>
    </div>
    <div class="modal-body">
        <p>Your password was changed, please log in again with a new password.</p>
        <button class="primary" (click)="Login()">Login</button>
    </div>
</ng-template>