import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'lib-scroll-to-top',
  styleUrls: ['./scroll-to-top.component.scss'],
  templateUrl: './scroll-to-top.component.html',
  styles: [
  ]
})
export class ScrollToTopComponent implements OnInit {
  windowScrolled: boolean;
  scrolledBottom: boolean;
  scrollIcon = faChevronUp;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private viewportScroller: ViewportScroller
    ) { }

  ngOnInit(): void {
  }
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) > 200) {
      this.windowScrolled = true;
    } 
    else if ((this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) < 100) {
      this.windowScrolled = false;
    }
    if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) > (document.body.offsetHeight - window.innerHeight - 100) ) {
      this.scrolledBottom = true;
    } else {
      this.scrolledBottom = false;
    }
    // console.log((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) > (document.body.offsetHeight - window.innerHeight - 100));
    // console.log("scroll: " + window.pageYOffset + ", body height: " + (document.body.offsetHeight - window.innerHeight - 100));
  }
  scrollToTop() {
    this.viewportScroller.scrollToPosition([0,0]);
    // (function smoothscroll() {
    //   var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
    //   if (currentScroll > 0) {
    //     // window.requestAnimationFrame(smoothscroll);
    //     window.scrollTo(0, 0);
    //   }
    // })();
  }

}
