import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-tabs-shared',
  templateUrl: './tabs-shared.component.html',
  styleUrls: ['./tabs-shared.component.scss']
})
export class TabsSharedComponent implements OnInit {
  @ViewChild(TemplateRef)
  sharedContent: TemplateRef<any>;

  constructor() { }

  ngOnInit() {
  }

}
