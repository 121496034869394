import { Injectable } from '@angular/core';
import { AppSettingsService } from 'projects/nine-gold-lib/src/lib/services/app-settings.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NineGoldSettingsService extends AppSettingsService {

  env = environment;

  constructor() {
    super();

    this.baseSetting = this.env;

    this.setNavigations(
      this.baseSetting.projectUrls.platform,
      this.baseSetting.projectUrls.generator,
      this.baseSetting.projectUrls.tools
      );

    this.UpdateLocalLinks(this.productLinks,this.baseSetting.baseUrl);
    this.UpdateLocalLinks(this.topNavLinks,this.baseSetting.baseUrl);
    this.UpdateLocalLinks(this.footerLinks,this.baseSetting.baseUrl);
    this.UpdateLocalLinks(this.userNavLinks,this.baseSetting.baseUrl);
    this.UpdateLocalLinks(this.infoNavLinks,this.baseSetting.baseUrl);
    this.UpdateLocalLinks(this.solutionsLinks,this.baseSetting.baseUrl);
    this.UpdateLocalLinks(this.resourcesLinks,this.baseSetting.baseUrl);
    this.topNavLinks = this.RemoveLinks(this.topNavLinks, this.baseSetting.baseUrl);
  }
}
