import { ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UserInterfaceService } from 'projects/nine-gold-lib/src/lib/services/user-interface.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements AfterViewInit {

  constructor(
    private viewportScroller: ViewportScroller,
    private uiService: UserInterfaceService
    ) { }

  ngAfterViewInit(): void {
    this.viewportScroller.setOffset([0,90]);
    setTimeout(() => {
      this.uiService.minHeader = true;  
    }, 100);
  }

  public onClick(elementId: string): void { 
    this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnDestroy(): void {
    this.uiService.minHeader = false;
  }

}
