import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { InviteAndCompanyDetails } from 'projects/nine-gold-lib/src/lib/models/invites';
import { AppSettingsService } from 'projects/nine-gold-lib/src/lib/services/app-settings.service';
import { AuthService } from 'projects/nine-gold-lib/src/lib/services/auth.service';
import { UserService } from 'projects/nine-gold-lib/src/lib/services/user.service';

@Component({
  selector: 'app-invitation-response',
  templateUrl: './invitation-response.component.html',
  styleUrls: ['./invitation-response.component.scss']
})
export class InvitationResponseComponent implements OnInit {
  @ViewChild('modal', { static: false }) autoShownModal: ModalDirective;
  modalRef: BsModalRef;

  inviteDetails: InviteAndCompanyDetails;
  inviteCode: string;
  code: string;
  companyName: string;
  invitee: string;
  inviter: string;

  userLoggedIn: boolean;
  inviteeLoggedIn: boolean;

  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private http: HttpClient,
    private settings: AppSettingsService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.GetInvite();
    this.route.params.subscribe(params => {
      this.code = params['code'];
      this.companyName = params['company-name'];
      this.invitee = params['invitee-email'];
      this.inviter = params['inviter-name'];
    });
    if(this.userService.userLoggedIn()) {
      this.userLoggedIn = true;
      this.inviteeLoggedIn = this.userService.currentUser.email === this.invitee;
    } else {
      this.userService.userUpdated.subscribe(user => {
        console.log('user updated')
        if(user) {
          this.userLoggedIn = true;
          this.inviteeLoggedIn = user.email === this.invitee;
        }
      });
    }
    // this.route.queryParams.subscribe(params => {
    //   this.code = params['code'];
    //   this.companyName = params['cn'];
    //   this.invitee = params['ie'];
    //   this.inviter = params['in'];
    // });
    // TODO: get new API from Dave
    // this.http.get(this.settings.getApi('inviteDetails',this.code).replace(/mine/gi, '9a13f60a-2c5b-45e2-bd9f-b36a7e928883')).subscribe(result => {
    //   console.log(result);
    // });
  }

  ngAfterViewInit() {
    // this.modalRef = this.modalService.show(this.autoShownModal, {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true });
  }

  GetInvite(): void {

  }

  LogOut(): void {
    this.authService.new_logout();
  }

  DeclineInvite():void {
    // TODO: send API call to decline invitation
    // TODO: notification
    // TODO: Redirect to homepage
  }

  GoAcceptDeclime(): void {
    let link = this.authService.getInvitaionResponseLink(this.code,this.companyName);
    window.location.href = link;
  }

  Login():void {
    let link = this.authService.getInviteLoginLink(this.code, this.companyName);
    window.location.href = link;
  }

  Register():void {
    let link = this.authService.getInviteRegistrationLink(this.code, this.companyName,this.invitee);
    window.location.href = link;
  }

}
