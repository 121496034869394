import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterContentChecked, AfterContentInit, Component, ContentChild, ContentChildren, QueryList, TemplateRef, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { delay, map, startWith } from 'rxjs/operators';
import { TabItemComponent } from '../tab-item/tab-item.component';
import { TabsSharedComponent } from '../tabs-shared/tabs-shared.component';

const slideInOut  = trigger(
  'slideInOut', [
    transition(':enter', [
      style({transform: 'translateX(+100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))

    ]),
    transition(':leave', [
      style({opacity: 1}),
      animate('.5s', style({opacity: 0}))
    ])
  ]
);

@Component({
  selector: 'app-tabs-list',
  templateUrl: './tabs-list.component.html',
  styleUrls: ['./tabs-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateX({{from}}%)', opacity: 0, position: "absolute", top: 0, left: 0, width: '100%'}),
        animate('.25s .26s ease-out', style({top: 'auto', position: 'relative', left: 'auto', width: 'auto', transform: 'translateX(0%)', opacity: 1})),
      ], {params : { from: "20", to: "-20" }}),
      transition(':leave',[
        style({transform: 'translateX(0%)', opacity: 1}),
        animate('.25s ease-in', style({transform: 'translateX({{to}}%)', opacity: 0}))
      ], {params : { from: "20", to: "-20" }})
    ]),
    trigger('animationOption2', [
      transition(':enter', [
        style({ backgroundColor: 'yellow' }),
        animate(300)
      ]),
      transition(':leave', [
        animate(300, style({ backgroundColor: 'yellow' }))
      ]),
      state('*', style({ backgroundColor: 'green' })),
    ])
  ]
})
export class TabsListComponent implements AfterContentInit, AfterContentChecked {
  @ContentChildren(TabItemComponent)
  tabs: QueryList<TabItemComponent>;

  @ContentChild(TemplateRef) itemWrapper: TemplateRef<any>;

  @ContentChild(TabsSharedComponent)
  tabShared: TabsSharedComponent;

  showShared = false;

  tabSharedContent: TemplateRef<any>;

  tabItems$: Observable<TabItemComponent[]>;

  activeTab: TabItemComponent;

  fromAnimation: number = 0;
  toAnimation: number = 0;

  switchingTabs = false;

  constructor() { }


  ngAfterContentInit(): void {

    if(this.tabShared) {
      this.itemWrapper = this.tabShared.sharedContent;
    }
    this.tabItems$ = this.tabs.changes
      .pipe(startWith(""))
      .pipe(delay(0))
      .pipe(map(() => this.tabs.toArray()));

      setTimeout(() => {
        this.showShared = true;
      }, 200);

  }

  ngAfterContentChecked() {
    //choose the default tab
    // we need to wait for a next VM turn,
    // because Tab item content, will not be initialized yet
    if (!this.activeTab) {
      Promise.resolve().then(() => {
        this.activeTab = this.tabs.first;
      });
    }
  }

  setFromTo(switchToRight: boolean) {
    if(switchToRight) {
      this.fromAnimation = 20;
      this.toAnimation = -20;
    } else {
      this.fromAnimation = -20;
      this.toAnimation = 20;
    }
  }

  getTabNumber(tabItem: TabItemComponent): number {
    return this.tabs.toArray().indexOf(tabItem);
  }

  selectTab(tabItem: TabItemComponent) {
    if (this.activeTab === tabItem) {
      return;
    }

    let leftToRight = this.getTabNumber(tabItem) > this.getTabNumber(this.activeTab);
    this.setFromTo(leftToRight);

    setTimeout(() => {
      if (this.activeTab) {
        this.activeTab.isActive = false;
      }

      this.activeTab = tabItem;
      this.switchingTabs = true;
      setTimeout(() => {
        this.switchingTabs = false;
      }, 450)

      tabItem.isActive = true;
    }, 100);


  }

}
